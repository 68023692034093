// React imports
import React from 'react';
// External imports
import {Box, TableCell, TableHead, TableRow} from '@mui/material';

export default function SalesTableHead({ webTransactions }) {

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" sx={{ fontSize: 13 }}>Date de transaction&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>Nom du client&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>Description&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>Total&nbsp;</TableCell>
        {webTransactions && <TableCell align="center" sx={{ fontSize: 13}}><Box width={125}>No. commande web&nbsp;</Box></TableCell>}
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
