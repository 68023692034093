import useFetchLocationCcavModalities from "@hooks/location-details/fetch-location-ccav-modalities.hook";
import LocationDetail, { DayModality } from "@@types/location-details/location-detail.type";
import { Dispatch, SetStateAction, useState } from "react";
import useEditLocationCcavModality from "@hooks/location-details/put-location-ccav-modality.hook";
import useCreateLocationCcavModality from "@hooks/location-details/post-location-ccav-modality.hook";
import useDeleteLocationCcavModality from "@hooks/location-details/delete-location-ccav-modality.hook";
import useLocationModalityModalValidation from "@components/location/route/modality/modals/hooks/location-modality-modal-validation.hook";
import useNotifier from "@hooks/useNotifier";
import moment from "moment/moment";

export type LocationDetailsData = {
  weekDays: DayModality[];
  locationModalities: LocationDetail[];
  modalityForModal: LocationDetail;
  openModalEditModality: boolean;
  openModalDeleteModality: boolean;
  openModalCreateModality: boolean;
};

export type LocationDetailsHandlers = {
  setModalityForModal: Dispatch<SetStateAction<LocationDetail | undefined>>;
  handleOpenModalityModal: (modalType: string, modality: LocationDetail) => void;
  handleCloseModalityModal: (modalType: string) => void;
  handleEditModality: (modality: LocationDetail) => void;
  handleCreateModality: (modality: LocationDetail) => void;
  handleDeleteModality: (modality: LocationDetail) => void;
};

export type useLocationModalitiesType = {
  data: LocationDetailsData;
  handlers: LocationDetailsHandlers;
};

const dateModalityInitialState = { value: "", active: false, label: "", error: false };

const modalityInitialStates: LocationDetail = {
  matter: { value: "", label: "", error: false },
  until: { ...dateModalityInitialState },
  from: {
    ...dateModalityInitialState,
    value: moment().format("YYYY-MM-DD"),
    active: true
  },
  frequency: { value: 1, label: "", error: false },
  monday: { value: 0, label: "monday", active: false, error: false },
  tuesday: { value: 0, label: "tuesday", active: false, error: false },
  wednesday: { value: 0, label: "wednesday", active: false, error: false },
  thursday: { value: 0, label: "thursday", active: false, error: false },
  friday: { value: 0, label: "friday", active: false, error: false },
  saturday: { value: 0, label: "saturday", active: false, error: false },
  sunday: { value: 0, label: "sunday", active: false, error: false },
};

const useLocationModalities = (locationId: number): useLocationModalitiesType => {
  const [modalityForModal, setModalityForModal] = useState<LocationDetail>(modalityInitialStates);
  const [openModalEditModality, setOpenModalEditModality] = useState<boolean>(false);
  const [openModalDeleteModality, setOpenModalDeleteModality] = useState<boolean>(false);
  const [openModalCreateModality, setOpenModalCreateModality] = useState<boolean>(false);
  const { data: locationModalities } = useFetchLocationCcavModalities(locationId);
  const { mutate: editLocationModality } = useEditLocationCcavModality(locationId);
  const { mutate: createLocationModality } = useCreateLocationCcavModality(locationId);
  const { mutate: deleteLocationModality } = useDeleteLocationCcavModality(locationId);
  const { valid } = useLocationModalityModalValidation();
  const { enqueueMessage } = useNotifier();

  const handleOpenModalityModal = (modalType: string, modality: LocationDetail): void => {
    setModalityForModal(modality);
    switch (modalType) {
      case "create":
        setOpenModalCreateModality(true);
        break;
      case "update":
        setOpenModalEditModality(true);
        break;
      case "delete":
        setOpenModalDeleteModality(true);
        break;
    }
  };

  const handleCloseModalityModal = (modalType: string): void => {
    setModalityForModal(modalityInitialStates);
    switch (modalType) {
      case "create":
        setOpenModalCreateModality(false);
        break;
      case "update":
        setOpenModalEditModality(false);
        break;
      case "delete":
        setOpenModalDeleteModality(false);
        break;
    }
  };

  const handleEditModality = (modality: LocationDetail): void => {
    const isValid = valid(modality, weekDays, setModalityForModal);
    if (isValid) {
      editLocationModality(modality).then(() => handleCloseModalityModal("update"));
      enqueueMessage("Modalité modifier avec succès.", "success");
    } else {
      enqueueMessage("Modalité invalide.", "error");
    }
  };

  const handleCreateModality = (modality: LocationDetail): void => {
    const isValid = valid(modality, weekDays, setModalityForModal);
    if (isValid) {
      createLocationModality(modality).then(() => handleCloseModalityModal("create"));
      enqueueMessage("Modalité créer avec succès.", "success");
    } else {
      enqueueMessage("Modalité invalide.", "error");
    }
  };

  const handleDeleteModality = (modality: LocationDetail): void => {
    deleteLocationModality(modality).then(() => handleCloseModalityModal("delete"));
    enqueueMessage("Modalité supprimer avec succès.", "success");
  };

  const weekDays = [
    modalityForModal.monday,
    modalityForModal.tuesday,
    modalityForModal.wednesday,
    modalityForModal.thursday,
    modalityForModal.friday,
    modalityForModal.saturday,
    modalityForModal.sunday,
  ];

  const data = {
    weekDays,
    locationModalities,
    modalityForModal,
    openModalEditModality,
    openModalDeleteModality,
    openModalCreateModality,
  };

  const handlers = {
    setModalityForModal,
    handleOpenModalityModal,
    handleCloseModalityModal,
    handleEditModality,
    handleDeleteModality,
    handleCreateModality,
  };

  return {
    data,
    handlers,
  } as useLocationModalitiesType;
};

export default useLocationModalities;
