// React imports
import React, { memo, useCallback } from "react";

// Internal imports
import { formattedDate } from "@utils/dates";

// External iports
import Select, { components } from "react-select";
import { isEqual } from "lodash";
import { Stack, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// const controlStyles = {
//   padding: 0,
//   color: "black",
//   margin: 0,
// };

// const ControlComponent = (props) => (
//   <div style={controlStyles}>
//     <div>Unité (facultatif)</div>
//     <components.Control {...props} />
//   </div>
// );

function DateUnitSelects({ date, unit, units, handlers }) {
  // console.log("[DateUnitSelects] >>> RENDERED");

  const handleChangeDate = useCallback((date) => {
    date && handlers.setSingleCurrent("date", formattedDate(date));
  }, []);

  const handleChangeUnit = useCallback((unit) => {
    unit && handlers.setSingleCurrent("unit", unit);
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
      <DesktopDatePicker
        label="Date"
        value={date}
        onChange={(date) => handleChangeDate(date)}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ flex: 1, padding: 0 }}
            sx={{
              ".MuiInputBase-input": {
                padding: "0 0 0 25px",
                height: 42,
              },
            }}
          />
        )}
      />

      <Select
        // components={{ Control: ControlComponent }}
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({ ...base, height: 42 }),
        }}
        value={unit.value ? unit : null}
        menuPosition="fixed"
        onChange={(unit) => handleChangeUnit(unit)}
        options={units.data}
        placeholder={"Sélectionner une unité"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { date: prevDate, unit: prevUnit, units: prevUnits } = prevProps;
  const { date: nextDate, unit: nextUnit, units: nextUnits } = nextProps;
  let arePropsEqual = true;

  // If the units array changed, rerender
  if (!isEqual(prevUnits, nextUnits)) {
    arePropsEqual = false;
  }

  // If the current unit changed, rerender
  if (!isEqual(prevUnit, nextUnit)) {
    arePropsEqual = false;
  }

  // If the current date changed, rerender
  if (!isEqual(prevDate, nextDate)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(DateUnitSelects, areEqual);
