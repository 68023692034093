// React imports
import React, { useState, useEffect } from "react";
// Internal imports
import { handleSortFunctional } from "@utils/filtering";
import TextFieldUi from "@ui/TextField";
import TableLoading from "@ui/TableLoading";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import useAsync from "@hooks/useAsync";
import { withRouter } from "@utils/withRouter";
import * as API from "@services";
// External imports
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Table, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import { Trans } from "react-i18next";

const headerLabel = {
  startDate: { label: <Trans i18nKey="delivery_notes.date" /> },
  name: { label: <Trans i18nKey="jobs_history.name" /> },
  kind: { label: <Trans i18nKey="kind" /> },
  status: { label: <Trans i18nKey="jobs_history.status" /> },
  unit: { label: <Trans i18nKey="units.title" /> },
};

const header = ["startDate", "name", "kind", "status", "unit"];

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
  columnName: "startDate",
  direction: "desc",
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const handleSearchFilter = (setFilters) =>
  debounce((event) => {
    setFilters((prevState) => ({ ...prevState, filter: event.target.value }));
  }, 500);

const fetchLocationJobsHistoryListAsync = async (setJobs, locationId, filters) => {
  const pageNumber = filters.page + 1;

  const res = await API.Location.fetchLocationJobsHistory(locationId, filters.filter, filters.limit, pageNumber);
  setJobs(res);
};

const handleClickOnCell = (navigate, path) => {
  navigate(path);
};

const LocationJobsHistoryListHeader = ({ filters, setFilters, handleSort }) => {
  return (
    <TableHead>
      <TableRow>
        {header.map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={filters.columnName === name}
              direction={filters.direction}
              onClick={handleSort(name, filters, setFilters)}
            >
              {headerLabel[name].label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const LocationJobsHistoryListBody = ({ jobs, navigate, router }) => {
  return jobs.map((job) => {
    return (
      <TableRow
        key={job.id}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={() =>
          handleClickOnCell(
            navigate,
            `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${job.customerItemId}/jobs/${job.id}`
          )
        }
      >
        <TableCell>{job.startDate}</TableCell>
        <TableCell>{job.name}</TableCell>
        <TableCell>{job.kind}</TableCell>
        <TableCell>{<Trans i18nKey={`jobs.statuses.${job.status.toLowerCase()}`} />}</TableCell>
        <TableCell>{job.unitName}</TableCell>
      </TableRow>
    );
  });
};

function LocationHistoryJobList(props) {
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState(filtersInitialState);
  const navigate = useNavigate();

  const { router } = props;

  useEffect(() => {
    fetchLocationJobsHistoryList.run();
  }, [filters]);

  const fetchLocationJobsHistoryList = useAsync(() =>
    fetchLocationJobsHistoryListAsync(setJobs, router.params.locationId, filters)
  );

  const handlePageChange = (event, pageNumber) => {
    setFilters((prevState) => ({ ...prevState, page: pageNumber }));
  };

  const handleSort = handleSortFunctional.bind(this);

  return (
    <>
      <TableOverflowWrapper>
        {/* FILTER */}
        <Toolbar>
          <TextFieldUi
            id="cpbr-filtre"
            label={"Filtre"}
            onChange={handleSearchFilter(setFilters)}
            type="search"
          />
        </Toolbar>

        <Table>
          {/* TABLE HEADER */}
          <LocationJobsHistoryListHeader
            filters={filters}
            setFilters={setFilters}
            handleSort={handleSort}
          />

          {/* TABLE BODY */}
          {!jobs?.data && <TableLoading />}
          {jobs?.data && (
            <LocationJobsHistoryListBody
              jobs={jobs.data}
              navigate={navigate}
              router={router}
            />
          )}
        </Table>
      </TableOverflowWrapper>

      {/* PAGINATION */}
      <TablePaginationWrapper
        component="div"
        count={jobs?.count || 0}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange(setFilters)}
        page={filters.page}
        rowsPerPage={filters.limit}
      />
    </>
  );
}
export default withRouter(LocationHistoryJobList);
