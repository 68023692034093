/// <reference types="vite-plugin-svgr/client" />

import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import CustomMarker from "@assets/images/icons/marker.svg?react";
import CustomMarkerSelected from "@assets/images/icons/marker-selected.svg?react";
import { IconProps } from "@components/icons/Icon.type";

interface MarkerIconProps extends IconProps {
  selected?: boolean;
}

export const MarkerIcon = ({
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
  selected,
}: MarkerIconProps = defaultIconProps): ReactElement => {
  if (selected) {
    return <CustomMarkerSelected style={{ fill: fillColor, stroke: strokeColor }} />;
  }
  return <CustomMarker style={{ fill: fillColor, stroke: strokeColor }} />;
};
