import { Tab } from "@mui/material";
import { withContract } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { withRouter } from "@utils/withRouter";

import Breadcrumbs from "./Breadcrumbs";
import ContractLocationsList from "./CustomerLocationsList";
import HeaderContextWrapper from "./ui/HeaderContextWrapper";
import PageHeader from "./ui/PageHeader";
import TabsWrapper from "./ui/TabsWrapper";
import FlexHeaderRowWrapper from "./ui/FlexHeaderRowWrapper";
import HeaderColumnWrapper from "./ui/HeaderColumnWrapper";
import CustomerItemsList from "./ContractCustomerItemsList";
import { Trans } from "react-i18next";
import { formattedDate } from "@utils/dates";

class Contract extends PureComponent {
  state = {
    currentTab: 0,
  };

  componentDidMount = () => {
    const { fetchContract, router } = this.props;

    fetchContract(router.params.customerId, router.params.contractId);
  };

  handleChangeTab = (_, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  renderPageHeader = () => {
    const { contract, router } = this.props;
    const { expireDate, name, contractNo, startDate, status, paymentTypeCode } = contract;

    return (
      <PageHeader
        titleText={`${name}${name ? " / " : ""}${contractNo}`}
        onBackClick={() => router.navigate(-1)}
      >
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper><Trans i18nKey="contract_start" />: {formattedDate(startDate)}</HeaderColumnWrapper>
        </FlexHeaderRowWrapper>
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper><Trans i18nKey="end_date" />: {formattedDate(expireDate)}</HeaderColumnWrapper>
        </FlexHeaderRowWrapper>
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="status.title" />: {status}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span>{`Mode de paiement: ${paymentTypeCode ? (paymentTypeCode === "CLIENT_ACCOUNT" ? "Compte Client" : "Carte de crédit") : '-'}`}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>
      </PageHeader>
    );
  };

  renderTabContainer = () => {
    let Content = null;

    switch (this.state.currentTab) {
      case 0: {
        Content = ContractLocationsList;
        break;
      }
      default: {
        Content = CustomerItemsList;
        break;
      }
    }

    return <Content {...this.props} />;
  };

  render() {
    const { router } = this.props;
    return (
      <div>
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${router.params.customerId}`}>
              <Trans i18nKey="contract_and_contact" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="contract" />
          </li>
        </Breadcrumbs>

        <HeaderContextWrapper>{this.renderPageHeader()}</HeaderContextWrapper>

        <TabsWrapper
          indicatorColor="primary"
          onChange={this.handleChangeTab}
          textColor="primary"
          value={this.state.currentTab}
        >
          <Tab label={<Trans i18nKey="locations" />} />
          <Tab label={<Trans i18nKey="customer_items" />} />
        </TabsWrapper>

        {this.renderTabContainer()}
      </div>
    );
  }
}

Contract.propTypes = {
  contract: PropTypes.object.isRequired,
  fetchContract: PropTypes.func.isRequired,
};

export default withRouter(withContract(Contract));
