import LocationDetail from "@@types/location-details/location-detail.type";
import React, { Dispatch, SetStateAction } from "react";
import { SelectChangeEvent } from "@mui/material";
import ListCode from "@@types/list-codes/list-code.type";

export type LocationModalityMatter = {
  handleChangeMatterValue: (
    listCodes: ListCode[],
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ) => void;
};

const useLocationModalityMatter = (
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>
): LocationModalityMatter => {
  const handleChangeMatterValue = (
    listCodes: ListCode[],
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ): void => {
    const matterLabel = listCodes.find((listCode) => listCode.code === event.target.value)?.description;

    if (matterLabel) {
      setModality((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            matter: { label: matterLabel, value: event.target.value, error: false },
          };
        }
      });
    }
  };

  return {
    handleChangeMatterValue,
  } as LocationModalityMatter;
};

export default useLocationModalityMatter;
