// React imports
import React, { FC } from "react";
// Internal imports
import Location from "@@types/locations/location.type";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
// External imports
import { Table, Button, Stack } from "@mui/material";
import useLocationModalities from "@components/location/route/modality/hooks/location-modalities.hook";
import LocationModalitiesListHeader from "@components/location/route/modality/LocationModalitiesListHeader";
import LocationModalitiesListBody from "@components/location/route/modality/LocationModalitiesListBody";
import LocationModalityDeleteModal from "@components/location/route/modality/modals/delete/LocationModalityDeleteModal";
import LocationModalityModal from "@components/location/route/modality/modals/LocationModalityModal";

type LocationModalitiesListProps = {
  selectedLocation: number;
  customerLocation: Location;
};

const LocationModalitiesList: FC<LocationModalitiesListProps> = ({ selectedLocation, customerLocation }) => {
  const { data, handlers } = useLocationModalities(selectedLocation);
  return (
    <TableOverflowWrapper>
      {/* ADD MODALITY BUTTON */}
      {/* ONLY APPEAR FOR PORTNEUF LOCATION */}
      {!customerLocation.isFromCiWeb && (
        <Stack
          p={2}
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handlers.handleOpenModalityModal("create", data.modalityForModal)}
          >
            AJOUTER UNE MODALITÉ
          </Button>
        </Stack>
      )}
      <Table>
        {/* TABLE HEADER */}
        <LocationModalitiesListHeader />

        {/* TABLE BODY */}
        {data?.locationModalities?.length ? (
          <LocationModalitiesListBody
            locationModalities={data.locationModalities}
            onClick={handlers.handleOpenModalityModal}
            isFromCiWeb={customerLocation.isFromCiWeb}
          />
        ) : "** Aucune donnée disponible **"}
      </Table>

      {/* MODAL CREATE MODALITY */}
      {data.openModalCreateModality && data.modalityForModal && (
        <LocationModalityModal
          openModal={data.openModalCreateModality}
          modality={data.modalityForModal}
          weekDays={data.weekDays}
          setModality={handlers.setModalityForModal}
          modalType={"create"}
          onCancel={handlers.handleCloseModalityModal}
          onMutate={handlers.handleCreateModality}
        />
      )}

      {/* MODAL EDIT MODALITY */}
      {data.openModalEditModality && data.modalityForModal && (
        <LocationModalityModal
          openModal={data.openModalEditModality}
          modality={data.modalityForModal}
          weekDays={data.weekDays}
          setModality={handlers.setModalityForModal}
          modalType={"update"}
          onCancel={handlers.handleCloseModalityModal}
          onMutate={handlers.handleEditModality}
        />
      )}

      {/* MODAL DELETE MODALITY */}
      {data.openModalDeleteModality && data.modalityForModal && (
        <LocationModalityDeleteModal
          openModal={data.openModalDeleteModality}
          modality={data.modalityForModal}
          weekDays={data.weekDays}
          modalType={"delete"}
          onCancel={handlers.handleCloseModalityModal}
          onMutate={handlers.handleDeleteModality}
        />
      )}
    </TableOverflowWrapper>
  );
};

export default LocationModalitiesList;
