// React imports
import React, { memo, useCallback } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Button, Stack } from "@mui/material";

function ContractSelect({ isError, contract, contracts, handlers }) {
  // console.log("[ContractSelect] >>> RENDERED");

  const handleChangeContract = useCallback((contract) => {
    contract && handlers.setSingleCurrent("contract", contract);
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={contract.value ? contract : null}
        isDisabled={contracts.loading}
        isLoading={contracts.loading}
        menuPosition="fixed"
        onChange={(contract) => handleChangeContract(contract)}
        options={contracts.data}
        placeholder={"Sélectionner un contrat"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, contract: prevContract, contracts: prevContracts } = prevProps;
  const { isError: nextIsError, contract: nextContract, contracts: nextContracts } = nextProps;
  let arePropsEqual = true;

  // If the contracts array changed, rerender
  if (!isEqual(prevContracts, nextContracts)) {
    arePropsEqual = false;
  }

  // If the current contract changed, rerender
  if (!isEqual(prevContract, nextContract)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(ContractSelect, areEqual);
