// React imports
import React, { useEffect, useRef, useState } from "react";
// Internal imports
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import FloatingActionButton from "@ui/FloatingActionButton";
import ItemsTableHead from "@components/items/ItemsTableHead";
import ItemNewModal from "@components/modals/item/ItemNewModal";
// External imports
import { FormControlLabel, Stack, Switch, Table, TableBody, TableContainer, Toolbar } from "@mui/material";
import TextFieldUi from "@ui/TextField";
import { Add } from "@mui/icons-material";
import ItemsTableBody from "@components/items/ItemsTableBody";
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import { isArrayTruthy } from "@utils/common";
import { debounce } from "lodash";
import TableLoading from "@ui/TableLoading";

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
};

const fetchItemsListAsync = async (filters, setItems) => {
  const pageNumber = filters.page + 1;

  const res = await API.Item.fetchItemsList(filters.filter, filters.limit, pageNumber);

  setItems(res);
};

const fetchAvailableItemsListAsync = async (filters, setItems) => {
  const pageNumber = filters.page + 1;

  const res = await API.Item.fetchAvailableItemsList(filters.filter, filters.limit, pageNumber);

  setItems(res);
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const handleSearchFilter = (setFilters) =>
  debounce((event) => {
    setFilters((prevState) => ({ ...prevState, filter: event.target.value }));
  }, 500);

const handleAvailableOnlyList = (isAvailable, items, filters, setIsAvailable, setItems) => (event) => {
  const checked = event.target.checked;
  let res;
  if (checked) {
    res = fetchAvailableItemsListAsync(filters, setItems);
  } else {
    res = fetchItemsListAsync(filters, setItems);
  }
  setItems(res);
  setIsAvailable((prevState) => !prevState);
};

const handleItemNewModal = (setIsItemNewModalOpen) => {
  setIsItemNewModalOpen((prevState) => !prevState);
};

export default function ItemsPage() {
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(filtersInitialState);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isItemNewModalOpen, setIsItemNewModalOpen] = useState(false);

  const fetchItemsList = useAsync(() => fetchItemsListAsync(filters, setItems));

  const fetchAvailableItemsList = useAsync(() => fetchAvailableItemsListAsync(filters, setItems));

  const handlePageChange = (event, pageNumber) => {
    setFilters((prevState) => ({ ...prevState, page: pageNumber }));
  };

  useEffect(() => {
    updateItemsList();
  }, [filters]);

  const updateItemsList = () => {
    isAvailable ? fetchAvailableItemsList.run() : fetchItemsList.run();
  };

  return (
    <PageContainer>
      <PaperWrapper>
        <TableContainer>
          <>
            {/* ADD ITEM BUTTON */}
            <FloatingActionButton color="secondary" onClick={() => handleItemNewModal(setIsItemNewModalOpen)}>
              <Add />
            </FloatingActionButton>

            {/* MODAL NEW ITEM */}
            {isItemNewModalOpen && (
              <ItemNewModal
                open={isItemNewModalOpen}
                onClose={() => handleItemNewModal(setIsItemNewModalOpen)}
                setIsItemNewModalOpen={setIsItemNewModalOpen}
                updateItemsList={updateItemsList}
              />
            )}

            {/* FILTER*/}
            <Stack direction={"row"}>
              <Toolbar>
                <TextFieldUi
                  id="cpbr-filtre"
                  label={"Filtre"}
                  onChange={handleSearchFilter(setFilters)}
                  type="search"
                />
              </Toolbar>

              {/* AVAILABLE ITEM LIST SWITCH */}
              <FormControlLabel
                control={
                  <Switch
                    checked={isAvailable}
                    onChange={handleAvailableOnlyList(isAvailable, items, filters, setIsAvailable, setItems)}
                    color="primary"
                  />
                }
                label="Disponible"
              />
            </Stack>

            {/* TABLE */}
            <Table>
              <ItemsTableHead />
              <TableBody>
                {isArrayTruthy(items?.data) ? <ItemsTableBody items={items.data} /> : <TableLoading />}
              </TableBody>
            </Table>

            {/* TABLE FOOTER BUTTONS */}
            <TablePaginationWrapper
              component="div"
              count={items?.count || 0}
              id="cpbr-pagination"
              labelRowsPerPage=""
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange(setFilters)}
              page={filters.page}
              rowsPerPage={filters.limit}
            />
          </>
        </TableContainer>
      </PaperWrapper>
    </PageContainer>
  );
}
