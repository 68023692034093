import React, { createContext, Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";
import { CustomerItemMasterRouteTemplate } from "@@types/customer-items/customerItemMasterRouteTemplate.type";

export type MasterRouteTemplatesContextState = {
  masterRouteTemplates: CustomerItemMasterRouteTemplate[];
  setMasterRouteTemplates: Dispatch<SetStateAction<CustomerItemMasterRouteTemplate[]>>;
};

export const MasterRouteTemplatesContext = createContext({} as MasterRouteTemplatesContextState);

type Props = {
  children: ReactElement;
};

function MasterRouteTemplatesProvider({ children }: Props): ReactElement {
  const [masterRouteTemplates, setMasterRouteTemplates] = useState<CustomerItemMasterRouteTemplate[]>([]);

  const value: MasterRouteTemplatesContextState = useMemo(
    () => ({
      masterRouteTemplates,
      setMasterRouteTemplates,
    }),
    [masterRouteTemplates]
  );

  return <MasterRouteTemplatesContext.Provider value={value}>{children}</MasterRouteTemplatesContext.Provider>;
}

function useMasterRouteTemplatesContext(): MasterRouteTemplatesContextState {
  const context = React.useContext(MasterRouteTemplatesContext);
  if (context === undefined) {
    throw new Error("useMasterRouteTemplatesContext must be used within a MasterRouteTemplatesProvider");
  }
  return context as unknown as MasterRouteTemplatesContextState;
}

/**
 * Children component
 * @param Component
 */
function withMasterRouteTemplatesContext(Component: React.ComponentType<unknown>): React.ComponentType<unknown> {
  return function fn() {
    return (
      <MasterRouteTemplatesProvider>
        <Component />
      </MasterRouteTemplatesProvider>
    );
  };
}

export { MasterRouteTemplatesProvider, withMasterRouteTemplatesContext, useMasterRouteTemplatesContext };
