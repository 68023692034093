// React imports
import React from 'react';
// External imports
import {Box, TableCell, TableHead, TableRow} from '@mui/material';

export default function SalesClientAccountHead({ webTransactions }) {

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" sx={{ fontSize: 10 }}></TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Nom client&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Bon de commande&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Plan&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Description&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Emplacement&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Flow Intervention précédente&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Date Intervention Précédente&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Flow&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Date tâche&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Jours&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Jours supp.&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Zone&nbsp;</TableCell>
        <TableCell align="left" sx={{ fontSize: 10 }}>Point de chute&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}
