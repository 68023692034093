const routeLocation = {
  all: () => ["route-location"] as const,
  lists: () => [...routeLocation.all(), "list"] as const,
  list: (filters: object) => [...routeLocation.lists(), { filters }] as const,
  details: () => [...routeLocation.all(), "detail"] as const,
  detail: (id: number) => [...routeLocation.details(), id?.toString()] as const,
  anomalies: (routeLocationId: number) => [...routeLocation.lists(), routeLocationId?.toString()] as const,
};

export default routeLocation;
