import { IconProps, IconSize } from "@components/icons/Icon.type";

export const ICON_SIZE: { [key: string]: string } = {
  xSmall: "12",
  small: "16",
  medium: "26",
  large: "64",
  xLarge: "128",
  xxLarge: "256",
};

export const DefaultStrokeColor = "rgba(0, 0, 0)";

export const defaultIconProps: IconProps = {
  strokeColor: DefaultStrokeColor,
  fillColor: "none",
  size: "medium" as IconSize,
};
