import axios from "axios";
import { requestOptions } from "../auth";

const createRoute = async (payload) => {
  try {
    const res = await axios.post(`${import.meta.env.VITE_API_URL}/routes`, { route: { ...payload } }, requestOptions());

    console.log("[createRoute] res:", res);

    return res.status;
  } catch (error) {
    console.warn("[API.RouteService] createRoute >> error:", error);
    return error;
  }
};

const deleteRoute = async (routeID) => {
  try {
    const res = await axios.delete(`${import.meta.env.VITE_API_URL}/routes/${routeID}`, requestOptions());

    return res.status;
  } catch (error) {
    console.warn("[API.RouteService] deleteRoute >> error:", error);
    return error;
  }
};

/**
 * Route api service for exposing api endpoints.
 *
 * Available requests:
 * - `createRoute(payload)`
 * - `deleteRoute(routeID)`
 */
export { createRoute, deleteRoute };
