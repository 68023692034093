import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import { ApiResponse } from "@@types/apiResponse";

import { requestOptions } from "./auth";

const setAxiosClient = (version: "v1" | "v2"): AxiosInstance => {
  return axios.create({
    ...requestOptions(),
    baseURL: `${import.meta.env.VITE_API_DOMAIN}/api/${version}`,
  });
};

const axiosClientV1 = setAxiosClient("v1");
const axiosClientV2 = setAxiosClient("v2");

axiosClientV1.defaults.timeout = 30_000;
axiosClientV2.defaults.timeout = 30_000;

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  config.withCredentials = true;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error("[axios.onRequestError] error:", error);

  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<ApiResponse<unknown>>): AxiosResponse<ApiResponse<unknown>> => {
  return response;
};

const onResponseError = (error: AxiosError<ApiResponse<unknown>>): Promise<AxiosError<ApiResponse<unknown>>> => {
  console.error("[axios.onResponseError] error:", error);

  return Promise.reject(error);
};

for (const axiosInstance of [axiosClientV1, axiosClientV2]) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
}

export { axiosClientV1, axiosClientV2 };
