import { useMutation } from "@tanstack/react-query";

import { useAuth } from "@context/auth/AuthProvider";
import GetCurrentUserService from "../../services/auth/get-current-user.service";

const useSetCurrentUser = () => {
  const { setAuth } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const currentUser = await GetCurrentUserService.get();

      if (currentUser) {
        const { roles, ...user } = currentUser;

        setAuth({ user, roles });
      }
    },
  });
};

export default useSetCurrentUser;
