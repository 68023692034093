import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Trans } from "react-i18next";

import FrequencyTextField from "./ui/FrequencyTextField";
import FrequencyWeeklyMultiSelectBox from "./ui/FrequencyWeeklyMultiSelectBox";

const RecurrenceWeekly = ({ daysError, error, onDaysChange, onIntervalChange, value }) => (
  <Fragment>
    <FrequencyTextField
      error={error}
      inputLabel={<Trans i18nKey="all_the" />}
      sideLabel={<Trans i18nKey="weeks" />}
      onChange={onIntervalChange}
      value={value}
      onKeyPress={event => !/[1-9]/.test(event.key) && event.preventDefault()}
      InputProps={{ inputProps: { min: 1, max: 52 } }}
      sx={{ minWidth: 100 }}
    />

    <FrequencyWeeklyMultiSelectBox
      error={daysError}
      onChange={onDaysChange("days")}
    />
  </Fragment>
);

RecurrenceWeekly.defaultProps = {
  value: null,
};

RecurrenceWeekly.propTypes = {
  daysError: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]).isRequired,
  onDaysChange: PropTypes.func.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default RecurrenceWeekly;
