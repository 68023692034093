import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
class CustomToolbar extends React.Component {
  handleClick = () => {
    const { changeDataTableDisplay } = this.props;
    return changeDataTableDisplay();
  };

  buttonSelectedOrAvailableLocations = () => {
    const { valueChangeDataTableDisplay } = this.props;
    return (
      <Button onClick={() => this.handleClick()} variant="outlined" color="primary">
        {valueChangeDataTableDisplay
          ? "Emplacement(s) assignés(s)"
          : "Emplacement(s) non-assigné(s)"}
      </Button>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Tooltip title="">{this.buttonSelectedOrAvailableLocations()}</Tooltip>
      </React.Fragment>
    );
  }
}

export default CustomToolbar;
