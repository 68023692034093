import axios from "axios";

import { requestOptions } from "../auth";

const createInterruption = async (interventionID, interruption) => {
  try {
    const payload = new FormData();
    payload.append("interruption[comment]", interruption.comment);
    payload.append("interruption[code]", interruption.code);
    interruption.photo && payload.append("interruption[photo]", interruption.photo, interruption.photo.name);

    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/interventions/${interventionID}/interruptions`,
      payload,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.InterventionService] createInterruption >> error:", error);
    throw error.response;
  }
};

/**
 * Interruption api service for exposing api endpoints.
 *
 * Available requests:
 * - `createInterruption(interventionID, params)`
 */
export { createInterruption };
