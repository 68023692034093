import React, { Fragment, useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import { Trans } from "react-i18next";
import TablePaginationWrapper from "../ui/TablePaginationWrapper";
import TableOverflowWrapper from "../ui/TableOverflowWrapper";
import TableLoading from "../ui/TableLoading";

import { handleSortFunctional, sortedData } from "@utils/filtering";

import * as API from "@services";
import useAsync from "@hooks/useAsync";
import { withRouter } from "@utils/withRouter";

const data = {
  code: { label: <Trans i18nKey="code" /> },
  type: { label: <Trans i18nKey="jobs_history.type" /> },
  end_date: { label: <Trans i18nKey="end_date" /> },
  customer_item_name: { label: <Trans i18nKey="customer_item" /> },
  recurrence_schedule: { label: <Trans i18nKey="schedule" /> },
  start_date: { label: <Trans i18nKey="start_date" /> },
  unit_name: { label: <Trans i18nKey="jobs.unit" /> },
};

const header = ["start_date", "end_date", "type", "code", "customer_item_name", "recurrence_schedule", "unit_name"];

function LocationsRecurrencesList(props) {
  const { filter, router } = props;

  const [state, setState] = useState({
    limit: 10,
    page: 0,
    columnName: "start_date",
    direction: "desc",
  });

  const fetchLocationRecurrencesAsync = useAsync(async () => {
    return await API.Location.fetchLocationRecurrences(router.params.locationId, {
      filter: filter ? filter.trim() : "",
      page: 1,
      limit: 5000,
    });
  });

  useEffect(() => {
    fetchLocationRecurrencesAsync.run();
  }, [state, filter]);

  const handleRowsPerPageChange = ({ target }) => {
    setState({ ...state, limit: target.value });
  };

  const handlePageChange = (event, pageNumber) => {
    setState({ ...state, page: pageNumber });
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        {header.map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={state.columnName === name}
              direction={state.direction}
              onClick={handleSortFunctional(name, state, setState)}
            >
              {data[name].label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableRows = () => {
    const { params } = router;
    const { limit, page } = state;

    if (fetchLocationRecurrencesAsync.loading) {
      return <TableLoading />;
    }

    const bottomLimit = page * limit;
    const topLimit = (page + 1) * limit;

    return sortedData(fetchLocationRecurrencesAsync.value, state)
      .slice(bottomLimit, topLimit)
      .map((recurrence, index) => {
        const { start_date, end_date, code, customer_item_name, unit_name, id, type, recurrence_schedule } = recurrence;

        return (
          <TableRow key={id}>
            <TableCell>{start_date}</TableCell>
            <TableCell>{end_date}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{code}</TableCell>
            <TableCell>{customer_item_name}</TableCell>
            <TableCell>{recurrence_schedule}</TableCell>
            <TableCell>{unit_name}</TableCell>
          </TableRow>
        );
      });
  };

  return (
    <Fragment>
      <TableOverflowWrapper>
        <Table>
          {renderTableHead()}
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableOverflowWrapper>

      <TablePaginationWrapper
        component="div"
        count={fetchLocationRecurrencesAsync.value?.length || 0}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={state.page}
        rowsPerPage={state.limit}
      />
    </Fragment>
  );
}

export default withRouter(LocationsRecurrencesList);
