import axios from "axios";
import { map } from "lodash";
import { requestOptions } from "../auth";
import { axiosClientV2 } from "../axios";

const fetchSuppliers = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/locations/suppliers.json`, requestOptions());

    const suppliers = map(response.data.instances.data, (supplier) => supplier.data.attributes);

    return suppliers;
  } catch (error) {
    console.warn("[fetchSuppliers] error:", error);
  }
};

const fetchLocationRecurrences = async (locationID, { filter = "", limit = 10, page = 1 } = {}) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/locations/${locationID}/job_recurrences.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data?.data?.instances;
  } catch (error) {
    console.warn("[fetchLocationRecurrences] error:", error);
  }
};

const fetchLocationRouteHistory = async (locationId, filter, limit, page) => {
  try {
    const response = await axiosClientV2.get(
      `locations/${locationId}/routes_history.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );
    return response.data;
  } catch (error) {
    console.warn("[fetchLocationRouteHistory] error:", error);
  }
};

const fetchLocationRouteFuture = async (locationId, filter, limit, page) => {
  try {
    const response = await axiosClientV2.get(
      `locations/${locationId}/routes_future.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[fetchLocationRouteFuture] error:", error);
  }
};

const fetchLocationJobsFuture = async (locationId, filter, limit, page) => {
  try {
    const response = await axiosClientV2.get(
      `locations/${locationId}/jobs_future.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[fetchLocationJobFuture] error:", error);
  }
};

const fetchLocationJobsHistory = async (locationId, filter, limit, page) => {
  try {
    const response = await axiosClientV2.get(
      `locations/${locationId}/jobs_history.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[fetchLocationJobsHistory] error:", error);
  }
};

/**
 * Location api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchSuppliers()`
 * - `fetchLocationRecurrences(locationID, { filter, limit, page })`
 * - `fetchLocationRouteHistory(locationId, filter, limit, page)`
 * - `fetchLocationRouteFuture(locationId, filter, limit, page)`
 * - `fetchLocationJobsHistory(locationId, filter, limit, page)`
 * - `fetchLocationJobsFuture(locationId, filter, limit, page)`
 */
export {
  fetchSuppliers,
  fetchLocationRecurrences,
  fetchLocationRouteHistory,
  fetchLocationRouteFuture,
  fetchLocationJobsHistory,
  fetchLocationJobsFuture,
};
