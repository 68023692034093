import axios from "axios";
import { requestOptions } from "../auth";

/**
 * Fetches units based on specified filters.
 * @param {Object} filterStates - The filter states object.
 * @param {string} filterStates.filter - The filter to apply.
 * @param {string|number} filterStates.rowsPerPage - The number of rows per page or 'all' for all rows.
 * @param {number} filterStates.page - The page number.
 * @param {Object} filterStates.sort - The column sorting object.
 * @param {string} filterStates.sort.columnName - The name of the column to sort.
 * @param {string} filterStates.sort.direction - The direction of the sorting, 'asc' or 'dsc'.
 * @returns {Promise<Object>} The units data retrieved from the API.
 */
const fetchUnits = async (filterStates = {}) => {
  try {
    const { filter = "", rowsPerPage = 10, page = 0, sort = { columnName: "", direction: "" } } = filterStates;

    const stringifySort = JSON.stringify(sort);
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/units.json?page=${
        page + 1
      }&limit=${rowsPerPage}&filter=${filter}&sort_attributes=${stringifySort}`,
      requestOptions()
    );

    return response.data?.data;
  } catch (error) {
    console.warn("[fetch-units.service] error:", error);
  }
};

export default fetchUnits;
