// React imports
import React, { FC } from "react";
// Externals imports
import { TableCell, TableHead, TableRow } from "@mui/material";

const LocationRouteHistoryDetailsHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Modèle conteneur</TableCell>
        <TableCell>Numéro conteneur</TableCell>
        <TableCell>Numéro puce conteneur</TableCell>
        <TableCell>Statut</TableCell>
        <TableCell>Levée le</TableCell>
        <TableCell>Cas problème</TableCell>
      </TableRow>
    </TableHead>
  );
};
export default LocationRouteHistoryDetailsHeader;
