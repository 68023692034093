// React imports
import React, { useEffect, useState, memo, useCallback, useReducer } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import { isArrayTruthy } from "@utils/common";
import PaymentMethod from "@components/payments/PaymentMethod";
import useApiResponseHandler from "@hooks/useApiResponseHandler";

// External iports
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

import useUpdateEffect from "@hooks/useUpdateEffect";

const sleep = (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

// Reducer function to manage loading state
const reducer = (state, action) => {
  switch (action.type) {
    case "start":
      return {
        ...state,
        loading: { ...state.loading, [action.id]: true },
        status: { ...state.status, [action.id]: "loading" },
      };
    case "finish":
      return {
        ...state,
        loading: { ...state.loading, [action.id]: false },
        status: { ...state.status, [action.id]: "success" },
        paymentMethods: state.paymentMethods.filter((paymentMethod) => paymentMethod.id !== action.id),
      };
    case "update_payment_methods":
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    case "error":
      return {
        ...state,
        loading: { ...state.loading, [action.id]: false },
        status: { ...state.status, [action.id]: "error" },
      };
    default:
      return state;
  }
};

const arePaymentMethodsPropsEqual = (prevProps, nextProps) => {
  const { paymentMethods: prevPaymentMethods } = prevProps;
  const { paymentMethods: nextPaymentMethods } = nextProps;

  return isEqual(prevPaymentMethods, nextPaymentMethods);
};

const PaymentMethodsList = memo(function PaymentMethodsList({
  paymentMethods,
  defaultPaymentMethod,
  setSingleProperty,
}) {
  const [state, dispatch] = useReducer(reducer, { paymentMethods: paymentMethods, loading: {}, status: {} });
  const handleApiResponse = useApiResponseHandler();

  useEffect(() => {
    // Update the component's state whenever the paymentMethods prop changes
    dispatch({ type: "update_payment_methods", paymentMethods });
  }, [paymentMethods]);

  useUpdateEffect(() => {
    // If we delete all of our payment methods, update the paymentMethods from the customer context
    if (!isArrayTruthy(state.paymentMethods)) {
      setSingleProperty("stripe", state.paymentMethods, "paymentMethods");
    }
  }, [state.paymentMethods]);

  const handleDetachPaymentMethod = useCallback(async (paymentMethodID) => {
    try {
      dispatch({ type: "start", id: paymentMethodID });
      await sleep(1);
      await API.Stripe.detachStripeCustomerPaymentMethod(paymentMethodID);

      dispatch({ type: "finish", id: paymentMethodID });
    } catch (error) {
      dispatch({ type: "error", id: paymentMethodID });
      handleApiResponse(error);
    }
  }, []);

  return (
    <Stack spacing={2}>
      {isArrayTruthy(state.paymentMethods) &&
        state.paymentMethods.map((paymentMethod) => (
          <PaymentMethod
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            isDefaultPaymentMethod={paymentMethod.id === defaultPaymentMethod || false}
            onDetachPaymentMethod={handleDetachPaymentMethod}
            status={state.status[paymentMethod.id] || ""}
            loading={state.loading[paymentMethod.id] || false}
          />
        ))}
    </Stack>
  );
},
arePaymentMethodsPropsEqual);

export default PaymentMethodsList;
