import React from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@mui/material";
import { withRouter } from "@utils/withRouter";

class SideDrawerRank extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number,
    change: PropTypes.func,
  };

  render() {
    const { value, rankChangeCallback, length, router } = this.props;
    const arrayOfNumber = Array.from({ length }, (_, i) => i + 1);
    return (
      <FormControl style={{ width: "100%" }}>
        {router.params?.routeTemplateId ? (
          <Select
            value={value}
            onChange={(event) => {
              rankChangeCallback(value - 1, event.target.value - 1);
            }} // source, destination
            MenuProps={{ style: { maxHeight: 400 } }}
          >
            {arrayOfNumber.map((numb, index) => (
              <MenuItem key={index} value={numb}>
                {numb}
              </MenuItem>
            ))}
          </Select>
        ) : (
          value
        )}
      </FormControl>
    );
  }
}

export default withRouter(SideDrawerRank);
