import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const CheckIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="7"
        viewBox="0 0 9 7"
        fill={fillColor}
      >
        <path
          d="M8 1L3.1875 6L1 3.72727"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default CheckIcon;
