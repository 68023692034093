import axios from "axios";

import { requestOptions } from "../auth";

const updateContractAccountingItem = async (payload, accountingItemID) => {
  try {
    const response = await axios.put(
      `${import.meta.env.VITE_API_URL}/accounting_items/${accountingItemID}`,
      payload,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[API.updateContractAccountingItem] Error:", error);

    return error.response.data;
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `updateContractAccountingItem`,
 */
export { updateContractAccountingItem };
