import React from "react";
import { Trans } from "react-i18next";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

const data = {
  address: { label: <Trans i18nKey="address.adr" /> },
  city: { label: <Trans i18nKey="address.city" /> },
  country: { label: <Trans i18nKey="address.country" /> },
  name: { label: <Trans i18nKey="name_simple" /> },
  postalCode: { label: <Trans i18nKey="address.postal_code" /> },
  province: { label: <Trans i18nKey="address.province" /> },
  active: { label: <Trans i18nKey="active" /> },
  edit: { label: "Modifier" },
};

export default function CustomersTableHead({ sort, setSort, handleSort }) {
  const { columnName, direction } = sort;

  return (
    <TableHead>
      <TableRow>
        {["name", "address", "city", "postalCode", "province", "active", "edit"].map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={columnName === name}
              direction={direction}
              onClick={() => handleSort(name, sort, setSort)}
            >
              {data[name].label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
