import LocationDetailResponse from "@@types/location-details/location-detail-response.type";
import LocationDetail from "@@types/location-details/location-detail.type";

function mapResponseToLocationDetail(response: LocationDetailResponse): LocationDetail {
  return {
    locationDetailFrequencyId: response.locationDetailFrequencyId,
    locationDetailDaysOfWeekId: response.locationDetailDaysOfWeekId,
    matter: response.matter,
    from: response.from,
    until: response.until,
    frequency: response.frequency,
    monday: response.monday,
    tuesday: response.tuesday,
    wednesday: response.wednesday,
    thursday: response.thursday,
    friday: response.friday,
    saturday: response.saturday,
    sunday: response.sunday,
  };
}

function mapResponseToLocationDetails(responses: LocationDetailResponse[]): LocationDetail[] {
  return responses.map(mapResponseToLocationDetail);
}

export default mapResponseToLocationDetails;
