import React, { useEffect, useState } from "react";
import { Collapse, ListItem, Stack, IconButton, FormControl, InputLabel, Input, Box } from "@mui/material";
import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import * as API from "@services";
import ModalWarning from "@components/ModalWarning";
import { Trans } from "react-i18next";
import { includes, map } from "lodash";

const valid = (fuelChargeTax, setFuelChargeTaxes, fuelChargeTaxes, index) => {
  const futureTaxesArray = [...fuelChargeTaxes.futureTaxes];

  const errors = map(fuelChargeTax.data, (value, key) => {
    if (key !== "end_date") {
      if (!value) {
        futureTaxesArray[index] = {
          data: { ...futureTaxesArray[index].data },
          errors: { ...futureTaxesArray[index].errors, [key]: true },
        };

        setFuelChargeTaxes((prevState) => ({
          ...prevState,
          futureTaxes: futureTaxesArray,
        }));
      }

      return !value;
    }
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const handleEditFuelChargeTax = (setFuelChargeTaxes, fuelChargeTax, fuelChargeTaxes) => (event) => {
  const { name, value } = event.target;

  const taxEditingIndex = fuelChargeTaxes.futureTaxes.indexOf(fuelChargeTax);

  const futureTaxesArray = [...fuelChargeTaxes.futureTaxes];

  futureTaxesArray[taxEditingIndex] = {
    data: { ...futureTaxesArray[taxEditingIndex].data, [name]: value },
    errors: { ...futureTaxesArray[taxEditingIndex].errors, [name]: !value },
  };

  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    futureTaxes: futureTaxesArray,
  }));
};

const handleButtonCancelFuelChargeTax = (fuelChargeTax, setFuelChargeTaxes, fuelChargeTaxes, handleButtonEditTax) => {
  const taxEditingIndex = fuelChargeTaxes.futureTaxes.indexOf(fuelChargeTax);

  const futureTaxesArray = [...fuelChargeTaxes.futureTaxes];

  futureTaxesArray[taxEditingIndex] = {
    data: { ...fuelChargeTaxes.prevTaxes.future[taxEditingIndex].data },
    errors: { name: false, percent_value: false, effective_date: false, end_date: false },
  };

  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    futureTaxes: futureTaxesArray,
  }));

  handleButtonEditTax(fuelChargeTax.data.id);
};

const handleSaveEditFuelChargeTax = async (
  fuelChargeTax,
  setFuelChargeTaxes,
  fuelChargeTaxes,
  handleButtonEditTax,
  notifier
) => {
  const taxEditingIndex = fuelChargeTaxes.futureTaxes.indexOf(fuelChargeTax);

  const isValid = valid(fuelChargeTax, setFuelChargeTaxes, fuelChargeTaxes, taxEditingIndex);

  if (isValid) {
    const res = await API.Tax.updateFuelTax(fuelChargeTax.data, fuelChargeTax.data.id);

    const error = res.status === "failure";

    const futureTaxesArray = [...fuelChargeTaxes.futureTaxes];

    futureTaxesArray[taxEditingIndex] = {
      data: { ...futureTaxesArray[taxEditingIndex].data },
      errors: { ...futureTaxesArray[taxEditingIndex].errors, effective_date: error, end_date: error },
    };

    if (error) {
      setFuelChargeTaxes((prevState) => ({
        ...prevState,
        futureTaxes: futureTaxesArray,
      }));

      notifier.enqueueMessages(res.messages);
    } else {
      setFuelChargeTaxes((prevState) => ({
        ...prevState,
        futureTaxes: futureTaxesArray,
        prevTaxes: {
          ...prevState.prevTaxes,
          future: futureTaxesArray,
        },
      }));

      handleButtonEditTax(fuelChargeTax.id);
    }
  }
};

const handleDeleteTax = (fuelChargeTax, fuelChargeTaxes, setFuelChargeTaxes, handleButtonDeleteTax) => async () => {
  await API.Tax.deleteFuelTax(fuelChargeTax.data.id);

  const updateFutureTaxes = fuelChargeTaxes.futureTaxes.filter((tax) => tax.data.id !== fuelChargeTax.data.id);

  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    futureTaxes: updateFutureTaxes,
    prevTaxes: {
      ...prevState.prevTaxes,
      future: updateFutureTaxes,
    },
  }));

  handleButtonDeleteTax(fuelChargeTax.data.id);
};

const FuelChargeTaxesFutureItem = ({
  fuelChargeTax,
  fuelChargeTaxes,
  setFuelChargeTaxes,
  isCollapseOpen,
  editFuelChargeTax,
  notifier,
  handleButtonEditTax,
  handleButtonDeleteTax,
}) => {
  const isEditing = editFuelChargeTax[fuelChargeTax.data.id];

  return (
    <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
      <ListItem sx={{ flexDirection: "column", paddingRight: "110px" }}>
        <Stack direction="row" spacing={2}>
          <FormControl variant="standard" disabled={!isEditing} sx={{ minWidth: "50%" }}>
            <InputLabel shrink>Nom</InputLabel>
            <Input
              name="name"
              onChange={handleEditFuelChargeTax(setFuelChargeTaxes, fuelChargeTax, fuelChargeTaxes)}
              value={fuelChargeTax.data.name}
              error={fuelChargeTax.errors.name}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditing} sx={{ minWidth: "10%" }}>
            <InputLabel shrink>%</InputLabel>
            <Input
              name="percent_value"
              type="number"
              inputProps={{ min: "0", step: "0.1" }}
              onChange={handleEditFuelChargeTax(setFuelChargeTaxes, fuelChargeTax, fuelChargeTaxes)}
              value={fuelChargeTax.data.percent_value}
              error={fuelChargeTax.errors.percent_value}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditing} sx={{ minWidth: "17%" }}>
            <InputLabel shrink>Début</InputLabel>
            <Input
              name="effective_date"
              type="date"
              onChange={handleEditFuelChargeTax(setFuelChargeTaxes, fuelChargeTax, fuelChargeTaxes)}
              value={fuelChargeTax.data.effective_date ?? ""}
              error={fuelChargeTax.errors.effective_date}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditing} sx={{ minWidth: "17.5%" }}>
            <InputLabel shrink>Fin</InputLabel>
            <Input
              name="end_date"
              type="date"
              onChange={handleEditFuelChargeTax(setFuelChargeTaxes, fuelChargeTax, fuelChargeTaxes)}
              value={fuelChargeTax.data.end_date ?? ""}
              error={fuelChargeTax.errors.end_date}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>
          <Box width={40} height={"100%"}>
            <Stack direction={"row"} height={40}>
              {isEditing && (
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleSaveEditFuelChargeTax(
                      fuelChargeTax,
                      setFuelChargeTaxes,
                      fuelChargeTaxes,
                      handleButtonEditTax(fuelChargeTax.data.id),
                      notifier
                    )
                  }
                >
                  <Save />
                </IconButton>
              )}

              {!isEditing && (
                <IconButton color="secondary" onClick={handleButtonEditTax(fuelChargeTax.data.id)}>
                  <Edit />
                </IconButton>
              )}

              {isEditing && (
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleButtonCancelFuelChargeTax(
                      fuelChargeTax,
                      setFuelChargeTaxes,
                      fuelChargeTaxes,
                      handleButtonEditTax(fuelChargeTax.data.id)
                    )
                  }
                >
                  <Cancel />
                </IconButton>
              )}

              {isEditing && (
                <IconButton color="secondary" onClick={handleButtonDeleteTax(fuelChargeTax.data.id)}>
                  <Delete />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Stack>
      </ListItem>
    </Collapse>
  );
};

export default function FuelChargeTaxesFuture({ fuelChargeTaxes, setFuelChargeTaxes, isCollapseOpen, notifier }) {
  const [editFuelChargeTax, setEditFuelChargeTax] = useState({});
  const [isEditFuelChargeTaxActive, setIsEditFuelChargeTaxActive] = useState(false);
  const [deleteFuelChargeTax, setDeleteFuelChargeTax] = useState({});
  const [isDeleteTaxModalOpen, setIisDeleteTaxModalOpen] = useState(false);

  const handleButtonEditTax = (id) => () => {
    const copyEditFuelChargeTax = editFuelChargeTax;
    const actualValue = copyEditFuelChargeTax[id];
    copyEditFuelChargeTax[id] = !actualValue;
    setIsEditFuelChargeTaxActive((prevState) => !prevState);
    setEditFuelChargeTax(copyEditFuelChargeTax);
  };

  const handleButtonDeleteTax = (id) => () => {
    const copyDeleteFuelChargeTax = deleteFuelChargeTax;
    const actualValue = copyDeleteFuelChargeTax[id];
    copyDeleteFuelChargeTax[id] = !actualValue;
    setIisDeleteTaxModalOpen((prevState) => !prevState);
    setDeleteFuelChargeTax(copyDeleteFuelChargeTax);
  };

  return fuelChargeTaxes.futureTaxes.map((fuelChargeTax) => {
    return (
      <>
        <FuelChargeTaxesFutureItem
          fuelChargeTax={fuelChargeTax}
          fuelChargeTaxes={fuelChargeTaxes}
          setFuelChargeTaxes={setFuelChargeTaxes}
          isCollapseOpen={isCollapseOpen}
          editFuelChargeTax={editFuelChargeTax}
          notifier={notifier}
          handleButtonEditTax={handleButtonEditTax}
          handleButtonDeleteTax={handleButtonDeleteTax}
        />
        {deleteFuelChargeTax[fuelChargeTax.data.id] && (
          <ModalWarning
            onCancel={handleButtonDeleteTax(fuelChargeTax.data.id)}
            onSubmit={handleDeleteTax(
              fuelChargeTax,
              fuelChargeTaxes,
              setFuelChargeTaxes,
              handleButtonDeleteTax(fuelChargeTax.data.id)
            )}
            open={deleteFuelChargeTax[fuelChargeTax.data.id]}
            title={<Trans i18nKey="warning" />}
          >
            Êtes-vous certains de vouloir supprimer cette taxe ?
          </ModalWarning>
        )}
      </>
    );
  });
}
