import { useMutation, useQueryClient } from "@tanstack/react-query";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import LocationCcavContainerResponse from "@@types/containers/location-ccav-container-response.type";
import { MutationResult } from "@@types/apiResponse";
import { DefaultError } from "@tanstack/react-query/build/modern/index";
import locationCcavContainersKeys from "@hooks/containers/location-ccav-containers.keys";
import createLocationCcavContainer from "../../services/containers/create-location-ccav-container.service";

export type UseCreateLocationCcavContainer = {
  mutate: (payload: LocationCcavContainer) => Promise<MutationResult<LocationCcavContainerResponse>>;
  isPending: boolean;
};

function useCreateLocationCcavContainer(locationId: number): UseCreateLocationCcavContainer {
  const queryClient = useQueryClient();

  const mutation = useMutation<MutationResult<LocationCcavContainerResponse>, DefaultError, LocationCcavContainer>({
    mutationFn: (payload: LocationCcavContainer): Promise<MutationResult<LocationCcavContainerResponse>> =>
      createLocationCcavContainer(payload, locationId),
    onSuccess: async (_data) => {
      // Invalidate queries to reflect the changes
      await queryClient.invalidateQueries({
        queryKey: locationCcavContainersKeys.detail(locationId),
        exact: false,
      });
    },
  });

  const mutate = async (payload: LocationCcavContainer): Promise<MutationResult<LocationCcavContainerResponse>> => {
    const response = await mutation.mutateAsync(payload);
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateLocationCcavContainer;
