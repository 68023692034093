import styled from "@emotion/styled";

/**
 * @StyledComponent Style for a route's container, usually under the map.
 */
export const RouteContentWrapper = styled.div`
  margin: auto;
  width: 75%;
  font-size: 0.85rem;
  z-index: 1200;
`;

/**
 * @StyledComponent Style for a route's map container.
 * @param {Boolean} isLocation - Whether or not this is called from the location page.
 */
export const MapContentWrapper = styled.div`
  width: ${(props) => (props.isLocation ? "100%" : "75%")};
  height: 575px;
  display: flex;
  margin: auto;
  flex-wrap: nowrap;
  flex-direction: row;
`;
