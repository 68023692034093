import React, { useState, useEffect, useCallback } from "react";

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import RouteHeader from "@ui/RouteHeader";
import Breadcrumbs from "@components/Breadcrumbs";
import { AppBar, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";

import { withCustomerLocations } from "optigo-redux";
import { withRouter } from "@utils/withRouter";

import * as API from "@services";

import LocationMap from "../location/LocationMap";
import RenderTabContainerRoute from "@components/location/route/LocationRouteTabs";
import RenderTabContainerJob from "@components/location/job/LocationJobTabs";

const BreadCrumbsComponent = ({ router }) => {
  const { params } = router;
  return (
    <Breadcrumbs>
      <li>
        <Link to="/customers">
          <Trans i18nKey="customers" />
        </Link>
      </li>
      <li>
        <Link to={`/customers/${params.customerId}`}>
          <Trans i18nKey="contract_and_contact" />
        </Link>
      </li>
      <li>
        <Link to={`/customers/${params.customerId}/contracts/${params.contractId}`}>
          <Trans i18nKey="contract" />
        </Link>
      </li>
      <li>
        <Trans i18nKey="place" />
      </li>
    </Breadcrumbs>
  );
};
const returnOnClick = (router) => {
  const { params } = router;
  const { contractId, customerId } = params;
  const path = `/customers/${customerId}/contracts/${contractId}`;
  router.navigate(path);
};

const TabsContentWrapper = styled.div`
  width: 75%;
  margin: 25px auto 25px auto;
  font-size: 0.85rem;
  z-index: 1200;
  min-height: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledTabs = styled(Tabs)`
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 5px -2px;
  .justifiedTabs {
    justify-content: space-evenly;
  }
`;

const handleTabChange = (setTabValue) => (event, value) => {
  setTabValue(value);
};

function Location(props) {
  const { router } = props;
  const [customerLocation, setCustomerLocation] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState("-1");

  const locationId = selectedLocation !== "-1" ? selectedLocation : router.params.locationId;

  useEffect(() => {
    API.Customer.fetchCustomerLocation(router.params.customerId, locationId).then((customerLocation) => {
      setCustomerLocation(customerLocation);
    });
  }, [selectedLocation]);

  const refreshData = () => {
    API.Customer.fetchCustomerLocation(router.params.customerId, locationId).then((customerLocation) => {
      setCustomerLocation(customerLocation);
    });
  };

  return (
    <div>
      <BreadCrumbsComponent router={router} />

      <RouteHeader
        returnOnClick={() => returnOnClick(router)}
        pageTitle="Emplacements"
      />

      {customerLocation && (
        <LocationMap
          refreshData={refreshData}
          customerLocation={customerLocation}
          setSelectedLocation={setSelectedLocation}
        />
      )}

      {customerLocation && (
        <TabsContentWrapper>
          <AppBar
            position="static"
            color="default"
            elevation={0}
          >
            {/* N'affiche que les tab d'un emplacement roll off */}
            {customerLocation.isAFrontLoading || (
              <Tabs
                value={tabValue}
                onChange={handleTabChange(setTabValue)}
                scrollButtons="auto"
                style={{ boxShadow: "rgb(0 0 0 / 24 %) 0px 2px 5px -2px" }}
                classes={{ flexContainer: "justifiedTabs" }}
              >
                <Tab
                  label="HISTORIQUE DES TÂCHES"
                  value={0}
                />
                <Tab
                  label="TÂCHES À VENIR"
                  value={1}
                />
                <Tab
                  label="RÉCURRENCES"
                  value={2}
                />
              </Tabs>
            )}

            {/* N'affiche que les tab d'un emplacement appartenant à une route */}
            {customerLocation.isAFrontLoading && (
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange(setTabValue)}
                scrollButtons="auto"
                classes={{ flexContainer: "justifiedTabs" }}
              >
                <Tab
                  label="CONTENEURS"
                  value={0}
                />
                <Tab
                  label="MODALITÉS"
                  value={1}
                />
                <Tab
                  label="ROUTES À VENIR"
                  value={2}
                />
                <Tab
                  label="HISTORIQUE DES ROUTES"
                  value={3}
                />
              </StyledTabs>
            )}
          </AppBar>
          <div style={{ position: "relative" }}>
            {customerLocation.isAFrontLoading ? (
              <RenderTabContainerRoute
                refreshData={refreshData}
                tabValue={tabValue}
                customerLocation={customerLocation}
                selectedLocation={selectedLocation}
              />
            ) : (
              <RenderTabContainerJob tabValue={tabValue} />
            )}
          </div>
        </TabsContentWrapper>
      )}
    </div>
  );
}

export default withCustomerLocations(withRouter(Location));
