import React, { useCallback, useEffect, useState } from "react";
import {
  ListItem,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  ListItemButton,
  Box,
  RadioGroup,
} from "@mui/material";
import { Add, Cancel, Edit, ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import FuelChargeTaxesFuture from "@components/accountingItemsPricesAndTaxes/Taxes/FuelChargeTaxesFuture";
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import { includes, map } from "lodash";
import FloatingActionButton from "@ui/FloatingActionButton";
import FuelChargeTaxesNew from "@components/accountingItemsPricesAndTaxes/Taxes/FuelChargeTaxesNew";
import useNotifier from "@hooks/useNotifier";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import FuelChargeTaxesPast from "@components/accountingItemsPricesAndTaxes/Taxes/FuelChargeTaxesPast";

const initialTaxesStateData = {
  name: "",
  code: "FUEL_CHARGE",
  province_or_state: "QC",
  country: "CA",
  percent_value: "",
  effective_date: "",
  end_date: "",
};

const initialErrorsState = {
  name: false,
  percent_value: false,
  effective_date: false,
  end_date: false,
};

// prevTaxes is a state use as previous / object prevState
const initialTaxesState = {
  prevTaxes: [],
  currentTax: { data: initialTaxesStateData, errors: initialErrorsState },
  futureTaxes: [],
  pastTaxes: [],
  newTax: { data: initialTaxesStateData, errors: initialErrorsState },
  errors: initialErrorsState,
};

const valid = (fuelChargeTax, setFuelChargeTaxes, taxType) => {
  const errors = map(fuelChargeTax, (value, key) => {
    if (key !== "end_date") {
      if (!value) {
        setFuelChargeTaxes((prevState) => ({
          ...prevState,
          [taxType]: { ...prevState[taxType], errors: { ...prevState[taxType].errors, [key]: true } },
        }));
      }

      return !value;
    }
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const fetchFuelChargeTaxesDetailsAsync = async (setFuelChargeTaxes) => {
  const res = await API.Tax.fetchTaxesPricesList();

  const futureTaxesArray = [];

  res.future.map((tax) => {
    const futureTax = { data: tax, errors: initialErrorsState };
    futureTaxesArray.push(futureTax);
  });

  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    prevTaxes: {
      current: { ...prevState.currentTax, data: res.current },
      future: futureTaxesArray,
    },
    currentTax: { ...prevState.currentTax, data: res.current },
    futureTaxes: futureTaxesArray,
    pastTaxes: res.past,
  }));
};

const handleSaveEditFuelChargeTax = async (
  fuelChargeTaxes,
  setIsEditFuelChargeTaxActive,
  setFuelChargeTaxes,
  notifier
) => {
  const isValid = valid(fuelChargeTaxes.currentTax.data, setFuelChargeTaxes, "currentTax");

  if (isValid) {
    const res = await API.Tax.updateFuelTax(fuelChargeTaxes.currentTax.data, fuelChargeTaxes.currentTax.data.id);

    if (res.status === "failure") {
      setFuelChargeTaxes((prevState) => ({
        ...prevState,
        currentTax: {
          ...prevState.currentTax,
          errors: { ...prevState.currentTax.errors, effective_date: true, end_date: true },
        },
      }));

      notifier.enqueueMessages(res.messages);
    }

    if (res.status === "success") {
      setFuelChargeTaxes((prevState) => ({
        ...prevState,
        currentTax: {
          ...prevState.currentTax,
          errors: initialErrorsState,
        },
        prevTaxes: {
          ...prevState.prevTaxes,
          current: { ...prevState.prevTaxes.current, data: fuelChargeTaxes.currentTax.data },
        },
      }));

      setIsEditFuelChargeTaxActive((prevState) => !prevState);
    }
  }
};

const handleButtonEditFuelChargeTax = (setIsEditFuelChargeTaxActive) => {
  setIsEditFuelChargeTaxActive((prevState) => !prevState);
};

const handleButtonCancelFuelChargeTax = (fuelChargeTaxes, setFuelChargeTaxes, setIsEditFuelChargeTaxActive) => {
  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    currentTax: { data: fuelChargeTaxes.prevTaxes.current.data, errors: initialErrorsState },
  }));
  setIsEditFuelChargeTaxActive((prevState) => !prevState);
};

const handleButtonCloseModalNewTax = (setIsNewTaxModalOpen, setFuelChargeTaxes) => {
  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    newTax: { data: initialTaxesStateData, errors: initialErrorsState },
  }));
  setIsNewTaxModalOpen((prevState) => !prevState);
};

const handleButtonCreateNewTax = (setIsNewTaxModalOpen) => {
  setIsNewTaxModalOpen((prevState) => !prevState);
};

const handleSaveFuelChargeNewTax = async (
  isEditFuelChargeTaxActive,
  fuelChargeTaxes,
  setFuelChargeTaxes,
  setIsNewTaxModalOpen,
  setIsEditFuelChargeTaxActive,
  notifier
) => {
  const isValid = valid(fuelChargeTaxes.newTax.data, setFuelChargeTaxes, "newTax");

  if (isValid) {
    const res = await API.Tax.createNewFuelTax(fuelChargeTaxes.newTax.data);

    if (res.status === "failure") {
      const taxType = !fuelChargeTaxes.currentTax.data.end_date ? "currentTax" : "newTax";

      taxType === "currentTax" && setIsNewTaxModalOpen((prevState) => !prevState);

      taxType === "currentTax" && !isEditFuelChargeTaxActive && setIsEditFuelChargeTaxActive((prevState) => !prevState);

      setFuelChargeTaxes((prevState) => ({
        ...prevState,
        [taxType]: {
          ...prevState[taxType],
          errors: { ...prevState[taxType].errors, effective_date: true, end_date: true },
        },
      }));

      notifier.enqueueMessages(res.messages);
    } else {
      setFuelChargeTaxes(initialTaxesState);
      await fetchFuelChargeTaxesDetailsAsync(setFuelChargeTaxes);
      setIsNewTaxModalOpen((prevState) => !prevState);
    }
  }
};

const handleEditFuelChargeTaxesTax = (setFuelChargeTaxes) => (event) => {
  const { name, value } = event.target;

  setFuelChargeTaxes((prevState) => ({
    ...prevState,
    currentTax: {
      ...prevState.currentTax,
      data: { ...prevState.currentTax.data, [name]: value },
      errors: { ...prevState.currentTax.errors, [name]: !value },
    },
  }));
};

const FuelChargeTaxesTaxesCurrentItem = ({
  fuelChargeTaxes,
  setFuelChargeTaxes,
  isEditFuelChargeTaxActive,
  setIsEditFuelChargeTaxActive,
  isCollapseOpen,
  handleCollapse,
  notifier,
  periodOfTime,
  handleAlignment,
}) => {
  const styleToggleRadioButtonFuture = {
    color: periodOfTime === "future" ? "white" : "#006F9C",
    backgroundColor: periodOfTime === "future" ? "#006F9C" : "white",
  };
  const styleToggleRadioButtonPast = {
    color: periodOfTime === "past" ? "white" : "#006F9C",
    backgroundColor: periodOfTime === "past" ? "#006F9C" : "white",
  };

  return (
    <>
      <Stack alignItems="center" pb={2}>
        <ToggleButtonGroup exclusive value={periodOfTime} onChange={handleAlignment} color="primary">
          <ToggleButton
            id="past"
            value="past"
            style={styleToggleRadioButtonPast}
            sx={{ borderColor: "#006F9C", width: "50%" }}
          >
            PASSÉ
          </ToggleButton>
          <ToggleButton
            id="future"
            value="future"
            style={styleToggleRadioButtonFuture}
            sx={{ borderColor: "#006F9C", width: "50%" }}
          >
            FUTURE
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <ListItem sx={{ flexDirection: "column" }}>
        <Stack direction="row" alignItems="flex-end" spacing={2} pb={2}>
          <FormControl variant="standard" disabled={!isEditFuelChargeTaxActive} sx={{ minWidth: "43.5%" }}>
            <InputLabel shrink>Nom</InputLabel>
            <Input
              name="name"
              onChange={handleEditFuelChargeTaxesTax(setFuelChargeTaxes)}
              value={fuelChargeTaxes.currentTax.data?.name}
              disableUnderline={!isEditFuelChargeTaxActive}
              error={fuelChargeTaxes.currentTax.errors.name}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditFuelChargeTaxActive} sx={{ minWidth: "8.5%" }}>
            <InputLabel shrink>%</InputLabel>
            <Input
              name="percent_value"
              type="number"
              inputProps={{ min: "0", step: "0.1" }}
              onChange={handleEditFuelChargeTaxesTax(setFuelChargeTaxes)}
              value={fuelChargeTaxes.currentTax.data?.percent_value}
              disableUnderline={!isEditFuelChargeTaxActive}
              error={fuelChargeTaxes.currentTax.errors.percent_value}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditFuelChargeTaxActive} sx={{ minWidth: "15%" }}>
            <InputLabel shrink>Début</InputLabel>
            <Input
              name="effective_date"
              type="date"
              onChange={handleEditFuelChargeTaxesTax(setFuelChargeTaxes)}
              value={fuelChargeTaxes.currentTax.data?.effective_date ?? ""}
              disableUnderline={!isEditFuelChargeTaxActive}
              error={fuelChargeTaxes.currentTax.errors.effective_date}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled={!isEditFuelChargeTaxActive} sx={{ minWidth: "15.2%" }}>
            <InputLabel shrink>Fin</InputLabel>
            <Input
              name="end_date"
              type="date"
              onChange={handleEditFuelChargeTaxesTax(setFuelChargeTaxes)}
              value={fuelChargeTaxes.currentTax.data.end_date ?? ""}
              disableUnderline={!isEditFuelChargeTaxActive}
              error={fuelChargeTaxes.currentTax.errors.end_date}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: 14 }}
            />
          </FormControl>

          <Stack direction={"row"} height={40}>
            {isEditFuelChargeTaxActive && (
              <IconButton
                color="secondary"
                onClick={() =>
                  handleSaveEditFuelChargeTax(
                    fuelChargeTaxes,
                    setIsEditFuelChargeTaxActive,
                    setFuelChargeTaxes,
                    notifier
                  )
                }
              >
                <Save />
              </IconButton>
            )}

            {!isEditFuelChargeTaxActive && (
              <IconButton
                color="secondary"
                onClick={() => handleButtonEditFuelChargeTax(setIsEditFuelChargeTaxActive)}
                disabled={!fuelChargeTaxes.currentTax.data?.id}
              >
                <Edit />
              </IconButton>
            )}
            <Box width={40} height={"100%"}>
              {isEditFuelChargeTaxActive && (
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleButtonCancelFuelChargeTax(fuelChargeTaxes, setFuelChargeTaxes, setIsEditFuelChargeTaxActive)
                  }
                >
                  <Cancel />
                </IconButton>
              )}
            </Box>

            <ListItemButton onClick={handleCollapse}>{isCollapseOpen ? <ExpandLess /> : <ExpandMore />}</ListItemButton>
          </Stack>
        </Stack>
        {periodOfTime === "future" && (
          <FuelChargeTaxesFuture
            fuelChargeTaxes={fuelChargeTaxes}
            setFuelChargeTaxes={setFuelChargeTaxes}
            isCollapseOpen={isCollapseOpen}
            notifier={notifier}
          />
        )}
        {periodOfTime === "past" && (
          <FuelChargeTaxesPast fuelChargeTaxes={fuelChargeTaxes} isCollapseOpen={isCollapseOpen} />
        )}
      </ListItem>
    </>
  );
};

export default function FuelChargeTaxes({ notifier }) {
  const [fuelChargeTaxes, setFuelChargeTaxes] = useState(initialTaxesState);
  const [isEditFuelChargeTaxActive, setIsEditFuelChargeTaxActive] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isNewTaxModalOpen, setIsNewTaxModalOpen] = useState(false);
  const [periodOfTime, setPeriodOfTime] = useState("future");

  const handleCollapse = useCallback(
    (name) => {
      setIsCollapseOpen((prevState) => !prevState);
    },
    [isCollapseOpen]
  );

  const fetchFuelChargeTaxesDetails = useAsync(() => fetchFuelChargeTaxesDetailsAsync(setFuelChargeTaxes));

  useEffect(() => {
    // Fetch taxes details values at mount
    fetchFuelChargeTaxesDetails.run();
  }, []);

  const handleAlignment = (event, periodOfTime) => {
    if (periodOfTime !== null) {
      setPeriodOfTime(periodOfTime);
    }
  };

  return (
    <>
      <FloatingActionButton color="secondary" onClick={() => handleButtonCreateNewTax(setIsNewTaxModalOpen)}>
        <Add />
      </FloatingActionButton>
      <FuelChargeTaxesTaxesCurrentItem
        fuelChargeTaxes={fuelChargeTaxes}
        setFuelChargeTaxes={setFuelChargeTaxes}
        isEditFuelChargeTaxActive={isEditFuelChargeTaxActive}
        setIsEditFuelChargeTaxActive={setIsEditFuelChargeTaxActive}
        isCollapseOpen={isCollapseOpen}
        handleCollapse={handleCollapse}
        notifier={notifier}
        periodOfTime={periodOfTime}
        handleAlignment={handleAlignment}
      />

      {isNewTaxModalOpen && (
        <FuelChargeTaxesNew
          open={isNewTaxModalOpen}
          onClose={() => handleButtonCloseModalNewTax(setIsNewTaxModalOpen, setFuelChargeTaxes)}
          onSave={() =>
            handleSaveFuelChargeNewTax(
              isEditFuelChargeTaxActive,
              fuelChargeTaxes,
              setFuelChargeTaxes,
              setIsNewTaxModalOpen,
              setIsEditFuelChargeTaxActive,
              notifier
            )
          }
          fuelChargeTaxes={fuelChargeTaxes}
          setFuelChargeTaxes={setFuelChargeTaxes}
        />
      )}
    </>
  );
}
