import React, { ReactElement } from "react";
import { Trans } from "react-i18next";

import theme from "@styles/theme";
import { Button } from "@ui/button/Button";
import Modal from "@ui/modal/Modal";

export type ModalAddSubRouteTemplateButtonsProps = {
  onClose: () => void;
  onCreate: () => void;
};

const ModalAddMasterRouteTemplateButtons = ({
  onClose,
  onCreate,
}: ModalAddSubRouteTemplateButtonsProps): ReactElement => {
  return (
    <Modal.Footer>
      <Modal.Buttons>
        <Button
          variant="text"
          textColor={theme.colors.palette.secondary.main}
          onClick={onClose}
        >
          <Trans i18nKey="cancel" />
        </Button>
        <Button
          textColor="#FFFFFF"
          onClick={onCreate}
        >
          <Trans i18nKey="add" />
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  );
};

export default ModalAddMasterRouteTemplateButtons;
