import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const handleTypeReport = (report, setReport) => (event) => {
  setReport((prevState) => ({
    ...prevState,
    selectedReportType: event.target.value,
    summaries: [],
    selectedContractsIds: [],
  }));
};

export default function ReportTypeSelect({ types, report, setReport }) {
  return (
    <FormControl sx={{ width: 250 }}>
      <InputLabel shrink>Types</InputLabel>
      <Select notched label="Types" value={report.selectedReportType} onChange={handleTypeReport(report, setReport)}>
        {types.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
