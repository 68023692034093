import React, {useEffect, useState} from "react";
import {
  Collapse,
  ListItem,
  Stack,
  IconButton,
  FormControl,
  InputLabel, Input, Box
} from "@mui/material";

const FuelChargeTaxesPastItem = ({ fuelChargeTax, isCollapseOpen }) => {

  return (
    <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
      <ListItem sx={{ flexDirection: "column", paddingRight: '165px' }}>
        <Stack direction="row" spacing={2}>

          <FormControl variant="standard" disabled sx={{ minWidth: "55%" }}>
            <InputLabel shrink>Nom</InputLabel>
            <Input
              name="name"
              value={fuelChargeTax.name}
              disableUnderline
              sx={{ "input.Mui-disabled": {'WebkitTextFillColor': 'black'}, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled sx={{ minWidth: "11%" }}>
            <InputLabel shrink>%</InputLabel>
            <Input
              name="percent_value"
              type="number"
              value={fuelChargeTax.percent_value}
              disableUnderline
              sx={{ "input.Mui-disabled": {'WebkitTextFillColor': 'black'}, fontSize: 14 }} />
          </FormControl>

          <FormControl variant="standard" disabled sx={{ minWidth: "18.5%" }}>
            <InputLabel shrink>Début</InputLabel>
            <Input
              name="effective_date"
              type="date"
              value={fuelChargeTax.effective_date ?? ""}
              disableUnderline
              sx={{ "input.Mui-disabled": {'WebkitTextFillColor': 'black'}, fontSize: 14 }}
            />
          </FormControl>

          <FormControl variant="standard" disabled sx={{ minWidth: "18.5%" }}>
            <InputLabel shrink>Fin</InputLabel>
            <Input
              name="end_date"
              type="date"
              value={fuelChargeTax.end_date ?? ""}
              disableUnderline
              sx={{ "input.Mui-disabled": {'WebkitTextFillColor': 'black'}, fontSize: 14 }}
            />
          </FormControl>
        </Stack>
      </ListItem>
    </Collapse>
  )
}

export default function FuelChargeTaxesPast({ fuelChargeTaxes, isCollapseOpen }) {

  return fuelChargeTaxes.pastTaxes.map((fuelChargeTax) => {
    return (
      <FuelChargeTaxesPastItem
        fuelChargeTax={fuelChargeTax}
        isCollapseOpen={isCollapseOpen}
      />
    );
  });
}
