import { Button, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { withCustomers } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

import DialogWrapper from "@ui/DialogWrapper";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import FormGroupWrapper from "@ui/FormGroupWrapper";
import FlexRowWrapper from "@ui/FlexRowWrapper";
import HalfFormControl from "@ui/HalfFormControl";
import SelectHalfUi from "@ui/SelectHalf";
import TextFieldUi from "@ui/TextField";
import LoaderSpinner from "@components/LoaderSpinner";

const initialState = {
  errors: {
    adr1: false,
    adr2: false,
    cardNumber: false,
    city: false,
    doorNo: false,
    cardExpirationDate: false,
    cardHolderName: false,
    customerEmail: false,
    customerName: false,
    postalCode: false,
    province: false,
    cardSecurityCode: false,
  },
  adr1: "",
  adr2: "",
  cardNumber: "",
  cardExpirationDate: "",
  cardHolderName: "",
  customerEmail: "",
  customerName: "",
  city: "",
  doorNo: "",
  focus: "",
  note: "",
  postalCode: "",
  province: "",
  cardSecurityCode: "",
  serverErrorMessage: "",
  showSpinner: false,
};

const provinces = [
  { name: <Trans i18nKey="provinces.ab" />, code: "AB" },
  { name: <Trans i18nKey="provinces.bc" />, code: "BC" },
  { name: <Trans i18nKey="provinces.pe" />, code: "PE" },
  { name: <Trans i18nKey="provinces.mb" />, code: "MB" },
  { name: <Trans i18nKey="provinces.nb" />, code: "NB" },
  { name: <Trans i18nKey="provinces.ns" />, code: "NS" },
  { name: <Trans i18nKey="provinces.on" />, code: "ON" },
  { name: <Trans i18nKey="provinces.qc" />, code: "QC" },
  { name: <Trans i18nKey="provinces.sk" />, code: "SK" },
  { name: <Trans i18nKey="provinces.nl" />, code: "NL" },
  { name: <Trans i18nKey="provinces.nu" />, code: "NU" },
  { name: <Trans i18nKey="provinces.nt" />, code: "NT" },
  { name: <Trans i18nKey="provinces.yt" />, code: "YT" },
];

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.basic.error};
`;

class ModalCustomer extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      ...initialState,
      ...props.customer,
    };
  }

  get valid() {
    const errors = { ...initialState.errors };
    let valid = true;

    for (const name of ["adr1", "city", "doorNo", "customerEmail", "customerName", "postalCode", "province"]) {
      if (!this.state[name].trim()) {
        valid = false;
        errors[name] = true;
      }
    }

    // const regex = /^[0-9\b]+$/;
    //
    // for (const name of ['cardNumber', 'cardExpirationDate', 'cardSecurityCode']) {
    //   if (!this.state[name].trim() || regex.test(this.state[name]) === false) {
    //     valid = false;
    //     errors[name] = true;
    //   }
    // }

    // for (const name of ['cardNumber']) {
    //   if (this.state[name].length < 16) {
    //     valid = false;
    //     errors[name] = true;
    //   }
    // }
    //
    // for (const name of ['cardExpirationDate']) {
    //   if (this.state[name].length < 4) {
    //     valid = false;
    //     errors[name] = true;
    //   }
    // }
    //
    // for (const name of ['cardSecurityCode']) {
    //   if (this.state[name].length < 3) {
    //     valid = false;
    //     errors[name] = true;
    //   }
    // }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleClose = () => {
    this.setState(initialState);
    this.setState({ showSpinner: false });
    this.props.onClose();
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      this.setState({ showSpinner: true, serverErrorMessage: "" });

      const { callback, customer, createCustomer, editCustomer } = this.props;

      const {
        adr1,
        adr2,
        cardNumber,
        city,
        doorNo,
        cardExpirationDate,
        cardHolderName,
        customerEmail,
        customerName,
        note,
        postalCode,
        province,
        cardSecurityCode,
      } = this.state;

      const { id } = customer;
      const method = id ? editCustomer : createCustomer;

      const callbackParam = await method(
        {
          adr_1: adr1,
          adr_2: adr2,
          // card_number: cardNumber,
          // card_expiration_date: cardExpirationDate,
          // card_holder_name: cardHolderName,
          // card_security_code: cardSecurityCode,
          city,
          door_no: doorNo,
          email: customerEmail,
          name: customerName,
          note,
          postal_code: postalCode,
          province,
          source: "DISPATCH",
        },
        id
      );

      const { message } = callbackParam;

      if (message) {
        // error occured
        this.setState({ showSpinner: false, serverErrorMessage: message });
      } else {
        this.setState(initialState);
        callback(callbackParam);
      }
    }
  };

  renderMenuItems = (label, data) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ name, code }) => (
      <MenuItem key={code} value={code}>
        {name}
      </MenuItem>
    )),
  ];

  renderServerError = () => {
    const { serverErrorMessage } = this.state;

    if (serverErrorMessage) {
      return (
        <ErrorMessage>
          <Trans i18nKey="error" /> {serverErrorMessage}
        </ErrorMessage>
      );
    }
    return null;
  };

  render() {
    const { actionName } = this.props;
    const {
      adr1,
      adr2,
      cardNumber,
      city,
      doorNo,
      errors,
      cardExpirationDate,
      cardHolderName,
      customerEmail,
      customerName,
      postalCode,
      province,
      cardSecurityCode,
      showSpinner,
    } = this.state;
    const modalTitle =
      actionName.props.id === "add" ? <Trans i18nKey="add_customer" /> : <Trans i18nKey="edit_customer" />;

    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        {showSpinner && <LoaderSpinner text="Création du client..." />}
        <DialogTitle>{modalTitle}</DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <TextFieldUi
                error={errors.name}
                helperText={this.getErrorMessage("customerName")}
                id="cpbr-name"
                inputProps={{ maxLength: 255 }}
                label={<Trans i18nKey="name_simple" />}
                onChange={this.handleChangeFields("customerName")}
                value={customerName || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                error={errors.name}
                helperText={this.getErrorMessage("customerEmail")}
                id="cpbr-email"
                inputProps={{ maxLength: 255 }}
                label={<Trans i18nKey="contact.email" />}
                onChange={this.handleChangeFields("customerEmail")}
                value={customerEmail || ""}
              />
            </FormGroupWrapper>

            <h3>
              <Trans i18nKey="address.adr" />
            </h3>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.doorNo}
                    fullWidth
                    helperText={this.getErrorMessage("doorNo")}
                    id="cpbr-door-no"
                    label={<Trans i18nKey="address.door_no" />}
                    onChange={this.handleChangeFields("doorNo")}
                    value={doorNo || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.postalCode}
                    fullWidth
                    helperText={this.getErrorMessage("postalCode")}
                    id="cpbr-postal-code"
                    label={<Trans i18nKey="address.postal_code" />}
                    onChange={this.handleChangeFields("postalCode")}
                    value={postalCode || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                error={errors.adr1}
                fullWidth
                helperText={this.getErrorMessage("adr1")}
                id="cpbr-adr1"
                label={<Trans i18nKey="address.adr_1" />}
                onChange={this.handleChangeFields("adr1")}
                value={adr1 || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                error={errors.adr2}
                fullWidth
                helperText={this.getErrorMessage("adr2")}
                id="cpbr-adr2"
                label={<Trans i18nKey="address.adr_2" />}
                onChange={this.handleChangeFields("adr2")}
                value={adr2 || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.city}
                    fullWidth
                    helperText={this.getErrorMessage("city")}
                    id="cpbr-city"
                    label={<Trans i18nKey="address.city" />}
                    onChange={this.handleChangeFields("city")}
                    value={city || ""}
                  />
                </HalfFormControl>

                <SelectHalfUi
                  className="cpbr-province-select"
                  formControlError={errors.province}
                  formHelperErrorMsg={this.getErrorMessage("province")}
                  id="cpbr-province"
                  inputLabelText={<Trans i18nKey="address.province" />}
                  onChange={this.handleChangeFields("province")}
                  value={`${province || ""}`}
                >
                  {this.renderMenuItems(<Trans i18nKey="select_province" />, provinces)}
                </SelectHalfUi>
              </FlexRowWrapper>
            </FormGroupWrapper>

            {/*<h3><Trans i18nKey="credit_card.information" /></h3>*/}

            {/*<FormGroupWrapper>*/}
            {/*  <FlexRowWrapper>*/}
            {/*    <HalfFormControl>*/}
            {/*      <TextFieldUi*/}
            {/*        error={errors.cardHolderName}*/}
            {/*        helperText={this.getErrorMessage('cardHolderName')}*/}
            {/*        id="cpbr-holder-name"*/}
            {/*        inputProps={{ maxLength: 255 }}*/}
            {/*        label={<Trans i18nKey="credit_card.holder_name" />}*/}
            {/*        name="name"*/}
            {/*        onChange={this.handleChangeFields('cardHolderName')}*/}
            {/*        onFocus={this.handleInputFocus}*/}
            {/*        value={cardHolderName || ''}*/}
            {/*      />*/}
            {/*    </HalfFormControl>*/}

            {/*    <HalfFormControl>*/}
            {/*      <TextFieldUi*/}
            {/*        error={errors.cardNumber}*/}
            {/*        helperText={this.getErrorMessage('cardNumber', 'invalid')}*/}
            {/*        id="cpbr-card-number"*/}
            {/*        inputProps={{ maxLength: 16 }}*/}
            {/*        label={<Trans i18nKey="credit_card.number" />}*/}
            {/*        name="number"*/}
            {/*        onChange={this.handleChangeFields('cardNumber')}*/}
            {/*        onFocus={this.handleInputFocus}*/}
            {/*        value={cardNumber || ''}*/}
            {/*      />*/}
            {/*    </HalfFormControl>*/}
            {/*  </FlexRowWrapper>*/}
            {/*</FormGroupWrapper>*/}

            {/*<FormGroupWrapper>*/}
            {/*  <FlexRowWrapper>*/}
            {/*    <HalfFormControl>*/}
            {/*      <TextFieldUi*/}
            {/*        error={errors.cardExpirationDate}*/}
            {/*        helperText={this.getErrorMessage('cardExpirationDate', 'invalid')}*/}
            {/*        id="cpbr-expiration-date"*/}
            {/*        inputProps={{ maxLength: 4 }}*/}
            {/*        label={<Trans i18nKey="credit_card.expiration_date" />}*/}
            {/*        name="expiry"*/}
            {/*        onChange={this.handleChangeFields('cardExpirationDate')}*/}
            {/*        onFocus={this.handleInputFocus}*/}
            {/*        placeholder="MMAA"*/}
            {/*        value={cardExpirationDate || ''}*/}
            {/*      />*/}
            {/*    </HalfFormControl>*/}

            {/*    <HalfFormControl>*/}
            {/*      <TextFieldUi*/}
            {/*        error={errors.cardSecurityCode}*/}
            {/*        helperText={this.getErrorMessage('cardSecurityCode', 'invalid')}*/}
            {/*        id="cpbr-security-code"*/}
            {/*        inputProps={{ maxLength: 3 }}*/}
            {/*        label={<Trans i18nKey="credit_card.security_code" />}*/}
            {/*        name="cvc"*/}
            {/*        onChange={this.handleChangeFields('cardSecurityCode')}*/}
            {/*        onFocus={this.handleInputFocus}*/}
            {/*        placeholder="123"*/}
            {/*        value={cardSecurityCode || ''}*/}
            {/*      />*/}
            {/*    </HalfFormControl>*/}
            {/*  </FlexRowWrapper>*/}
            {/*</FormGroupWrapper>*/}
          </form>
          {this.renderServerError()}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalCustomer.defaultProps = {
  customer: {},
};

ModalCustomer.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  callback: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  customer: PropTypes.object,
  editCustomer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withCustomers(ModalCustomer);
