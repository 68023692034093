import svgToTinyDataUri from "mini-svg-data-uri";
export const MarkerSelectedIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#0C9DBD;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerAssignedIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#0000FF;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerUnassignedIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#E73B2F;}
	.st2{fill:#000000;}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerUnfinishedIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#656565;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerFinishedWithAnomalyIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#FDBE02;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerFinishedWithoutAnomalyIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#A3C52D;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerFinishedWithOnlyAnomalyIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#E73B2F;}
	.st2{fill:rgba(255,255,255,0);}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);

export const MarkerLocationIcon = svgToTinyDataUri(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 27 43" style="enable-background:new 0 0 27 43;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#E73B2F;}
	.st2{fill:#000000;}
</style>
<path class="st0" d="M13.5,0.3C5.8,0.3,0.4,5.6,0.4,13.2c0,4.5,2.6,8.2,5.3,12.1c3.2,4.6,6.6,9.3,6.8,16.5c0,0.5,0.5,1,1,1
	s1-0.4,1-1c0.2-7.2,3.6-11.9,6.8-16.5c2.7-3.9,5.3-7.5,5.3-12.1C26.6,5.6,21.2,0.3,13.5,0.3z"/>
<path class="st1" d="M13.5,0.8C6.1,0.8,0.9,5.9,0.9,13.2c0,4.4,2.5,8,5.2,11.8c3.1,4.4,6.7,9.5,6.9,16.8c0,0.3,0.2,0.5,0.5,0.5
	S14,42,14,41.8c0.2-7.3,3.8-12.4,6.9-16.8c2.7-3.8,5.2-7.4,5.2-11.8C26.1,5.9,20.9,0.8,13.5,0.8z"/>
<circle class="st2" cx="13.5" cy="13.4" r="4.7"/>
</svg>`);
