// @ts-check

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "@context/auth/AuthProvider";

const AuthRoute = () => {
  const { auth } = useAuth();

  if (auth.user) return <Navigate to="/" replace />;

  return <Outlet />;
};

export default AuthRoute;
