import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Trans } from "react-i18next";

import FrequencyTextField from "./ui/FrequencyTextField";

const RecurrenceAnnualy = ({ error, onIntervalChange, value }) => (
  <Fragment>
    <FrequencyTextField
      error={error}
      inputLabel={<Trans i18nKey="all_the" />}
      sideLabel={<Trans i18nKey="years" />}
      onChange={onIntervalChange}
      value={value}
      onKeyPress={event => !/[1-9]/.test(event.key) && event.preventDefault()}
      InputProps={{ inputProps: { min: 1, max: 99 } }}
      sx={{ minWidth: 100 }}
    />
  </Fragment>
);

RecurrenceAnnualy.defaultProps = {
  value: null,
};

RecurrenceAnnualy.propTypes = {
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]).isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default RecurrenceAnnualy;
