import { Button, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { withListCodes } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import TextAreaUi from "./ui/TextArea";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import SelectHalfUi from "./ui/SelectHalf";
import * as API from "@services";

const initialState = {
  errors: {
    axles: false,
    bucketBrand: false,
    bucketLoadingCapacityImperial: false,
    bucketLoadingCapacityMetric: false,
    bucketModel: false,
    compactionAndUnloadingSystems: false,
    ghgReductionMeasures: false,
    loadingType: false,
    name: false,
    note: false,
    truckBrand: false,
    truckId: false,
    truckModel: false,
    truckSerialNumber: false,
    truckRegistration: false,
    unloadedWeight: false,
    year: false,
  },
  axles: "",
  bucketBrand: "",
  bucketLoadingCapacityImperial: "",
  bucketLoadingCapacityMetric: "",
  bucketModel: "",
  compactionAndUnloadingSystems: "",
  ghgReductionMeasures: "",
  loadingType: "-1",
  name: "",
  note: "",
  truckBrand: "",
  truckId: "",
  truckModel: "",
  truckSerialNumber: "",
  truckRegistration: "",
  unloadedWeight: "",
  year: "",
  loadingTypes: [],
};

class ModalUnit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      ...props.unit,
    };
  }

  componentDidMount() {
    this.fetchListCode();
  }

  fetchListCode = async () => {
    const { specificListOfCodes } = this.props;

    const res = await specificListOfCodes("UNIT_TYPES");

    const loadingTypes = res.map((unitType) => {
      return { label: unitType.name, value: unitType.id };
    });

    this.setState({ loadingTypes: loadingTypes });
  };

  get valid() {
    const errors = { ...initialState.errors };
    let valid = true;

    for (const name of ["loadingType", "name"]) {
      if (this.state[name].toString().trim() === "" || this.state[name].toString().trim() === "-1") {
        valid = false;
        errors[name] = true;
      }
    }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleClose = () => {
    const { actionName } = this.props;
    if (actionName.props.id === "add") {
      this.setState(initialState);
    }
    this.props.onClose();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { unit, refreshList } = this.props;

      const {
        axles,
        bucketBrand,
        bucketLoadingCapacityImperial,
        bucketLoadingCapacityMetric,
        bucketModel,
        compactionAndUnloadingSystems,
        ghgReductionMeasures,
        loadingType,
        name,
        note,
        truckBrand,
        truckId,
        truckModel,
        truckSerialNumber,
        truckRegistration,
        unloadedWeight,
        year,
      } = this.state;

      const { id } = unit;
      const method = id ? API.Unit.editUnit : API.Unit.createUnit;

      await method(
        {
          axles,
          bucket_brand: bucketBrand,
          bucket_loading_capacity_imperial: bucketLoadingCapacityImperial,
          bucket_loading_capacity_metric: bucketLoadingCapacityMetric,
          bucket_model: bucketModel,
          compaction_and_unloading_systems: compactionAndUnloadingSystems,
          ghg_reduction_measures: ghgReductionMeasures,
          loading_type: loadingType,
          name,
          note,
          truck_brand: truckBrand,
          truck_id: truckId,
          truck_model: truckModel,
          truck_serial_number: truckSerialNumber,
          truck_registration: truckRegistration,
          unloaded_weight: unloadedWeight,
          year,
        },
        id
      );

      this.setState(initialState);

      refreshList();
      this.handleClose();
    }
  };

  render() {
    const { actionName } = this.props;
    const {
      errors,
      axles,
      bucketBrand,
      bucketLoadingCapacityImperial,
      bucketLoadingCapacityMetric,
      bucketModel,
      compactionAndUnloadingSystems,
      ghgReductionMeasures,
      loadingType,
      name,
      note,
      truckBrand,
      truckId,
      truckModel,
      truckSerialNumber,
      truckRegistration,
      unloadedWeight,
      year,
      loadingTypes,
    } = this.state;

    const modalTitle =
      actionName.props.id === "add" ? (
        <Trans i18nKey="units.add_unit" />
      ) : (
        <Trans i18nKey="units.edit_unit" />
      );

    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        <DialogTitle>{modalTitle}</DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.name}
                    helperText={this.getErrorMessage("name")}
                    id="cpbr-unit-name"
                    label={<Trans i18nKey="units.name" />}
                    onChange={this.handleChangeFields("name")}
                    value={name || ""}
                    maxLength={255}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.truckId}
                    helperText={this.getErrorMessage("truckId")}
                    id="cpbr-unit-truckId"
                    label={<Trans i18nKey="units.truck_id" />}
                    onChange={this.handleChangeFields("truckId")}
                    value={truckId || ""}
                    maxLength={255}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.truckBrand}
                    helperText={this.getErrorMessage("truckBrand")}
                    id="cpbr-unit-truckBrand"
                    label={<Trans i18nKey="units.truck_brand" />}
                    onChange={this.handleChangeFields("truckBrand")}
                    value={truckBrand || ""}
                    maxLength={255}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.truckModel}
                    helperText={this.getErrorMessage("truckModel")}
                    id="cpbr-unit-truckModel"
                    label={<Trans i18nKey="units.truck_model" />}
                    onChange={this.handleChangeFields("truckModel")}
                    value={truckModel || ""}
                    maxLength={255}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.year}
                    helperText={this.getErrorMessage("year")}
                    id="cpbr-unit-year"
                    label={<Trans i18nKey="units.year" />}
                    onChange={this.handleChangeFields("year")}
                    value={year || ""}
                    type="number"
                    inputProps={{ min: 1900 }}
                  />
                </HalfFormControl>

                <SelectHalfUi
                  error={errors.loadingType}
                  formControlError={errors.loadingType}
                  formHelperErrorMsg={this.getErrorMessage("loadingType")}
                  id="cpbr-unit-loadingType"
                  inputLabelText={"Type de collecte"}
                  onChange={this.handleChangeFields("loadingType")}
                  value={loadingType}
                >
                  {[
                    <MenuItem key="-1" value="-1">
                      Sélectionner type de collecte
                    </MenuItem>,

                    loadingTypes.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )),
                  ]}
                </SelectHalfUi>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.axles}
                    helperText={this.getErrorMessage("axles")}
                    id="cpbr-unit-axles"
                    label={<Trans i18nKey="units.axles" />}
                    onChange={this.handleChangeFields("axles")}
                    value={axles || ""}
                    type="number"
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.unloadedWeight}
                    helperText={this.getErrorMessage("unloadedWeight")}
                    id="cpbr-unit-unloadedWeight"
                    label={<Trans i18nKey="units.unloaded_weight" />}
                    onChange={this.handleChangeFields("unloadedWeight")}
                    value={unloadedWeight || ""}
                    type="number"
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.truckSerialNumber}
                    helperText={this.getErrorMessage("truckSerialNumber")}
                    id="cpbr-unit-truckSerialNumber"
                    label={<Trans i18nKey="units.truck_serial_number" />}
                    onChange={this.handleChangeFields("truckSerialNumber")}
                    value={truckSerialNumber || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.truckRegistration}
                    helperText={this.getErrorMessage("truckRegistration")}
                    id="cpbr-unit-truckRegistration"
                    label={<Trans i18nKey="units.truck_registration" />}
                    onChange={this.handleChangeFields("truckRegistration")}
                    value={truckRegistration || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.bucketBrand}
                    helperText={this.getErrorMessage("bucketBrand")}
                    id="cpbr-unit-bucketBrand"
                    label={<Trans i18nKey="units.bucket_brand" />}
                    onChange={this.handleChangeFields("bucketBrand")}
                    value={bucketBrand || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.bucketModel}
                    helperText={this.getErrorMessage("bucketModel")}
                    id="cpbr-unit-bucketModel"
                    label={<Trans i18nKey="units.bucket_model" />}
                    onChange={this.handleChangeFields("bucketModel")}
                    value={bucketModel || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.bucketLoadingCapacityMetric}
                    helperText={this.getErrorMessage("bucketLoadingCapacityMetric")}
                    id="cpbr-unit-bucketLoadingCapacityMetric"
                    label={<Trans i18nKey="units.bucket_loading_capacity_metric" />}
                    onChange={this.handleChangeFields("bucketLoadingCapacityMetric")}
                    value={bucketLoadingCapacityMetric || ""}
                    type="number"
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.bucketLoadingCapacityImperial}
                    helperText={this.getErrorMessage("bucketLoadingCapacityImperial")}
                    id="cpbr-unit-bucketLoadingCapacityImperial"
                    label={<Trans i18nKey="units.bucket_loading_capacity_imperial" />}
                    onChange={this.handleChangeFields("bucketLoadingCapacityImperial")}
                    value={bucketLoadingCapacityImperial || ""}
                    type="number"
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                error={errors.compactionAndUnloadingSystems}
                helperText={this.getErrorMessage("compactionAndUnloadingSystems")}
                id="cpbr-unit-compactionAndUnloadingSystems"
                label={<Trans i18nKey="units.compaction_and_unloading_systems" />}
                onChange={this.handleChangeFields("compactionAndUnloadingSystems")}
                value={compactionAndUnloadingSystems || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                error={errors.ghgReductionMeasures}
                helperText={this.getErrorMessage("ghgReductionMeasures")}
                id="cpbr-unit-ghgReductionMeasures"
                label={<Trans i18nKey="units.ghg_reduction_measures" />}
                onChange={this.handleChangeFields("ghgReductionMeasures")}
                value={ghgReductionMeasures || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                error={errors.note}
                helperText={this.getErrorMessage("note")}
                id="cpbr-unit-note"
                label={<Trans i18nKey="units.note" />}
                onChange={this.handleChangeFields("note")}
                value={note || ""}
              />
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalUnit.defaultProps = {
  unit: {},
};

ModalUnit.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  refreshList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  unit: PropTypes.object,
};

export default withListCodes(ModalUnit);
