import { ApiResponse } from "@@types/apiResponse";
import ListCode from "@@types/list-codes/list-code.type";

import { axiosClientV2 as http } from "../axios";

export type FetchListCodesServiceReturn = ListCode[];

const fetchListCodesService = async (codeType: string): Promise<FetchListCodesServiceReturn> => {
  try {
    const res = await http.get<ApiResponse<FetchListCodesServiceReturn>>(`/list_codes?code_type=${codeType}`);

    return res.data?.data || [];
  } catch (error) {
    throw error as Error;
  }
};

export default fetchListCodesService;
