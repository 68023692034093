import {
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { debounce } from "lodash";
import { withContracts } from "optigo-redux";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";

import styled from "@emotion/styled";

import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TextFieldUi from "./ui/TextField";
import Tooltip from "./ui/Tooltip";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import FloatingActionButton from "@ui/FloatingActionButton";
import AddIcon from "@mui/icons-material/Add";
import ModalContract from "@components/modals/contract/ModalContract";

const RemoveIconWrapper = styled(RemoveIcon)`
  font-size: 12px !important;
  position: relative;
`;

const ToolbarFormControlWrapper = styled.div`
  margin-right: 25px;
`;

const data = {
  customerName: { label: <Trans i18nKey="client_contract" /> },
  name: { label: <Trans i18nKey="name_simple" /> },
  no: { label: <Trans i18nKey="no" /> },
  startDate: { label: <Trans i18nKey="start_date" /> },
};

const statuses = [
  { label: <Trans i18nKey="status.all" />, value: "ALL" },
  { label: <Trans i18nKey="status.init" />, value: "INIT" },
  { label: <Trans i18nKey="status.quoted" />, value: "QUOTED" },
];

class ContractsList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "identifier",
    direction: "asc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    customerToDelete: null,
    statusFilter: statuses[0].value,
    sort: {
      ...this.initialSort,
    },
    openModalCustomerContract: false,
  };

  state = {
    ...this.initialState,
  };

  componentDidMount() {
    this.fetchContracts();
  }

  componentWillUnmount() {
    this.props.flushContracts();
  }

  debouncedFetchContracts = debounce(() => {
    this.fetchContracts();
  }, 300);

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchContracts
    );
  };

  handleCloseModalContract = () => {
    this.setState((prevState) => ({ openModalCustomerContract: !prevState.openModalCustomerContract }));
    this.fetchAndResetPagination();
  };

  fetchContracts = () => {
    const { filter, limit, page, statusFilter } = this.state;

    const { fetchContracts, fetchContractsUnprepared, router } = this.props;

    const { params } = router;
    const { customerId } = params;

    const contractParams = {
      filter: filter.trim(),
      page: page + 1,
      status: statusFilter,
      limit,
    };

    customerId ? fetchContracts(customerId, contractParams) : fetchContractsUnprepared(contractParams);
  };

  handleNavigate = (path) => () => {
    const { router } = this.props;
    router.navigate(path);
  };

  handleSelectFilterChange =
    (name) =>
      ({ target }) => {
        this.setState(
          {
            [name]: target.value,
          },
          this.fetchContracts
        );
      };

  handleFilter = handleFilter(this.debouncedFetchContracts).bind(this);
  handlePageChange = handlePageChange(this.fetchContracts).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchContracts).bind(this);
  handleSort = handleSort.bind(this);

  renderCheckbox = (checked) =>
    checked ? <CheckCircleIcon color="success" fontSize="small" /> : <RemoveIconWrapper fontSize="small" />;

  // renderPreparationIcon = (contract) => (
  //   <Tooltip title={<Trans i18nKey="prepare" />}>
  //     <IconButton
  //       color="secondary"
  //       id="cpbr-prepare"
  //       onClick={this.handleToggleModalPreparation(true, contract)}
  //       size="large"
  //     >
  //       <BuildIcon fontSize="small" />
  //     </IconButton>
  //   </Tooltip>
  // );

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["customerName", "no", "name", "startDate"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel active={columnName === name} direction={direction} onClick={this.handleSort(name)}>
                {data[name].label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableRows = () => {
    const { contracts, contractsLoading } = this.props;

    if (contractsLoading) {
      return <TableLoading />;
    }

    return sortedData(contracts, this.state).map((contract) => {
      const { id, contractNo, customerName, customerId, name, startDate } = contract;

      const path = `/customers/${customerId}/contracts/${id}`;

      return (
        <TableRow
          key={id}
          onClick={this.handleNavigate(path)}
          sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        >
          <TableCell sx={{ maxWidth: 300 }}>{customerName}</TableCell>

          <TableCell>{contractNo}</TableCell>

          <TableCell sx={{ maxWidth: 300 }}>{name}</TableCell>

          <TableCell classes={{ root: "date-cell" }}>{moment(startDate).format("YYYY-MM-DD")}</TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { filter, limit, page, openModalCustomerContract } = this.state;
    const { router } = this.props;
    const { params } = router;
    const { customerId } = params;

    return (
      <PageContainer>
        <PaperWrapper>
          <div>
            <FloatingActionButton
              color="secondary"
              onClick={
                // eslint-disable-next-line max-len
                () => this.setState({ openModalCustomerContract: !openModalCustomerContract })
              }
            >
              <AddIcon />
            </FloatingActionButton>
          </div>
          <TableOverflowWrapper>
            <Toolbar>
              <ToolbarFormControlWrapper>
                <TextFieldUi
                  id="cpbr-filtre"
                  label={<Trans i18nKey="filter" />}
                  onChange={this.handleFilter}
                  type="search"
                  value={filter}
                />
              </ToolbarFormControlWrapper>
            </Toolbar>

            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={this.props.contractsCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />
        </PaperWrapper>

        {openModalCustomerContract && (
          <ModalContract
            open={openModalCustomerContract}
            onClose={this.handleCloseModalContract}
            customer={this.props.customer}
          />
        )}
      </PageContainer>
    );
  }
}

ContractsList.defaultProps = {
  contractsLoading: true,
};

ContractsList.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.object).isRequired,
  contractsCount: PropTypes.number.isRequired,
  contractsLoading: PropTypes.bool,
  fetchContracts: PropTypes.func.isRequired,
  fetchContractsUnprepared: PropTypes.func.isRequired,
  flushContracts: PropTypes.func.isRequired,
};

export default withRouter(withContracts(ContractsList));
