import { TableCell as TableCellMU, TableRow } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

const TableCellSC = styled(TableCellMU)`
  && {
    background: ${({ theme }) => theme.colors.palette.background.light};
    text-align: center;
  }
`;

const TableCellNoData = () => (
  <TableRow>
    <TableCellSC colSpan="100">
      <Trans i18nKey="no_data" />
    </TableCellSC>
  </TableRow>
);

export default TableCellNoData;
