import axios from "axios";

import { requestOptions } from "../auth";

const retrieveStripeCustomer = async (stripeCustomerID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_customer?stripe_customer_id=${stripeCustomerID}`,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripeCustomer] error:", error);

    return error.response.data;
  }
};

const createStripeSetupIntent = async (stripeCustomerID) => {
  const payload = { stripe_customer_id: stripeCustomerID };

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/stripe/create_setup_intent`,
      payload,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.createStripeSetupIntent] Error:", error);

    throw error.response.data;
  }
};

const retrieveStripeSetupIntent = async (stripeSetupIntentID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_setup_intent?setup_intent_id=${stripeSetupIntentID}`,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripeSetupIntent] error:", error);

    return error.response.data;
  }
};

const retrieveStripePaymentIntent = async (stripePaymentIntentID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_payment_intent?payment_intent_id=${stripePaymentIntentID}`,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripePaymentIntent] error:", error);

    return error.response.data;
  }
};

const retrieveStripePaymentIntentWithPurchases = async (stripePaymentIntentID) => {
  try {
    const res = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/stripe/retrieve_payment_intent_with_purchases?payment_intent_id=${stripePaymentIntentID}`,
      requestOptions()
    );

    return res.data.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripePaymentIntentWithPurchases] error:", error);

    return error.response.data;
  }
};

const retrieveStripePaymentMethod = async (stripePaymentMethodID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_payment_method?payment_method_id=${stripePaymentMethodID}`,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripePaymentMethod] error:", error);

    // Backend returns us the error message in data response
    return error.response.data;
  }
};

const retrieveStripeCustomerPaymentMethods = async (stripeCustomerID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_customer_payment_methods?stripe_customer_id=${stripeCustomerID}`,
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripeCustomerPaymentMethods] error:", error);

    // Backend returns us the error message in data response
    throw error.response.data;
  }
};

const detachStripeCustomerPaymentMethod = async (stripePaymentMethodID) => {
  try {
    const res = await axios.post(
      `${
        import.meta.env.VITE_API_URL
      }/stripe/detach_customer_payment_method?payment_method_id=${stripePaymentMethodID}`,
      {},
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[StripeService.detachStripeCustomerPaymentMethod] error:", error);

    // Backend returns us the error message in data response
    throw error.response.data;
  }
};

const retrieveStripeCustomerPurchase = async (stripePaymentIntentID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_customer_purchase?payment_intent_id=${stripePaymentIntentID}`,
      requestOptions()
    );

    return res.data.data.purchases;
  } catch (error) {
    console.warn("[StripeService.retrieveStripeCustomerPurchase] error:", error);

    // Backend returns us the error message in data response
    return error.response.data;
  }
};

const retrieveStripeCustomerPurchases = async (stripeCustomerID) => {
  return [];
  // try {
  //   const res = await axios.get(
  //     `${import.meta.env.VITE_API_URL}/stripe/retrieve_customer_purchases?customer=${stripeCustomerID}`,
  //     requestOptions()
  //   );
  //
  //   return res.data.data.purchases;
  // } catch (error) {
  //   console.warn("[StripeService.retrieveStripeCustomerPurchases] error:", error);
  //
  //   // Backend returns us the error message in data response
  //   return error.response.data;
  // }
};

const retrieveStripeCustomerFull = async (stripeCustomerID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/retrieve_customer_full?customer=${stripeCustomerID}`,
      requestOptions()
    );

    if (res.data.status !== "success") {
      console.warn("[StripeService.retrieveStripeCustomerFull] status is not successfull:", res);
      return null;
    }

    return res.data.data;
  } catch (error) {
    console.warn("[StripeService.retrieveStripeCustomerFull] error:", error);

    // Backend returns us the error message in data response
    return error.response.data;
  }
};

const customerCreditCardsValidation = async (stripeCustomerID) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/stripe/customer_credit_cards_validation?stripe_customer_id=${stripeCustomerID}`,
      requestOptions()
    );

    if (res.data.status === "failure") {
      console.warn("[API.customerCreditCardsValidation] status is not successfull:", res);
    }

    return res.data;
  } catch (error) {
    console.warn("[API.customerCreditCardsValidation] error:", error);

    // Backend returns us the error message in data response
    return error.response.data;
  }
};
/**
 * Stripe api service for exposing api endpoints.
 *
 * Available requests:
 * - `retrieveStripeCustomer(stripeCustomerID)`
 * - `createStripeSetupIntent(stripeCustomerID)`
 * - `retrieveStripeSetupIntent(stripeSetupIntentID)`
 * - `retrieveStripePaymentIntent(stripePaymentIntentID)`
 * - `retrieveStripePaymentIntentWithPurchases(stripePaymentIntentID)`
 * - `retrieveStripePaymentMethod(stripePaymentMethodID)`
 * - `retrieveStripeCustomerPaymentMethods(stripeCustomerID)`
 * - `detachStripeCustomerPaymentMethod(stripePaymentMethodID)`
 * - `retrieveStripeCustomerPurchase(stripePaymentIntentID)`
 * - `retrieveStripeCustomerPurchases(stripeCustomerID)`
 * - `retrieveStripeCustomerFull(stripeCustomerID)`
 * - `customerCreditCardsValidation(stripeCustomerID)`
 */
export {
  retrieveStripeCustomer,
  createStripeSetupIntent,
  retrieveStripeSetupIntent,
  retrieveStripePaymentIntent,
  retrieveStripePaymentIntentWithPurchases,
  retrieveStripePaymentMethod,
  retrieveStripeCustomerPaymentMethods,
  detachStripeCustomerPaymentMethod,
  retrieveStripeCustomerFull,
  retrieveStripeCustomerPurchase,
  retrieveStripeCustomerPurchases,
  customerCreditCardsValidation,
};
