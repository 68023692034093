import { reducers as optigoReducers } from "optigo-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

// User reducer
const initialState = {};
const user = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  ...optigoReducers,
  user,
});

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
