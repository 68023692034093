import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import {
  withCustomerLocations,
  withJobs,
  withJobTemplates,
  withLocations,
  withRoutes,
  withRouteTemplates,
  withSupplierLocations,
} from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";

import DatePicker from "./form/DatePickerMui";
import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import RecurrenceAnnualy from "./RecurrenceAnnualy";
import RecurrenceMonthly from "./RecurrenceMonthly";
import RecurrenceWeekly from "./RecurrenceWeekly";
import RecurrenceDaily from "./RecurrenceDaily";
import SelectUi from "./ui/Select";
import SelectHalfUi from "./ui/SelectHalf";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { filterJobTemplatesByCustomerItem, filterSupplierLocations } from "@utils/filtering";
import { formattedDate } from "@utils/dates";
import TextAreaUi from "./ui/TextArea";
import AutocompleteModal from "./ui/AutocompleteModal";
import styled from "@emotion/styled";
import LoaderSpinner from "./LoaderSpinner";
import { orderBy } from "lodash";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as API from "@services";

const frequencies = [
  { label: <Trans i18nKey="frequencies.daily" />, value: "DAILY" },
  { label: <Trans i18nKey="frequencies.weekly" />, value: "WEEKLY" },
  { label: <Trans i18nKey="frequencies.monthly" />, value: "MONTHLY" },
  { label: <Trans i18nKey="frequencies.annualy" />, value: "ANNUALY" },
];

const initialState = {
  errors: {
    customerDestinationLocationId: false,
    customerLocationId: false,
    // depositId: false,
    jobTemplateId: false,
    frequency: false,
    recurrence: false,
    startDate: false,
    routeTemplateId: false,
    smsAlertPhoneNumber: false,
    days_of_month: false,
    days_of_week: false,
    days: false,
  },
  averageLiftingTime: "",
  purchaseOrderNo: "",
  smsAlertPhoneNumber: "",
  customerMobilePhoneNumber: "",
  customerDestinationLocationId: "-1",
  customerLocationId: "-1",
  // depositId: "-1",
  frequency: frequencies[0].value,
  jobTemplateId: "-1",
  locationModalOpened: false,
  // noteCommentsColor: "#000",
  // noteComments: "",
  noteSchedule: "",
  noteLocation: "",
  currentCustomerLocationNote: "",
  currentCustomerPurchaseOrderNo: "",
  noteLocationColor: "#000",
  recurrence: { interval: null },
  routeTemplateId: "-1",
  showSpinner: false,
  startDate: formattedDate(),
  supplierLocationId: "-1",
  supplierSecondLocationId: "-1",
  unitId: "-1",
  routeFrequencyWeeklyChecked: true,
  routeFrequencyTwiceMonthlyChecked: true,
  routeFrequencyMonthlyChecked: true,
  units: [],
};

const LiftingFrequencyWrapper = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.basic.lightGrey};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  padding: ${({ theme }) => theme.inputPadding};
  padding-left: ${({ adornment, theme }) => (adornment === "start" ? "40px" : theme.inputPadding)};
  padding-right: ${({ adornment, theme }) => (adornment === "end" ? "40px" : theme.inputPadding)};
  display: flex;
  align-items: space-between;
  flex-direction: row;
  position: relative;
  padding: 20px;

  .color-picker-label {
    position: absolute;
    top: -6px;
    left: 6px;
    background: #fff;
    padding: 0 5px;
    font-size: 12px;
  }
`;

class ModalRecurrence extends PureComponent {
  state = {
    ...initialState,
  };

  async componentDidMount() {
    await this.fetchAllSuppliersLocations();
    this.fetchCustomerLocations();
    await this.fetchUnits();
    this.defaultSupplierLocation();
    this.fetchJobTemplates();
    const { router } = this.props;
    const { customerItemId } = router.params;
    this.props.fetchRouteTemplatesByCustomerItem(customerItemId, { limit: "all" }); // TODO: On devrait seuelemtn faire ça si on choisi Route
  }

  get valid() {
    const errors = { ...initialState.errors };
    const {
      customerDestinationLocationId,
      jobTemplateId,
      recurrence,
      startDate,
      routeTemplateId,
      smsAlertPhoneNumber,
      frequency,
    } = this.state;
    let valid = true;
    const { customerItem } = this.props;
    const { contractType } = customerItem;

    for (const name of ["frequency", "customerLocationId", "jobTemplateId"]) {
      if (this.state[name] === "-1" && contractType === "job") {
        valid = false;
        errors[name] = true;
      }
    }

    if (jobTemplateId !== "-1" && contractType === "job") {
      const jobTemplate = this.getJobTemplate(jobTemplateId);

      if (jobTemplate.code === "RE" && customerDestinationLocationId === "-1") {
        valid = false;
        errors.customerDestinationLocationId = true;
      }
    }
    if (routeTemplateId === "-1" && contractType === "route") {
      valid = false;
      errors.routeTemplateId = true;
    }

    if (!startDate && contractType === "job") {
      valid = false;
      errors.startDate = true;
    }

    if (!recurrence.interval) {
      valid = false;
      errors.recurrence = true;
    }

    if (frequency === "MONTHLY" && recurrence.type === "monthly-DOM") {
      if (!recurrence.days_of_month || recurrence.days_of_month.length === 0) {
        valid = false;
        errors.days_of_month = true;
      }
    }

    if (frequency === "MONTHLY" && recurrence.type === "monthly-DOW") {
      if (
        !recurrence.days_of_week ||
        (recurrence.days_of_week &&
          Object.keys(recurrence.days_of_week).filter((week) => recurrence.days_of_week[week].length > 0).length === 0)
      ) {
        valid = false;
        errors.days_of_week = true;
      }
    }

    if (frequency === "WEEKLY") {
      if (!recurrence.days || recurrence.days.length === 0) {
        valid = false;
        errors.days = true;
      }
    }

    // Validate if phone is a number and has 10 digits
    if (smsAlertPhoneNumber) {
      const phoneNumber = smsAlertPhoneNumber.replace(/[^\d]/g, "");
      if (phoneNumber.length > 11 || phoneNumber.length < 10) {
        valid = false;
        errors.smsAlertPhoneNumber = true;
      }
    }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  // eslint-disable-next-line react/sort-comp
  fetchAllSuppliersLocations = async () => {
    await this.props.fetchAllSuppliersLocations();
  };

  fetchCustomerLocations = () => {
    const { fetchCustomerLocations, router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    fetchCustomerLocations(customerId, { limit: -1 }, contractId);
  };

  fetchCustomerLocationsAndCloseModal = () => {
    this.setState(
      {
        locationModalOpened: false,
      },
      this.fetchCustomerLocations
    );
  };

  fetchUnits = async () => {
    const units = await API.Unit.fetchUnits({ rowsPerPage: "all" });

    this.setState({ units: units?.instances ?? [] });
  };

  fetchJobTemplates = async () => {
    const { fetchJobTemplates } = this.props;
    await fetchJobTemplates();
  };

  // eslint-disable-next-line max-len
  getJobTemplate = (jobTemplateId) => this.props.jobTemplates.find(({ id }) => id === jobTemplateId) || {};

  // eslint-disable-next-line max-len
  getSupplierLocation = (locationId) => this.props.locations.find(({ id }) => id === locationId) || {};

  getRouteTemplate = (routeTemplateId) => this.props.routeTemplates.find(({ id }) => id === routeTemplateId) || {};

  toggleFrequencyCheckbox = (frequency) => (event) => {
    const stateVariable = `routeFrequency${frequency}Checked`;
    this.setState({ [stateVariable]: !this.state[stateVariable] });
  };

  handleChangeFields = handleChangeFields.bind(this);

  handleChangeCustomerLocation = () => (value) => {
    if (!this.props.customerLocationsLoading) {
      const customerLocationId = value.value; // jhaïs ça
      const customerLocation = this.props.customerLocations.find(
        (loc) => loc.id.toString() === customerLocationId.toString()
      );
      this.setState({
        errors: {
          customLocationId: false,
        },
        customerLocationId: value,
        currentCustomerLocationNote: customerLocation.note,
        currentCustomerPurchaseOrderNo: customerLocation.purchaseOrderNo,
        noteLocation: customerLocation.note,
        purchaseOrderNo: customerLocation.purchaseOrderNo,
        customerMobilePhoneNumber: customerLocation.customerMobilePhoneNumber,
      });
    }
  };

  handleChangeFrequency = ({ target }) => {
    this.setState({
      errors: {
        ...this.state.errors,
        frequency: false,
        recurrence: false,
      },
      frequency: target.value,
      recurrence: {},
    });
  };

  handleChangeRecurrenceInterval =
    (type) =>
      ({ target }) => {
        this.setState({
          errors: {
            ...this.state.errors,
            recurrence: false,
          },
          recurrence: {
            ...this.state.recurrence,
            interval: target.value,
            type,
          },
        });
      };

  handleChangeRecurrenceDays = (key) => (days) => {
    this.setState({
      errors: {
        ...this.state.errors,
        days_of_month: false,
        days_of_week: false,
        days: false,
      },
      recurrence: {
        ...this.state.recurrence,
        [key]: days,
      },
    });
  };

  handleChangeRecurrenceType = (type) => {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        type,
      },
    });
  };

  handleChangeStartDate = (date) => {
    this.setState({
      errors: {
        ...this.state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  handleChangeRouteTemplateId =
    (name) =>
      ({ target }) => {
        const routeTemplateSelected = this.props.routeTemplates.filter((f) => target.value === f.id);

        this.setState(
          {
            errors: {
              ...this.state.errors,
              [name]: false,
            },
            [name]: target.value,
          },
          this.setState({
            name: routeTemplateSelected[0].name,
            jobTemplateId: routeTemplateSelected[0].jobTemplateId,
            supplierLocationId: routeTemplateSelected[0].supplierLocationId,
            averageLiftingTime: routeTemplateSelected[0].averageLiftingTime,
          })
        );
      };

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleDisplayFrequencyComponent = () => {
    const { frequency, recurrence } = this.state;

    let Component;
    let onIntervalChange;
    let daysError;

    switch (frequency) {
      case "ANNUALY": {
        Component = RecurrenceAnnualy;
        onIntervalChange = this.handleChangeRecurrenceInterval("yearly");
        break;
      }
      case "MONTHLY": {
        daysError = recurrence.type === "monthly-DOM" ? "days_of_month" : "days_of_week";
        Component = RecurrenceMonthly;
        onIntervalChange = this.handleChangeRecurrenceInterval;
        break;
      }
      case "WEEKLY": {
        daysError = "days";
        Component = RecurrenceWeekly;
        onIntervalChange = this.handleChangeRecurrenceInterval("weekly");
        break;
      }
      default: {
        Component = RecurrenceDaily;
        onIntervalChange = this.handleChangeRecurrenceInterval("daily");
        break;
      }
    }

    return (
      <Component
        daysError={this.state.errors[daysError]}
        error={this.getErrorMessage("recurrence")}
        onDaysChange={this.handleChangeRecurrenceDays}
        onIntervalChange={onIntervalChange}
        onTypeChange={this.handleChangeRecurrenceType}
        value={recurrence.interval}
      />
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { createJob, router, refreshList, createRoute, customerItem } = this.props;
      const { contractType } = customerItem;

      this.setState({ showSpinner: true });

      const {
        averageLiftingTime,
        customerDestinationLocationId,
        customerLocationId,
        // depositId,
        jobTemplateId,
        //noteComments,
        // noteSchedule,
        //noteCommentsColor,
        noteLocation,
        noteLocationColor,
        purchaseOrderNo,
        recurrence,
        routeTemplateId,
        name,
        startDate,
        supplierLocationId,
        supplierSecondLocationId,
        unitId,
        routeFrequencyWeeklyChecked,
        routeFrequencyMonthlyChecked,
        routeFrequencyTwiceMonthlyChecked,
        smsAlertPhoneNumber,
      } = this.state;

      if (contractType === "job") {
        await createJob(router.params.customerItemId, {
          customer_destination_location_id: customerDestinationLocationId,
          customer_location_id: customerLocationId.value,
          // deposit_id: depositId,
          job_template_id: jobTemplateId,
          //note_comments: noteComments.trim(),
          //note_comments_color: noteCommentsColor,
          // note_schedule: noteSchedule.trim(),
          note_location: noteLocation ? noteLocation.trim() : "",
          note_location_color: noteLocationColor ? noteLocationColor.trim() : "",
          purchase_order_no: purchaseOrderNo ? purchaseOrderNo.trim() : "",
          start_date: startDate,
          supplier_location_id: supplierLocationId,
          supplier_second_location_id: supplierSecondLocationId,
          unit_id: unitId,
          recurrence,
          sms_alert_phone_number: smsAlertPhoneNumber,
        });
      } else {
        await createRoute({
          customer_item_id: router.params.customerItemId,
          job_template_id: jobTemplateId,
          route_template_id: routeTemplateId,
          name,
          average_lifting_time: averageLiftingTime,
          start_date: startDate,
          unit_id: unitId,
          supplier_location_id: supplierLocationId,
          weekly: routeFrequencyWeeklyChecked,
          monthly: routeFrequencyMonthlyChecked,
          twice_monthly: routeFrequencyTwiceMonthlyChecked,
          recurrence,
        });
      }

      this.setState(initialState);

      refreshList();
    }
  };

  handleToggleLocationModal = (opened) => () => {
    this.setState({
      locationModalOpened: opened,
    });
  };

  defaultSupplierLocation = () => {
    const newState = {};
    const { customerItem } = this.props;
    if (customerItem) {
      const { contractMatterSpec } = customerItem;
      if (contractMatterSpec && contractMatterSpec.length > 0) {
        const supplierLocations = ["supplierLocationId", "supplierSecondLocationId"];
        contractMatterSpec.forEach(({ defaultSupplierLocationId }, index) => {
          if (defaultSupplierLocationId) {
            newState[supplierLocations[index]] = defaultSupplierLocationId;
          }
        });
      }
      if (Object.keys(newState).length > 0) {
        this.setState(newState);
      }
    }
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderLocationsFields = () => {
    const { customerDestinationLocationId, errors, jobTemplateId, supplierLocationId, supplierSecondLocationId } =
      this.state;

    const { customerLocations, supplierLocations } = this.props;

    const sortedCustomerLocations = orderBy(customerLocations, ["name", "id"], ["asc", "asc"]);

    const jobTemplate = this.getJobTemplate(jobTemplateId);

    if (jobTemplate.code === "RE") {
      return (
        <Select
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
          error={errors.customerDestinationLocationId}
          formControlError={errors.customerDestinationLocationId}
          formHelperErrorMsg={this.getErrorMessage("customerDestinationLocationId")}
          id="cpbr-customer-destination-location"
          inputLabelText={<Trans i18nKey="customer_destination_location" />}
          onChange={this.handleChangeFields("customerDestinationLocationId")}
          value={`${customerDestinationLocationId}`}
        >
          {this.renderMenuItems(
            <Trans i18nKey="select_customer_destination_location" />,
            sortedCustomerLocations,
            "name"
          )}
        </Select>
      );
    }

    const filteredSupplierLocations = filterSupplierLocations(supplierLocations, jobTemplate.code);

    return (
      <>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
          error={errors.supplierLocationId}
          formControlError={errors.supplierLocationId}
          formHelperErrorMsg={this.getErrorMessage("supplierLocation")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_1" />}
          onChange={this.handleChangeFields("supplierLocationId")}
          value={`${supplierLocationId}`}
        >
          {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
        </Select>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase() !== "ROSD_VP_CC"}
          error={errors.supplierSecondLocationId}
          formControlError={errors.supplierSecondLocationId}
          formHelperErrorMsg={this.getErrorMessage("supplierSecondLocationId")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_2" />}
          onChange={this.handleChangeFields("supplierSecondLocationId")}
          value={`${supplierSecondLocationId}`}
        >
          {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
        </Select>
      </>
    );
  };

  renderJob = () => {
    const {
      purchaseOrderNo,
      errors,
      frequency,
      jobTemplateId,
      customerLocationId,
      locationModalOpened,
      noteLocation,
      currentCustomerPurchaseOrderNo,
      currentCustomerLocationNote,
      noteLocationColor,
      // noteSchedule,
      startDate,
      unitId,
      smsAlertPhoneNumber,
      customerMobilePhoneNumber,
      units,
    } = this.state;

    const { customerItem, customerLocations, jobTemplates, router } = this.props;

    const { params } = router;
    const { contractId, customerId } = params;

    const filteredJobTemplates = filterJobTemplatesByCustomerItem(jobTemplates, customerItem);
    const sortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]);

    return (
      <DialogContent>
        <form onSubmit={this.handleSubmit}>
          <AutocompleteModal
            actionName="add"
            displayHelperButton
            autocompleteList={customerLocations}
            autocompletePlaceholder="Saisir l'emplacement"
            autocompleteValue={customerLocationId}
            buttonClass="cta-add-location"
            buttonTextId="preparation.add_location_button"
            contractId={contractId}
            customerId={customerId}
            errors={errors}
            errorMessagesHelper={this.getErrorMessage("customerLocationId")}
            modalCallback={this.fetchCustomerLocationsAndCloseModal}
            modalName="customerLocation"
            modalOpened={locationModalOpened}
            onChangeAutocomplete={this.handleChangeCustomerLocation(customerLocationId)}
            onClickButton={this.handleToggleLocationModal(true)}
            onCloseModal={this.handleToggleLocationModal(false)}
            dropDownMenuPortalTarget="#recurrence-modal"
          />

          <FormGroupWrapper>
            <SelectUi
              error={errors.jobTemplateId}
              formControlError={errors.jobTemplateId}
              formHelperErrorMsg={this.getErrorMessage("jobTemplateId")}
              id="cpbr-job-template"
              inputLabelText={<Trans i18nKey="flow" />}
              onChange={this.handleChangeFields("jobTemplateId")}
              value={`${jobTemplateId}`}
            >
              {this.renderMenuItems(<Trans i18nKey="select_flow" />, filteredJobTemplates, "kind")}
            </SelectUi>
          </FormGroupWrapper>

          <Stack
            direction="row"
            pb={3}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack width={"97%"}>
              <DatePicker
                error={errors.startDate}
                label="Date"
                value={moment(startDate)}
                onChange={this.handleChangeStartDate}
                variant="outlined"
                disablePast
              />
            </Stack>
            <Select
              fullWidth
              id="cpbr-unit"
              inputLabelText={<Trans i18nKey="unit" />}
              onChange={this.handleChangeFields("unitId")}
              value={`${unitId}`}
            >
              {this.renderMenuItems(<Trans i18nKey="select_unit" />, sortedUnit, "name")}
            </Select>
          </Stack>

          {/* {this.renderDepositField()} */}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            pb={3}
          >
            {this.renderLocationsFields()}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            pb={3}
          >
            <TextFieldUi
              fullWidth
              error={errors.purchaseOrderNo}
              helperText={this.getErrorMessage("purchaseOrderNo")}
              label={<Trans i18nKey="purchase_order_no" />}
              onChange={this.handleChangeFields("purchaseOrderNo")}
              value={purchaseOrderNo ?? currentCustomerPurchaseOrderNo}
            />
            <TextField
              error={errors.smsAlertPhoneNumber}
              label="Alerte SMS"
              InputLabelProps={{
                shrink: true,
              }}
              value={formattedPhoneNumber(smsAlertPhoneNumber) || ""}
              onChange={this.handleChangeFields("smsAlertPhoneNumber")}
              fullWidth
            />
            <Tooltip
              title={"Même numéro que la fiche client (Cellulaire)"}
              placement="top"
            >
              <Button
                onClick={() => this.setState({ smsAlertPhoneNumber: customerMobilePhoneNumber })}
                color="primary"
                sx={{ minWidth: 40, minHeight: 48 }}
                disabled={smsAlertPhoneNumber || !customerMobilePhoneNumber}
              >
                <ContentCopyIcon />
              </Button>
            </Tooltip>
          </Stack>

          <FormGroupWrapper>
            <TextAreaUi
              error={errors.note_location}
              helperText={this.getErrorMessage("note_location")}
              id="cpbr-note-location"
              label={<Trans i18nKey="preparation.note_location" />}
              onChange={this.handleChangeFields("noteLocation")}
              value={noteLocation ?? currentCustomerLocationNote}
            />
          </FormGroupWrapper>

          {/*<FormGroupWrapper>*/}
          {/*  <InlineColorPicker*/}
          {/*    color={noteLocationColor}*/}
          {/*    id="cpbr-note-comments-color"*/}
          {/*    onChangeComplete={({ hex }) => {*/}
          {/*      this.setState({ noteLocationColor: hex });*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</FormGroupWrapper>*/}

          <FormGroupWrapper>
            <FlexRowWrapper>
              <SelectHalfUi
                error={errors.frequency}
                formControlError={errors.frequency}
                formHelperErrorMsg={this.getErrorMessage("frequency")}
                id="cpbr-job-frequency"
                inputLabelText={<Trans i18nKey="frequency" />}
                onChange={this.handleChangeFrequency}
                value={frequency}
              >
                {frequencies.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </SelectHalfUi>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>{this.handleDisplayFrequencyComponent()}</FormGroupWrapper>
        </form>
      </DialogContent>
    );
  };

  renderRoute = () => {
    const { errors, routeTemplateId, frequency, startDate, averageLiftingTime, unitId, units } = this.state;
    const { routeTemplates } = this.props;
    const routeTemplate = this.getRouteTemplate(routeTemplateId);
    const availableSortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]).filter(
      (unit) => unit.loadingType === "AVANT"
    );
    return (
      <DialogContent>
        <form onSubmit={this.handleSubmit}>
          <FormGroupWrapper>
            <SelectUi
              error={errors.routeTemplateId}
              formControlError={errors.routeTemplateId}
              formHelperErrorMsg={this.getErrorMessage("routeTemplateId")}
              id="cpbr-route-template"
              inputLabelText={<Trans i18nKey="routes.route_template" />}
              onChange={this.handleChangeRouteTemplateId("routeTemplateId")}
              value={`${routeTemplateId}`}
            >
              {this.renderMenuItems(
                <Trans i18nKey="select_route_template_id" />,
                routeTemplates.filter((routeTemplate) => !routeTemplate.isAMasterRoute),
                "completeName"
              )}
            </SelectUi>
          </FormGroupWrapper>

          {/* <FormGroupWrapper> */}
          {/*  <LiftingFrequencyWrapper autoFocus> */}
          {/*    <InputLabel className="color-picker-label" ><Trans i18nKey="frequency_of_lifting_to_import" /></InputLabel> */}
          {/*    <FormControlLabel */}
          {/*      control={<Checkbox checked={this.state.routeFrequencyWeeklyChecked} />} */}
          {/*      label={<Trans i18nKey="frequencies.weekly" />} */}
          {/*    /> */}
          {/*    <FormControlLabel */}
          {/*      control={<Checkbox checked={this.state.routeFrequencyTwiceMonthlyChecked} />} */}
          {/*      onChange={this.toggleFrequencyCheckbox('TwiceMonthly')} */}
          {/*      label={<Trans i18nKey="frequencies.twice_monthly" />} */}
          {/*    /> */}
          {/*    <FormControlLabel */}
          {/*      control={<Checkbox checked={this.state.routeFrequencyMonthlyChecked} />} */}
          {/*      onChange={this.toggleFrequencyCheckbox('Monthly')} */}
          {/*      label={<Trans i18nKey="frequencies.monthly" />} */}
          {/*    /> */}
          {/*  </LiftingFrequencyWrapper> */}
          {/* </FormGroupWrapper> */}

          <FormGroupWrapper>
            <FlexRowWrapper>
              <HalfFormControl>
                <TextFieldUi
                  disabled={routeTemplateId !== "-1"}
                  error={errors.averageLiftingTime}
                  helperText={this.getErrorMessage("averageLiftingTime")}
                  id="route-template-average-lifting-time"
                  label={<Trans i18nKey="route_templates.average_lifting_time" />}
                  onChange={this.handleChangeFields("averageLiftingTime")}
                  value={averageLiftingTime || routeTemplate.averageLiftingTime || ""}
                />
              </HalfFormControl>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>
            <FlexRowWrapper>
              <HalfFormControl error={errors.startDate}>
                <DatePicker
                  error={errors.startDate}
                  label="Date"
                  value={moment(startDate)}
                  onChange={this.handleChangeStartDate}
                  variant="outlined"
                  disablePast
                />
                <FormHelperText>{this.getErrorMessage("startDate")}</FormHelperText>
              </HalfFormControl>
              <HalfFormControl error={errors.startDate}>
                <SelectHalfUi
                  id="cpbr-unit"
                  inputLabelText={<Trans i18nKey="unit" />}
                  onChange={this.handleChangeFields("unitId")}
                  value={`${unitId}`}
                >
                  {this.renderMenuItems(<Trans i18nKey="select_unit" />, availableSortedUnit, "name")}
                </SelectHalfUi>
              </HalfFormControl>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper style={{ paddingTop: "20px" }}>
            <FlexRowWrapper>
              <SelectHalfUi
                error={errors.frequency}
                formControlError={errors.frequency}
                formHelperErrorMsg={this.getErrorMessage("frequency")}
                id="cpbr-job-frequency"
                inputLabelText={<Trans i18nKey="frequency" />}
                onChange={this.handleChangeFrequency}
                value={frequency}
              >
                {frequencies.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </SelectHalfUi>
            </FlexRowWrapper>
          </FormGroupWrapper>

          <FormGroupWrapper>{this.handleDisplayFrequencyComponent()}</FormGroupWrapper>
        </form>
      </DialogContent>
    );
  };

  render() {
    const { showSpinner } = this.state;
    const { open, customerItem } = this.props;
    const { contractType } = customerItem;
    return (
      <DialogWrapper
        id="recurrence-modal"
        onClose={this.handleClose}
        open={open}
      >
        {showSpinner && <LoaderSpinner text={<Trans i18nKey="recurrence_creation" />} />}
        <DialogTitle>
          <span style={{ paddingRight: "5px" }}>
            {contractType === "job" ? <Trans i18nKey="common.title.jobs" /> : <Trans i18nKey="route" />}
          </span>
        </DialogTitle>

        {contractType === "job" ? this.renderJob() : this.renderRoute()}

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button
            onClick={this.handleSubmit}
            variant="contained"
          >
            <Trans i18nKey="add" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalRecurrence.propTypes = {
  createJob: PropTypes.func.isRequired,
  createRoute: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  // deposits: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  fetchRouteTemplatesByCustomerItem: PropTypes.func.isRequired,
  // fetchDeposits: PropTypes.func.isRequired,
  jobTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,

  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  routeTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  supplierLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRoutes(
  withRouteTemplates(
    withJobs(withJobTemplates(withCustomerLocations(withSupplierLocations(withLocations(withRouter(ModalRecurrence))))))
  )
);
