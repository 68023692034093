import React, { ReactElement, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import { Header } from "@ui/modal/Header";
import Content from "@ui/modal/Content";
import { ModalFooter } from "@ui/modal/Modal.styles";
import Buttons from "@ui/modal/Buttons";
import { Breakpoint } from "@mui/material";
import CloseButton from "@ui/modal/CloseButton";

export interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  maxWidth?: false | Breakpoint | undefined;
  scroll?: "body" | "paper";
}

export const Modal = ({ open, children, onClose, scroll, maxWidth = "xs" }: ModalProps): ReactElement => (
  <Dialog
    scroll={scroll}
    maxWidth={maxWidth}
    fullWidth
    open={open}
    onClose={onClose}
    hideBackdrop
  >
    {children}
  </Dialog>
);

Modal.Content = Content;
Modal.Footer = ModalFooter;
Modal.Buttons = Buttons;
Modal.Header = Header;
Modal.CloseButton = CloseButton;

export default Modal;
