import { SupplierResponse } from "@@types/suppliers/supplier-response.type";
import { Supplier } from "@@types/suppliers/supplier.type";

function mapResponseToSupplier(response: SupplierResponse): Supplier {
  return {
    id: response.id,
    name: response.name,
  };
}

function mapResponseToSuppliers(responses: SupplierResponse[]): Supplier[] {
  return responses.map(mapResponseToSupplier);
}

export { mapResponseToSupplier, mapResponseToSuppliers };
