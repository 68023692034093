import React, { ReactElement } from "react";
import { SelectProps as SelectPropsMui } from "@mui/material/Select/Select";
import { FormControl, FormHelperText } from "@mui/material";
import { StyledSelect, StyledSelectLabel } from "./Dropdown.styles";
import DropdownMenuItem from "@ui/dropdown/Dropdown.MenuItem";

export interface DropdownProps extends Pick<SelectPropsMui<string>, "onChange" | "onClick" | "sx" | "inputProps"> {
  children: ReactElement | ReactElement[];
  disabled?: boolean;
  error?: string | ReactElement | false | boolean;
  fullWidth?: boolean;
  label: string | ReactElement;
  name: string;
  value: string | null;
  width?: string;
}

const defaultProps = {
  disabled: false,
  fullWidth: false,
  width: "auto",
};

export function Dropdown({
  children,
  disabled,
  error,
  fullWidth,
  label,
  name,
  onChange,
  onClick,
  sx,
  value,
  width,
  ...others
}: DropdownProps & typeof defaultProps): ReactElement {
  return (
    <FormControl
      error={!!error}
      onClick={(e) => e.stopPropagation()}
      sx={{ ...sx, width: fullWidth ? "100%" : width, minWidth: fullWidth ? "100%" : "70px" }}
    >
      <StyledSelectLabel>{label}</StyledSelectLabel>
      <StyledSelect
        disabled={disabled}
        label={label}
        defaultValue=""
        name={name}
        onChange={onChange}
        onClick={onClick}
        sx={{ minWidth: "inherit", width: "inherit" }}
        value={value || undefined}
        variant="outlined"
        {...others}
      >
        {children}
      </StyledSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

Dropdown.MenuItem = DropdownMenuItem;

Dropdown.defaultProps = { ...defaultProps };

export default Dropdown;
