import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DefaultError } from "@tanstack/react-query/build/modern/index";
import deleteLocationCcavModality from "../../services/location-details/delete-location-ccav-modality";
import locationDetail from "@hooks/location-details/location-detail.keys";
import LocationDetail from "@@types/location-details/location-detail.type";

export type UseLocationCcavModalityDelete = {
  mutate: (payload: LocationDetail) => Promise<void>;
  isPending: boolean;
};

const useDeleteLocationCcavModality = (locationId: number): UseLocationCcavModalityDelete => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, DefaultError, LocationDetail>({
    mutationFn: (payload: LocationDetail): Promise<void> =>
      deleteLocationCcavModality(payload.locationDetailFrequencyId, payload.locationDetailDaysOfWeekId),
    onSuccess: async (_data) => {
      // Invalidate queries to reflect the changes
      await queryClient.invalidateQueries({
        queryKey: locationDetail.detail(locationId),
        exact: false,
      });
    },
  });

  const mutate = async (payload: LocationDetail): Promise<void> => {
    const response = await mutation.mutateAsync(payload);
    return response;
  };

  return { mutate, isPending: mutation.isPending };
};

export default useDeleteLocationCcavModality;
