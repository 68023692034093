import axios from "axios";
import { requestOptions } from "../auth";

const editUnit = async (params, unitId) => {
  try {
    const response = await axios.put(
      `${import.meta.env.VITE_API_URL}/units/${unitId}.json`,
      { unit: { ...params } },
      requestOptions()
    );

    return response.data?.data?.attributes ?? null;
  } catch (error) {
    console.warn("[edit-unit.service] error:", error);

    return false;
  }
};

export default editUnit;
