// React imports
import React, { FC } from "react";
// Externals imports
import { Trans } from "react-i18next";

import {
  LocationContainerModalLiftingRequestData,
  LocationContainerModalLiftingRequestHandlers,
} from "@components/location/route/containers/modals/hooks/location-container-modal-lifting-requests.hook";
import ContainerLiftingRequestsMultiSelect from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsMultiSelect";
import { Stack } from "@mui/material";
import TextFieldUi from "@ui/TextField";

type ContainerLiftingRequestsFilteringProps = {
  liftingRequestData: LocationContainerModalLiftingRequestData;
  liftingRequestHandlers: LocationContainerModalLiftingRequestHandlers;
};
const ContainerLiftingRequestsFiltering: FC<ContainerLiftingRequestsFilteringProps> = ({
  liftingRequestData,
  liftingRequestHandlers,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <ContainerLiftingRequestsMultiSelect
        label={"Type de demande"}
        listCodes={liftingRequestData.registrationCategories}
        selected={liftingRequestData.selectedRegistrationCategories}
        setSelected={liftingRequestHandlers.setSelectedRegistrationCategories}
        handleChange={liftingRequestHandlers.handleChangeMultipleSelect}
        renderMenuItemsListCodes={liftingRequestHandlers.renderMenuItemsListCodes}
      />
      <ContainerLiftingRequestsMultiSelect
        label={"Type de levée"}
        listCodes={liftingRequestData.liftingTypes}
        selected={liftingRequestData.selectedLiftingTypes}
        setSelected={liftingRequestHandlers.setSelectedLiftingTypes}
        handleChange={liftingRequestHandlers.handleChangeMultipleSelect}
        renderMenuItemsListCodes={liftingRequestHandlers.renderMenuItemsListCodes}
      />
      <TextFieldUi
        label={<Trans i18nKey="filter" />}
        onChange={liftingRequestHandlers.handleFilterOnChange}
        type="search"
      />
    </Stack>
  );
};

export default ContainerLiftingRequestsFiltering;
