import React, { ReactElement, ReactNode } from "react";
import { ModalHeader, ModalHeaderText } from "./Modal.styles";

export type HeaderProps = {
  textColor?: string;
  title: ReactElement | string;
};

export const Header = ({ title }: HeaderProps): ReactNode => {
  return (
    <ModalHeader>
      <ModalHeaderText>{title}</ModalHeaderText>
    </ModalHeader>
  );
};
