import React, {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent, ListItemText, Checkbox } from "@mui/material";
import useFilterAndPagination from "@hooks/useFilterAndPagination";
import TableFilterStates from "@@types/common/table-filter-states.type";
import ListCode from "@@types/list-codes/list-code.type";
import useFetchContainerLiftingRequestsFuture, {
  LiftingRequests,
} from "@hooks/lifting-requests/fetch-container-lifting-requests-future.hook";
import useFetchContainerLiftingRequestsHistory from "@hooks/lifting-requests/fetch-container-lifting-requests-history";
import useFetchListCodes from "@hooks/list-codes/fetch-list-codes.hook";

export type LocationContainerModalLiftingRequestData = {
  filterStates: TableFilterStates;
  liftingTypes: ListCode[];
  registrationCategories: ListCode[];
  liftingRequests: LiftingRequests;
  liftingRequestTabValue: number;
  selectedRegistrationCategories: string[];
  selectedLiftingTypes: string[];
};

export type LocationContainerModalLiftingRequestHandlers = {
  handleChangeTabRequestLifting: (event: unknown, tabValue: number) => void;
  handleChangeRowsPerPage: (event: { target: HTMLInputElement }) => void;
  handleChangePage: (event: MouseEventHandler, newPage: number) => void;
  handleFilterOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  renderMenuItemsListCodes: (listCodes: ListCode[], selected: string[]) => ReactElement | ReactElement[];
  handleChangeMultipleSelect: (
    event: SelectChangeEvent<string[]>,
    setSelected: Dispatch<SetStateAction<string[]>>
  ) => void;
  setSelectedRegistrationCategories: Dispatch<SetStateAction<string[]>>;
  setSelectedLiftingTypes: Dispatch<SetStateAction<string[]>>;
};

export type UseLocationContainerModalLiftingRequests = {
  liftingRequestData: LocationContainerModalLiftingRequestData;
  liftingRequestHandlers: LocationContainerModalLiftingRequestHandlers;
};

const useLocationContainerModalLiftingRequests = (containerId: number): UseLocationContainerModalLiftingRequests => {
  const [selectedRegistrationCategories, setSelectedRegistrationCategories] = useState<string[]>([]);
  const [selectedLiftingTypes, setSelectedLiftingTypes] = useState<string[]>([]);
  const [liftingRequestTabValue, setLiftingRequestTabValue] = useState<number>(1);
  const { filterStates, updateFilterStates } = useFilterAndPagination();
  const { data: liftingTypes = [] } = useFetchListCodes("LIFTING_TYPE");
  const { data: registrationCategories = [] } = useFetchListCodes("REGISTRATION_CATEGORY");
  const [liftingRequests, setLiftingRequests] = useState<LiftingRequests | undefined>();
  const { data: liftingRequestsFuture } = useFetchContainerLiftingRequestsFuture(
    containerId,
    filterStates,
    selectedRegistrationCategories,
    selectedLiftingTypes
  );
  const { data: liftingRequestsHistory } = useFetchContainerLiftingRequestsHistory(
    containerId,
    filterStates,
    selectedRegistrationCategories,
    selectedLiftingTypes
  );

  const handleChangeTabRequestLifting = (event: unknown, tabValue: number): void => {
    setLiftingRequestTabValue(tabValue);
    updateFilterStates.setPage(0);
  };

  useEffect(() => {
    switch (liftingRequestTabValue) {
      case 1:
        setLiftingRequests(liftingRequestsFuture);
        break;
      case 2:
        setLiftingRequests(liftingRequestsHistory);
        break;
    }
  }, [liftingRequestsFuture, liftingRequestsHistory, liftingRequestTabValue]);

  const renderMenuItemsListCodes = (listCodes: ListCode[], selected: string[]): ReactElement | ReactElement[] => {
    if (!listCodes) {
      return <Dropdown.MenuItem value=" "></Dropdown.MenuItem>;
    }

    return listCodes.map((listCode: ListCode) => (
      <Dropdown.MenuItem
        key={listCode.id}
        value={listCode.code}
      >
        <Checkbox checked={selected.indexOf(listCode.code) > -1} />
        <ListItemText primary={listCode.description} />
      </Dropdown.MenuItem>
    ));
  };

  const handleChangeMultipleSelect = (
    event: SelectChangeEvent<string[]>,
    setSelected: Dispatch<SetStateAction<string[]>>
  ): void => {
    const {
      target: { value },
    } = event;
    if (Array.isArray(value)) {
      setSelected(value);
    }
  };

  const handleFilterOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    updateFilterStates.setFilter(event.target.value);
  };

  const handleChangePage = (event: MouseEventHandler, newPage: number): void => {
    updateFilterStates.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: HTMLInputElement }): void => {
    updateFilterStates.setRowsPerPage(parseInt(event.target.value, 10));
    updateFilterStates.setPage(0);
  };

  const liftingRequestData = {
    filterStates,
    liftingTypes,
    registrationCategories,
    liftingRequests,
    liftingRequestTabValue,
    selectedRegistrationCategories,
    selectedLiftingTypes,
  };

  const liftingRequestHandlers = {
    handleChangeTabRequestLifting,
    handleChangeRowsPerPage,
    handleChangePage,
    handleFilterOnChange,
    renderMenuItemsListCodes,
    handleChangeMultipleSelect,
    setSelectedRegistrationCategories,
    setSelectedLiftingTypes,
  };

  return {
    liftingRequestData,
    liftingRequestHandlers,
  } as UseLocationContainerModalLiftingRequests;
};

export default useLocationContainerModalLiftingRequests;
