import React, { ReactElement } from "react";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import Dropdown from "@ui/dropdown/Dropdown";
import { Day } from "@components/modals/routeTemplate/add-master-route-template/ModalAddMasterRouteTemplate";

export type ModalAddMasterRouteTemplateDayProps = {
  day: Day;
  onChange: (value: SelectChangeEvent<string>) => void;
};

const ModalAddMasterRouteTemplateDay = ({ day, onChange }: ModalAddMasterRouteTemplateDayProps): ReactElement => {
  return (
    <Dropdown
      error={day.error}
      label="Jour"
      value={day.value}
      name="day"
      onChange={onChange}
      width="100px"
    >
      <MenuItem value={"LU"}>LU</MenuItem>
      <MenuItem value={"MA"}>MA</MenuItem>
      <MenuItem value={"ME"}>ME</MenuItem>
      <MenuItem value={"JE"}>JE</MenuItem>
      <MenuItem value={"VE"}>VE</MenuItem>
    </Dropdown>
  );
};

export default ModalAddMasterRouteTemplateDay;
