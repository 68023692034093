// React imports
import React, { Dispatch, FC, ReactElement, SetStateAction } from "react";
// Externals imports
import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import ListCode from "@@types/list-codes/list-code.type";

type ContainerLiftingRequestsMultiSelectProps = {
  label: string;
  listCodes: ListCode[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  handleChange: (event: SelectChangeEvent<string[]>, setSelected: Dispatch<SetStateAction<string[]>>) => void;
  renderMenuItemsListCodes: (listCodes: ListCode[], selected: string[]) => ReactElement | ReactElement[];
};
const ContainerLiftingRequestsMultiSelect: FC<ContainerLiftingRequestsMultiSelectProps> = ({
  label,
  listCodes,
  selected,
  setSelected,
  handleChange,
  renderMenuItemsListCodes,
}) => {
  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        renderValue={(selectedOptions) => {
          const values = selectedOptions.map((option: string) => {
            return listCodes.find((listCode) => listCode.code === option)?.description;
          });
          return values.join(", ");
        }}
        value={selected}
        label={label}
        onChange={(event) => handleChange(event, setSelected)}
      >
        {renderMenuItemsListCodes(listCodes, selected)}
      </Select>
    </FormControl>
  );
};

export default ContainerLiftingRequestsMultiSelect;
