// React imports
import React, { FC } from "react";
// Externals imports
import { TableCell, TableRow } from "@mui/material";
import { LiftingRequest } from "@@types/lifting-requests/lifting-request.type";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { formattedTime } from "@utils/dates";

type ContainerLiftingRequestsListBodyProps = {
  liftingRequests: LiftingRequest[];
};

const ContainerLiftingRequestsListBody: FC<ContainerLiftingRequestsListBodyProps> = ({ liftingRequests }) => {
  return liftingRequests.map((liftingRequest) => {
    return (
      <TableRow key={liftingRequest.id}>
        <TableCell>
          {liftingRequest.whitelisted ? <CheckCircleIcon color="success" /> : <DangerousIcon color="error" />}
        </TableCell>
        <TableCell>{liftingRequest.liftingDate}</TableCell>
        <TableCell>{liftingRequest.category}</TableCell>
        <TableCell>{liftingRequest.registrationType}</TableCell>
        <TableCell>{liftingRequest.routeName || "N/A"}</TableCell>
        <TableCell>{formattedTime(liftingRequest.createdAt)}</TableCell>
      </TableRow>
    );
  });
};

export default ContainerLiftingRequestsListBody;
