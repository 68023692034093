import PageContainer from "@ui/PageContainer";
import { Tab, TableContainer } from "@mui/material";
import PaperWrapper from "@ui/PaperWrapper";
import React, { useEffect, useState } from "react";
import UsersTableBody from "@components/users/UsersTableBody";
import useAsync from "@hooks/useAsync";
import * as API from "@services";
import FloatingActionButton from "@ui/FloatingActionButton";
import { Add } from "@mui/icons-material";
import UserNewModal from "@components/modals/user/UserNewModal";

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
};

const userInitialDataState = {
  value: "",
  error: false,
};

const userInitialPasswordsState = {
  value: "",
  error: false,
  show: false,
};

const userInitialStates = {
  id: "",
  data: {
    firstName: userInitialDataState,
    lastName: userInitialDataState,
    email: userInitialDataState,
  },
  passwords: {
    password: userInitialPasswordsState,
    confirmPassword: userInitialPasswordsState,
    nip: userInitialPasswordsState,
    confirmNip: userInitialPasswordsState,
  },
  roles: {
    admin: false,
    accounting: false,
  },
  status: {
    disabled: false,
  },
};

const handleButtonCloseModalNewUser = (setIsNewUserModalOpen) => {
  setIsNewUserModalOpen((prevState) => !prevState);
};

const handleButtonCreateNewUser = (setIsNewUserModalOpen, setUser) => {
  setUser(userInitialStates);
  setIsNewUserModalOpen((prevState) => !prevState);
};

const fetchUsersListAsync = async (filters, setUsers) => {
  const pageNumber = filters.page + 1;

  const res = await API.User.fetchUsersList(filters.filter, filters.limit, pageNumber);

  setUsers(res);
};

export default function UserPage() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(userInitialStates);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [filters, setFilters] = useState(filtersInitialState);

  const fetchUsersList = useAsync(() => fetchUsersListAsync(filters, setUsers));

  useEffect(() => {
    fetchUsersList.run();
  }, [filters]);

  return (
    <PageContainer>
      <PaperWrapper>
        <TableContainer>
          <FloatingActionButton
            color="secondary"
            onClick={() => handleButtonCreateNewUser(setIsNewUserModalOpen, setUser)}
          >
            <Add />
          </FloatingActionButton>

          {/* USERS LIST */}
          <UsersTableBody
            users={users}
            user={user}
            setUser={setUser}
            filters={filters}
            setFilters={setFilters}
            fetchUsersList={() => fetchUsersList.run()}
          />

          {/* MODAL NEW USER */}
          {isNewUserModalOpen && (
            <UserNewModal
              open={isNewUserModalOpen}
              onClose={() => handleButtonCloseModalNewUser(setIsNewUserModalOpen)}
              setIsNewUserModalOpen={setIsNewUserModalOpen}
              user={user}
              setUser={setUser}
              fetchUsersList={() => fetchUsersList.run()}
            />
          )}
        </TableContainer>
      </PaperWrapper>
    </PageContainer>
  );
}
