import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  margin-right: 5px;
  padding: 2px 7px;
  background: #e9e9e9;
  border: solid 1px #e9e9e9;
  border-radius: 4px;
  font-size: 0.8rem;

  ${({ color, theme }) =>
    color === "dark" &&
    `
    background: ${theme.colors.font.default};
    color: white;
  `}
`;

const SimpleBadge = ({ children, color, ...remainingProps }) => (
  <Wrapper color={color} {...remainingProps}>
    {children}
  </Wrapper>
);

SimpleBadge.defaultProps = {
  color: "light",
};

SimpleBadge.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["light", "dark"]),
};

export default SimpleBadge;
