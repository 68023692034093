import moment from "moment";
import { FormControl, Stack } from "@mui/material";
import DatePicker from "@components/form/DatePickerMui";
import { Trans } from "react-i18next";
import React from "react";

const handleDateSelect = (report, setReport, date_type) => (event) => {
  setReport((prevState) => ({
    ...prevState,
    [date_type]: moment(event).format("YYYY-MM-DD"),
  }));
};

export default function ReportDatesSelects({ report, setReport }) {
  return (
    <>
      <FormControl variant="outlined">
        <DatePicker
          label={<Trans i18nKey="start_date" />}
          onChange={handleDateSelect(report, setReport, "startDate")}
          value={report.startDate}
          variant="outlined"
          minDate={moment().subtract(1, "years")}
        />
      </FormControl>
      <FormControl variant="outlined">
        <DatePicker
          label={<Trans i18nKey="end_date" />}
          onChange={handleDateSelect(report, setReport, "endDate")}
          value={report.endDate}
          variant="outlined"
          maxDate={moment().add(1, "years")}
        />
      </FormControl>
    </>
  );
}
