import React, { ReactElement } from "react";
import { Trans } from "react-i18next";

import {
  NewSubRouteTemplate,
} from "@@types/master-route-templates/masterRouteTemplate.type";
import { TextField } from "@mui/material";
import theme from "@styles/theme";
import { Button } from "@ui/button/Button";
import Modal from "@ui/modal/Modal";

export type ModalAddSubRouteTemplateProps = {
  newSubRouteTemplate: NewSubRouteTemplate;
  onClose: () => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  opened: boolean;
  onCreate: (newSubRouteTemplate: NewSubRouteTemplate) => void;
};

const ModaAddSubRouteTemplate = ({
  newSubRouteTemplate,
  onClose,
  onChange,
  opened,
  onCreate,
}: ModalAddSubRouteTemplateProps): ReactElement => {
  return (
    <Modal
      open={opened}
      onClose={onClose}
    >
      <Modal.Header title={"Ajouter une sous-route"} />
      <Modal.Content>
        <TextField
          error={newSubRouteTemplate.error}
          fullWidth
          label={"Nom"}
          value={newSubRouteTemplate?.name || ""}
          onChange={onChange}
        />
      </Modal.Content>
      <Modal.Footer>
        <Modal.Buttons>
          <Button
            variant="text"
            textColor={theme.colors.palette.secondary.main}
            onClick={onClose}
          >
            <Trans i18nKey="cancel" />
          </Button>
          <Button
            textColor="#FFFFFF"
            onClick={() => onCreate(newSubRouteTemplate)}
          >
            <Trans i18nKey="add" />
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  );
};

export default ModaAddSubRouteTemplate;
