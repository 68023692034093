import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { sortBy } from "lodash";
import moment from "moment";
import { withComments, withJob } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import IconButton from "@mui/material/IconButton";
import ModalPictures from "./Picture";

import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import { formattedDateDayMonthYear, formattedTimeHourMinutes } from "@utils/dates";
import { Trans } from "react-i18next";

const tableHead = {
  day: <Trans i18nKey="day" />,
  hour: <Trans i18nKey="hour" />,
  text: <Trans i18nKey="comment" />,
  imagecomment: <Trans i18nKey="show_pictures" />,
};

class InterventionsList extends PureComponent {
  state = {
    ...this.initialState,
  };
  componentDidMount() {
    this.fetchComments();
  }

  componentWillUnmount() {
    this.props.flushComments();
  }

  initialState = {
    commentPicturesModalOpened: false,
    commentPicturesToEdit: {},
  };

  fetchComments = () => {
    const { fetchJobComments, router } = this.props;

    fetchJobComments(router.params.jobId);
  };

  handleTogglePicturesModal = (opened, photo) => () => {
    this.setState({
      commentPicturesModalOpened: opened,
      commentPicturesToEdit: photo,
    });
  };

  renderTableHead = () => (
    <TableHead>
      <TableRow>
        {["day", "hour", "text", "imagecomment"].map((name) => (
          <TableCell key={name}>{tableHead[name]}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  renderTableCellOfCamera = (photo) => {
    if (photo) {
      return (
        <IconButton
          color="secondary"
          onClick={this.handleTogglePicturesModal(true, photo)}
          size="large"
        >
          <CameraAltIcon fontSize="small" />
        </IconButton>
      );
    }
    return <IconButton size="large" />;
  };

  renderTableRows = () => {
    const { comments, commentsLoading } = this.props;

    if (commentsLoading) {
      return <TableLoading />;
    }

    if (!comments.length) {
      return <TableCellNoData />;
    }

    return sortBy(comments, ["createdAt"]).map((comment) => {
      const { id, createdAt, message, photo } = comment;

      return (
        <TableRow key={id}>
          <TableCell>
            {formattedDateDayMonthYear(createdAt)}
          </TableCell>
          <TableCell>
            {formattedTimeHourMinutes(createdAt)}
          </TableCell>
          <TableCell>{message}</TableCell>
          <TableCell>{this.renderTableCellOfCamera(photo)}</TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { commentPicturesToEdit } = this.state;

    return (
      <PageContainer>
        <PaperWrapper>
          <TableOverflowWrapper>
            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>
        </PaperWrapper>

        <ModalPictures
          photo={commentPicturesToEdit}
          onClose={this.handleTogglePicturesModal(false)}
          open={this.state.commentPicturesModalOpened}
        />
      </PageContainer>
    );
  }
}

InterventionsList.defaultProps = {
  commentsLoading: true,
};

InterventionsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchJobComments: PropTypes.func.isRequired,
  flushComments: PropTypes.func.isRequired,
  commentsLoading: PropTypes.bool,
};

export default withComments(withJob(InterventionsList));