import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import LoaderSpinner from "@components/LoaderSpinner";

const LoadingSpinner = () => <LoaderSpinner text={<Trans i18nKey="warning_delete" />} />;

const handleSubmit = async (setIsLoading, onSubmit) => {
  setIsLoading(true);
  await onSubmit();
  setIsLoading(false);
};

function ModalWarning({ children, onCancel, onSubmit, open, title }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog onClose={onCancel} open={open}>
      {isLoading && <LoadingSpinner />}

      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button onClick={() => handleSubmit(setIsLoading, onSubmit)} variant="contained" autoFocus>
          <Trans i18nKey="continue" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalWarning;
