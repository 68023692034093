// React imports
import React, { useCallback, useEffect, useState } from 'react';
// Internal imports
import { formattedDate } from "@utils/dates";
import SalesClientAccountHead from "@components/sales/SalesClientAccountHead";
import * as API from "@services";
import useAsync from "@hooks/useAsync";

// External imports
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar
} from '@mui/material';
import InlineDatePickerWrapper from "@ui/InlineDatePickerWrapper";
import moment from "moment/moment";
import theme from "@styles/theme";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  > * {
    margin-left: 10px;
  }
`;

const fetcDailyClientAccountJobsAsync = async (setClientAccountJobs, setShowSpinner, target_date) => {
  let res = await API.Job.fetcDailyClientAccountJobs(target_date);
  setClientAccountJobs(res.data.data);
  setShowSpinner(false);
};

const handleChangeDate = (setCurrentDate, setShowSpinner) => (value) => {
  setShowSpinner(true);
  setCurrentDate(value);
};

const handleChangeBillingWitness = (job, setModifiedBillingWitness) => async (event, value) => {
  await API.Job.updateBillingWitness(job.id, value);
  setModifiedBillingWitness(prevState => ({ ...prevState, [job.id]: value }));
}

const SalesClientAccountTransactionsTabTable = () => {

  const [currentDate, setCurrentDate] = useState(moment());
  const [clientAccountJobs, setclientAccountJobs] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [modifiedBillingWitness, setModifiedBillingWitness] = useState({});

  useEffect(() => {
    fetchClientAccountJobs.run();
  }, [currentDate]);

  const fetchClientAccountJobs = useAsync(() => {
    fetcDailyClientAccountJobsAsync(setclientAccountJobs, setShowSpinner, formattedDate(currentDate))
  }
  );

  return (
    <>
      <Toolbar>

        <Wrapper>
          <InlineDatePickerWrapper
            id="cpbr-date"
            format="LL"
            keyboard
            label="Date"
            value={moment(currentDate)}
            onChange={handleChangeDate(setCurrentDate, setShowSpinner)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  svg: { color: theme.colors.palette.secondary.main },
                }}
              />
            )}
          />
        </Wrapper>
        &nbsp;({clientAccountJobs.length})
      </Toolbar>
      <Table>
        <SalesClientAccountHead />
        <TableBody>
          {!showSpinner && clientAccountJobs.map((clientAccountJob) => (
            <TableRow key={clientAccountJob.id}>

              <TableCell sx={{ fontSize: 10 }} align="left">
                <Checkbox size={'small'}
                  checked={modifiedBillingWitness[clientAccountJob.id] ?? clientAccountJob.billingWitness}
                  onChange={handleChangeBillingWitness(clientAccountJob, setModifiedBillingWitness)} /></TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.customerName}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.purchaseOrderNo}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.purchasePlanName}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.customerItemName}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.customerLocationName}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.previousInterventionKind}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.previousInterventionDate}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.hasInterruption ? 'INTERRUPTION' : clientAccountJob.kind}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{formattedDate(clientAccountJob.endDate)}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.daysSinceLastIntervention}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.extraDaysSinceLastIntervention}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.areaName}</TableCell>
              <TableCell sx={{ fontSize: 10 }} align="left">{clientAccountJob.supplierLocationName}</TableCell>

            </TableRow>
          ))}
          {showSpinner &&
            <TableRow key={0}> <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="left">Un instant...</TableCell></TableRow>}
          {!showSpinner && clientAccountJobs.length == 0 &&
            <TableRow key={0}> <TableCell sx={{ fontSize: 14, fontWeight: "bold" }} align="left">Aucune donnée</TableCell></TableRow>}
        </TableBody>
      </Table>

    </>
  )
}

export default function SalesClientAccountTransactionsTab() {

  return (
    <SalesClientAccountTransactionsTabTable />
  )
}