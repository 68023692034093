import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const BubbleIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill={fillColor}
      >
        <path
          d="M12 6.19445C12.0021 7.00104 11.8136 7.79671 11.45 8.51667C11.0188 9.3794 10.3559 10.1051 9.53565 10.6123C8.71536 11.1196 7.77003 11.3885 6.80555 11.3889C5.99896 11.391 5.20329 11.2025 4.48333 10.8389L1 12L2.16111 8.51667C1.79746 7.79671 1.60901 7.00104 1.61111 6.19445C1.61148 5.22997 1.88037 4.28464 2.38766 3.46435C2.89495 2.64406 3.6206 1.9812 4.48333 1.55002C5.20329 1.18636 5.99896 0.997914 6.80555 1.00002H7.1111C8.38487 1.07029 9.58796 1.60793 10.49 2.50998C11.3921 3.41204 11.9297 4.61513 12 5.8889V6.19445Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default BubbleIcon;
