import { axiosClientV2 as http } from "../axios";

const deleteLocationContainer = async (containerId: number): Promise<void> => {
  try {
    await http.delete(`/containers/${containerId}`);
  } catch (error) {
    throw error as Error;
  }
};

export default deleteLocationContainer;
