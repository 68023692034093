import { RequestOptions, ResponseType } from "@@types/auth";

/**
 * Gets the current user's bearer token saved on the device.
 * @returns Bearer token or null.
 */
const getUserToken = (): string => {
  const token = localStorage.getItem("bearerToken");

  // If token is not null, return it else return null;
  return token || "";
};

/**
 * The request options that we always pass.
 */
export const requestOptions = (responseType?: ResponseType): RequestOptions => ({
  headers: {
    Authorization: getUserToken(),
  },
  responseType,
});

/**
 * Auth api service for exposing api endpoints.
 *
 * Available requests:
 * - `getUserToken()`
 */
export { getUserToken };
