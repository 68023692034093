import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const AutomaticLiftingIcon = ({ size, strokeColor, fillColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="25"
        viewBox="0 0 22 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6561 10.4091C20.6561 17.7273 10.953 24 10.953 24C10.953 24 1.25 17.7273 1.25 10.4091C1.25 7.91364 2.27228 5.52041 4.09195 3.75586C5.91162 1.99131 8.37963 1 10.953 1C13.5264 1 15.9944 1.99131 17.8141 3.75586C19.6338 5.52041 20.6561 7.91364 20.6561 10.4091Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="7"
          width="20"
          height="8"
          rx="2"
          fill={fillColor}
        />
        <path
          d="M5.43973 12.31H3.93973L3.71173 13H2.47573L4.01773 8.77H5.37373L6.90973 13H5.66773L5.43973 12.31ZM5.14573 11.41L4.68973 10.042L4.23973 11.41H5.14573ZM8.44503 8.77V11.224C8.44503 11.452 8.49703 11.628 8.60103 11.752C8.70903 11.876 8.87103 11.938 9.08703 11.938C9.30303 11.938 9.46503 11.876 9.57303 11.752C9.68503 11.624 9.74103 11.448 9.74103 11.224V8.77H10.917V11.224C10.917 11.612 10.835 11.944 10.671 12.22C10.507 12.492 10.283 12.698 9.99903 12.838C9.71903 12.974 9.40703 13.042 9.06303 13.042C8.71903 13.042 8.41103 12.974 8.13903 12.838C7.87103 12.698 7.65903 12.492 7.50303 12.22C7.35103 11.948 7.27503 11.616 7.27503 11.224V8.77H8.44503ZM14.7505 8.77V9.706H13.6225V13H12.4465V9.706H11.3305V8.77H14.7505ZM17.2136 13.042C16.8176 13.042 16.4536 12.95 16.1216 12.766C15.7896 12.578 15.5256 12.32 15.3296 11.992C15.1376 11.66 15.0416 11.286 15.0416 10.87C15.0416 10.454 15.1376 10.082 15.3296 9.754C15.5256 9.422 15.7896 9.164 16.1216 8.98C16.4536 8.796 16.8176 8.704 17.2136 8.704C17.6136 8.704 17.9776 8.796 18.3056 8.98C18.6376 9.164 18.8996 9.422 19.0916 9.754C19.2836 10.082 19.3796 10.454 19.3796 10.87C19.3796 11.286 19.2836 11.66 19.0916 11.992C18.8996 12.32 18.6376 12.578 18.3056 12.766C17.9736 12.95 17.6096 13.042 17.2136 13.042ZM17.2136 11.95C17.5136 11.95 17.7496 11.852 17.9216 11.656C18.0976 11.46 18.1856 11.198 18.1856 10.87C18.1856 10.534 18.0976 10.27 17.9216 10.078C17.7496 9.882 17.5136 9.784 17.2136 9.784C16.9096 9.784 16.6716 9.882 16.4996 10.078C16.3276 10.27 16.2416 10.534 16.2416 10.87C16.2416 11.202 16.3276 11.466 16.4996 11.662C16.6716 11.854 16.9096 11.95 17.2136 11.95Z"
          fill="white"
        />
      </svg>
    </Container>
  );
};
