import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { Trans } from "react-i18next";

import LoaderSpinner from "@components/LoaderSpinner";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const LoadingSpinner = (): ReactElement => <LoaderSpinner text={"Modification en cours..."} />;

const handleSubmit = async (setIsLoading: Dispatch<SetStateAction<boolean>>, onSubmit: () => void): Promise<void> => {
  setIsLoading(true);
  await onSubmit();
  setIsLoading(false);
};

type ModalConfirmType = {
  children: string;
  onCancel: () => void;
  onSave: () => void;
  open: boolean;
  title: string;
};

const ModalConfirm: FC<ModalConfirmType> = ({ children, onCancel, onSave, open, title }): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      onClose={onCancel}
      open={open}
    >
      {isLoading && <LoadingSpinner />}

      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          onClick={() => handleSubmit(setIsLoading, onSave)}
          variant="contained"
          autoFocus
        >
          <Trans i18nKey="continue" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
