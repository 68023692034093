import React, { Dispatch, FC, SetStateAction } from "react";
import { Button, Checkbox, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/";
import moment from "moment/moment";
import useLocationModalityDate from "@components/location/route/modality/modals/hooks/location-modality-modal-date.hook";
import LocationDetail, { DateModality } from "@@types/location-details/location-detail.type";

type LocationModalityModalDatesProps = {
  modalityDate: DateModality;
  dateType: string;
  label: string;
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>;
};

const LocationModalityModalDate: FC<LocationModalityModalDatesProps> = ({
  modalityDate,
  dateType,
  label,
  setModality,
}) => {
  const { handleEnableDateSelector, handleChangeDate } = useLocationModalityDate(modalityDate, setModality);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
    >
      <Button
        style={{ bottom: 9 }}
        onClick={() => handleEnableDateSelector(dateType)}
      >
        <Checkbox checked={modalityDate.active} />
        {label}
      </Button>
      {modalityDate.active && (
        <DatePicker
          key={modalityDate.label}
          label={modalityDate.label}
          value={modalityDate.value ? moment(modalityDate.value) : moment()}
          onChange={(event) => handleChangeDate(dateType, event)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ svg: { color: modalityDate.error ? "red" : "primary.main" } }}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default LocationModalityModalDate;
