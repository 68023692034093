import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React from "react";

export default function ItemsTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" sx={{ fontSize: 13 }}>
          Dispo.&nbsp;
        </TableCell>
        <TableCell align="center" sx={{ fontSize: 13 }}>
          Num.&nbsp;
        </TableCell>
        <TableCell align="center" sx={{ fontSize: 13 }}>
          Format&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Emplacement&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Client&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Flow&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Date&nbsp;
        </TableCell>
        <TableCell>
          <TableSortLabel direction={"desc"}>Jours&nbsp;</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
