import React from "react";

/**
 * Higher-Order Component to get access to a provider for a specific component.
 *
 *  Example usage: `export default withProvider(SomeProvider)(SomeComponent);`
 * @param {*} Provider Imported context provider to wrap a component
 * @returns Component but with access to the specified provider context
 */
export const withProvider = (Provider) => (WrappedComponent) => (props) =>
  (
    <Provider>
      <WrappedComponent {...props} />
    </Provider>
  );

/**
 * Higher-Order Component to get access to multiple providers for a specific component.
 *
 *  Example usage: `export default withProviders(Provider1, Provider2)(SomeComponent);`
 * @param {*} Provider Imported context providers to wrap a component
 * @returns Component but with access to the specified providers contexts
 */
export const withProviders =
  (...providers) =>
  (WrappedComponent) =>
  (props) =>
    providers.reduceRight((acc, Provider) => {
      return <Provider>{acc}</Provider>;
    }, <WrappedComponent {...props} />);
