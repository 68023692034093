// React imports
import React, { FC, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
// Internal imports
import Location from "@@types/locations/location.type";
import RouteHistory from "@@types/routes/route-history.type";
import LocationRouteHistoryListBody from "@components/location/route/history/LocationRoutesHistoryListBody";
import LocationRouteHistoryListHeader from "@components/location/route/history/LocationRoutesHistoryListHeader";
import LocationRouteHistoryDetails from "@components/location/route/history/LocationRouteHistoryDetails";
import useFilterAndPagination from "@hooks/useFilterAndPagination";
import useFetchLocationRoutesHistory from "@hooks/routes/fetch-location-routes-history.hook";
import TableLoading from "@ui/TableLoading";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
// External imports
import { Table } from "@mui/material";

type LocationRoutesHistoryListProps = {
  customerLocation: Location;
  selectedLocation: number;
};

const LocationRoutesHistoryList: FC<LocationRoutesHistoryListProps> = ({ customerLocation, selectedLocation }) => {
  const { filterStates, updateFilterStates } = useFilterAndPagination();
  const [route, setRoute] = useState<RouteHistory>();
  const [isRouteDetailsOpen, setIsRouteDetailsOpen] = useState(false);
  const { data, isLoading } = useFetchLocationRoutesHistory(selectedLocation, filterStates);
  const navigate = useNavigate();

  const handleSort = (columnName: string): void => {
    const isDesc = filterStates.sort.columnName === columnName && filterStates.sort.direction === "desc";
    updateFilterStates.setSort(columnName, isDesc ? "desc" : "asc");
  };

  const handleChangePage = (event: MouseEventHandler, newPage: number): void => {
    updateFilterStates.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: HTMLInputElement }): void => {
    updateFilterStates.setRowsPerPage(parseInt(event.target.value, 10));
    updateFilterStates.setPage(0);
  };

  const handleOpenRouteDetails =
    (route?: RouteHistory): MouseEventHandler<HTMLButtonElement> =>
    (event): void => {
      event.stopPropagation();
      setIsRouteDetailsOpen(true);
      setRoute(route);
    };

  const handleCloseRouteDetails = (): void => {
    setRoute(undefined);
    setIsRouteDetailsOpen(false);
  };

  return (
    <>
      <TableOverflowWrapper>
        <Table>
          {/* TABLE HEADER */}
          <LocationRouteHistoryListHeader
            filterStates={filterStates}
            onSort={handleSort}
          />

          {/* TABLE BODY */}
          {isLoading && <TableLoading />}
          {data?.routes.length ? (
            <LocationRouteHistoryListBody
              customerLocation={customerLocation}
              routes={data.routes}
              navigate={navigate}
              handleOpenRouteDetails={handleOpenRouteDetails}
            />
          ) : (
            "** Aucune donnée disponible **"
          )}
        </Table>
      </TableOverflowWrapper>

      {/* PAGINATION */}
      <TablePaginationWrapper
        // eslint-disable-next-line react/no-children-prop
        children=""
        component="div"
        count={data?.count || 0}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={filterStates?.page}
        rowsPerPage={filterStates?.rowsPerPage}
      />

      {/* RAPPORT DES CONTENANTS D'UNE ROUTE DE L'EMPLACEMENT */}
      {isRouteDetailsOpen && route && (
        <LocationRouteHistoryDetails
          open={isRouteDetailsOpen}
          onClose={handleCloseRouteDetails}
          route={route}
        />
      )}
    </>
  );
};

export default LocationRoutesHistoryList;
