import { useState, useEffect, useRef, useMemo, useCallback } from "react";

export default function useToggle(initialState = false) {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  // Handlers
  const toggle = useCallback(() => setState((prev) => !prev), []);
  const on = useCallback(() => setState(true), []);
  const off = useCallback(() => setState(false), []);

  const updateState = useMemo(
    () => ({
      toggle,
      on,
      off,
    }),
    []
  );

  // Store current value in ref
  useEffect(() => {
    ref.current = state;
  }, [state]);

  return { state, ref, setState: updateState };
}
