import React from "react";
import InputLabel from "@mui/material/InputLabel";
import { HuePicker } from "react-color";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const ColorPickerWrapper = styled.div(
  {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    padding: "20px",

    ".color-picker-label": {
      position: "absolute",
      top: "-6px",
      left: "6px",
      background: "#fff",
      padding: "0 5px",
      fontSize: "12px",
    },
  },
  (props) => ({
    ...props.styles,
    border: `1px solid ${props.theme.colors.basic.lightGrey}`,
    borderRadius: props.theme.inputBorderRadius,
    paddingLeft: props.adornment === "start" ? "40px" : props.theme.inputPadding,
    paddingRight: props.adornment === "end" ? "40px" : props.theme.inputPadding,
  })
);

const InlineColorPicker = ({ color, id = "color-picker", onChangeComplete, styles }) => (
  <ColorPickerWrapper autoFocus styles={styles}>
    <InputLabel className="color-picker-label" htmlFor={id}>
      Couleur note de tâche
    </InputLabel>
    <HuePicker id={id} color={color} onChangeComplete={onChangeComplete} />
  </ColorPickerWrapper>
);

InlineColorPicker.defaultProps = {
  color: "#000",
  styles: {},
};

InlineColorPicker.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  styles: PropTypes.object,
  onChangeComplete: PropTypes.func.isRequired,
};

export default InlineColorPicker;
