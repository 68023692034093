// React imports
import React, { memo, useCallback, useEffect, useState } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

function CustomerItemSelect({ isError, contractPaymentType, customerItem, customerItems, handlers }) {
  const [availableCustomerItems, setAvailableCustomerItems] = useState([]);
  // console.log("[CustomerItemSelect] >>> RENDERED");

  const handleChangeCustomerItem = useCallback((item) => {
    item && handlers.setSingleCurrent("customerItem", item);
  }, []);

  useEffect(() => {
    const availableCustomerItemsWithCreditCard =
      customerItems.data &&
      customerItems.data.filter(
        (customerItem) =>
          customerItem.data.accounting_item_sku !== "P-TH-TH-SD-RO-NS" &&
          customerItem.data.accounting_item_sku !== "P-SD-RO-NS-NS"
      );
    const availableCustomerItems =
      contractPaymentType === "CREDIT_CARD" && availableCustomerItemsWithCreditCard
        ? availableCustomerItemsWithCreditCard
        : customerItems.data;
    setAvailableCustomerItems(availableCustomerItems);
  }, [customerItems]);

  return (
    <Stack direction="row" alignItems="center">
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 42,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={customerItem.value ? customerItem : null}
        menuPosition="fixed"
        onChange={(item) => handleChangeCustomerItem(item)}
        options={availableCustomerItems}
        placeholder={"Sélectionner un item de contrat"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, customerItem: prevCustomerItem, customerItems: prevCustomerItems } = prevProps;
  const { isError: nextIsError, customerItem: nextCustomerItem, customerItems: nextCustomerItems } = nextProps;
  let arePropsEqual = true;

  // If the customerItems array changed, rerender
  if (!isEqual(prevCustomerItems, nextCustomerItems)) {
    arePropsEqual = false;
  }

  // If the current customerItem changed, rerender
  if (!isEqual(prevCustomerItem, nextCustomerItem)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(CustomerItemSelect, areEqual);
