import React, { ReactElement } from "react";
import { IconProps } from "@components/icons/Icon.type";
import Container from "@components/icons/Container";

const DotIcon = ({ size, fillColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill={fillColor}
      >
        <circle
          cx="5"
          cy="5"
          r="5"
          fill={fillColor}
        />
      </svg>
    </Container>
  );
};

export default DotIcon;
