import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BackspaceIcon from "@mui/icons-material/Backspace";
class CustomToolbarSelect extends React.Component {
  state = {
    arrayWithKeyValue: [],
    dataSelected: [],
  };

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find((selectedRow) => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  assignLocation = (assignOrUnassign) => {
    const { dataToChangeBetweenTable, displayData, selectedRows } = this.props;
    const { dataSelected } = this.state;
    const idPosition = assignOrUnassign ? 0 : 1;
    this.state.dataSelected.length = 0;

    const originObjects = displayData.filter((f) =>
      selectedRows.data.map((e) => e.dataIndex).includes(f.dataIndex)
    );

    originObjects.map((e) => {
      dataSelected.push({
        id: e.data[idPosition],
      });
    });

    dataToChangeBetweenTable(dataSelected);
    this.handleClickDeselectAll();
  };

  buttonAssign = () => {
    return (
      <Tooltip title="Assigner">
        <IconButton color="secondary" onClick={() => this.assignLocation(true)} size="large">
          Assigner
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    );
  };

  buttonUnassign = () => {
    return (
      <Tooltip title="Désassigner">
        <IconButton color="secondary" onClick={() => this.assignLocation(false)} size="large">
          Désassigner
          <BackspaceIcon />
        </IconButton>
      </Tooltip>
    );
  };

  render() {
    const { valueChangeDataTableDisplay } = this.props;

    return (
      <div style={{ marginRight: "24px" }}>
        {valueChangeDataTableDisplay ? this.buttonAssign() : this.buttonUnassign()}
        <Tooltip title="Deselect ALL">
          <IconButton color="secondary" onClick={this.handleClickDeselectAll} size="large">
            <IndeterminateCheckBoxIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Inverse selection">
          <IconButton color="secondary" onClick={this.handleClickInverseSelection} size="large">
            <CompareArrowsIcon style={{ transform: "rotate(90deg)" }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default CustomToolbarSelect;
