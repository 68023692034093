import useFetchLocationCcavContainers from "@hooks/containers/fetch-location-ccav-containers.hook";
import { Dispatch, SetStateAction, useState } from "react";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";

export type LocationCcavContainersData = {
  locationContainers: LocationCcavContainer[];
  selectedContainer: LocationCcavContainer;
  openModalDeleteContainer: boolean;
  openModalEditContainer: boolean;
  openModalCreateContainer: boolean;
  openModalContainerLiftingRequests: boolean;
  locationContainersIsLoading: boolean;
};

export type LocationCcavContainersHandlers = {
  handleCloseModal: (modalType: string) => void;
  handleOpenModal: (modalType: string, container: LocationCcavContainer) => void;
  setSelectedContainer: Dispatch<SetStateAction<LocationCcavContainer | undefined>>;
};

export type UseLocationCcavContainers = {
  data: LocationCcavContainersData;
  handlers: LocationCcavContainersHandlers;
};

const selectedContainerInitialState = {
  id: 0,
  ciWebLabelcode: "",
  ciWebChipcode: "",
  ciWebStatus: "",
  memo: "",
  model: "",
  volume: "",
  matterType: "",
  containerProfileId: 0,
  error: false,
};

const useLocationCcavContainers = (locationId: number): UseLocationCcavContainers => {
  const [selectedContainer, setSelectedContainer] = useState<LocationCcavContainer>(selectedContainerInitialState);
  const [openModalDeleteContainer, setOpenModalDeleteContainer] = useState<boolean>(false);
  const [openModalEditContainer, setOpenModalEditContainer] = useState<boolean>(false);
  const [openModalCreateContainer, setOpenModalCreateContainer] = useState<boolean>(false);
  const [openModalContainerLiftingRequests, setOpenModalContainerLiftingRequests] = useState<boolean>(false);
  const { data: locationContainers, isLoading: locationContainersIsLoading } =
    useFetchLocationCcavContainers(locationId);

  const handleCloseModal = (modalType: string): void => {
    setSelectedContainer(selectedContainerInitialState);
    switch (modalType) {
      case "delete":
        setOpenModalDeleteContainer(false);
        break;
      case "edit":
        setOpenModalEditContainer(false);
        break;
      case "create":
        setOpenModalCreateContainer(false);
        break;
      case "liftingRequests":
        setOpenModalContainerLiftingRequests(false);
        break;
    }
  };

  const handleOpenModal = (modalType: string, container: LocationCcavContainer): void => {
    setSelectedContainer(container);
    switch (modalType) {
      case "delete":
        setOpenModalDeleteContainer(true);
        break;
      case "edit":
        setOpenModalEditContainer(true);
        break;
      case "create":
        setOpenModalCreateContainer(true);
        break;
      case "liftingRequests":
        setOpenModalContainerLiftingRequests(true);
        break;
    }
  };

  const data = {
    openModalDeleteContainer,
    openModalEditContainer,
    openModalCreateContainer,
    openModalContainerLiftingRequests,
    locationContainers,
    selectedContainer,
    locationContainersIsLoading,
  };

  const handlers = {
    handleCloseModal,
    handleOpenModal,
    setSelectedContainer,
  };

  return {
    data,
    handlers,
  } as UseLocationCcavContainers;
};

export default useLocationCcavContainers;
