import axios from "axios";

import { requestOptions } from "../auth";

const responseType = "blob";

const exportFinancialReport = async (params) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/reports/export_financial_report`,
      params,
      requestOptions(responseType)
    );

    return response;
  } catch (error) {
    console.warn("[API.exportFinancialReport] Error:", error);

    return error.response.data;
  }
};

const exportProblemCaseReport = async (params) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/reports/export_problem_case_report`,
      params,
      requestOptions(responseType)
    );

    return response;
  } catch (error) {
    console.warn("[API.exportProblemCaseReport] Error:", error);

    return error.response.data;
  }
};

const exportProblemCaseReportVdq = async (params) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/reports/export_problem_case_report_usable_by_the_client`,
      params,
      requestOptions(responseType)
    );

    return response;
  } catch (error) {
    console.warn("[API.exportProblemCaseReportVdq] Error:", error);

    return error.response.data;
  }
};

const downloadProblemCasePhotos = async (customerId, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/reports/download_problem_case_photos?customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}`,
      requestOptions(responseType)
    );

    return response.data;
  } catch (error) {
    console.warn("[API.downloadProblemCasePhotos] Error:", error);

    return error.response.data;
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `exportFinancialReport(params)`,
 * - `exportProblemCaseReport(params)`,
 * - `exportProblemCaseReportVdq(params)`,
 */
export { exportFinancialReport, exportProblemCaseReport, exportProblemCaseReportVdq, downloadProblemCasePhotos };
