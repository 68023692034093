// @ts-check

import { axiosClientV1 } from "../axios";
import SetBearerTokenService from "./set-bearer-token.service";

/**
 * Signs the user in
 *
 * @class
 */
class LoginService {
  /**
   * Signs a user in using its email and password as credentials
   *
   * @param {{email: string, password: string}} credentials The user's email and password
   * @returns {Promise<void>}
   */
  static async login({ email, password }) {
    try {
      const response = await axiosClientV1.post(`/users/login`, {
        user: {
          email,
          password,
        },
      });

      if (response.status !== 200) throw new Error(`${response.status}`);

      SetBearerTokenService.set(response.headers.authorization);
    } catch (error) {
      console.warn("[API.login] Error:", error);

      throw error;
    }
  }
}

export default LoginService;
