import axios from "axios";
import { map } from "lodash";

import { requestOptions } from "../auth";

const fetchItemSpecs = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/item_specs`, requestOptions());

    return response.data;
  } catch (error) {
    console.warn("[fetchItemSpecsFormats] error:", error);
  }
};

/**
 * Location api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchItemSpecs`
 */
export { fetchItemSpecs };
