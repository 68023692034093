import axios from "axios";
import { requestOptions } from "../auth";

const createUnit = async (params) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/units.json`,
      { unit: { ...params } },
      requestOptions()
    );

    return response.data?.data?.attributes ?? null;
  } catch (error) {
    console.warn("[create-unit.service] error:", error);

    return false;
  }
};

export default createUnit;
