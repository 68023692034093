// React imports
import React, { FC, MouseEventHandler } from "react";
import { Trans } from "react-i18next";

import TableFilterStates from "@@types/common/table-filter-states.type";
// Externals imports
import { Button, DialogActions, Stack } from "@mui/material";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";

type ContainerLiftingRequestsFooterProps = {
  filterStates: TableFilterStates;
  count: number;
  handleChangePage: (event: MouseEventHandler, newPage: number) => void;
  handleChangeRowsPerPage: (event: { target: HTMLInputElement }) => void;
  onClose: () => void;
};
const ContainerLiftingRequestsFooter: FC<ContainerLiftingRequestsFooterProps> = ({
  filterStates,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onClose,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
    >
      <Stack pr={40}>
        <TablePaginationWrapper
          component="div"
          count={count || 0}
          page={filterStates.page}
          labelRowsPerPage=""
          onPageChange={handleChangePage}
          rowsPerPage={filterStates.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      <DialogActions sx={{ paddingRight: 5 }}>
        <Button
          size="large"
          onClick={onClose}
        >
          <Trans i18nKey="cancel" />
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default ContainerLiftingRequestsFooter;
