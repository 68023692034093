import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import FixedActionButton from "@ui/FixedActionButton";
import { formatLength, formatTime, getTotalDistance, getTotalDuration } from "../maps/tools";
import { filterSupplierLocations } from "@utils/filtering";
import { InputControl, getItemsCustomerLocations } from "../routes/utils";
import { Button, FormControl, Grid, OutlinedInput, Select as MuiSelect } from "@mui/material";
import { Trans } from "react-i18next";

export const createRouteTemplateLocationsParams = function (selected) {
  const { routeTemplate, router } = this.props;
  const { routeTemplateLocations } = routeTemplate;
  const { master_route, masterRouteId } = router.params;

  let removedRouteLocations = [];

  if (routeTemplateLocations) {
    removedRouteLocations = routeTemplateLocations.filter(
      (loc) => selected.findIndex((sel) => sel.routeTemplateLocationId === loc.id) === -1
    );
  }

  return [
    ...selected.map((selectedLocation, index) => ({
      // eslint-disable-next-line max-len
      id:
        selectedLocation.routeTemplateLocationId || master_route || masterRouteId
          ? selectedLocation.routeTemplateLocationId
          : null,
      location_id: selectedLocation.locationId,
      visit_rank: index + 1,
    })),
    ...removedRouteLocations.map((routeTemplateLocation) => ({
      id: routeTemplateLocation.id,
      _destroy: "1",
    })),
  ];
};

export const loadSavedRouteTemplateLocations = function (routeTemplate) {
  const { router } = this.props;
  const { master_route } = router.params;
  const newSelected = [];
  if (master_route) {
    return this.setState({
      selected: newSelected,
    });
  }
  const { routeTemplateLocations } = routeTemplate;

  if (routeTemplate && routeTemplateLocations && routeTemplateLocations.length > 0) {
    const dataFromCustomerLocations = getItemsCustomerLocations(this.props.customerLocations);

    routeTemplateLocations.sort((a, b) => a.visitRank - b.visitRank);
    const newItems = Array.from(this.items);
    for (const routeTemplateLocation of routeTemplateLocations) {
      const { id } = routeTemplateLocation;
      const { locationId } = routeTemplateLocation;
      const indexOfCurrentItem = newItems.findIndex((el) => el.routeTemplateLocationId === id);
      if (indexOfCurrentItem >= 0) {
        const [removed] = newItems.splice(indexOfCurrentItem, 1);
        if (removed) {
          const itemToAdd = Object.assign({}, removed);
          itemToAdd.fileNumber = routeTemplateLocation.fileNumber ? routeTemplateLocation.fileNumber : "";
          itemToAdd.routeTemplateLocationId = routeTemplateLocation.id;
          itemToAdd.nbContainers = routeTemplateLocation.nbContainers;
          itemToAdd.completeName = routeTemplateLocation.completeName;
          itemToAdd.item = routeTemplateLocation.item; // Maintenant les RouteLocation sont associés a un et un seul item
          itemToAdd.companyName = routeTemplateLocation.companyName;
          itemToAdd.ciWebLinkId = routeTemplateLocation.location.ci_web_link_id;
          itemToAdd.timesPerWeek = routeTemplateLocation.frequency;
          itemToAdd.weeklyFrequency = routeTemplateLocation.weeklyFrequency;
          itemToAdd.locationName = routeTemplateLocation.addressString;
          itemToAdd.collectStop = routeTemplateLocation.collectStop;
          itemToAdd.collectStopTimestamp = routeTemplateLocation.collectStopTimestamp;
          itemToAdd.rotationInfosForDriver = routeTemplateLocation.rotationInfosForDriver;
          newSelected.push(itemToAdd);
        }
      } else {
        // Pour ajouter les même locations avec des containers de grosseur différent
        const indexOfCurrentItem = dataFromCustomerLocations.findIndex((el) => el.locationId === locationId);
        if (indexOfCurrentItem >= 0) {
          const itemToAdd = Object.assign({}, dataFromCustomerLocations[indexOfCurrentItem]);
          itemToAdd.fileNumber = routeTemplateLocation.fileNumber ? routeTemplateLocation.fileNumber : "";
          itemToAdd.routeTemplateLocationId = routeTemplateLocation.id;
          itemToAdd.nbContainers = routeTemplateLocation.nbContainers;
          itemToAdd.containerModels = routeTemplateLocation.containerModels;
          itemToAdd.completeName = routeTemplateLocation.completeName;
          itemToAdd.item = routeTemplateLocation.item; // Maintenant les RouteLocation sont associés a un et un seul item
          itemToAdd.companyName = routeTemplateLocation.companyName;
          itemToAdd.ciWebLinkId = routeTemplateLocation.location.ci_web_link_id;
          itemToAdd.timesPerWeek = routeTemplateLocation.frequency;
          itemToAdd.weeklyFrequency = routeTemplateLocation.weeklyFrequency;
          itemToAdd.locationName = routeTemplateLocation.addressString;
          itemToAdd.collectStop = routeTemplateLocation.collectStop;
          itemToAdd.collectStopTimestamp = routeTemplateLocation.collectStopTimestamp;
          itemToAdd.rotationInfosForDriver = routeTemplateLocation.rotationInfosForDriver;
          newSelected.push(itemToAdd);
        }
      }
    }
    this.setState({
      items: newItems,
      selected: newSelected,
      originalSelected: newSelected,
    });
  }
};

export const displaySaveButton = function (routeTemplateId, masterRouteId, master_route, isAMasterRoute) {
  if (routeTemplateId && !master_route) {
    if (masterRouteId !== null && routeTemplateId && isAMasterRoute) {
      return (
        <FixedActionButton
          style={{ display: "none" }}
          color="secondary"
          tooltipLabel="Sauvegarder"
          onClick={this.handleSubmit}
        >
          <SaveIcon />
        </FixedActionButton>
      );
    }
  }
  return (
    <FixedActionButton disabled={false} color="secondary" tooltipLabel="Sauvegarder" onClick={this.handleSubmit}>
      <SaveIcon />
    </FixedActionButton>
  );
};

export const loadTsp = function () {
  const begin = moment();
  // console.log('BEGIN', begin);
  const directionsPanel = document.getElementById("directions");
  const { map } = this.state;
  const tsp = new BpTspSolver(map, directionsPanel);
  window.tsp = tsp;

  this.selected.map((item, i) => {
    const lat = parseFloat(item.address.lat);
    const lng = parseFloat(item.address.lng);
    const latLng = new window.google.maps.LatLng(lat, lng);
    // tsp.addPosition(latLng);
    // console.log('TSP addWaypoint...');
    tsp.addGeocodedAddressWithLabel(
      latLng,
      item.locationAddressBasedId,
      item.locationAddressBasedId,
      console.log("callback")
    );
  });

  tsp.setTravelMode(window.google.maps.DirectionsTravelMode.DRIVING);
  // tsp.setOnProgressCallback(onProgressCallback);
  tsp.solveAtoZ(this.tspCallback, false);
};

export const tspCallback = function (tsp, begin) {
  const end = moment();
  // console.log('END', end);

  const dirRes = tsp.getGDirections();
  const dir = dirRes.routes[0];

  document.getElementById("duree").innerHTML = `<p>Durée trajet routier: <strong>${formatTime(
    getTotalDuration(dir)
  )}</strong>`;
  document.getElementById("distance").innerHTML = `<p>Distance: <strong>${formatLength(
    getTotalDistance(dir)
  )}</strong>`;

  const dirRenderer = new window.google.maps.DirectionsRenderer({
    directions: dirRes,
    hideRouteList: true,
    map: this.state.map,
    panel: null,
    preserveViewport: false,
    suppressInfoWindows: true,
    suppressMarkers: true,
  });

  // Set the order of the selected locations with the order from the optimized route
  const order = tsp.getOrder();
  const items = [];
  for (let i = 0; i < order.length; i++) {
    items.push(this.selected[order[i]]);
  }
  this.setState({ selected: items });
};

export const renderLocationsFields = function () {
  const { errors, supplierLocationId } = this.state;
  const { supplierLocations, customerItem } = this.props;
  const { containerKindDefaultSupplier } = customerItem;

  if (supplierLocations.length === 0) {
    return null;
  }

  const filteredSupplierLocations = filterSupplierLocations(supplierLocations, {});
  const supplier = filteredSupplierLocations.find(({ id }) => id === containerKindDefaultSupplier);

  if (supplierLocationId === -1) {
    this.setState({ supplierLocationId: supplier.id });
  }
  return (
    <InputControl>
      <FormControl fullWidth>
        <MuiSelect
          input={<OutlinedInput />}
          error={errors.supplierLocationId}
          formcontrolerror={errors.supplierLocationId.toString()}
          formhelpererrormsg={this.getErrorMessage("supplierLocation")}
          id="cpbr-supplier-location"
          inputlabeltext={<Trans i18nKey="drop_point_1" />}
          onChange={this.handleChangeFields("supplierLocationId")}
          value={`${supplierLocationId}`}
        >
          {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, filteredSupplierLocations, "name")}
        </MuiSelect>
      </FormControl>
    </InputControl>
  );
};

export const displayAddLocationButton = function (routeTemplateId, masterRouteId, master_route, isAMasterRoute) {
  if (routeTemplateId && !master_route) {
    if (masterRouteId !== null && routeTemplateId && isAMasterRoute) {
      return (
        <Grid item xs={12}>
          {/* <InputControl> */}
          {/*  <Button */}
          {/*    style={{ display: 'none' }} */}
          {/*    onClick={this.handleToggleCustomerLocationModal(true)} */}
          {/*    variant="contained" */}
          {/*  > */}
          {/*    <Trans i18nKey="route_templates.add_location" /> */}
          {/*  </Button> */}
          {/* </InputControl> */}
        </Grid>
      );
    }
  }
  return (
    <Grid item xs={12}>
      {/* <InputControl> */}
      {/*  <Button */}
      {/*    onClick={this.handleToggleCustomerLocationModal(true)} */}
      {/*    variant="contained" */}
      {/*  > */}
      {/*    <Trans i18nKey="route_templates.add_location" /> */}
      {/*  </Button> */}
      {/* </InputControl> */}
    </Grid>
  );
};

export const displayRightTitle = function (routeTemplateId, master_route, masterRouteId, isAMasterRoute) {
  if (routeTemplateId && !master_route && !isAMasterRoute && !masterRouteId) {
    return <Trans i18nKey="edit_route_template" />;
  }
  if (routeTemplateId && master_route && isAMasterRoute) {
    return <Trans i18nKey="route_templates.sub_route" />;
  }
  if (routeTemplateId && !master_route) {
    if (masterRouteId !== null && routeTemplateId && isAMasterRoute) {
      return <Trans i18nKey="route_templates.view_route_master" />;
    }
    return <Trans i18nKey="route_templates.edit_route_template_sub_route" />;
  }
  return <Trans i18nKey="route_templates.add_route_master" />;
};

export const displayActionName = function (customerLocationToEditId, master_route) {
  if (customerLocationToEditId) {
    if (master_route) {
      return <Trans i18nKey="route_templates.sub_route" />;
    }
    return <Trans i18nKey="edit" />;
  }
  return <Trans i18nKey="add" />;
};

export const manageInfoWindowAppearance = function (id, selectedOrItem) {
  if (selectedOrItem === "selected") {
    const { selected, lastSelectedUpdatedIndex } = this.state;
    const newSelected = Array.from(selected);
    const index = newSelected.findIndex((e) => e.id === id);

    if (
      newSelected[lastSelectedUpdatedIndex] &&
      lastSelectedUpdatedIndex !== -1 &&
      lastSelectedUpdatedIndex !== index
    ) {
      newSelected[lastSelectedUpdatedIndex].showWindowInfo = false;
    }
    newSelected[index].showWindowInfo = !newSelected[index].showWindowInfo;

    this.setState({ selected: newSelected, lastSelectedUpdatedIndex: index });
  }

  if (selectedOrItem === "item") {
    const { items, lastItemUpdatedIndex } = this.state;
    const newItems = Array.from(items);
    const index = newItems.findIndex((e) => e.id === id);

    if (newItems[lastItemUpdatedIndex] && lastItemUpdatedIndex !== -1 && lastItemUpdatedIndex !== index) {
      newItems[lastItemUpdatedIndex].showWindowInfo = false;
    }
    newItems[index].showWindowInfo = !newItems[index].showWindowInfo;
    this.setState({ items: newItems, lastItemUpdatedIndex: index });
  }
};
