import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationKeys from "./locales/fr-ca";

i18n
  .use(initReactI18next)
  .init({
    lng: 'fr-CA',
    debug: true,
    resources: {
      "fr-CA": {
        translation: translationKeys
      }
    }
  });

export default i18n;