import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DialogWrapper from "./ui/DialogWrapper";

class Pictures extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { photo: props.photo };
  }

  initialState = {
    photo: this.props.photo,
  };

  handleClose = () => {
    this.props.onClose();
  };

  renderPictures = () => (
    <ImageList>
      {this.props.photo ? (
        <ImageListItem>
          <img src={this.props.photo} />
        </ImageListItem>
      ) : null}
    </ImageList>
  );
  render() {
    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        <DialogTitle>
          <Trans i18nKey="show_pictures" />
        </DialogTitle>

        <DialogContent>{this.props.photo ? this.renderPictures() : null}</DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="close" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

Pictures.defaultProps = {
  id: null,
  open: false,
  parent: {},
};

Pictures.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  parent: PropTypes.object,
  parents: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Pictures;
