// React imports
import React, { memo, useCallback } from "react";

// External iports
import Select from "react-select";
import { isEqual } from "lodash";
import { Stack } from "@mui/material";

function FlowSelect({ isError, flow, flows, handlers }) {
  // console.log("[FlowSelect] >>> RENDERED");

  const handleChangeFlow = useCallback((flow) => {
    flow && handlers.setSingleCurrent("flow", flow);
  }, []);

  return (
    <Stack direction="row" alignItems="center">
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            height: 42,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={flow.value ? flow : null}
        menuPosition="fixed"
        onChange={(flow) => handleChangeFlow(flow)}
        options={flows.data}
        placeholder={"Sélectionner un flow"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, flow: prevFlow, flows: prevFlows } = prevProps;
  const { isError: nextIsError, flow: nextFlow, flows: nextFlows } = nextProps;
  let arePropsEqual = true;

  // If the flows array changed, rerender
  if (!isEqual(prevFlows, nextFlows)) {
    arePropsEqual = false;
  }

  // If the current flow changed, rerender
  if (!isEqual(prevFlow, nextFlow)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(FlowSelect, areEqual);
