// React imports
import React, { FC } from "react";
// Externals imports
import { TableCell, TableHead, TableRow } from "@mui/material";

const ContainerLiftingRequestsListHeader: FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>À lever</TableCell>
        <TableCell>Levée prévue le</TableCell>
        <TableCell>Type de demande</TableCell>
        <TableCell>Type de levée</TableCell>
        <TableCell>Nom de route</TableCell>
        <TableCell>Reçus le</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ContainerLiftingRequestsListHeader;
