import { formatLength, formatTime, getTotalDistance, getTotalDuration } from "../maps/tools";

export const bindResizeListener = function (map, maps, bounds) {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

export const fitBoundsForSelected = function () {
  if (this.state.selected && this.state.selected.length > 0) {
    const bounds = new window.google.maps.LatLngBounds();
    this.state.selected.map((item, i) => {
      bounds.extend(new window.google.maps.LatLng(item.address.lat, item.address.lng));
    });
    this.state.map.fitBounds(bounds);
  }
};

export const fitBoundsForAll = function () {
  let willSetBounds = false;
  const bounds = new window.google.maps.LatLngBounds();
  if (this.state.selected && this.state.selected.length > 0) {
    willSetBounds = true;
    this.state.selected.map((item, i) => {
      bounds.extend(new window.google.maps.LatLng(item.address.lat, item.address.lng));
    });
  }
  if (this.state.items && this.state.items.length > 0) {
    willSetBounds = true;
    this.state.items.map((item, i) => {
      bounds.extend(new window.google.maps.LatLng(item.address.lat, item.address.lng));
    });
  }
  if (willSetBounds) {
    this.state.map.fitBounds(bounds);
  }
};

// zoomChanged = () => {
//   // //console.log('zoomChanged');
// };

export const apiIsLoaded = function (map, maps) {
  this.setState({ map });
  this.fitBoundsForAll();
};
