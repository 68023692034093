import { useReducer, useCallback, useEffect } from "react";

/**
 * stateReducer is a function that updates the state of the async function
 *
 * @param {Object} state the current state of the async function
 * @param {Object} action the action that is dispatched to update the state
 * @return {Object} the updated state of the async function
 */
const stateReducer = (_, action) => {
  switch (action.type) {
    case "start":
      return { loading: true, error: null, value: null };
    case "finish":
      return { loading: false, error: null, value: action.value };
    case "error":
      return { loading: false, error: action.error, value: null };
    default:
      return { loading: false, error: null, value: null };
  }
};

/**
 * useAsync is a custom React hook that handles async operations
 *
 * @param {Function} fn the async function to be invoked
 * @param {Array} [dependencies=[]] the dependencies that will trigger the async function
 * @return {{loading: boolean, error: Error, value: any, run: function}}
 *  an object that contains the state and run function of the async operation
 */
function useAsync(fn, dependencies = []) {
  const initialState = { loading: false, error: null, value: null };

  const [state, dispatch] = useReducer(stateReducer, initialState);

  /**
   * run is a function that invokes the async function
   *
   * @param {any} [args=null] the arguments to be passed to the async function
   */
  const run = useCallback(
    async (...args) => {
      try {
        dispatch({ type: "start" });
        const value = await fn(...args);
        dispatch({ type: "finish", value });
      } catch (error) {
        dispatch({ type: "error", error });
      }
    },
    [fn]
  );

  useEffect(() => {
    if (dependencies.length) {
      run();
    }
  }, [run, ...dependencies]);

  return { ...state, run };
}

export default useAsync;
