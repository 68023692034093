import { useQuery, UseQueryResult } from "@tanstack/react-query";

import fetchListCodes, { FetchListCodesServiceReturn } from "../../services/list-codes/fetch-list-codes.service";
import listCodes from "./list-code.keys";

const useFetchListCodes = (codeType: string): UseQueryResult<FetchListCodesServiceReturn> => {
  return useQuery({
    queryKey: listCodes.byCode(codeType),
    queryFn: () => fetchListCodes(codeType),
  });
};

export default useFetchListCodes;
