// @ts-check

import { useMutation } from "@tanstack/react-query";

import LogoutService from "../../services/auth/logout.service";

/**
 * Hook to sign a user out wrapping a @tanstack/react-query mutation
 *
 * @typedef {import("@tanstack/react-query").UseMutationResult} UseMutationResult
 *
 * @returns {UseMutationResult}
 */
const useLogout = () => {
  return useMutation({ mutationFn: LogoutService.logout });
};

export default useLogout;
