import axios from "axios";
import { map } from "lodash";

import { getUserToken } from "../auth";
import { requestOptions } from "../auth";

const fetchMasterRouteTemplate = async (masterRouteTemplateId) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/route_templates/${masterRouteTemplateId}`,
      requestOptions()
    );
    return response.data.data;
  } catch (error) {
    console.warn("[fetchMasterRouteTemplate] error:", error);
  }
};

const uploadXlsxFileForRouteTemplate = async (body) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/upload_route_file.json`,
      {
        body,
      },
      {
        headers: {
          Authorization: getUserToken(),
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.warn("[API.uploadXlsxFileForRouteTemplate] Error:", error);
  }
};

/**
 * RouteTemplate api service for exposing api endpoints.
 *
 * Available requests:
 * - `uploadXlsxFileForRouteTemplate(body)`
 */
export { uploadXlsxFileForRouteTemplate, fetchMasterRouteTemplate };
