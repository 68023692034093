// @ts-check

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "@context/auth/AuthProvider";

const PrivateRoute = () => {
  const { auth } = useAuth();

  if (!auth.user) return <Navigate to="login" replace />;

  return <Outlet />;
};

export default PrivateRoute;
