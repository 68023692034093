// TODO Change .js to .ts

/**
 * Sets the bearer token in localStorage
 *
 * @class
 */
class SetBearerTokenService {
  /**
   * Sets the bearer token in localStorage
   *
   * @param {string} [bearerToken]
   * @returns {void}
   */
  static set(bearerToken) {
    const value = bearerToken || "";

    localStorage.setItem("bearerToken", value);
  }
}

export default SetBearerTokenService;
