// React imports
import React, { useEffect, useState } from "react";
// Internal imports
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import TableLoading from "@ui/TableLoading";
import { handleSortFunctional } from "@utils/filtering";
import * as API from "@services";
import useAsync from "@hooks/useAsync";
// External imports
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Table, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

const headerLabel = {
  startDate: { label: <Trans i18nKey="route_location.date" /> },
  routeName: { label: <Trans i18nKey="route_location.name" /> },
  masterRouteName: { label: "Route maître" },
  unit: { label: <Trans i18nKey="units.title" /> },
};

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
  columnName: "startDate",
  direction: "desc",
};

const header = ["startDate", "masterRouteName", "routeName", "unit"];

const fetchLocationRouteFutureListAsync = async (setRoutes, locationId, filters) => {
  const pageNumber = filters.page + 1;

  const res = await API.Location.fetchLocationRouteFuture(locationId, filters.filter, filters.limit, pageNumber);
  setRoutes(res);
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const LocationRouteFutureListBody = ({ customerLocation, routes, navigate }) => {
  return routes.map((route) => {
    return (
      <TableRow
        key={route.id}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={handleRowClick(
          navigate,
          `/customers/${customerLocation.locatableId}/contracts/${customerLocation.contractId}/preparations/${route.customerItemId}/routes/${route.id}`
        )}
      >
        <TableCell>{route.originalStartDate || "Pas encore commencé"}</TableCell>
        <TableCell>{route.masterRouteName}</TableCell>
        <TableCell>{route.name}</TableCell>
        <TableCell>{route.unitName}</TableCell>
      </TableRow>
    );
  });
};

const LocationRouteFutureListHeader = ({ filters, setFilters, handleSort }) => {
  return (
    <TableHead>
      <TableRow>
        {header.map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={filters.columnName === name}
              direction={filters.direction}
              onClick={handleSort(name, filters, setFilters)}
            >
              {headerLabel[name].label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const handleRowClick = (navigate, path) => () => {
  navigate(path);
};

export default function LocationFutureRouteList({ selectedLocation, customerLocation }) {
  const [routes, setRoutes] = useState([]);
  const [filters, setFilters] = useState(filtersInitialState);

  const navigate = useNavigate();

  useEffect(() => {
    fetchLocationRouteFutureList.run();
  }, [filters, selectedLocation]);

  const fetchLocationRouteFutureList = useAsync(() =>
    fetchLocationRouteFutureListAsync(setRoutes, selectedLocation, filters)
  );

  const handleSort = handleSortFunctional.bind(this);

  const handlePageChange = (event, pageNumber) => {
    setFilters((prevState) => ({ ...prevState, page: pageNumber }));
  };

  return (
    <>
      <TableOverflowWrapper>
        <Table>
          <LocationRouteFutureListHeader
            filters={filters}
            setFilters={setFilters}
            handleSort={handleSort}
          />
          {!routes.data && <TableLoading />}
          {routes.data && (
            <LocationRouteFutureListBody
              customerLocation={customerLocation}
              routes={routes.data}
              navigate={navigate}
            />
          )}
        </Table>
      </TableOverflowWrapper>
      <TablePaginationWrapper
        component="div"
        count={routes.count || 0}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={filters.page}
        rowsPerPage={filters.limit}
      />
    </>
  );
}
