import ContainerProfileResponse from "@@types/container-profile/container-profile-response.type";
import ContainerProfile from "@@types/container-profile/container-profile.type";

function mapResponseToContainerProfile(response: ContainerProfileResponse): ContainerProfile {
  return {
    id: response.id,
    collectionType: response.collectionType,
    containerType: response.containerType,
    description: response.description,
    category: response.category,
    fraction: response.fraction,
    model: response.model,
    volume: response.volume,
  };
}

function mapResponseToContainerProfiles(responses: ContainerProfileResponse[]): ContainerProfile[] {
  return responses.map(mapResponseToContainerProfile);
}

export default mapResponseToContainerProfiles;
