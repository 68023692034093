import axios from "axios";
import { requestOptions } from "../auth";

const deleteUnit = async (unitId) => {
  try {
    await axios.delete(`${import.meta.env.VITE_API_URL}/units/${unitId}.json`, requestOptions());

    return true;
  } catch (error) {
    console.warn("[delete-unit.service] error:", error);

    return false;
  }
};

export default deleteUnit;
