import { useQuery, UseQueryResult } from "@tanstack/react-query";

import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import locationCcavContainersKeys from "@hooks/containers/location-ccav-containers.keys";
import fetchLocationCcavContainersService from "../../services/containers/fetch-location-ccav-containers.service";
import mapResponseToLocationCcavContainers from "@@types/containers/mappers/mapResponseToLocationCcavContainer.mapper";

const useFetchLocationCcavContainers = (locationId: number): UseQueryResult<LocationCcavContainer[]> => {
  return useQuery({
    queryKey: locationCcavContainersKeys.detail(locationId),
    queryFn: () => fetchLocationCcavContainersService(locationId),
    select: ({ data: response }): LocationCcavContainer[] => mapResponseToLocationCcavContainers(response || []),
  });
};

export default useFetchLocationCcavContainers;
