import React, { FC } from "react";
import { Trans } from "react-i18next";

import LocationDetail from "@@types/location-details/location-detail.type";
import { Button, DialogActions } from "@mui/material";

type LocationModalityModalActionButtonProps = {
  modality: LocationDetail;
  onCancel: (modalType: string) => void;
  onMutate: (modality: LocationDetail) => void;
  modalType: string;
};

const LocationModalityModalActionbuttons: FC<LocationModalityModalActionButtonProps> = ({
  modality,
  onCancel,
  onMutate,
  modalType,
}) => {
  return (
    <DialogActions>
      <Button onClick={() => onCancel(modalType)}>
        <Trans i18nKey="cancel" />
      </Button>

      <Button
        variant="contained"
        onClick={() => onMutate(modality)}
      >
        {modalType === "create" ? <Trans i18nKey="add" /> : <Trans i18nKey="edit" />}
      </Button>
    </DialogActions>
  );
};

export default LocationModalityModalActionbuttons;
