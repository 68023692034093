import React from "react";
import { Trans } from "react-i18next";

import { DayModality } from "@@types/location-details/location-detail.type";

export type LocationModalityDeleteModal = {
  collectDaysFormatted: string[];
};

const useLocationModalityDeleteModal = (weekDays: DayModality[]): LocationModalityDeleteModal => {
  const collectDays = Object.values(weekDays)
    .filter((day) => day.active)
    .map((collectDay) => (
      <Trans
        key={collectDay.label}
        i18nKey={`weekdays_location.complete.${collectDay.label}`}
      />
    ));
  const collectDaysFormatted = [];
  for (let i = 0; i < collectDays.length; i++) {
    collectDaysFormatted.push(collectDays[i]);
    if (i < collectDays.length - 1) {
      collectDaysFormatted.push(", ");
    }
  }

  return {
    collectDaysFormatted,
  } as LocationModalityDeleteModal;
};

export default useLocationModalityDeleteModal;
