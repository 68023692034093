import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";

import FlexRowWrapper from "./FlexRowWrapper";
import AutocompleteHalf from "./AutocompleteHalf";
import { renderAutocompleteList } from "@utils/form";
import HalfButtonWrapper from "./HalfButtonWrapper";
import FormGroupWrapper from "./FormGroupWrapper";
import ModalContractLocation from "../ModalContractLocation";
import ModalCustomerLocation from "../ModalCustomerLocation";
import ModalCustomer from "@components/modals/ModalCustomer";
import { Trans } from "react-i18next";

const AutocompleteModal = ({
  actionName,
  displayHelperButton,
  autocompleteList,
  autocompletePlaceholder,
  autocompleteValue,
  buttonClass,
  buttonTextId,
  dropDownMenuPortalTarget,
  errors,
  errorMessagesHelper,
  modalCallback,
  modalName,
  modalOpened,
  onChangeAutocomplete,
  onClickButton,
  onCloseModal,
  ...remainingProps
}) => {
  let Modal = null;

  switch (modalName) {
    case "contractLocation":
      Modal = ModalContractLocation;
      break;
    case "customer":
      Modal = ModalCustomer;
      break;
    default:
      Modal = ModalCustomerLocation;
      break;
  }

  return (
    <FormGroupWrapper>
      <FlexRowWrapper>
        <AutocompleteHalf
          error={errors[`${autocompleteValue}`]}
          formControlError={errors[`${autocompleteValue}`]}
          formControlWidthClass="two-thirds"
          formHelperErrorMsg={errorMessagesHelper}
          id={`cpbr-${kebabCase(autocompleteValue)}`}
          value={autocompleteValue}
          options={renderAutocompleteList(autocompleteList)}
          onChange={onChangeAutocomplete}
          placeholder={autocompletePlaceholder}
          dropDownMenuPortalTarget={dropDownMenuPortalTarget}
        />

        <HalfButtonWrapper className="one-sixth">
          {displayHelperButton ? (
            <Button
              classes={{ contained: buttonClass }}
              onClick={onClickButton}
              variant="contained"
            >
              <AddIcon fontSize="small" />
              <Trans i18nKey={buttonTextId} />
            </Button>
          ) : (
            ""
          )}
        </HalfButtonWrapper>
      </FlexRowWrapper>

      {modalOpened ? (
        <Modal
          actionName={<Trans i18nKey={`${actionName}`} />}
          onClose={onCloseModal}
          open={modalOpened}
          callback={modalCallback}
          {...remainingProps}
        />
      ) : (
        ""
      )}
    </FormGroupWrapper>
  );
};

AutocompleteModal.defaultProps = {
  modalCallback: () => { },
  onChangeAutocomplete: () => { },
};

AutocompleteModal.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  autocompleteList: PropTypes.arrayOf(PropTypes.object).isRequired,
  autocompletePlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  autocompleteValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonClass: PropTypes.string.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
  errorMessagesHelper: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  modalCallback: PropTypes.func,
  modalName: PropTypes.string.isRequired,
  modalOpened: PropTypes.bool.isRequired,
  onChangeAutocomplete: PropTypes.func,
  onClickButton: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dropDownMenuPortalTarget: PropTypes.string.isRequired,
};

export default AutocompleteModal;
