import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { withContacts } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withRouter } from "@utils/withRouter";

import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { Trans } from "react-i18next";

const initialState = {
  errors: {
    cellPhone: false,
    email: false,
    homePhone: false,
    fullName: false,
    workPhone: false,
    workPhoneExt: false,
  },
  cellPhone: "",
  email: "",
  homePhone: "",
  fullName: "",
  workPhone: "",
  workPhoneExt: "",
};

class ModalContact extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      ...props.contact,
    };
  }

  get valid() {
    const { contact } = this.props;
    const { email, fullName, workPhone } = this.state;
    const errors = { ...initialState.errors };
    let valid = true;

    // Validate the presence
    for (const name of ["fullName"]) {
      if (!this.state[name]) {
        valid = false;
        errors[name] = true;
      }
    }

    if (fullName !== "FACTURATION") {
      if (!email.includes("@")) {
        valid = false;
        errors.email = true;
      }
    }

    // Validate if phone is a number and has 10 digits
    for (const name of ["homePhone", "workPhone", "cellPhone"]) {
      const phoneNumber = this.state[name].replace(/[^\d]/g, "");
      if (phoneNumber.length > 0) {
        if (phoneNumber.length > 11 || phoneNumber.length < 10) {
          valid = false;
          errors[name] = true;
        }
      }
    }

    // work phone mandatory if name is FACTURATION (only possible when editing an existing contact)
    if (fullName === "FACTURATION") {
      if (contact.id && workPhone.length > 0) {
        if (workPhone.length > 11 || workPhone.length < 10) {
          valid = false;
          errors.workPhone = true;
        }
      }
      // cant create a new contact with name FACTURATION (this contact name is reserved to contact created for stripe)
      if (!contact.id) {
        valid = false;
        errors.fullName = true;
      }
    }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { contact, createContact, editContact, router, refreshList } = this.props;

      const { cellPhone, email, homePhone, fullName, workPhone, workPhoneExt } = this.state;

      const { id } = contact;
      const method = id ? editContact : createContact;

      await method(
        router.params.customerId,
        {
          cell_phone: cellPhone,
          name: fullName,
          home_phone: homePhone,
          work_phone: workPhone,
          work_phone_ext: workPhoneExt,
          email,
        },
        id
      );

      this.setState(initialState);

      refreshList();
    }
  };

  render() {
    const { actionName, contact } = this.props;

    const { cellPhone, email, errors, homePhone, fullName, workPhone, workPhoneExt } = this.state;

    const modalTitle =
      actionName.props.id === "add" ? <Trans i18nKey="add_contact" /> : <Trans i18nKey="edit_contact" />;

    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        <DialogTitle>{modalTitle}</DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <FlexRowWrapper>
                <TextFieldUi
                  inputProps={{ maxLength: 100 }}
                  error={errors.fullName}
                  fullWidth
                  helperText={this.getErrorMessage(
                    "fullName",
                    "required",
                    fullName === "FACTURATION" && !contact.id
                      ? "Le nom FACTURATION est réservé pour des raisons administratives."
                      : null
                  )}
                  id="cpbr-full-name"
                  label={<Trans i18nKey="contact.name" />}
                  onChange={this.handleChangeFields("fullName")}
                  value={fullName || ""}
                />
                {/*<HalfFormControl>*/}
                {/*  <TextFieldUi*/}
                {/*    error={errors.firstName}*/}
                {/*    fullWidth*/}
                {/*    helperText={this.getErrorMessage("firstName")}*/}
                {/*    id="cpbr-first-name"*/}
                {/*    label={<Trans i18nKey="contact.first_name" />}*/}
                {/*    onChange={this.handleChangeFields("firstName")}*/}
                {/*    value={firstName || ""}*/}
                {/*  />*/}
                {/*</HalfFormControl>*/}

                {/*<HalfFormControl>*/}
                {/*  <TextFieldUi*/}
                {/*    error={errors.lastName}*/}
                {/*    fullWidth*/}
                {/*    helperText={this.getErrorMessage("lastName")}*/}
                {/*    id="cpbr-last-name"*/}
                {/*    label={<Trans i18nKey="contact.last_name" />}*/}
                {/*    onChange={this.handleChangeFields("lastName")}*/}
                {/*    value={lastName || ""}*/}
                {/*  />*/}
                {/*</HalfFormControl>*/}
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                inputProps={{ maxLength: 100 }}
                error={errors.email}
                helperText={this.getErrorMessage("email", "invalid")}
                id="cpbr-email"
                label={<Trans i18nKey="contact.email" />}
                onChange={this.handleChangeFields("email")}
                value={email?.trim() || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 14 }}
                    error={errors.homePhone}
                    fullWidth
                    helperText={this.getErrorMessage("homePhone", "invalid")}
                    id="cpbr-home-phone"
                    label={<><Trans i18nKey="contact.home_phone_full" /> <Trans i18nKey="optional" /></>}
                    onChange={this.handleChangeFields("homePhone")}
                    value={homePhone?.trim() || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 14 }}
                    error={errors.workPhone}
                    fullWidth
                    helperText={this.getErrorMessage("workPhone", "invalid")}
                    id="cpbr-work-phone"
                    label={<><Trans i18nKey="contact.work_phone_full" /> <Trans i18nKey="optional" /></>}
                    onChange={this.handleChangeFields("workPhone")}
                    value={workPhone?.trim() || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 10 }}
                    error={errors.workPhoneExt}
                    fullWidth
                    helperText={this.getErrorMessage("workPhoneExt")}
                    id="cpbr-work-phone-ext"
                    label={<><Trans i18nKey="contact.ext" /> <Trans i18nKey="optional" /></>}
                    onChange={this.handleChangeFields("workPhoneExt")}
                    value={workPhoneExt || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 14 }}
                    error={errors.cellPhone}
                    fullWidth
                    helperText={this.getErrorMessage("cellPhone", "invalid")}
                    id="cpbr-cell-phone"
                    label={<><Trans i18nKey="contact.cell_phone_full" /> <Trans i18nKey="optional" /></>}
                    onChange={this.handleChangeFields("cellPhone")}
                    value={cellPhone || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalContact.defaultProps = {
  contact: {},
};

ModalContact.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contact: PropTypes.object,
  createContact: PropTypes.func.isRequired,
  editContact: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withContacts(withRouter(ModalContact));
