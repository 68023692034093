import React, { ReactElement } from "react";
import { SelectChangeEvent } from "@mui/material";
import Dropdown from "@ui/dropdown/Dropdown";
import useFetchSuppliersList from "@hooks/suppliers/fetch-suppliers-list.hook";
import { Supplier } from "@@types/suppliers/supplier.type";

export type ModalAddMasterRouteTemplateSupplierProps = {
  supplier: Supplier;
  onChange: (value: SelectChangeEvent<string>) => void;
};

const ModalAddMasterRouteTemplateSupplier = ({
  supplier,
  onChange,
}: ModalAddMasterRouteTemplateSupplierProps): ReactElement => {
  const { data = [] } = useFetchSuppliersList();

  const renderMenuItems = (): ReactElement | ReactElement[] => {
    if (data.length === 0) {
      return <Dropdown.MenuItem value=" "></Dropdown.MenuItem>;
    }
    return data.map((supplier) => (
      <Dropdown.MenuItem
        key={supplier.id}
        value={supplier.id.toString()}
      >
        {supplier.name}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      error={supplier.error}
      label="Point de chute"
      value={supplier?.id?.toString()}
      name="supplier"
      onChange={onChange}
      width="500px"
    >
      {renderMenuItems()}
    </Dropdown>
  );
};

export default ModalAddMasterRouteTemplateSupplier;
