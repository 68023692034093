import { useQuery, UseQueryResult } from "@tanstack/react-query";
import LocationDetailResponse from "@@types/location-details/location-detail-response.type";
import locationDetail from "@hooks/location-details/location-detail.keys";
import fetchLocationCcavModalities from "../../services/location-details/fetch-location-ccav-modalities.service";
import mapResponseToLocationDetails from "@@types/location-details/mappers/mapResponseToLocationDetail.mapper";

const useFetchLocationCcavModalities = (location_id: number): UseQueryResult<LocationDetailResponse[]> => {
  return useQuery({
    queryKey: locationDetail.detail(location_id),
    queryFn: () => fetchLocationCcavModalities(location_id),
    select: ({ data: response }): LocationDetailResponse[] => mapResponseToLocationDetails(response || []),
  });
};

export default useFetchLocationCcavModalities;
