import theme from "./theme";
import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#49454F",
          "&.MuiInputLabel-root": {
            top: "-4px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.filter-width": {
            width: "125px",
            "@media (min-width: 992px)": {
              width: "217px",
            },
          },
          "&.two-thirds": {
            width: "73%",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            display: "block",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "12.5px 0px 12.5px 25px",
        },
        notchedOutline: {
          border: "1px solid #79747E",
        },
        root: {
          "&.Mui-focused": {
            "& $notchedOutline": {
              borderColor: theme.colors.default,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
        root: {
          "&:hover": {
            backgroundColor: "#fbfbfb",
          },
          "&.Mui-selected": {
            color: `${theme.colors.default} !important`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.colors.default,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "auto",
          padding: "4px 0px 4px 25px",
          textOverflow: "ellipsis",

          "&.action-cell": {
            paddingRight: "25px",
            textAlign: "left",
            width: "8%",
          },
          "&.icon-cell": {
            padding: 0,
            textAlign: "center",
            width: "8%",
          },
          "&.province-cell": {
            paddingRight: "25px",
            width: "8%",
          },
          "&.zipcode-cell": {
            width: "8%",
          },
          "&.no-pointer": {
            cursor: "default !important",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        caption: {
          color: theme.colors.font.light,
        },
        select: {
          color: theme.colors.font.light,
          fontSize: "0.750em",
        },
        toolbar: {
          padding: "60px 24px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "60px",
          transition: "background-color .25s ease-in-out",
          "&:nth-of-type(even)": {
            backgroundColor: theme.colors.palette.background.medium,
          },
          "&.link-row:hover": {
            backgroundColor: "#e7f8ff",
            cursor: "pointer",
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          width: "450px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingRight: "6px",
          paddingLeft: "6px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        gutters: {
          margin: 0,
        },
        contentGutters: {
          margin: 0,
        },
        content: {
          cursor: "auto",
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
        root: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: theme.colors.palette.primary.main,
    },
    secondary: {
      main: theme.colors.palette.secondary.main,
      contrastText: theme.colors.palette.secondary.contrastText,
    },
    background: {
      default: theme.colors.palette.background.medium,
    },
  },
});

export default muiTheme;
