import DialogWrapper from "@ui/DialogWrapper";
import { Button, Divider, Stack, Typography } from "@mui/material";
import LoadingButton from "@ui/LoadingButton";
import { Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import UserPassword from "@components/modals/user/UserPassword";
import UserNip from "@components/modals/user/UserNip";
import UserRoles from "@components/modals/user/UserRoles";
import UserInformations from "@components/modals/user/UserInformations";
import { includes, map } from "lodash";
import * as API from "@services";
import UserStatus from "@components/modals/user/UserStatus";
import useNotifier from "@hooks/useNotifier";

const validUserData = (user, setUser) => {
  const errors = map(user.data, (value, key) => {
    if (value.value === "") {
      setUser((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: {
            value: prevState.data[key].value,
            error: true,
          },
        },
      }));
    }
    return value.value === "";
  });

  return errors;
};

const validUserPasswords = (user, setUser) => {
  const isPasswordIdentical = user.passwords.password.value === user.passwords.confirmPassword.value;

  const isNipIdentical = user.passwords.nip.value === user.passwords.confirmNip.value;

  if (!isPasswordIdentical || !isNipIdentical) {
    setUser((prevState) => ({
      ...prevState,
      passwords: {
        ...prevState.passwords,
        confirmPassword: {
          value: prevState.passwords.confirmPassword.value,
          error: !isPasswordIdentical,
          show: prevState.passwords.confirmPassword.show,
        },
        confirmNip: {
          value: prevState.passwords.confirmNip.value,
          error: !isNipIdentical,
          show: prevState.passwords.confirmNip.show,
        },
      },
    }));
  }

  const errors = map(user.passwords, (value, key) => {
    if (value.value === "") {
      setUser((prevState) => ({
        ...prevState,
        passwords: {
          ...prevState.passwords,
          [key]: {
            value: prevState.passwords[key].value,
            error: true,
            show: prevState.passwords[key].show,
          },
        },
      }));
    }
    return value.value === "";
  });

  return [...errors, !isPasswordIdentical, !isNipIdentical];
};

const valid = (user, setUser) => {
  const userData = validUserData(user, setUser);

  const userPasswords = validUserPasswords(user, setUser);

  const errors = [...userData, ...userPasswords];

  let isValid = !includes(errors, true);

  return isValid;
};

const handleSaveNewUser = async (user, setUser, setIsNewUserModalOpen, notifier) => {
  const isValid = valid(user, setUser);

  if (isValid) {
    const payload = {
      first_name: user.data.firstName.value,
      last_name: user.data.lastName.value,
      email: user.data.email.value,
      password: user.passwords.password.value,
      nip: user.passwords.nip.value,
      admin: user.roles.admin,
      accounting: user.roles.accounting,
      disabled: user.status.disabled,
    };

    const res = await API.User.createUser(payload);

    if (res.error) {
      // format error exception created by devise (custom messages in fr.yml)
      const message = res.exception.slice(0, -1).split(":")[4].split(",");

      setUser((prevState) => {
        let test;

        if (message[0].includes("Courriel")) {
          test = { ...prevState, data: { ...prevState.data, email: { ...prevState.data.email, error: true } } };
        } else {
          test = {
            ...prevState,
            passwords: {
              ...prevState.passwords,
              password: { ...prevState.passwords.password, error: true },
              confirmPassword: { ...prevState.passwords.confirmPassword, error: true },
            },
          };
        }
        return test;
      });
      notifier.enqueueMessages(message);
    } else {
      setIsNewUserModalOpen((prevState) => !prevState);
    }
  }
};

export default function UserNewModal({ open, onClose, setIsNewUserModalOpen, user, setUser, fetchUsersList }) {
  const notifier = useNotifier();
  return (
    <DialogWrapper onClose={onClose} open={open}>
      <Stack spacing={1}>
        {/* USER STATUS (active, inactive) */}
        <UserStatus user={user} setUser={setUser} type={"new"} />

        <Divider />

        <Stack spacing={2}>
          {/* ROLES */}
          <UserRoles user={user} setUser={setUser} />

          {/* INFORMATIONS */}
          <UserInformations user={user} setUser={setUser} />

          {/* PASSWORDS */}
          <Typography pl={3} pb={1} fontSize={20} fontWeight="bold">
            Mots de passes
          </Typography>

          <UserPassword user={user} setUser={setUser} modalType={"newUser"} />

          <UserNip user={user} setUser={setUser} modalType={"newUser"} />
        </Stack>
      </Stack>

      {/* ACTION BUTTONS  */}
      <Stack display="flex" justifyContent="flex-end" p={1} pr={3} spacing={1} direction="row">
        <Button onClick={onClose}>Annuler</Button>

        <LoadingButton
          startIcon={<Save />}
          onClick={() => handleSaveNewUser(user, setUser, setIsNewUserModalOpen, notifier).then(fetchUsersList)}
          loadingPosition="start"
        >
          Enregister
        </LoadingButton>
      </Stack>
    </DialogWrapper>
  );
}
