import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";

const CustomHeaderLabel = styled.div`
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #b9b9b9;
  }
`;

const handleClickOnCell = (navigate, path) => {
  navigate(path);
};

export default function ItemsTableBody({ items }) {
  const navigate = useNavigate();

  return items.map((item) => {
    return (
      <TableRow key={item.id}>
        <TableCell align="center">
          {item.available ? <CheckCircleIcon sx={{ color: "green" }} /> : <DangerousIcon sx={{ color: "red" }} />}
        </TableCell>
        <TableCell align="center" className="name">
          {item.containerNumber || "-"}
        </TableCell>
        <TableCell align="center" className="name">
          {item.itemFormat || "-"}
        </TableCell>
        <TableCell align="left" className="name">
          {item?.lastLocationAddress ? (
            <CustomHeaderLabel
              onClick={() =>
                handleClickOnCell(
                  navigate,

                  `/customers/${item.lastCustomer?.id}/contracts/${item.lastContract?.id}/locations/${item.lastLocation?.id}`
                )
              }
            >
              {item.lastLocationAddress}
            </CustomHeaderLabel>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="left" className="name">
          {item.lastCustomer?.name ? (
            <CustomHeaderLabel onClick={() => handleClickOnCell(navigate, `/customers/${item.lastCustomer?.id}`)}>
              {item.lastCustomer?.name}
            </CustomHeaderLabel>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="left" className="name">
          {item.lastFlow || "-"}
        </TableCell>
        <TableCell align="left" className="name">
          {item.lastJobEndDate || "-"}
        </TableCell>
        <TableCell align="center" className="name">
          {item.daysUnavailable || "-"}
        </TableCell>
      </TableRow>
    );
  });
}
