import React, { ReactElement } from "react";
import { Trans } from "react-i18next";

import { Recurrence } from "@@types/recurrences/recurrence.type";
import { Unit } from "@@types/units/unit.type";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import theme from "@styles/theme";
import { Button } from "@ui/button/Button";
import Modal from "@ui/modal/Modal";

export type ModalEditRecurrenceProps = {
  units: Unit[];
  recurrenceToEdit: Recurrence;
  opened: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  onSubmit: () => void;
  onClose: () => void;
};

const ModalEditRecurrence = ({
  units,
  recurrenceToEdit,
  opened,
  onChange,
  onSubmit,
  onClose,
}: ModalEditRecurrenceProps): ReactElement => {
  return (
    <Modal
      open={opened}
      onClose={onClose}
    >
      <Modal.Header title={"Modifier une récurrence"} />
      <Modal.Content>
        <FormControl fullWidth>
          <InputLabel>Camion</InputLabel>
          <Select
            error={recurrenceToEdit.error}
            label={"Camion"}
            value={recurrenceToEdit.unitName}
            name="matter"
            onChange={(event) => onChange(event)}
            fullWidth
          >
            {units?.map((unit) => {
              return (
                <MenuItem
                  key={unit.id}
                  value={unit.name}
                >
                  {unit.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Buttons>
          <Button
            variant="text"
            textColor={theme.colors.palette.secondary.main}
            onClick={onClose}
          >
            <Trans i18nKey="cancel" />
          </Button>
          <Button
            textColor="#FFFFFF"
            onClick={onSubmit}
          >
            <Trans i18nKey="edit" />
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditRecurrence;
