import React from "react";
import { default as MuiLoadingButton } from "@mui/lab/LoadingButton";

export default function LoadingButton({
  color,
  loading,
  disabled,
  startIcon,
  onClick,
  loadingPosition,
  children,
  ...remainingProps
}) {
  return (
    <MuiLoadingButton
      color={color}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      startIcon={startIcon}
      loadingPosition={loadingPosition}
      variant="contained"
      {...remainingProps}
    >
      {children}
    </MuiLoadingButton>
  );
}
