import { DatePicker } from "@mui/x-date-pickers/";
import styled from "@emotion/styled";

const InlineDatePickerWrapper = styled(DatePicker)`
  width: 230px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background: #fff;
  border-radius: 5px;

  .WithUtils-DateTextField--input-146 {
    align-items: center;
  }

  fieldset,
  label {
    display: none;
  }
  label + div {
    align-items: center;
  }
`;

export default InlineDatePickerWrapper;
