import React from "react";
import { FormControlLabel, FormGroup, Grid, IconButton } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { SimpleSwitch } from "./SimpleSwitch";
import PageContainer from "./PageContainer";

const PopupSidebar = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1115;
  background-color: #fff;
  box-shadow: 5px 0 5px -5px #888;
  padding: 12px 24px;

  @media screen and (min-width: 767px) {
    width: 1300px;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 111, 156, 0.25);
`;

const SidebarTitle = styled.h2`
  font-size: 1.5em;
  color: rgb(43, 78, 93);
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const SidebarTitleSwitchFlex = styled.div`
  display: flex;
  align-items: center;
`;

const SidebarContent = styled.div`
  height: 85%;
  overflow-y: scroll;
  padding-bottom: 25px;
`;

const RouteSidebar = ({
  children,
  closeSidebarOnClick,
  sidebarTitle,
  showSideBar,
  switchChecked,
  switchOnChange,
}) => (
  <PageContainer>
    <div className="route-sidebar-wrapper">
      <CSSTransition in={showSideBar} timeout={300} classNames="sidebar" unmountOnExit>
        <PopupSidebar>
          <SidebarTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <SidebarTitleSwitchFlex>
                  {sidebarTitle}
                  {/*<FormGroup>*/}
                  {/*  <FormControlLabel*/}
                  {/*    control={<SimpleSwitch checked={switchChecked} onChange={switchOnChange} name="checkedB" />}*/}
                  {/*    label="Table de données"*/}
                  {/*    style={{ marginLeft: 0 }}*/}
                  {/*  />*/}
                  {/*</FormGroup>*/}
                </SidebarTitleSwitchFlex>
              </Grid>
              <Grid item>
                <IconButton
                  color="secondary"
                  aria-label="close"
                  onClick={closeSidebarOnClick}
                  style={{ border: "1px solid #2b4e5d", color: "#2b4e5d", padding: 6 }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </SidebarTitle>
          <SidebarContent>{children}</SidebarContent>
        </PopupSidebar>
      </CSSTransition>
      {showSideBar && <PopupOverlay />}
    </div>
  </PageContainer>
);

RouteSidebar.defaultProps = {
  children: null,
  sidebarTitle: null,
  showSideBar: false,
  switchChecked: false,
};

RouteSidebar.propTypes = {
  children: PropTypes.node,
  closeSidebarOnClick: PropTypes.func.isRequired,
  sidebarTitle: PropTypes.node,
  showSideBar: PropTypes.bool,
  switchChecked: PropTypes.bool,
  switchOnChange: PropTypes.func.isRequired,
};

export default RouteSidebar;
