import LocationDetail from "@@types/location-details/location-detail.type";
import LocationDetailPayload from "@@types/location-details/location-detail-payload.type";

function mapPayloadToLocationDetail(payload: LocationDetail): LocationDetailPayload {
  return {
    locationDetailFrequencyId: payload.locationDetailFrequencyId,
    locationDetailDaysOfWeekId: payload.locationDetailDaysOfWeekId,
    matter: payload.matter.value,
    from: payload.from.value,
    until: payload.until.value,
    frequency: payload.frequency.value,
    monday: payload.monday.value,
    tuesday: payload.tuesday.value,
    wednesday: payload.wednesday.value,
    thursday: payload.thursday.value,
    friday: payload.friday.value,
    saturday: payload.saturday.value,
    sunday: payload.sunday.value,
  };
}
export default mapPayloadToLocationDetail;
