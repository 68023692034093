// React imports
import React, { FC, useState } from "react";
// Internal imports
import RouteHistory from "@@types/routes/route-history.type";
import LocationRoutesHistoryDetailsHeader from "@components/location/route/history/LocationRouteHistoryDetailsHeader";
import LocationRoutesHistoryDetailsBody from "@components/location/route/history/LocationRouteHistoryDetailsBody";
import RouteAnomalyDetailsDialog from "@components/RouteAnomalyDetailsDialog";
import useFetchRouteLocationAnomalies from "@hooks/route-locations/useFetchRouteLocationAnomalies.hook";
import DialogWrapper from "@ui/DialogWrapper";
// External imports
import { Button, DialogTitle, Stack, Table } from "@mui/material";
import Typography from "@mui/material/Typography";

type LocationRouteHistoryDetailsProps = {
  route: RouteHistory;
  open: boolean;
  onClose: () => void;
};

const LocationRouteHistoryDetails: FC<LocationRouteHistoryDetailsProps> = ({ open, onClose, route }) => {
  const [selectedRouteLocationId, setSelectedRouteLocationId] = useState<number>(0);
  const [showRouteLocationAnomaliesDialog, setShowRouteLocationAnomaliesDialog] = useState(false);

  const handleCloseAnomalyContainerDetailsDialog = (): void => {
    setShowRouteLocationAnomaliesDialog(false);
  };

  const { data, isLoading } = useFetchRouteLocationAnomalies(selectedRouteLocationId);

  return (
    <>
      <DialogWrapper
        width=""
        onClose={onClose}
        open={open}
      >
        <DialogTitle>
          Détail(s) route {route.masterRouteName}-{route.name} | {route.originalStartDate}
        </DialogTitle>
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Table>
            {/* TABLE HEADERS */}
            <LocationRoutesHistoryDetailsHeader />

            {/* /!* TABLE BODY *!/*/}
            <LocationRoutesHistoryDetailsBody
              routeLocations={route.routeLocations}
              setSelectedRouteLocationId={setSelectedRouteLocationId}
              setShowRouteLocationAnomaliesDialog={setShowRouteLocationAnomaliesDialog}
            />
          </Table>
        </Stack>

        {/* ROTATION INFO */}
        <Stack alignItems="flex-end">
          <Stack
            p={1}
            direction="row"
            alignItems="center"
          >
            {route.rotationInfos && (
              <Typography
                color="red"
                pr={10}
              >
                {route.rotationInfos}
              </Typography>
            )}
            <Button onClick={onClose}>Annuler</Button>
          </Stack>
        </Stack>
      </DialogWrapper>

      {showRouteLocationAnomaliesDialog && !isLoading && (
        <RouteAnomalyDetailsDialog
          anomalyItems={data}
          dialogOpen={showRouteLocationAnomaliesDialog}
          onDialogClose={handleCloseAnomalyContainerDetailsDialog}
        />
      )}
    </>
  );
};

export default LocationRouteHistoryDetails;
