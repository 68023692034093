import styled from "@emotion/styled";
import MuiButton from "@mui/material/Button";

export const StyledButton = styled(MuiButton)<{
  backgroundcolor: string;
  bordercolor: string | undefined;
  textcolor: string;
  variant: string;
  isicon?: string;
}>`
  background-color: ${({ backgroundcolor, variant }): string => (variant === "text" ? "unset" : backgroundcolor)};
  border-color: ${({ bordercolor }): string => bordercolor || "#FFFFFF"};
  height: ${({ isicon }): string => (isicon === "true" ? "unset" : "48px")};
  color: ${({ textcolor }): string => textcolor};
  padding: ${({ isicon }): string => (isicon === "true" ? "0" : "8px 22px")};

  :hover {
    filter: "brightness(1.2)";
    background-color: ${({ backgroundcolor, variant }): string => (variant === "text" ? "unset" : backgroundcolor)};
  }

  :disabled {
    transition: opacity 0.5s;
    background-color: #0000001f;
  }

  :enabled {
    transition: opacity 0.5s;
  }
`;
