import axios from "axios";
import { requestOptions } from "../auth";

const fetchUsersList = async (filter, limit, page) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/users/users_list.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data;
  } catch (error) {
    console.warn("[API.fetchUsersList] Error:", error);
  }
};

const updateUser = async (userId, payload) => {
  try {
    const response = await axios.put(`${import.meta.env.VITE_API_URL}/users/${userId}`, payload, requestOptions());

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createUser = async (payload) => {
  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/users`, payload, requestOptions());

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchUsersList`,
 * - `createUser`,
 * - `updateUser`,
 */
export { fetchUsersList, createUser, updateUser };
