import { map } from "lodash";

/**
 * Formats the backend data to only retrieve the relevant data.
 * @param {*} apiResponse The data property of the result of the axios api call, such as `res.data`.
 * @returns Formatted data so that we only have the actual data and not the nested object properties.
 */
const formatBackendCollectionData = (apiResponse) => {
  /**
   * Backend normalized/serliazed data example:
   *
   * {
   *    "instances": {
   *      "data": {
   *        "[elmentID]": {
   *          "data": {
   *            ...,
   *            "attributes": {
   *              ----> DATA IS HERE
   *            }
   *          }
   *        }
   *      }
   *    }
   * }
   */

  try {
    return map(apiResponse.instances.data, (element) => element.data.attributes);
  } catch (error) {
    console.warn("[API.formatBackendCollectionData] error:", error);
  }
};

/**
 * Api utils specific to our backend.
 */
export { formatBackendCollectionData };
