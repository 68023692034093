// React imports
import React, { useState, useEffect, useCallback } from "react";

// Internal imports
import * as API from "@services";
import LoadingButton from "@ui/LoadingButton";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";

// External iports
import styled from "@emotion/styled";
import { loadStripe } from "@stripe/stripe-js";
import { Stack } from "@mui/material";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

const StyledForm = styled.form`
  width: "100%";
  position: "relative";
  margin-bottom: "10px";
`;

const StyledPaymentElement = styled(PaymentElement)`
  min-height: 290px;
`;

const StyledMessage = styled.div`
  color: #df1b41;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-top: 16px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
`;

const StyledSaveButton = styled(LoadingButton)`
  width: 100%;
  font-weight: 600;
  color: #ffffff;
  background-color: rgb(5, 112, 222);

  &:hover {
    filter: contrast(115%);
    background-color: rgb(5, 112, 222);
  }
`;

function StripeForm({ customer, setCustomer }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    data,
    stripe: { setupIntent },
  } = customer;

  const isIntentIncomplete =
    setupIntent && (setupIntent.status === "" || setupIntent.status === "requires_payment_method");

  useEffect(() => {
    return () => {
      setMessage("");
      setIsLoading(false);
    };
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: { payment_method_data: { billing_details: { address: { postal_code: "never" } } } },
        redirect: "if_required", // Disable redirect
      });

      if (result.error) {
        // Set the error message which will be displayed under the save button
        setMessage(result.error.message);
      } else {
        // Since there is no error, that means the payment method was successfully
        // saved so we want to fetch the stripe customer payment methods to show them.
        const paymentMethods = await API.Stripe.retrieveStripeCustomerPaymentMethods(data.stripeCustomerId);

        // Update the customer's stripe default payment method
        API.Customer.updateCustomer(data.id, {
          stripe_default_payment_method_id: result.setupIntent?.payment_method,
        });

        setCustomer((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            stripeDefaultPaymentMethodId: result.setupIntent?.payment_method,
          },
          stripe: {
            ...prevState.stripe,
            paymentMethods: paymentMethods?.data,
            setupIntent: null, // Set setupIntent to null to unmount Stripe's Elements
          },
        }));
      }

      setIsLoading(false);
    },
    [stripe, elements]
  );

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledPaymentElement
        options={{
          fields: {
            billingDetails: {
              address: { postalCode: "never" },
            },
          },
          wallets: { applePay: "never", googlePay: "never" },
        }}
      />

      <StyledSaveButton
        id="submit"
        loading={isLoading}
        onClick={handleSubmit}
        loadingPosition="center"
        disabled={!isIntentIncomplete}
      >
        Sauvegarder la carte
      </StyledSaveButton>

      {/* Show the validation message if there's any */}
      {message && setupIntent.status !== "succeeded" && <StyledMessage>{message}</StyledMessage>}
    </StyledForm>
  );
}

// Stripe loader
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

/**
 * Form that is used to save a payment method - for future usage - to an
 * existing Stripe Customer. Requires a setupIntent secret to be rendered.
 */
export default function StripeSetupForm({ style = {} }) {
  const { customer, handlers } = useCustomerContext();

  const clientSecret = customer.stripe.setupIntent ? customer.stripe.setupIntent.client_secret : "";

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    locale: "fr-CA",
    loader: "always",
  };

  if (!clientSecret) {
    console.warn("[StripeSetupForm] No client secret provided, cannot show the form!");
  }

  return (
    <Stack style={style}>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {/* The stripe form where you fill the credit card details */}
          <StripeForm customer={customer} setCustomer={handlers.setCustomer} />
        </Elements>
      )}
    </Stack>
  );
}
