import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { debounce } from "lodash";
import { withContracts, withCustomerItems } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withRouter } from "@utils/withRouter";
import styled from "@emotion/styled";

// import ModalPreparation from "./ModalPreparation";
import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import Tooltip from "./ui/Tooltip";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import { Trans } from "react-i18next";

const RemoveIconWrapper = styled(RemoveIcon)`
  font-size: 12px !important;
  position: relative;
`;

const data = {
  name: { label: <Trans i18nKey="name_simple" /> },
  planned: { label: <Trans i18nKey="planified" /> },
  prepared: { label: <Trans i18nKey="prepared" /> },
  startDate: { label: <Trans i18nKey="start_date" /> },
  endDate: { label: <Trans i18nKey="end_date" /> },
};

const daysRange = [7, 14, 30, 60, 0];

const statuses = [
  { label: <Trans i18nKey="status.all" />, value: "ALL" },
  { label: <Trans i18nKey="status.wo_items" />, value: "WO_ITEMS" },
  { label: <Trans i18nKey="status.not_prepared" />, value: "NOT_PREPARED" },
  { label: <Trans i18nKey="status.not_planned" />, value: "NOT_PLANNED" },
];

class CustomerItemsList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "identifier",
    direction: "asc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    customerItemToEdit: {},
    customerToDelete: null,
    daysRangeFilter: daysRange[1],
    deleteCustomerItemModalOpened: false,
    // modalPreparationOpened: false,
    statusFilter: statuses[0].value,
    sort: {
      ...this.initialSort,
    },
  };

  state = {
    ...this.initialState,
  };

  componentDidMount() {
    this.fetchCustomerItems();
  }

  componentWillUnmount() {
    this.props.flushCustomerItems();
  }

  debouncedFetchCustomerItems = debounce(() => {
    this.fetchCustomerItems();
  }, 300);

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchCustomerItems
    );
  };

  fetchCustomerItems = () => {
    const { fetchCustomerItemsByContract, router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    fetchCustomerItemsByContract(contractId, customerId);
  };

  handleDeleteCustomerItem = async () => {
    const { deleteCustomerItem } = this.props;

    await deleteCustomerItem(this.state.customerItemToDelete);

    const { deleteCustomerItemModalOpened, customerItemToDelete } = this.initialState;

    this.setState(
      {
        deleteCustomerItemModalOpened,
        customerItemToDelete,
      },
      this.fetchCustomerItems
    );
  };

  handleNavigateToJobs = (path) => () => {
    this.props.router.navigate(path);
  };

  // handleToggleModalPreparation = (opened, customerItem) => () => {
  //   const customerItemToEdit = opened ? customerItem : this.initialState.customerItemToEdit;
  //
  //   this.setState({
  //     modalPreparationOpened: opened,
  //     customerItemToEdit,
  //   });
  // };

  handleSelectFilterChange =
    (name) =>
      ({ target }) => {
        this.setState(
          {
            [name]: target.value,
          },
          this.fetchCustomerItems
        );
      };

  handleFilter = handleFilter(this.debouncedFetchCustomerItems).bind(this);
  handlePageChange = handlePageChange(this.fetchCustomerItems).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchCustomerItems).bind(this);
  handleSort = handleSort.bind(this);

  handleToggleDeleteCustomerItemModal = (opened, customerItemId) => () => {
    const customerItemToDelete = opened ? customerItemId : this.initialState.customerItemToDelete;

    this.setState({ deleteCustomerItemModalOpened: opened, customerItemToDelete });
  };

  renderCheckbox = (checked) =>
    checked ? (
      <CheckCircleIcon color="success" fontSize="small" />
    ) : (
      <RemoveIconWrapper fontSize="small" />
    );

  renderDeleteIcon = (id, planned) => {
    const label = planned ? "warning_customer_item_delete" : "delete";

    return (
      <Tooltip title={<Trans i18nKey={label} />}>
        <span>
          <IconButton
            color="secondary"
            id="cpbr-delete-customer-item"
            disabled={planned}
            onClick={this.handleToggleDeleteCustomerItemModal(true, id)}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  // renderPreparationIcon = (customerItem) => (
  //   <Tooltip title={<Trans i18nKey="prepare" />}>
  //     <IconButton
  //       color="secondary"
  //       id="cpbr-prepare"
  //       onClick={this.handleToggleModalPreparation(true, customerItem)}
  //       size="large"
  //     >
  //       <BuildIcon fontSize="small" />
  //     </IconButton>
  //   </Tooltip>
  // );

  renderJobsIcon = (path, prepared) => (
    <Tooltip title={<Trans i18nKey="tasks" />}>
      <span>
        <IconButton
          color="secondary"
          // disabled={!prepared}
          onClick={this.handleNavigateToJobs(path)}
          size="large"
        >
          <AssignmentIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["name", "startDate", "endDate", "planned"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={columnName === name}
                direction={direction}
                onClick={this.handleSort(name)}
              >
                {data[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  renderTableRows = () => {
    const { customerItems, customerItemsLoading, router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    if (customerItemsLoading) {
      return <TableLoading />;
    }

    return sortedData(customerItems, this.state).map((customerItem) => {
      const { id, name, planned, prepared, startDate, expirationDate } = customerItem;

      const path = `/customers/${customerId}/contracts/${contractId}/preparations/${id}`;

      return (
        <TableRow key={id}>
          <TableCell sx={{ 'color': expirationDate ? 'red' : 'default' }}>{expirationDate ? name + " (expiré)" : name}</TableCell>

          <TableCell classes={{ root: "date-cell" }}>{startDate}</TableCell>

          <TableCell classes={{ root: "date-cell" }}>{expirationDate}</TableCell>

          <TableCell classes={{ root: "icon-cell" }}>{this.renderCheckbox(planned)}</TableCell>

          <TableCell classes={{ root: "action-cell no-pointer" }}>
            {this.renderJobsIcon(path, prepared)}

            {this.renderDeleteIcon(id, planned)}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const {
      customerItemToEdit,
      deleteCustomerItemModalOpened,
      // modalPreparationOpened,
      limit,
      page,
    } = this.state;

    return (
      <PageContainer>
        <PaperWrapper>
          <TableOverflowWrapper>
            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={this.props.customerItemsCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />

          {/*<ModalPreparation*/}
          {/*  key={customerItemToEdit.id}*/}
          {/*  customerItem={customerItemToEdit}*/}
          {/*  onClose={this.handleToggleModalPreparation(false)}*/}
          {/*  open={modalPreparationOpened}*/}
          {/*  refresh={this.fetchAndResetPagination}*/}
          {/*/>*/}

          <ModalWarning
            onCancel={this.handleToggleDeleteCustomerItemModal(false)}
            onSubmit={this.handleDeleteCustomerItem}
            open={deleteCustomerItemModalOpened}
            title={<Trans i18nKey="warning" />}
          >
            <Trans i18nKey="warning_delete_contract_item" />
          </ModalWarning>
        </PaperWrapper>
      </PageContainer>
    );
  }
}

CustomerItemsList.defaultProps = {
  customerItemsLoading: true,
};

CustomerItemsList.propTypes = {
  customerItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerItemsCount: PropTypes.number.isRequired,
  customerItemsLoading: PropTypes.bool,
  fetchCustomerItemsByContract: PropTypes.func.isRequired,
  fetchCustomerItems: PropTypes.func.isRequired,
  flushCustomerItems: PropTypes.func.isRequired,
};

export default withRouter(withContracts(withCustomerItems(CustomerItemsList)));
