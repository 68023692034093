import styled from "@emotion/styled";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

export const StyledSelect = styled(Select<string>)`
  width: inherit;
  border-radius: 4px;
  z-index: 2;

  :disabled {
    transition: opacity 0.5s;
    color: #0000001f;
  }
`;

export const StyledSelectLabel = styled(InputLabel)``;
