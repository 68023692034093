import React, { ReactElement } from "react";
import { MenuItem } from "@mui/material";
import Dropdown from "@ui/dropdown/Dropdown";

export type ModalAddMasterRouteTemplateTypeProps = {
  type: string;
};

const ModalAddMasterRouteTemplateType = ({ type }: ModalAddMasterRouteTemplateTypeProps): ReactElement => {
  return (
    <Dropdown
      disabled
      label="Type"
      value={type}
      name="type"
      width="100px"
    >
      <MenuItem value={"CCAV"}>CCAV</MenuItem>
    </Dropdown>
  );
};

export default ModalAddMasterRouteTemplateType;
