import { MutationResult } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { Recurrence } from "@@types/recurrences/recurrence.type";

const updateRecurrence = async (payload: Recurrence): Promise<MutationResult<void>> => {
  const res = await http.put<MutationResult<void>>(`/recurrences/${payload.id}`, payload);
  return res.data;
};

export default updateRecurrence;
