import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const CubeIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill={fillColor}
      >
        <g clipPath="url(#clip0_280_6470)">
          <path
            d="M6 1L11 4.25V7.75L6 11L1 7.75V4.25L6 1Z"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 11V7.75"
            stroke="black"
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 4.25L6 7.75L1 4.25"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 7.75L6 4.25L11 7.75"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 1V4.25"
            stroke="black"
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_280_6470">
            <rect
              width="12"
              height="12"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};

export default CubeIcon;
