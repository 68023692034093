import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
} from '@mui/material';
import { withLocations } from 'optigo-redux';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Trans } from "react-i18next";
import DialogWrapper from './ui/DialogWrapper';
import FlexRowWrapper from './ui/FlexRowWrapper';
import FormGroupWrapper from './ui/FormGroupWrapper';
import HalfFormControl from './ui/HalfFormControl';
import TextFieldUi from './ui/TextField';
import { getErrorMessage, handleChangeFields } from '@utils/form';
import SelectHalfUi from './ui/SelectHalf';

const provinces = [
  { name: <Trans i18nKey="provinces.ab" />, code: 'AB' },
  { name: <Trans i18nKey="provinces.bc" />, code: 'BC' },
  { name: <Trans i18nKey="provinces.pe" />, code: 'PE' },
  { name: <Trans i18nKey="provinces.mb" />, code: 'MB' },
  { name: <Trans i18nKey="provinces.nb" />, code: 'NB' },
  { name: <Trans i18nKey="provinces.ns" />, code: 'NS' },
  { name: <Trans i18nKey="provinces.on" />, code: 'ON' },
  { name: <Trans i18nKey="provinces.qc" />, code: 'QC' },
  { name: <Trans i18nKey="provinces.sk" />, code: 'SK' },
  { name: <Trans i18nKey="provinces.nl" />, code: 'NL' },
  { name: <Trans i18nKey="provinces.nu" />, code: 'NU' },
  { name: <Trans i18nKey="provinces.nt" />, code: 'NT' },
  { name: <Trans i18nKey="provinces.yt" />, code: 'YT' },
];

class ModalEditLocationContractAddress extends PureComponent {
  getErrorMessage = getErrorMessage.bind(this);
  handleChangeFields = handleChangeFields.bind(this);

  constructor(props) {
    super(props);

    const { customerLocation } = this.props;
    const {
      companyName, id, doorNo, city, province, postalCode, adr1, adr2, contractId, name,
    } =
      customerLocation;

    this.state = {
      id,
      adr1,
      adr2,
      companyName,
      doorNo,
      city,
      province,
      postalCode,
      contractId,
      name,
      errors: {
        adr1: false,
        name: false,
        province: false,
        postalCode: false,
        doorNo: false,
        city: false,
      },
    };
  }

  get valid() {
    const {
      errors, postalCode, city, province, doorNo,
    } = this.state;
    let valid = true;

    if (city.replace(/\s/g, "") === "" || city.replace(/\s/g, "") === "-") {
      valid = false;
      errors.city = true;
      this.setState({ errorMessage: true });
    } else {
      this.setState({ errorMessage: false });
    }

    if (province === "-1" || province.replace(/\s/g, "") === "-") {
      valid = false;
      errors.province = true;
      this.setState({ errorMessage: true });
    } else {
      this.setState({ errorMessage: false });
    }

    const checkFields = ["adr1", "doorNo"];

    for (const name of checkFields) {

      if (this.state[name].toString().trim() === "" || this.state[name] === null) {
        valid = false;
        errors[name] = true;
        this.setState({ errorMessage: true });
      } else {
        this.setState({ errorMessage: false });
      }
    }

    const canadianPostalCode = new RegExp(/[abceghjklmnprstvxy][0-9][abceghjklmnprstvwxyz]\s?[0-9][abceghjklmnprstvwxyz][0-9]/i);

    if (!canadianPostalCode.test(postalCode)) {
      valid = false;
      errors.postalCode = true;
      this.setState({ errorMessage: true });
    } else {
      this.setState({ errorMessage: false });
    }

    if (doorNo.length > 10) {
      errors.doorNo = true;
      valid = false;
    }

    this.setState({ errors });
    return valid;
  }

  updateLocation = async () => {
    if (this.valid) {
      const {
        id, adr1, adr2, doorNo, city, province, postalCode, contractId, name,
      } = this.state;

      const { refreshData, onClose, editLocation } = this.props;

      const fullAddress = doorNo + " " + adr1 + ", " + city + ", " + province + ", " + postalCode;

      await editLocation(id, {
        adr_1: adr1,
        adr_2: adr2,
        door_no: doorNo,
        postal_code: postalCode,
        city,
        province,
        contract_id: contractId,
        name: name || fullAddress,
      });
      refreshData();
      onClose();
    }
  };

  renderMenuItems = (label, data) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ name, code }) => (
      <MenuItem key={code} value={code}>
        {name}
      </MenuItem>
    )),
  ];

  renderCustomerName = () => {
    const { companyName } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              // error={errors.companyName}
              // helperText={this.getErrorMessage('companyName')}
              disabled
              label={<Trans i18nKey="customers" />}
              value={companyName || ''}
              onChange={this.handleChangeFields('companyName')}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderLocationName = () => {
    const { name } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 100 }}
              label={<Trans i18nKey="address.name" />}
              value={name || ''}
              onChange={this.handleChangeFields('name')}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderCustomerAddress = () => {
    const { adr1, adr2, errors } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 50 }}
              error={errors.adr1}
              helperText={this.getErrorMessage('adr1')}
              label={<Trans i18nKey="address.adr_1" />}
              value={adr1 || ''}
              onChange={this.handleChangeFields('adr1')}
            />
          </FormControl>
        </FormGroupWrapper>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 50 }}
              label={<Trans i18nKey="address.adr_2" />}
              value={adr2 || ''}
              onChange={this.handleChangeFields('adr2')}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderCustomerDoorNoAndPostalCode = () => {
    const { doorNo, postalCode, errors } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 10 }}
                error={errors.doorNo}
                helperText={this.getErrorMessage('doorNo')}
                label={<Trans i18nKey="address.door_no" />}
                value={doorNo?.trim() || ''}
                onChange={this.handleChangeFields('doorNo')}
              />
            </HalfFormControl>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 7 }}
                error={errors.postalCode}
                helperText={<Trans i18nKey="validate_postal_code" />}
                label={<Trans i18nKey="address.postal_code" />}
                value={postalCode.trim() || ''}
                onChange={this.handleChangeFields('postalCode')}
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderCityAndProvince = () => {
    const { city, province, errors } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextFieldUi
                inputProps={{ maxLength: 45 }}
                error={errors.city}
                helperText={this.getErrorMessage('city')}
                label={<Trans i18nKey="address.city" />}
                value={city || ''}
                onChange={this.handleChangeFields('city')}
              />
            </HalfFormControl>
            <SelectHalfUi
              formControlError={errors.province}
              formHelperErrorMsg={this.getErrorMessage('province')}
              inputLabelText={<Trans i18nKey="address.province" />}
              onChange={this.handleChangeFields('province')}
              value={`${province || ''}`}
            >
              {this.renderMenuItems(<Trans i18nKey="select_province" />, provinces)}
            </SelectHalfUi>
          </FlexRowWrapper>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  render() {
    const { open, customerLocation } = this.props;
    return (
      <DialogWrapper id="quick-job-modal" open={open}>
        <DialogTitle>
          <span>
            {customerLocation.addressOrGeoposition === 'address' ? (
              <Trans i18nKey="edit_address" />
            ) : (
              <Trans i18nKey="edit_geolocation" />
            )}
          </span>
          <span style={{ display: "inline-block", float: "right", textAlign: "right" }} />
        </DialogTitle>

        <DialogContent>
          {this.renderCustomerName()}
          {this.renderLocationName()}
          {this.renderCustomerDoorNoAndPostalCode()}
          {this.renderCustomerAddress()}
          {this.renderCityAndProvince()}
          <form />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.props.onClose()}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button variant="contained" onClick={() => this.updateLocation()}>
            <Trans i18nKey="edit" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalEditLocationContractAddress.propTypes = {
  locationId: PropTypes.number,
  refreshData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

// eslint-disable-next-line max-len
export default withLocations(ModalEditLocationContractAddress);
