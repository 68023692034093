// React imports
import React, { FC } from "react";
// Internal imports
import Location from "@@types/locations/location.type";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import TableLoading from "@ui/TableLoading";
// External imports
import { Button, Stack, Table } from "@mui/material";
import LocationCcavContainersListHeader from "@components/location/route/containers/LocationCcavContainersListHeader";
import LocationCcavContainersListBody from "@components/location/route/containers/LocationCcavContainersListBody";
import LocationCcavDeleteContainerModal from "@components/location/route/containers/modals/LocationCcavDeleteContainerModal";
import useLocationCcavContainers from "@components/location/route/containers/hooks/location-ccav-containers-list.hook";
import ContainerLiftingRequestsModal from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsModal";
import LocationCcavContainerModal from "@components/location/route/containers/modals/LocationCcavContainerModal";

type LocationCcavContainersListProps = {
  customerLocation: Location;
  selectedLocation: number;
};

const LocationCcavContainersList: FC<LocationCcavContainersListProps> = ({ selectedLocation, customerLocation }) => {
  const { data, handlers } = useLocationCcavContainers(selectedLocation);
  return (
    <>
      <TableOverflowWrapper>
        {/* ADD CONTAINER BUTTON */}
        {/* ONLY APPEAR FOR PORTNEUF LOCATION */}
        {!customerLocation.isFromCiWeb && (
          <Stack
            p={2}
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handlers.handleOpenModal("create", data.selectedContainer)}
            >
              AJOUTER UN CONTENEUR
            </Button>
          </Stack>
        )}
        <Table>
          {/* TABLE HEADER */}
          <LocationCcavContainersListHeader />

          {/* TABLE BODY */}
          {data.locationContainersIsLoading && <TableLoading />}
          {data.locationContainers?.length ? (
            <LocationCcavContainersListBody
              containers={data.locationContainers}
              isFromCiWeb={customerLocation.isFromCiWeb}
              handleOpenModal={handlers.handleOpenModal}
            />
          ): "** Aucune donnée disponible **"}
        </Table>
        {/* MODAL LIFTING REQUESTS */}
        {data.openModalContainerLiftingRequests && (
          <ContainerLiftingRequestsModal
            openModalContainerLiftingRequests={data.openModalContainerLiftingRequests}
            selectedContainer={data.selectedContainer}
            onClose={handlers.handleCloseModal}
          />
        )}

        {/* MODAL CREATE CONTAINER */}
        {data.openModalCreateContainer && (
          <LocationCcavContainerModal
            modalType={"create"}
            openModalContainer={data.openModalCreateContainer}
            selectedLocation={selectedLocation}
            selectedContainer={data.selectedContainer}
            onClose={handlers.handleCloseModal}
            isFromCiWeb={customerLocation.isFromCiWeb}
            setSelectedContainer={handlers.setSelectedContainer}
          />
        )}

        {/* MODAL EDIT CONTAINER */}
        {data.selectedContainer && (
          <LocationCcavContainerModal
            modalType={"edit"}
            isFromCiWeb={customerLocation.isFromCiWeb}
            openModalContainer={data.openModalEditContainer}
            selectedContainer={data.selectedContainer}
            selectedLocation={selectedLocation}
            setSelectedContainer={handlers.setSelectedContainer}
            onClose={handlers.handleCloseModal}
          />
        )}

        {/* MODAL DELETE CONTAINER */}
        {data.openModalDeleteContainer && data.selectedContainer && (
          <LocationCcavDeleteContainerModal
            openModalDeleteContainer={data.openModalDeleteContainer}
            selectedLocation={selectedLocation}
            selectedContainer={data.selectedContainer}
            onClose={handlers.handleCloseModal}
          />
        )}
      </TableOverflowWrapper>
    </>
  );
};

export default LocationCcavContainersList;
