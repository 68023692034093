import React, { FC } from "react";
import { Trans } from "react-i18next";

import LocationDetail, {
  DayModality,
} from "@@types/location-details/location-detail.type";
import useLocationModalityDeleteModal from "@components/location/route/modality/modals/delete/hooks/location-modality-delete-modal.hook";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogWrapper from "@ui/DialogWrapper";

type LocationModalityDeleteModalProps = {
  openModal: boolean;
  modality: LocationDetail;
  weekDays: DayModality[];
  modalType: string;
  onCancel: (modalType: string) => void;
  onMutate: (modality: LocationDetail) => void;
};

const LocationModalityDeleteModal: FC<LocationModalityDeleteModalProps> = ({
  openModal,
  modality,
  weekDays,
  modalType,
  onCancel,
  onMutate,
}) => {
  const { collectDaysFormatted } = useLocationModalityDeleteModal(weekDays);
  return (
    <DialogWrapper
      width={40}
      open={openModal}
    >
      <DialogTitle>
        <Trans i18nKey="delete" />
      </DialogTitle>

      <DialogContent>
        Êtes-vous certain de vouloir supprimer la modalitée ?
        <br />
        Matière: {modality.matter.label || "INCONNUE"}
        <br />
        Fréquence: {modality.frequency.label || "INCONNUE"}
        <br />
        Jours de collecte: {collectDaysFormatted || "INCONNUE"}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onCancel(modalType)}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          variant="contained"
          onClick={() => onMutate(modality)}
        >
          <Trans i18nKey="delete" />
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default LocationModalityDeleteModal;
