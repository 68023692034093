import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import styled from "@emotion/styled";
import ModalAnomaliesPhotos from "@components/routeManagement/modals-anomalies/ModalAnomaliesPhotos";
import { formattedDateDayMonthYear } from "@utils/dates";

const PhotoWrapper = styled.div`
  background-color: #f3f3f3;
`;

const Photo = styled.img`
  display: inherit;
  width: 100%;
  height: auto;
  max-width: 640px;
  margin: 0 auto;
`;

const RouteDetailsDialog = ({ onDialogClose, dialogOpen, anomalyItems, ...remainingProps }) => {
  if (anomalyItems === "" || anomalyItems === undefined || anomalyItems.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...remainingProps}
      scroll="body"
      maxWidth="lg"
    >
      {anomalyItems.map((anomaly, index) => (
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Détails du cas problème {index + 1}</DialogTitle>
          <DialogContentText id="alert-dialog-description">
            Date du cas problème :{" "}
            {formattedDateDayMonthYear(anomaly.date)}
            <br />
            Numéro de puce : {anomaly.containerChipNumber || "INCONNU"}
            <br />
            Numéro de contenant : {anomaly.containerNumber || "INCONNU"}
            <br />
            Code : {anomaly.code}
            <br />
            Catégorie: {anomaly.categoryName}
            <br />
            Détails de la catégorie : {anomaly.categoryDetailName ?? "Aucun"}
            <br />
            {anomaly.customMessage && <div>Message personnalisé : {anomaly.customMessage}</div>}
          </DialogContentText>
          <br />
          <ModalAnomaliesPhotos photos={anomaly.photos} />
          <br />
        </DialogContent>
      ))}
      <DialogActions>
        <Button onClick={onDialogClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

RouteDetailsDialog.propTypes = {
  dialogContentText: PropTypes.string,
  routeData: PropTypes.object,
  onDialogClose: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
};

export default RouteDetailsDialog;
