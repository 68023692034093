import { IconButton, InputLabel, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { memo } from "react";
import styled from "@emotion/styled";
import { isArrayTruthy } from "@utils/common";

const CollapsibleRow = styled(TableRow)`
  height: 0 !important;
  // background-color: #F8F8F8 !important;

  td {
    display: none !important;
    height: 0 !important;
    overflow: hidden;
    min-height: 0px;
  }

  &.expended-true {
    height: initial;

    td {
      display: table-cell !important;
    }
  }
`;

const TableRowEndDates = ({ rows, collapseRow }) => {
  return rows.map((row) => {
    return (
      <>
        <CollapsibleRow className={`collapsible-row expended-${collapseRow} link-row`}>
          <TableCell />
          <TableCell>{row?.date}</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell align="right" sx={{ paddingRight: 6.25 }}>
            {row.count}
          </TableCell>
        </CollapsibleRow>
      </>
    );
  });
};

const TableFinancialSummariesCcav = ({ summary, collapseRow, handleCollapse }) => {
  const rowName = `${summary.customer_item_name} - ${summary.contract_name} - ${summary.type}`;
  return (
    <>
      <TableRow
        key={rowName}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={() => handleCollapse(rowName)}
      >
        <TableCell>
          <IconButton size="large" color="secondary">
            {collapseRow[rowName] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {summary.contract_name}
        </TableCell>
        <TableCell component="th" scope="row">
          <span style={{ fontWeight: "bold" }}>{summary.type}</span> - {summary.customer_item_name}
        </TableCell>
        <TableCell component="th" scope="row">
          -
        </TableCell>
        <TableCell component="th" scope="row">
          -
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 6.25 }}>
          {summary.count}
        </TableCell>
      </TableRow>
      {isArrayTruthy(summary.dates) && collapseRow[rowName] && (
        <TableRowEndDates rows={summary.dates} collapseRow={collapseRow[rowName]} />
      )}
    </>
  );
};

const TableFinancialSummariesRo = ({ summary, collapseRow, handleCollapse }) => {
  const rowName = `${summary.customer_item_name} - ${summary.contract_name} - ${summary.flow} - ${summary.drop_point} - ${summary.status}`;
  return (
    <>
      <TableRow
        key={rowName}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={() => handleCollapse(rowName)}
      >
        <TableCell>
          <IconButton size="large" color="secondary">
            {collapseRow[rowName] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {summary.contract_name}
        </TableCell>
        <TableCell component="th" scope="row">
          <span style={{ fontWeight: "bold" }}>{summary?.status}</span> - {summary.customer_item_name}
        </TableCell>
        <TableCell component="th" scope="row">
          {summary.flow}
        </TableCell>
        <TableCell component="th" scope="row">
          {summary.drop_point}
        </TableCell>
        <TableCell align="right" sx={{ paddingRight: 6.25 }}>
          {summary.count}
        </TableCell>
      </TableRow>
      {isArrayTruthy(summary.dates) && collapseRow[rowName] && (
        <TableRowEndDates rows={summary.dates} collapseRow={collapseRow[rowName]} />
      )}
    </>
  );
};

export default function ReportTableFinancialSummaries({ summaries, collapseRow, handleCollapse }) {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Contrat</TableCell>
          <TableCell>item de contrat</TableCell>
          <TableCell>Flow</TableCell>
          <TableCell>Point de chute</TableCell>
          <TableCell align="right" sx={{ paddingRight: 3.125 }}>
            Nombres
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {summaries.map((summary) => (
          <>
            {summary.type === "RO" ? (
              <TableFinancialSummariesRo summary={summary} collapseRow={collapseRow} handleCollapse={handleCollapse} />
            ) : (
              <TableFinancialSummariesCcav
                summary={summary}
                collapseRow={collapseRow}
                handleCollapse={handleCollapse}
              />
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
}
