import { useQuery, UseQueryResult } from "@tanstack/react-query";

import ContainerProfile from "@hooks/container-profiles/container-profile.keys";
import fetchAvailableCcavContainerModels from "../../services/container-profiles/fetch-available-ccav-container-models.service";
import ContainerProfileType from "@@types/container-profile/container-profile.type";
import mapResponseToContainerProfiles from "@@types/container-profile/mappers/mapResponseToContainerProfile.mapper";

const useFetchAvailableCcavContainerModels = (locationId: number): UseQueryResult<ContainerProfileType[]> => {
  return useQuery({
    queryKey: ContainerProfile.lists(),
    queryFn: () => fetchAvailableCcavContainerModels(locationId),
    select: ({ data: response }): ContainerProfileType[] => mapResponseToContainerProfiles(response || []),
    enabled: locationId.toString() !== "-1",
  });
};

export default useFetchAvailableCcavContainerModels;
