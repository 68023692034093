import React, { Dispatch, FC, SetStateAction } from "react";
import { FormGroup, Stack, Typography } from "@mui/material";
import {
  FlexWrapper,
  MultiSelectBox,
  SelectBoxWrapper,
} from "@components/location/route/modality/modals/LocationModalityModal.style";
import useLocationModalityDays from "@components/location/route/modality/modals/hooks/location-modality-modal-days.hook";
import LocationDetail, { DayModality } from "@@types/location-details/location-detail.type";

type LocationModalityModalDaysProps = {
  weekDays: DayModality[];
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>;
};

export type WeekDayTranslated = {
  label: string;
  weekDay: string;
};

const translatedWeekDays: WeekDayTranslated[] = [
  { label: "LU", weekDay: "monday" },
  { label: "MA", weekDay: "tuesday" },
  { label: "ME", weekDay: "wednesday" },
  { label: "JE", weekDay: "thursday" },
  { label: "VE", weekDay: "friday" },
  { label: "SA", weekDay: "saturday" },
  { label: "DI", weekDay: "sunday" },
];

const LocationModalityModalDays: FC<LocationModalityModalDaysProps> = ({ weekDays, setModality }) => {
  const { handleSelectModalityDays } = useLocationModalityDays(setModality);

  return (
    <SelectBoxWrapper>
      <FormGroup>
        <FlexWrapper>
          <Stack
            pt={1}
            pr={2}
            direction={"column"}
            spacing={3}
          >
            <span>Jour(s): </span>
          </Stack>
          <Stack
            direction="row"
            alignItems="left"
            spacing={0.25}
          >
            {weekDays.map((weekDay) => {
              const day = translatedWeekDays.find((translatedWeekDay) => translatedWeekDay.weekDay === weekDay.label);
              return (
                <MultiSelectBox
                  className={weekDay.active ? "active" : ""}
                  key={weekDay.label}
                  onClick={() => handleSelectModalityDays(weekDay)}
                >
                  <Typography>{day?.label}</Typography>
                </MultiSelectBox>
              );
            })}
          </Stack>
        </FlexWrapper>
      </FormGroup>
    </SelectBoxWrapper>
  );
};

export default LocationModalityModalDays;
