import React, { ReactElement } from "react";
import { ModalButtons } from "@ui/modal/Modal.styles";

export interface ButtonsProps {
  children: ReactElement | ReactElement[];
}

const Buttons = ({ children }: ButtonsProps): ReactElement => <ModalButtons>{children}</ModalButtons>;

export default Buttons;
