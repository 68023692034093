import styled from "@emotion/styled";

const TableOverflowWrapper = styled.div`
  overflow-x: scroll;

  ${({ theme }) => `
    ${theme.breakpoint.lg} {
      overflow-x: hidden;
    }
  `}
`;

export default TableOverflowWrapper;
