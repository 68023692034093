import { Fab as FabMui } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import Tooltip from "./Tooltip";

const Wrapper = styled.span`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1200;
`;

const FixedActionButton = ({ children, tooltipLabel, ...remainingProps }) => (
  <Tooltip title={tooltipLabel}>
    <Wrapper>
      <FabMui {...remainingProps}>{children}</FabMui>
    </Wrapper>
  </Tooltip>
);

FixedActionButton.defaultProps = {
  children: "",
  tooltipLabel: "",
};

FixedActionButton.propTypes = {
  children: PropTypes.node,
  tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FixedActionButton;
