// React imports
import React, { useState, useCallback, useMemo, memo } from "react";

// Internal imports
import * as API from "@services";
import {
  Collapse,
  ListItem,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  Box,
  Switch,
  InputAdornment,
} from "@mui/material";

// External imports
import { Edit, Save, Cancel } from "@mui/icons-material";
import { includes, map, isEqual } from "lodash";

const valid = (accountingItemChildren, setAccountingItems, accountingItemParentIndex, accountingItemChildrenIndex) => {
  const errors = map(accountingItemChildren.data, (value, key) => {
    if (value === "") {
      setAccountingItems((prevState) => {
        const updatedAccountingItems = [...prevState];

        updatedAccountingItems[accountingItemParentIndex].children[accountingItemChildrenIndex] = {
          data: { ...updatedAccountingItems[accountingItemParentIndex].children[accountingItemChildrenIndex].data },
          errors: {
            ...updatedAccountingItems[accountingItemParentIndex].children[accountingItemChildrenIndex].errors,
            [key]: true,
          },
        };
        return updatedAccountingItems;
      });
    }
    return value === "";
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const handleSaveEditAccountingItemChildren = async (
  accountingItemChildren,
  data,
  handlers,
  accountingItemParentIndex,
  index
) => {
  const isValid = valid(accountingItemChildren, handlers.setAccountingItems, accountingItemParentIndex, index);

  if (isValid) {
    await API.AccountingItem.updateContractAccountingItem(accountingItemChildren.data, accountingItemChildren.data.id);

    const accountingItemsArray = [...data.accountingItems];

    handlers.setAccountingItems(() => {
      accountingItemsArray[accountingItemParentIndex].children[index] = {
        data: { ...accountingItemsArray[accountingItemParentIndex].children[index].data },
        errors: {
          ...accountingItemsArray[accountingItemParentIndex].children[index].errors,
          name: false,
          limit: false,
          sku: false,
        },
      };
      return accountingItemsArray;
    });
    data.prevRef.current = accountingItemsArray;
    handlers.handleEditAccountingItemChildren(accountingItemChildren.data.id);
  }
};

const AccountingItemChildrenItem = ({ accountingItemChildren, data, handlers }) => {
  const isEditing = data.editAccountingItemChildren[accountingItemChildren.data.id];
  const index = data.accountingItem.children.indexOf(accountingItemChildren);
  const accountingItemParentIndex = data.accountingItems.indexOf(data.accountingItem);

  return (
    <Collapse
      in={handlers.collapseRow}
      timeout="auto"
      unmountOnExit
    >
      <ListItem
        component="div"
        key={accountingItemChildren.data.id}
        divider={index !== data.accountingItem.children.length - 1}
        sx={{ flexDirection: "column", paddingRight: 50 }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={2}
        >
          <FormControl
            variant="standard"
            disabled={!isEditing}
            sx={{ minWidth: "79%" }}
          >
            <InputLabel>Nom</InputLabel>
            <Input
              name="name"
              value={accountingItemChildren.data.name}
              onChange={handlers.handleEditAccountingItemChild(index, accountingItemParentIndex)}
              error={accountingItemChildren.errors.name}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
            />
          </FormControl>

          <FormControl
            variant="standard"
            disabled={!isEditing}
            sx={{ minWidth: "13%", flexDirection: "row" }}
          >
            <InputLabel shrink>Limite</InputLabel>
            <Stack
              pt={2}
              justifyContent="center"
              direction={"row"}
              alignItems={"center"}
            >
              <Input
                id={accountingItemChildren.data.id.toString()}
                name="limit"
                value={accountingItemChildren.data.has_limit ? accountingItemChildren.data.limit : "N/A"}
                onChange={handlers.handleEditAccountingItemChild(index, accountingItemParentIndex)}
                error={accountingItemChildren.errors.limit}
                disabled={!accountingItemChildren.data.has_limit}
                disableUnderline={!isEditing}
                sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
              />

              <Switch
                id={accountingItemChildren.data.id.toString()}
                name="has_limit"
                checked={accountingItemChildren.data.has_limit}
                onChange={handlers.handleEditAccountingItemChild(index, accountingItemParentIndex)}
                size={"small"}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          </FormControl>

          <FormControl
            variant="standard"
            disabled={!isEditing}
            sx={{ minWidth: "20.75%" }}
          >
            <InputLabel htmlFor="component-simple">Sku</InputLabel>
            <Input
              name="sku"
              value={accountingItemChildren.data.sku}
              onChange={handlers.handleEditAccountingItemChild(index, accountingItemParentIndex)}
              error={accountingItemChildren.errors.sku}
              disableUnderline={!isEditing}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
            />
          </FormControl>

          <FormControl
            variant="standard"
            disabled={!isEditing}
            sx={{ minWidth: "14%" }}
          >
            <InputLabel htmlFor="component-simple">Prix unitaire</InputLabel>
            <Input
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              name="unit_price"
              value={accountingItemChildren.data.unit_price}
              onChange={handlers.handleEditAccountingItemChild(index, accountingItemParentIndex)}
              error={accountingItemChildren.errors.unit_price}
              disableUnderline={!isEditing}
              style={{ textAlign: "center" }}
              sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
            />
          </FormControl>

          <Box
            width={40}
            height="100%"
          >
            <Stack
              direction="row"
              height={40}
            >
              {isEditing && (
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleSaveEditAccountingItemChildren(
                      accountingItemChildren,
                      data,
                      handlers,
                      accountingItemParentIndex,
                      index
                    )
                  }
                >
                  <Save />
                </IconButton>
              )}

              {!isEditing && (
                <IconButton
                  color="secondary"
                  onClick={() => handlers.handleEditAccountingItemChildren(accountingItemChildren.data.id)}
                >
                  <Edit />
                </IconButton>
              )}

              {isEditing && (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    handlers.handleButtonCancelAccountingItemChild(accountingItemChildren);
                    handlers.handleEditAccountingItemChildren(accountingItemChildren.data.id);
                  }}
                >
                  <Cancel />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Stack>
      </ListItem>
    </Collapse>
  );
};

function AccountingItemChild({
  collapseRow,
  customerItemId,
  accountingItem,
  accountingItems,
  setAccountingItems,
  prevRef,
}) {
  const [isEditAccountingItemChildrenActive, setIsEditAccountingItemChildrenActive] = useState(false);
  const [editAccountingItemChildren, setEditAccountingItemChildren] = useState({});

  const handleEditAccountingItemChildren = (id) => {
    const copyEditAccountingItemChildren = editAccountingItemChildren;
    const actualValue = copyEditAccountingItemChildren[id];
    copyEditAccountingItemChildren[id] = !actualValue;
    setIsEditAccountingItemChildrenActive(!isEditAccountingItemChildrenActive);
    setEditAccountingItemChildren(copyEditAccountingItemChildren);
  };

  const handleEditAccountingItemChild = (index, accountingItemParentIndex) => (event) => {
    const { name, value, checked } = event.target;
    setSingleAccountingItemChild(name, value, checked, accountingItems, index, accountingItemParentIndex);
  };

  const handleButtonCancelAccountingItemChild = (accountingItemChildren) => {
    const accountingItemParentIndex = accountingItems.indexOf(accountingItem);
    const accountingItemChildrenIndex = accountingItem.children.indexOf(accountingItemChildren);

    setAccountingItems((prevState) => {
      const updatedAccountingItems = [...prevState];
      updatedAccountingItems[accountingItemParentIndex].children[accountingItemChildrenIndex] = {
        data: { ...prevRef.current[accountingItemParentIndex].children[accountingItemChildrenIndex].data },
        errors: { ...prevRef.current[accountingItemParentIndex].children[accountingItemChildrenIndex].errors },
      };
      return updatedAccountingItems;
    });
  };

  const setSingleAccountingItemChild = useCallback((name, value, checked, parent, childrenIndex, parentIndex) => {
    setAccountingItems((prevState) => {
      const updatedAccountingItems = [...prevState];
      const updatedAccountingItemChild = [...prevState[parentIndex].children];
      if (name === "has_limit") {
        updatedAccountingItemChild[childrenIndex] = {
          data: {
            ...updatedAccountingItemChild[childrenIndex].data,
            [name]: checked,
            limit: checked ? prevRef.current[parentIndex].children[childrenIndex].data.limit ?? 1 : null,
          },
          errors: { ...updatedAccountingItemChild[childrenIndex].errors, [name]: !value },
        };
      } else {
        updatedAccountingItemChild[childrenIndex] = {
          data: { ...updatedAccountingItemChild[childrenIndex].data, [name]: value },
          errors: { ...updatedAccountingItemChild[childrenIndex].errors, [name]: !value },
        };
      }

      updatedAccountingItems[parentIndex] = {
        data: { ...updatedAccountingItems[parentIndex].data },
        errors: { ...updatedAccountingItems[parentIndex].errors },
        children: updatedAccountingItemChild,
      };
      return updatedAccountingItems;
    });
  });

  const data = {
    accountingItemsChildrens: accountingItem.children,
    accountingItem: accountingItem,
    accountingItems: accountingItems,
    editAccountingItemChildren: editAccountingItemChildren,
    prevRef: prevRef,
  };

  const handlers = {
    setAccountingItems: setAccountingItems,
    setSingleAccountingItemChild: setSingleAccountingItemChild,
    collapseRow: collapseRow[customerItemId],
    handleEditAccountingItemChildren: handleEditAccountingItemChildren,
    handleEditAccountingItemChild: handleEditAccountingItemChild,
    handleButtonCancelAccountingItemChild: handleButtonCancelAccountingItemChild,
  };

  return data.accountingItemsChildrens.map((accountingItemChildren) => (
    <AccountingItemChildrenItem
      key={accountingItemChildren.data.id}
      accountingItemChildren={accountingItemChildren}
      data={data}
      handlers={handlers}
    />
  ));
}

const areEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.accountingItem, nextProps.accountingItem)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.accountingItems, nextProps.accountingItems)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.prevRef, nextProps.prevRef)) {
    arePropsEqual = false;
  }

  if (isEqual(prevProps.collapseRow, nextProps.collapseRow)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(AccountingItemChild, areEqual);
