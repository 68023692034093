const colors = {
  default: "#006F9C", // brand color
  palette: {
    primary: {
      main: "#006F9C",
    },
    secondary: {
      main: "#21a8d9",
      contrastText: "#fff",
    },
    background: {
      light: "#fdfdfd",
      medium: "#F8F8F8",
    },
  },
  font: {
    default: "#414141",
    title: "#494949",
    light: "#757575",
  },
  icon: {
    default: "#21a8d9",
    hover: "#2196f3",
    prepared: "#00D76C",
    faded: "#b9b9b9",
  },
  button: {
    default: "#21a8d9",
    hover: "#2196f3",
  },
  basic: {
    white: "#FFF",
    black: "#000",
    lightGrey: "#D2D2D2",
    warning: "#FF4C82",
    error: "#D23C3C",
  },
  components: {
    taskStatus: {
      COMPLETED: "#02c8a7",
      DEFAULT: "#EFEFEF",
      IN_PROGRESS: "#F9BE02",
      INTERRUPTED: "#D23C3C",
      INCOMPLETED: "#a101f9",
      PAUSED: "#e67300",
    },
    badgeFlow: {
      1: "#C2C1A1",
      2: "#A1C2B9",
      3: "#A1A1C2",
      4: "#C89FA0",
      5: "#CBCBCB",
    },
    dashboard: {
      taskColumnBackground: "#f1f1f1",
    },
    breadcrumb: {
      background: "#f3f3f3",
    },
  },
};

const variables = {
  mainFont: "'Roboto', sans-serif",
  // inputBorderColor: '#DDDDDD',
  // inputBorderRadius: '3px',
  // inputPadding: '10px',
  breakpoint: {
    xs: "@media (min-width: 375px)",
    sm: "@media (min-width: 576px)",
    md: "@media (min-width: 768px)",
    lg: "@media (min-width: 992px)",
    xl: "@media (min-width: 1200px)",
    xxl: "@media (min-width: 1440px)",
  },
};

const theme = {
  ...variables,
  colors,
};

export default theme;
