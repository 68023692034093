import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const EditIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.295 0.692412L13.3075 1.70491C13.9 2.28991 13.9 3.24241 13.3075 3.82741L3.385 13.7499H0.25V10.6149L8.05 2.80741L10.1725 0.692412C10.7575 0.107412 11.71 0.107412 12.295 0.692412ZM1.75 12.2499L2.8075 12.2949L10.1725 4.92241L9.115 3.86491L1.75 11.2299V12.2499Z"
          fill={strokeColor}
        />
      </svg>
    </Container>
  );
};
