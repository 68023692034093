import React, { ReactElement } from "react";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

const BoltIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill={fillColor}
      >
        <path
          d="M11 1L1 13H10L9 21L19 9H10L11 1Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default BoltIcon;
