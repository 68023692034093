import axios from "axios";

import { requestOptions } from "../auth";
import { axiosClientV2 as http } from "../axios";
import { formatBackendCollectionData } from "../utils";

const fetchCustomerRouteContracts = async (customerId, filter = "", limit = 10, page = 1) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/customers/${customerId}/customer_route_contracts.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    const routeContracts = formatBackendCollectionData(response.data);

    return routeContracts;
  } catch (error) {
    console.warn("[fetchCustomerRouteContracts] error:", error);
  }
};

const fetchCustomerJobContracts = async (customerId, filter = "", limit = 10, page = 1) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/customers/${customerId}/customer_job_contracts.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    const jobContracts = formatBackendCollectionData(response.data);

    return jobContracts;
  } catch (error) {
    console.warn("[fetchCustomerJobContracts] error:", error);
  }
};

const fetchCustomerContractLocations = async (customerID, contractID) => {
  try {
    const response = await http.get(
      `/customers/${customerID}/contracts/${contractID}/quick_dispatch_locations`,
      requestOptions()
    );

    return response.data?.data;
  } catch (error) {
    console.warn("[fetchCustomerContractLocations] error:", error);
  }
};

const fetchCustomerContractRouteTemplates = async (customerID, contractID, filter = "", limit = -1, page = 1) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/customers/${customerID}/contracts/${contractID}/route_templates.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    const routeTemplates = formatBackendCollectionData(response.data);

    return routeTemplates;
  } catch (error) {
    console.warn("[fetchCustomerContractLocations] error:", error);
  }
};

const createContractWithSalesInformation = async (customerID, params) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customers/${customerID}/contracts/create_contract_with_sales_information`,
      { ...params },
      requestOptions()
    );

    if (res.data.status === "failure") {
      console.warn("[ContractService.createContractWithSalesInformation] status is not successfull:", res);
    }

    return res.data;
  } catch (error) {
    console.warn("[API.createContractWithSalesInformation] error:", error);

    return error.response.data;
  }
};

const fetchContractsPrices = async (saleType, planType) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/contracts/prices.json?sale_type_code=${saleType}&purchase_plan_code=${planType}`,
      requestOptions()
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.warn("[API.fetchContractsPrices] Error:", error);
  }
};

const fetchContractsSummaries = async (payload) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/contracts/contracts_summaries_report.json?`,
      payload,
      requestOptions()
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.warn("[API.fetchContractsSummaries] Error:", error);
  }
};

/**
 * Customer api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchCustomerRouteContracts(customerID, filter, limit, page)`
 * - `fetchCustomerJobContracts(customerID, filter, limit, page)`
 * - `fetchCustomerContractLocations(customerID, contractID, filter, limit, page)`
 * - `fetchCustomerContractRouteTemplates(customerID, contractID, filter, limit, page)`
 * - `fetchContractsPrices(saleType, planType)`
 * - `fetchContractsSummaries(payload)`
 */
export {
  fetchCustomerRouteContracts,
  fetchCustomerJobContracts,
  fetchCustomerContractLocations,
  fetchCustomerContractRouteTemplates,
  createContractWithSalesInformation,
  fetchContractsPrices,
  fetchContractsSummaries,
};
