import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/withRouter";
import { MapErrorView, MapLoadingSpinner, withGoogleMap } from "@utils/withGoogleMap";
import { Trans } from "react-i18next";
import moment from "moment";
import * as API from "@services";
// Map imports
import MapMarker, { MarkerIcon } from "./maps/MapMarker";
import { GoogleMap } from "@react-google-maps/api";
import MapSideDrawer, { componentToLoad } from "./mapSideDrawer/MapSideDrawer";
import { MapContentWrapper, RouteContentWrapper } from "@styles/globalStyle";

import { withAnomalies, withCustomerLocations, withRoute } from "optigo-redux";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";

import { getErrorMessage, handleChangeFields } from "@utils/form";
import {
  createArrayForDataMenu,
  handleMenuDataTable,
  menuDataTable,
  moveDataFromItemsToSelected,
  updateArrayDataTableMenu,
} from "./routes/dataTable";
import {
  ButtonWrapper,
  getItemsRouteLocations,
  handleToggleAllItemsAndSelected,
  InputControl,
  LoadingSpinner,
  renderCancelButton,
  resetAllCheckboxInState,
  toggleSelectionCheckbox,
  willShowCountCircleForItem,
} from "./routes/utils";

import move from "lodash-move";
import { fitBoundsForAll, fitBoundsForSelected } from "./routes/mapsAndTsp";
import DatePicker from "./form/DatePickerMui";
import FixedActionButton from "./ui/FixedActionButton";
import { formattedDate } from "@utils/dates";
import RouteHeader from "./ui/RouteHeader";
import RouteSidebar from "./ui/RouteSidebar";
import TextFieldUi from "./ui/TextField";
import RouteDetailsDialog from "./RouteDetailsDialog";
import RouteAnomalyDetailsDialog from "./RouteAnomalyDetailsDialog";
import MultiSelect from "@khanacademy/react-multi-select";
import styled from "@emotion/styled";
import Breadcrumbs from "./Breadcrumbs";
import ModalAddLifting from "./ModalAddLifting";
import { orderBy } from "lodash";
import { offsetLegacyMarkersOnSameSpot } from "@utils/offsetMarkers";

const MapButton = styled(Button)`
  && {
    padding: 0px 5px;

    &:hover {
      transform: scale(1.02);
      transition: transform 0.02s;
      background-color: transparent;
    }
  }
`;

const FloatingButton = styled(Fab)`
  && {
    position: fixed;
    right: 25px;
    background-color: ${({ theme }) => theme.colors.icon.default};

    &:hover {
      background-color: ${({ theme }) => theme.colors.icon.hover};
    }
  }
`;

const ContentWrapper = styled.div`
  margin: auto;
  width: 75%;
  font-size: 0.85rem;
  z-index: 1200;
`;

const SelectUnit = styled.div`
  .dropdown {
    display: inline-block !important;
  }

  .dropdown-heading {
    width: auto !important;
    border: none !important;
    background: none !important;
  }

  .dropdown-heading-value {
    position: relative !important;
    padding-left: 0 !important;
  }

  .dropdown-content {
    width: 250px !important;
    font-size: 16px !important;
  }

  z-index: 10;
`;

export const MonthlyFrequencySentence = styled.h3`
  font-weight: bold;
  font-size: 16px;
  color: rgb(43, 78, 93);
`;

const initialState = {
  errors: {
    averageLiftingTime: false,
    customerDestinationLocationId: false,
    customerItemId: false,
    jobTemplateId: false,
    name: false,
    completeName: false,
    noteComments: false,
    routeTemplateId: false,
    startDate: false,
  },
  anomalyItems: null,
  addLiftingModalOpened: false,
  averageLiftingTime: "",
  // checkedDraggableMaster: null,
  customerDestinationLocationId: "-1",
  customerItemId: "-1",
  draggedItemIsChecked: false,
  items: null,
  jobTemplateId: "-1",
  itemsAllChecked: false,
  name: "",
  completeName: "",
  openModalCompletedRoute: false,
  openModalDeletedRoute: false,
  originalStartDate: formattedDate(),
  nbCheckedItemsLeft: 0,
  nbCheckedItemsRight: 0,
  noteComments: "",
  routeTemplateId: "",
  routeTemplateMasterRoute: "",
  routeTemplateName: "",
  routeTemplateCompleteName: "",
  startDate: formattedDate(),
  selected: [],
  selectedAllChecked: false,
  selectedListLoaded: false,
  selectedUnits: [],
  editRouteLoading: false,
  supplierLocationId: "-1",
  numberRouteLocations: "",
  numberOfAnomaly: "",
  numberRouteLocationDone: "",
  numberRouteLocationNotDone: "",
  unitId: null,
  unitAlreadyAssigned: -1,
  unitsAssignedForRoute: [],
  menuDataTableOpened: false,
  arrayDataTableMenu: [],
  itemToAdd: [],
  isOpen: false,
  dataTableAvailableOrSelected: true,
  monthly: "",
  twiceMonthly: "",
  weekly: "",
  showSideBar: false,
  showContainerDialog: false,
  showContainerDialogAnomaly: false,
  lastSelectedShowWindowsIndex: "-1",
  mapType: "roadmap",
  displayFinishedMarker: true,
  displayUnfinishedMarker: true,
  displayAnomalyMarker: true,
  isFinishedCheckboxDisabled: false,
  isUnfinishedCheckboxDisabled: false,
  isAnomalyCheckboxDisabled: false,
  isMapSideDrawerOpen: false,
  sideDrawerLeftTabContent: {
    data: {},
    rank: {
      text: 1,
      length: 1,
      rankChangeCallback: () => { },
    },
  },
  sideDrawerRightTabContent: [],
  quickAssign: false,
  units: [],
};

class Route extends PureComponent {
  state = {
    ...initialState,
  };

  // eslint-disable-next-line react/sort-comp
  locationsTypes = {
    droppable: "items",
    droppable2: "selected",
  };
  // Bind nécessaires pour les fonctions externes qui doivent avoir accès à "this"
  fitBoundsForSelected = fitBoundsForSelected.bind(this);
  fitBoundsForAll = fitBoundsForAll.bind(this);
  getErrorMessage = getErrorMessage.bind(this);
  handleChangeFields = handleChangeFields.bind(this);
  renderCancelButton = renderCancelButton.bind(this);
  resetAllCheckboxInState = resetAllCheckboxInState.bind(this);
  toggleSelectionCheckbox = toggleSelectionCheckbox.bind(this);
  willShowCountCircleForItem = willShowCountCircleForItem.bind(this);
  menuDataTable = menuDataTable.bind(this);
  createArrayForDataMenu = createArrayForDataMenu.bind(this);
  moveDataFromItemsToSelected = moveDataFromItemsToSelected.bind(this);
  updateArrayDataTableMenu = updateArrayDataTableMenu.bind(this);
  handleMenuDataTable = handleMenuDataTable.bind(this);
  handleToggleAllItemsAndSelected = handleToggleAllItemsAndSelected.bind(this);

  get items() {
    return this.state.items;
  }

  get selected() {
    return this.state.selected;
  }

  get valid() {
    const errors = { ...initialState.errors };

    const { averageLiftingTime, jobTemplateId, routeTemplateId, startDate } = this.state;

    let valid = true;

    for (const name of ["name"]) {
      if (this.state[name].toString().trim() === "") {
        valid = false;
        errors[name] = true;
      }
    }

    if (routeTemplateId === -1 && jobTemplateId === "-1") {
      valid = false;
      errors.jobTemplateId = true;
    }

    if (routeTemplateId === -1 && averageLiftingTime.toString().trim() === "") {
      valid = false;
      errors.averageLiftingTime = true;
    }

    if (!startDate) {
      valid = false;
      errors.startDate = true;
    }
    this.setState({ errors });

    return valid;
  }

  get unitsList() {
    const { units } = this.state;
    const availableSortedUnit = orderBy(units, ["name", "id"], ["asc", "asc"]).filter(
      (unit) => unit.loadingType === "AVANT"
    );
    return availableSortedUnit.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }

  async componentDidMount() {
    await this.fetchRoute();
    await this.setStateForLocations();
    await this.fetchUnits();
    this.informationRouteLocation();
    this.setStateForUnitsAlreadyAssignedForThisRoute();
  }

  async componentWillUnmount() {
    await this.flushRoute();
  }

  getList = (id) => this.state[this.locationsTypes[id]];

  // eslint-disable-next-line max-len
  getJobTemplate = (jobTemplateId) => this.props.jobTemplates.find(({ id }) => id === jobTemplateId.toString()) || {};

  // eslint-disable-next-line max-len
  getRouteTemplate = (routeTemplateId) =>
    this.props.routeTemplates.find(({ id }) => id === routeTemplateId.toString()) || {};

  fetchRoutePunctualLiftings = async () => {
    const { fetchRoutePunctualLiftings, router } = this.props;
    const currentRouteId = router.params.routeId;

    await fetchRoutePunctualLiftings(currentRouteId);
  };

  fetchRoute = async () => {
    const { fetchRoute, router } = this.props;
    const currentRouteId = router.params.routeId;

    if (currentRouteId) {
      await fetchRoute(currentRouteId, "web");

      const newState = {
        ...this.state,
        ...this.props.route,
        originalStartDate: this.props.route.startDate,
      };
      this.setState(newState, this.setStateForUnitsAlreadyAssignedForThisRoute);
    }
  };

  fetchUnits = async () => {
    const units = await API.Unit.fetchUnits({ rowsPerPage: "all" });

    this.setState({ units: units?.instances ?? [] });
  };

  flushRoute = async () => {
    const { flushRoute } = this.props;
    await flushRoute();
  };

  setStateForUnitsAlreadyAssignedForThisRoute = () => {
    const { unitIds } = this.state;
    this.setState({ selectedUnits: unitIds });
  };

  setStateForLocations = async () => {
    const { route } = this.props;
    const { routeLocations } = route;

    if (routeLocations === undefined || routeLocations?.length <= 0) return;

    const items = getItemsRouteLocations([...routeLocations]);

    // eslint-disable-next-line max-len
    routeLocations.sort(({ visitRank: visitRankA }, { visitRank: visitRankB }) => visitRankA - visitRankB);

    const newSelected = [];
    const newItems = Array.from(items);

    routeLocations.forEach(
      ({
        address,
        customerItemId,
        id,
        locationId,
        done,
        item,
        anomaly,
        weeklyFrequency,
        nbContainers,
        completeName,
        companyName,
        frequency,
        ciWebLinkId,
        ciWebLiftingComments,
        isFromCiWeb,
        anomalyIds,
        status,
        containerModels,
      }) => {
        const indexOfCurrentItem = newItems.findIndex((el) => el.locationId === locationId);
        if (indexOfCurrentItem >= 0) {
          const [removed] = newItems.splice(indexOfCurrentItem, 1);
          if (removed) {
            const itemToAdd = Object.assign({}, removed);
            itemToAdd.routeLocationId = id;
            itemToAdd.done = done;
            itemToAdd.weeklyFrequency = weeklyFrequency;
            itemToAdd.anomaly = anomaly;
            itemToAdd.item = item;
            itemToAdd.customerItemId = customerItemId;
            itemToAdd.nbContainers = nbContainers;
            itemToAdd.completeName = completeName;
            itemToAdd.showWindowInfo = false;
            itemToAdd.companyName = companyName;
            itemToAdd.isFromCiWeb = isFromCiWeb;
            itemToAdd.ciWebLinkId = ciWebLinkId;
            itemToAdd.ciWebLiftingComments = ciWebLiftingComments;
            itemToAdd.frequency = frequency;
            itemToAdd.anomalyIds = anomalyIds;
            itemToAdd.status = status;
            itemToAdd.containerModels = containerModels;
            itemToAdd.locationName = address || "";
            newSelected.push(itemToAdd);
          }
        } else {
          // Pour ajouter les même locations avec des containers de grosseur différent
          const index = items.findIndex((el) => el.locationId === locationId);
          if (index >= 0) {
            const itemToAdd = Object.assign({}, items[index]);
            itemToAdd.routeLocationId = id;
            itemToAdd.done = done;
            itemToAdd.weeklyFrequency = weeklyFrequency;
            itemToAdd.anomaly = anomaly;
            itemToAdd.item = item;
            itemToAdd.customerItemId = customerItemId;
            itemToAdd.nbContainers = nbContainers;
            itemToAdd.completeName = completeName;
            itemToAdd.showWindowInfo = false;
            itemToAdd.companyName = companyName;
            itemToAdd.isFromCiWeb = isFromCiWeb;
            itemToAdd.ciWebLinkId = ciWebLinkId;
            itemToAdd.ciWebLiftingComments = ciWebLiftingComments;
            itemToAdd.frequency = frequency;
            itemToAdd.anomalyIds = anomalyIds;
            itemToAdd.containerModels = containerModels;
            itemToAdd.locationName = address || "";
            newSelected.push(itemToAdd);
          }
        }
      }
    );
    await this.setState({ items: newItems, selected: newSelected });
  };

  createRouteLocationsParams = (selected) => {
    const { route } = this.props;
    const { routeLocations } = route;

    const removedRouteLocations = routeLocations.filter(
      (loc) => selected.findIndex((sel) => sel.routeLocationId === loc.id) === -1
    );

    return [
      ...selected.map((selectedLocation, index) => ({
        id: selectedLocation.routeLocationId ? selectedLocation.routeLocationId : null,
        location_id: selectedLocation.locationId,
        visit_rank: index + 1,
      })),
      ...removedRouteLocations.map((routeLocation) => ({ id: routeLocation.id, _destroy: "1" })),
    ];
  };

  handleChangeStartDate = (date) => {
    this.setState({
      errors: {
        ...this.state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  handleClickRouteEdit = () => {
    const { router } = this.props;
    const path = `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${router.params.customerItemId}/routes/${router.params.routeId}/edit`;
    router.navigate(path);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      this.setState({ editRouteLoading: true });

      const {
        averageLiftingTime,
        name,
        completeName,
        noteComments,
        startDate,
        supplierLocationId,
        unitId,
        monthly,
        selected,
        selectedUnits,
        twiceMonthly,
        weekly,
      } = this.state;

      const { createRoute, flushRoute, router, updateRoute } = this.props;
      const actualRouteId = router.params.routeId;

      const routeLocationsParams = this.createRouteLocationsParams(selected);

      if (actualRouteId) {
        await updateRoute(actualRouteId, {
          average_lifting_time: averageLiftingTime,
          name,
          complete_name: completeName,
          note_comments: noteComments,
          route_locations_attributes: routeLocationsParams,
          selected_units: selectedUnits,
          start_date: startDate,
          supplier_location_id: supplierLocationId,
          unit_id: unitId,
          monthly,
          twice_monthly: twiceMonthly,
          weekly,
        });
        await flushRoute();
      } else {
        await createRoute(router.params.customerItemId, {
          average_lifting_time: averageLiftingTime,
          customer_item_id: router.params.customerItemId,
          name,
          complete_name: completeName,
          note_comments: noteComments,
          route_locations_attributes: routeLocationsParams,
          start_date: startDate,
          selected_units: selectedUnits,
          supplier_location_id: supplierLocationId,
          unit_id: unitId,
          monthly,
          twice_monthly: twiceMonthly,
          weekly,
        });
      }

      this.setState(initialState);

      const path = `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${router.params.customerItemId}?current_tab=3`;
      router.navigate(path);
    }
  };

  dateWhereRouteTemplateIsAlreadyAssigned = (date, routeTemplateIsAssignedToDate) => {
    const { originalStartDate } = this.state;
    const rtIsAssignedToDateWithOutDateWhereRouteIsAssigned = routeTemplateIsAssignedToDate.filter(
      (rtDate) => rtDate !== originalStartDate
    );
    if (rtIsAssignedToDateWithOutDateWhereRouteIsAssigned.includes(date.format("YYYY-MM-DD"))) {
      return true;
    }
  };

  handleToggleAddLiftingModal = (addLiftingModalOpened) => () => {
    this.setState({
      addLiftingModalOpened,
    });
  };

  handleRefreshAfterModify = async () => {
    await this.refreshLocations();
    await this.setStateForLocations();
  };

  handleShowHideSidebar = () => {
    const { showSideBar } = this.state;
    this.setState({ showSideBar: !showSideBar });
  };

  handleShowContainerDetailsDialog = (item) => {
    this.setState({
      locationItems: { [item.locationId]: [item.item] },
      locationId: item.locationId,
      showContainerDialog: true,
    });
  };

  handleShowAnomalyDetails = (anomalyIds) => {
    this.getAnomalyItems(anomalyIds).then((value) => {
      this.setState({
        anomalyItems: value,
        showContainerDialogAnomaly: true,
      });
    });
  };

  handleCloseAnomalyContainerDetailsDialog = () => {
    this.setState({
      showContainerDialogAnomaly: false,
      anomalyItems: null,
    });
  };

  handleCloseContainerDetailsDialog = () => {
    this.setState({
      showContainerDialog: false,
      locationId: null,
    });
  };

  handleCompleteRouteManually = async () => {
    const { completeRouteManually, router } = this.props;
    console.log("router is :", router);
    const { routeId } = router.params;
    await completeRouteManually(routeId);

    this.setState(
      {
        openModalCompletedRoute: false,
      },
      this.handleRefreshAfterModify
    );
    const path = "/";
    router.navigate(path);
  };

  handleDeleteRoute = async () => {
    const { router } = this.props;
    const { routeId } = router.params;
    await API.Route.deleteRoute(routeId);

    this.setState(
      {
        openModalDeletedRoute: false,
      },
      this.handleRefreshAfterModify
    );
    const path = "/";
    router.navigate(path);
  };

  informationRouteLocation = () => {
    const { route } = this.props;
    const { nbLocationsWhitelisted, nbLocationsDone, nbLocationsWithAnomalies } = route;

    this.setState({
      numberRouteLocations: nbLocationsWhitelisted,
      numberOfAnomaly: nbLocationsWithAnomalies,
      numberRouteLocationDone: nbLocationsDone,
      numberRouteLocationNotDone: nbLocationsWhitelisted - nbLocationsDone,
    });
  };

  refreshLocations = async () => {
    await this.fetchRoute(); // to refresh routeLocations ...
    await this.fetchRoutePunctualLiftings();
    this.informationRouteLocation();
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderFixedActionButton = (action) => {
    let currentButton = null;
    const { route } = this.props;
    if (route.status !== "COMPLETED") {
      if (action === "show") {
        currentButton = (
          <FixedActionButton
            color="secondary"
            tooltipLabel="Modifier la route"
            onClick={this.handleClickRouteEdit}
          >
            <EditIcon />
          </FixedActionButton>
        );
      } else {
        currentButton = (
          <Tooltip
            title="Sauvegarder"
            placement="left-start"
          >
            <FloatingButton
              style={{ bottom: "25px" }}
              onClick={this.handleSubmit}
              color="secondary"
            >
              <SaveIcon />
            </FloatingButton>
          </Tooltip>
        );
      }
    }
    return currentButton;
  };

  setPageTitle = (action) => {
    let retVal = null;
    switch (action) {
      case "edit":
        retVal = "Modifier - ";
        break;
      case "new":
        retVal = "Ajouter - ";
        break;
      case "show":
        retVal = "Détail de la route - ";
        break;
      default:
        return null;
    }
    return retVal;
  };

  showEditNew = () => {
    let actionName = "show";
    const { router } = this.props;
    const { action, routeId } = router.params;

    if (action && routeId && action === "edit") {
      actionName = "edit";
    }
    if (!routeId) {
      actionName = "new";
    }
    return actionName;
  };

  getAnomalyItems = async (anomalyId) => {
    const { fetchAnomalyItems } = this.props;
    const anomalyItemsDetails = await fetchAnomalyItems(anomalyId);
    return anomalyItemsDetails;
  };

  renderUnitsSelectValues = (selectedUnits) => {
    const nbOfUnits = this.unitsList.length;
    const selectedNumber = selectedUnits.length;
    const unitsCounter = `(${selectedNumber}/${nbOfUnits})`;

    if (!nbOfUnits) {
      return <Trans i18nKey="unit_select.none_available" />;
    }

    if (!selectedNumber) {
      return (
        <span>
          <Trans i18nKey="unit_select.none_selected" /> {unitsCounter}
        </span>
      );
    }

    if (selectedNumber === nbOfUnits) {
      return (
        <span>
          <Trans i18nKey="unit_select.all_selected" /> ({selectedNumber})
        </span>
      );
    }

    if (selectedNumber === 1) {
      return (
        <span>
          <Trans i18nKey="unit_select.one_selected" /> {unitsCounter}
        </span>
      );
    }

    return (
      <span>
        <Trans i18nKey="unit_select.many_selected" /> {unitsCounter}
      </span>
    );
  };

  handleUnitSelection = (selectedUnits) => {
    this.setState({ selectedUnits });
  };

  getUnitName = () => {
    const unitsNameToDisplay = this.state.units.filter((unit) => this.state.selectedUnits.includes(unit.id));
    return unitsNameToDisplay.reduce((result, item) => `${result}${item.name}  |  `, "");
  };

  changeMapType = (mapType) => {
    this.setState({ mapType });
  };

  assignLocationFromItemToSelected = (item) => {
    const { items, selected } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newSelected = [...selected, data];
    const newItems = [...items.filter((f) => !(data.id === f.id))];

    this.setState((prevState) => ({
      selected: newSelected,
      items: newItems,
      sideDrawerLeftTabContent: {
        // Update only the rank data
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: prevState.selected.length + 1,
          length: prevState.selected.length + 1,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  unassignLocationFromSelectedToItem = (item) => {
    const { items, selected } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newItems = [...items, data];
    const newSelected = [...selected.filter((f) => !(data.id === f.id))];

    this.setState((prevState) => ({
      items: newItems,
      selected: newSelected,
      sideDrawerLeftTabContent: { ...prevState.sideDrawerLeftTabContent, rank: {} }, // Remove rank data since unassigned
    }));
  };

  modalDeletedRoute = () => (
    <Dialog open={this.state.openModalDeletedRoute}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Attention cette action va supprimer la route.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ openModalDeletedRoute: false })}>
          <Trans i18nKey="cancel" />
        </Button>
        <Button
          onClick={() => this.handleDeleteRoute()}
          variant="contained"
          autoFocus
        >
          <Trans i18nKey="continue" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  modalCompletedRoute = () => (
    <Dialog open={this.state.openModalCompletedRoute}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Attention cette action forcera la route à être complétée</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ openModalCompletedRoute: false })}>
          <Trans i18nKey="cancel" />
        </Button>
        <Button
          onClick={() => this.handleCompleteRouteManually()}
          variant="contained"
          autoFocus
        >
          <Trans i18nKey="continue" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  handleIsLocationAssigned = (itemID) => this.state.selected.some((selectedItem) => selectedItem.id === itemID);

  updateArrayMarker = async (source, destination) => {
    const newSelected = await move(this.state.selected, source, destination);
    this.setState((prevState) => ({
      selected: newSelected,
      sideDrawerLeftTabContent: {
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: destination + 1,
          length: newSelected.length,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  hideMapSideDrawer = () => this.setState({ isMapSideDrawerOpen: false, sideDrawerLeftTabContent: {} });

  showMapSideDrawer = (item, rank) => {
    // If no rank is passed as parameter, only shows item data
    this.setState({
      isMapSideDrawerOpen: true,
      sideDrawerLeftTabContent: { data: item, rank },
      sideDrawerRightTabContent: [item.item],
    });
  };

  handleChangeQuickAssign = (event) => {
    this.setState({ quickAssign: event.target.checked, sideDrawerLeftTabContent: {} }, () => {
      this.state.quickAssign && this.hideMapSideDrawer();
    });
  };

  renderBreadCrumbs = () => {
    const { router } = this.props;
    const { params } = router;
    const { contractId, customerId, customerItemId } = params;

    if (contractId && customerId) {
      return (
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}`}>
              <Trans i18nKey="contract_and_contact" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}`}>
              <Trans i18nKey="contract" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}?current_tab=3/`}>
              <Trans i18nKey="customer_item" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="route_templates.conception" />
          </li>
        </Breadcrumbs>
      );
    }
  };

  renderMapMarkers = () => {
    const {
      displayUnfinishedMarker,
      displayAnomalyMarker,
      displayFinishedMarker,
      quickAssign,
      selected,
      sideDrawerLeftTabContent,
    } = this.state;
    const selectedLength = selected.length === 0 ? 1 : selected.length;
    const { data } = sideDrawerLeftTabContent;

    const unfinishedMarkers = [];
    const finishedWithAnomalyMarkers = [];
    const finishedWithoutAnomalyMarkers = [];
    const finishedWithOnlyAnomalyMarkers = [];

    offsetLegacyMarkersOnSameSpot(this.state.selected).map((selected, index) => {
      const { status } = selected;
      const rank = {
        text: index + 1,
        length: selectedLength,
        rankChangeCallback: this.updateArrayMarker,
      };

      displayUnfinishedMarker &&
        status === "unfinished" &&
        unfinishedMarkers.push(
          <MapMarker
            key={index}
            icon={MarkerIcon.unfinished}
            label={index + 1} // Show label on markers since it's an assigned marker
            position={{ lat: +selected.address.lat, lng: +selected.address.lng }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.unassignLocationFromSelectedToItem(selected)
                : () => this.showMapSideDrawer(selected, rank)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
            }
          />
        );

      displayAnomalyMarker &&
        status === "finished_with_anomaly" &&
        finishedWithAnomalyMarkers.push(
          <MapMarker
            key={index}
            icon={MarkerIcon.finishedWithAnomaly}
            label={index + 1} // Show label on markers since it's an assigned marker
            position={{ lat: +selected.address.lat, lng: +selected.address.lng }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.unassignLocationFromSelectedToItem(selected)
                : () => this.showMapSideDrawer(selected, rank)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
            }
          />
        );

      displayFinishedMarker &&
        status === "finished_without_anomaly" &&
        finishedWithoutAnomalyMarkers.push(
          <MapMarker
            key={index}
            icon={MarkerIcon.finishedWithoutAnomaly}
            label={index + 1} // Show label on markers since it's an assigned marker
            position={{ lat: +selected.address.lat, lng: +selected.address.lng }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.unassignLocationFromSelectedToItem(selected)
                : () => this.showMapSideDrawer(selected, rank)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
            }
          />
        );

      displayAnomalyMarker &&
        status === "finished_with_only_anomaly" &&
        finishedWithOnlyAnomalyMarkers.push(
          <MapMarker
            key={index}
            icon={MarkerIcon.finishedWithOnlyAnomaly}
            label={index + 1} // Show label on markers since it's an assigned marker
            position={{ lat: +selected.address.lat, lng: +selected.address.lng }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.unassignLocationFromSelectedToItem(selected)
                : () => this.showMapSideDrawer(selected, rank)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
            }
          />
        );
    });

    // Join all arrays under one array and if an array is null, replace it with an empty array instead
    return [
      ...(unfinishedMarkers || []),
      ...(finishedWithAnomalyMarkers || []),
      ...(finishedWithoutAnomalyMarkers || []),
      ...(finishedWithOnlyAnomalyMarkers || []),
    ];
  };

  handleModalCompleteRoute = () => {
    this.setState({ openModalCompletedRoute: true });
  };

  handleModalDeleteRoute = () => {
    this.setState({ openModalDeletedRoute: true });
  };

  handleDoneCheckboxClick = () => {
    const { displayFinishedMarker } = this.state;
    this.setState({
      displayFinishedMarker: !displayFinishedMarker,
      isFinishedCheckboxDisabled: true,
    });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isFinishedCheckboxDisabled: false }), 1200);
  };

  handleToDoCheckboxClick = () => {
    const { displayUnfinishedMarker } = this.state;
    this.setState({
      displayUnfinishedMarker: !displayUnfinishedMarker,
      isUnfinishedCheckboxDisabled: true,
    });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isUnfinishedCheckboxDisabled: false }), 1200);
  };

  handleAnomalyCheckboxClick = () => {
    const { displayAnomalyMarker } = this.state;
    this.setState({ displayAnomalyMarker: !displayAnomalyMarker, isAnomalyCheckboxDisabled: true });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isAnomalyCheckboxDisabled: false }), 1200);
  };

  renderModalAddLifting = () => {
    const { selected, addLiftingModalOpened } = this.state;
    const { router, updateRoute } = this.props;

    const { routeId, contractId, customerId } = router.params;

    return (
      <ModalAddLifting
        contractId={contractId}
        customerId={customerId}
        onClose={this.handleToggleAddLiftingModal(false)}
        open={addLiftingModalOpened}
        refreshList={this.handleRefreshAfterModify}
        routeId={routeId}
        selected={selected}
        updateRoute={updateRoute}
      />
    );
  };

  render() {
    const {
      addLiftingModalOpened,
      errors,
      items,
      menuDataTableOpened,
      name,
      routeTemplateMasterRoute,
      showSideBar,
      startDate,
      selectedUnits,
      noteComments,
      numberRouteLocations,
      numberOfAnomaly,
      numberRouteLocationDone,
      numberRouteLocationNotDone,
      showContainerDialog,
      showContainerDialogAnomaly,
      locationId,
      displayFinishedMarker,
      displayUnfinishedMarker,
      displayAnomalyMarker,
      openModalCompletedRoute,
      openModalDeletedRoute,
      sideDrawerLeftTabContent,
      sideDrawerRightTabContent,
      isMapSideDrawerOpen,
      mapType,
      isFinishedCheckboxDisabled,
      isUnfinishedCheckboxDisabled,
      isAnomalyCheckboxDisabled,
      editRouteLoading,
    } = this.state;

    const { center, router, route, routeLoading, zoom } = this.props;

    const { isLoaded, loadError } = this.props.googleMap;

    const { routeId } = router.params;

    if ((routeId && !route) || routeLoading || this.state.units.length <= 0 || editRouteLoading) {
      return (
        <LoadingSpinner>
          <CircularProgress
            color="primary"
            size={70}
          />
        </LoadingSpinner>
      );
    }

    if (!items) {
      return null;
    }

    const currentAction = this.showEditNew();
    const action = this.setPageTitle(currentAction);

    const path = router.location.pathname;

    // Single object to contain all passed props for side drawer component
    const sideDrawerContractItemData = {
      isMapSideDrawerOpen,
      isLocationAssignable: path.includes("edit"),
      hideMapSideDrawer: this.hideMapSideDrawer,
      handleIsLocationAssigned: this.handleIsLocationAssigned,
      handleShowAnomalyDetails: this.handleShowAnomalyDetails,
      handleAssignSelectedLocation: this.assignLocationFromItemToSelected,
      handleUnassignSelectedLocation: this.unassignLocationFromSelectedToItem,
      sideDrawerLeftTabContent,
      sideDrawerRightTabContent,
    };

    // Single object to contain all passed props/properties to google map component
    const mapProps = {
      mapContainerStyle: { width: "100%" },
      center,
      zoom,
      clickableIcons: false,
      mapTypeId: mapType,
      options: { mapTypeControl: false, streetViewControl: true },
      onLoad: (map) => {
        const bounds = new window.google.maps.LatLngBounds();

        this.state.selected.forEach((marker) => {
          bounds.extend({ lat: +marker.address.lat, lng: +marker.address.lng });
        });

        map.fitBounds(bounds);
      },
    };

    // Single object to contain all passed props to header component
    const headerProps = {
      returnOnClick: this.renderCancelButton,
      pageTitle: action + " " + route.routeTemplateCompleteName,
      sidebarOnClick: this.handleShowHideSidebar,
      sidebarTitle: "EMPLACEMENTS",
      isQuickAssignShowing: path.includes("edit"),
      quickAssign: this.state.quickAssign,
      quickAssignOnClick: this.handleChangeQuickAssign,
    };

    const { routeTemplateIsAssignedToDate, routeIsFromCiWeb } = route;

    return (
      <Fragment>
        {this.renderBreadCrumbs()}
        <div id="route-template-wrapper">
          <RouteHeader {...headerProps} />
          {path.includes("edit") && (
            <>
              <Tooltip
                title="Complèter"
                placement="left-start"
              >
                <FloatingButton
                  style={{ bottom: "155px" }}
                  onClick={this.handleModalCompleteRoute}
                >
                  <DoneIcon />
                </FloatingButton>
              </Tooltip>
              <Tooltip
                title="Supprimer"
                placement="left-start"
              >
                <FloatingButton
                  style={{ bottom: "90px" }}
                  onClick={this.handleModalDeleteRoute}
                >
                  <DeleteIcon />
                </FloatingButton>
              </Tooltip>
            </>
          )}
          {openModalDeletedRoute && this.modalDeletedRoute()}
          {openModalCompletedRoute && this.modalCompletedRoute()}
          <MapContentWrapper>
            {!loadError ? (
              isLoaded ? (
                <>
                  <MapSideDrawer
                    componentToLoad={componentToLoad.contractItem}
                    componentDataToLoad={sideDrawerContractItemData}
                    urlCustomerContract={`/customers/${this.props.router.params.customerId}/contracts/${this.props.router.params.contractId}`}
                  />
                  <GoogleMap {...mapProps}>{this.renderMapMarkers()}</GoogleMap>
                </>
              ) : (
                <MapLoadingSpinner />
              )
            ) : (
              <MapErrorView />
            )}
          </MapContentWrapper>

          <RouteContentWrapper>
            <form>
              <Grid
                container
                justifyContent="center"
              >
                <MapButton
                  sx={{ color: "black" }}
                  onClick={() => this.changeMapType("satellite")}
                >
                  Satellite
                </MapButton>
                <MapButton
                  sx={{ color: "black" }}
                  onClick={() => this.changeMapType("terrain")}
                >
                  Terrain
                </MapButton>
                <MapButton
                  sx={{ color: "black" }}
                  onClick={() => this.changeMapType("roadmap")}
                >
                  Carte routière
                </MapButton>
                <MapButton
                  sx={{ color: "black" }}
                  onClick={() => this.changeMapType("hybrid")}
                >
                  Hybride
                </MapButton>

                <Fragment>
                  <MapButton
                    sx={{ color: "black" }}
                    style={{ marginLeft: 70 }}
                    disabled={isFinishedCheckboxDisabled}
                    onClick={this.handleDoneCheckboxClick}
                  >
                    <Checkbox checked={displayFinishedMarker} />
                    Emplacements complétés sans anomalie
                  </MapButton>
                  <MapButton
                    sx={{ color: "black" }}
                    disabled={isUnfinishedCheckboxDisabled}
                    onClick={this.handleToDoCheckboxClick}
                  >
                    <Checkbox checked={displayUnfinishedMarker} />
                    Emplacements à faire
                  </MapButton>
                  <MapButton
                    sx={{ color: "black" }}
                    disabled={isAnomalyCheckboxDisabled}
                    onClick={this.handleAnomalyCheckboxClick}
                  >
                    <Checkbox checked={displayAnomalyMarker} />
                    Emplacements en anomalie
                  </MapButton>
                </Fragment>
              </Grid>
              <Grid container>
                <Grid>
                  <MonthlyFrequencySentence>
                    Cette route inclut les levées: {route.includedMonthlyFrequencySentence}
                  </MonthlyFrequencySentence>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  sm={3}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      id="Total"
                      label={<Trans i18nKey="routes.total" />}
                      fullWidth
                      disabled={currentAction === "show"}
                      value={numberRouteLocations}
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      id="Complete"
                      label={<Trans i18nKey="routes.completed" />}
                      fullWidth
                      disabled={currentAction === "show"}
                      value={numberRouteLocationDone}
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      id="Restant"
                      label={<Trans i18nKey="routes.remaining" />}
                      fullWidth
                      disabled={currentAction === "show"}
                      value={numberRouteLocationNotDone}
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      id="Anomaly"
                      label={<Trans i18nKey="routes.anomaly" />}
                      fullWidth
                      disabled={currentAction === "show"}
                      value={numberOfAnomaly}
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={9}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.name}
                      helperText={this.getErrorMessage("invalid")}
                      id="Anomaly"
                      label={<Trans i18nKey="routes.name" />}
                      onChange={this.handleChangeFields("name")}
                      disabled={currentAction === "show"}
                      value={name}
                      fullWidth
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.routeTemplateMasterRoute}
                      id="route-master-route"
                      label={<Trans i18nKey="routes.master_route_name" />}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={routeTemplateMasterRoute}
                      fullWidth
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputControl>
                    <FormControl fullWidth>
                      <DatePicker
                        error={errors.startDate}
                        label={<Trans i18nKey="start_date" />}
                        onChange={this.handleChangeStartDate}
                        variant="outlined"
                        value={moment(startDate)}
                        disabled={currentAction === "show"}
                        disablePast={false}
                        shouldDisableDate={(date) =>
                          this.dateWhereRouteTemplateIsAlreadyAssigned(date, routeTemplateIsAssignedToDate)
                        }
                      />
                      <FormHelperText>{this.getErrorMessage("startDate")}</FormHelperText>
                    </FormControl>
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                />
                <Grid
                  item
                  sm={3}
                >
                  <InputControl>
                    <FormControl fullWidth>
                      <SelectUnit>
                        <MultiSelect
                          onSelectedChanged={this.handleUnitSelection}
                          options={this.unitsList}
                          selectAllLabel={<Trans i18nKey="select_all" />}
                          selected={selectedUnits}
                          disabled={currentAction === "show"}
                          valueRenderer={this.renderUnitsSelectValues}
                          disableSearch
                        />
                      </SelectUnit>
                    </FormControl>
                  </InputControl>
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.routeTemplateMasterRoute}
                      id="selected_unit"
                      label={<Trans i18nKey="jobs.unit" />}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={this.getUnitName()}
                      fullWidth
                    />
                  </InputControl>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*  <InputControl>*/}
                {/*    <FormControl fullWidth>*/}
                {/*      <TextAreaUi*/}
                {/*        error={errors.note_comments}*/}
                {/*        helperText={this.getErrorMessage("note_comments")}*/}
                {/*        id="cpbr-note-comments"*/}
                {/*        label={<Trans i18nKey="preparation.note_comments" />}*/}
                {/*        onChange={this.handleChangeFields("noteComments")}*/}
                {/*        disabled={currentAction === "show"}*/}
                {/*        value={noteComments || ""}*/}
                {/*      />*/}
                {/*    </FormControl>*/}
                {/*  </InputControl>*/}
                {/*</Grid>*/}

                <Grid
                  item
                  xs={12}
                >
                  <InputControl>
                    <ButtonWrapper
                      disabled={routeIsFromCiWeb || currentAction === "show"}
                      onClick={this.handleToggleAddLiftingModal(true)}
                      variant="contained"
                    >
                      <Trans i18nKey="routes.add_lifting" />
                    </ButtonWrapper>
                  </InputControl>
                </Grid>
              </Grid>

              <RouteSidebar
                closeSidebarOnClick={this.handleShowHideSidebar}
                sidebarTitle={action + " " + route.routeTemplateCompleteName}
                showSideBar={showSideBar}
                switchChecked={menuDataTableOpened}
                switchOnChange={this.handleMenuDataTable}
              >
                {!menuDataTableOpened && this.state.selected && this.state.items
                  ? this.menuDataTable()
                  : console.log("this.renderDragAndDrop()")}
              </RouteSidebar>
            </form>
          </RouteContentWrapper>

          {addLiftingModalOpened && this.renderModalAddLifting()}

          {this.renderFixedActionButton(currentAction)}

          {showContainerDialog ? (
            <RouteDetailsDialog
              items={this.state.locationItems[locationId]}
              dialogOpen={showContainerDialog}
              onDialogClose={this.handleCloseContainerDetailsDialog}
            // locationId={locationId}
            />
          ) : null}

          {showContainerDialogAnomaly ? (
            <RouteAnomalyDetailsDialog
              anomalyItems={this.state.anomalyItems}
              dialogOpen={showContainerDialogAnomaly}
              onDialogClose={this.handleCloseAnomalyContainerDetailsDialog}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

Route.defaultProps = {
  routeLoading: true,
  customerItemLoading: true,
  locationsLoading: true,
  center: {
    lat: 46.807763,
    lng: -71.38549,
  },
  zoom: 10,
};

export default withRouter(withGoogleMap(withAnomalies(withCustomerLocations(withRoute(Route)))));
