import UsersTableHeader from "@components/users/UsersTableHeader";
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@mui/material";
import TextFieldUi from "@ui/TextField";
import { Trans } from "react-i18next";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import React, { useState } from "react";
import { Edit, Save } from "@mui/icons-material";
import { isArrayTruthy } from "@utils/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";
import UserEditModal from "@components/modals/user/UserEditModal";
import { debounce } from "lodash";

const handleEditUser = (user, setUser, setIsEditModalOpen) => {
  setUser((prevState) => ({
    ...prevState,
    id: user.data.id,
    data: {
      firstName: { error: false, value: user.data.first_name },
      lastName: { error: false, value: user.data.last_name },
      email: { error: false, value: user.data.email },
    },
    passwords: {
      password: { error: false, show: false, value: "" },
      confirmPassword: { error: false, show: false, value: "" },
      nip: { error: false, show: false, value: "" },
      confirmNip: { error: false, show: false, value: "" },
    },
    roles: {
      admin: user.roles.admin,
      accounting: user.roles.accounting,
    },
    status: {
      disabled: user.data.disabled,
    },
  }));
  setIsEditModalOpen((prevState) => !prevState);
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const handleSearchFilter = (setFilters) =>
  debounce((event) => {
    setFilters((prevState) => ({ ...prevState, filter: event.target.value }));
  }, 500);

export default function UsersTableBody({ users, user, setUser, filters, setFilters, fetchUsersList }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handlePageChange = (event, pageNumber) => {
    setFilters((prevState) => ({ ...prevState, page: pageNumber }));
  };

  return (
    <>
      <Toolbar>
        <TextFieldUi
          id="cpbr-filtre"
          label={<Trans i18nKey="filter" />}
          onChange={handleSearchFilter(setFilters)}
          type="search"
        />
      </Toolbar>
      <Table>
        <UsersTableHeader />
        {isArrayTruthy(users.data) && (
          <TableBody>
            {users.data.map((user) => (
              <TableRow key={user.data.email}>
                <TableCell align="left" className="name">{`${user.data.first_name} ${user.data.last_name}`}</TableCell>
                <TableCell align="left">{user.data.email}</TableCell>
                <TableCell align="left">{`${user.roles.admin ? "Administrateur" : ""} ${user.roles.accounting ? (user.roles.admin ? " / Comptabilité" : "Comptabilité") : ""
                  }`}</TableCell>
                <TableCell align="left">
                  {user.data.disabled ? (
                    <DangerousIcon sx={{ color: "red" }} />
                  ) : (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  )}
                </TableCell>
                <TableCell align="left">
                  <IconButton
                    size="medium"
                    color="secondary"
                    onClick={() => handleEditUser(user, setUser, setIsEditModalOpen)}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <TablePaginationWrapper
        component="div"
        count={users.count}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange(setFilters)}
        page={filters.page}
        rowsPerPage={filters.limit}
      />

      {isEditModalOpen && (
        <UserEditModal
          user={user}
          setUser={setUser}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          fetchUsersList={fetchUsersList}
        />
      )}
    </>
  );
}
