// React imports
import React, { FC, MouseEventHandler } from "react";
// Internal imports
import RouteLocationHistory from "@@types/route-locations/route-location-history.type";
import RouteHistory from "@@types/routes/route-history.type";
import Location from "@@types/locations/location.type";
import { getLocationStatusColor } from "@utils/filtering";
// External imports
import { TableCell, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import ListIcon from "@mui/icons-material/List";
import styled from "@emotion/styled";

const CircleStatusColor = styled.div`
  height: 25px;
  width: 25px;
  background: ${(props): string => props.color || "none"};
  border-radius: 50%;
  display: flex;
`;

type LocationRouteHistoryListBodyProps = {
  customerLocation: Location;
  routes: RouteHistory[];
  navigate: (path: string) => void;
  handleOpenRouteDetails: (
    route: RouteHistory,
    routeLocation: RouteLocationHistory[]
  ) => MouseEventHandler<HTMLButtonElement>;
};

const handleClickOnCell = (navigate: (path: string) => void, path: string): void => {
  navigate(path);
};

export type RouteLocationCountByStatus = {
  [status: string]: number;
};

const LocationRouteHistoryListBody: FC<LocationRouteHistoryListBodyProps> = ({
  customerLocation,
  routes,
  navigate,
  handleOpenRouteDetails,
}) => {
  return routes.map((route) => {
    const routeLocationsStatuscounts = route.routeLocations.reduce((routeLocationCountByStatus, routeLocation) => {
      const status = routeLocation.status;
      if (!routeLocationCountByStatus[status]) {
        routeLocationCountByStatus[status] = 0;
      }
      routeLocationCountByStatus[status]++;
      return routeLocationCountByStatus;
    }, {} as RouteLocationCountByStatus);

    return (
      <TableRow
        key={route.id}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={() =>
          handleClickOnCell(
            navigate,
            `/customers/${customerLocation.locatableId}/contracts/${customerLocation.contractId}/preparations/${route.customerItemId}/routes/${route.id}`
          )
        }
      >
        <TableCell>{route.originalStartDate}</TableCell>
        {<TableCell>{route.masterRouteName}</TableCell>}
        <TableCell>{route.name}</TableCell>
        <TableCell>{route.unitNames.join(",")}</TableCell>
        <TableCell>
          <CircleStatusColor color={getLocationStatusColor(route.locationStatus)} />
        </TableCell>
        <TableCell>
          {route.assigned ? (
            <div>
              Levées:{" "}
              {routeLocationsStatuscounts?.finished_without_anomaly ||
                routeLocationsStatuscounts?.finished_with_anomaly ||
                0}
              <br />
              Non levées: {routeLocationsStatuscounts?.unfinished || 0}
              <br />
              Cas problèmes :{" "}
              {routeLocationsStatuscounts?.finished_with_only_anomaly ||
                routeLocationsStatuscounts?.finished_with_anomaly ||
                0}
            </div>
          ) : (
            "Ne pas lever"
          )}
        </TableCell>

        <TableCell>
          {route.assigned && (
            <Button
              key={route.id}
              onClick={handleOpenRouteDetails(route, route.routeLocations)}
            >
              <ListIcon color="secondary" />
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  });
};

export default LocationRouteHistoryListBody;
