import React, { useState } from "react";

import { Paper, Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";

import LocationsHistoryJobList from "@components/location/job/LocationHistoryJobList";
import LocationsFutureJobList from "@components/location/job/LocationFutureJobList";
import LocationsRecurrencesList from "@components/location/LocationsRecurrenceList";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

function RenderTabContainerJob(props) {
  const { tabValue } = props;

  switch (tabValue) {
    case 0:
      {
        /* HISTORIQUE DES TÂCHES  */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationsHistoryJobList />
          </Paper>
        </TabContainer>
      );
    case 1:
      {
        /* TÂCHES À VENIR */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationsFutureJobList />
          </Paper>
        </TabContainer>
      );
    case 2: {
      /* RÉCURRENCES */

      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationsRecurrencesList />
          </Paper>
        </TabContainer>
      );
    }
    default:
      console.log("[ERROR] renderTabContainer: returned default.");
      return <div />;
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default RenderTabContainerJob;
