import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Tab, Stack, Box } from "@mui/material";
import { withCustomer, withListCodes } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import ContractsList from "./ContractsList";
import ContactsList from "./ContactsList";
import LoadingRoutes from "./LoadingRoutes";
import Report from "./report/Report";
import HeaderContextWrapper from "./ui/HeaderContextWrapper";
import PageHeader from "./ui/PageHeader";
import TabsWrapper from "./ui/TabsWrapper";
import FlexHeaderRowWrapper from "./ui/FlexHeaderRowWrapper";
import HeaderColumnWrapper from "./ui/HeaderColumnWrapper";
import { withRouter } from "@utils/withRouter";
import DialogWrapper from "@ui/DialogWrapper";
import FormGroupWrapper from "@ui/FormGroupWrapper";
import FlexRowWrapper from "@ui/FlexRowWrapper";
import HalfFormControl from "@ui/HalfFormControl";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import SelectUi from "@ui/Select";
import axios from "axios";
import { getUserToken } from "../services/auth";
import { ButtonWrapper } from "@components/routes/utils";
import { managementBlockCustomerJob } from "../services/customers/CustomerService";
import stripeLogoWhite from "@assets/images/stripe/stripeLogoWhite.png";

const initialState = {
  errors: {
    reason: false,
  },
  reason: "-1",
  currentTab: 0,
  openModalToBlockTheClient: false,
};

class Customer extends PureComponent {
  state = {
    ...initialState,
    listOfCodes: [],
  };

  handleChangeFields = handleChangeFields.bind(this);
  getErrorMessage = getErrorMessage.bind(this);

  get valid() {
    const { reason } = this.state;
    const errors = { ...initialState.errors };

    let valid = true;

    for (const name of ["reason"]) {
      if (this.state[name].toString().trim() === "" || this.state[name].toString().trim() === "-1") {
        valid = false;
        errors[name] = true;
        this.setState({ errorMessage: true });
      } else {
        this.setState({ errorMessage: false });
      }
    }

    this.setState({ errors });

    return valid;
  }

  componentDidMount() {
    const { fetchCustomer, router } = this.props;

    fetchCustomer(router.params.customerId).then((e) => {
      this.setCustomerInitialState();
    });

    this.fetchListOfCodes();
  }

  fetchListOfCodes = async () => {
    const { specificListOfCodes } = this.props;
    specificListOfCodes("REASON_BLOCK_CLIENT_TASK").then((response) => {
      this.setState({ listOfCodes: response });
    });
  };

  setCustomerInitialState = () => {
    const { customer } = this.props;
    const { taskBlock } = customer;
    if (customer && taskBlock) {
      const { reason } = taskBlock;

      this.setState({
        reason,
      });
    }
  };

  handleChangeTab = (_, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  openModalToBlockTheClient = () => {
    const { openModalToBlockTheClient } = this.state;
    this.setState({ openModalToBlockTheClient: !openModalToBlockTheClient });
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem key={id} value={id}>
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderPageHeader = () => {
    const { customer } = this.props;
    const { address, taskBlock, isUserAccounting, isUserAdmin } = customer;

    const headerAddress = address ? <span>{address}</span> : "";

    return (
      <Stack>
        <FlexHeaderRowWrapper style={{ alignItems: "center" }}>
          <HeaderColumnWrapper>{headerAddress}</HeaderColumnWrapper>
          {(isUserAccounting || isUserAdmin) && (
            <Stack direction="row" spacing={1}>
              {taskBlock && (
                <ButtonWrapper
                  style={{ backgroundColor: "#006F9C", color: "#FFF" }}
                  onClick={() => this.handleSubmitTaskBlock(true)}
                >
                  Débloquer le client
                </ButtonWrapper>
              )}

              <ButtonWrapper
                style={{ backgroundColor: "#006F9C", color: "#FFF" }}
                onClick={() => this.openModalToBlockTheClient()}
              >
                {taskBlock ? "Changer la raison du blocage" : "Bloquer le client"}
              </ButtonWrapper>
            </Stack>
          )}
        </FlexHeaderRowWrapper>

        {/* Stripe button to link to the current stripe customer in the stripe dashboard */}
        {(isUserAccounting || isUserAdmin) && (
          <Box>
            <Button
              color="info"
              disabled={!customer.stripeCustomerId}
              variant="contained"
              sx={{ background: "#635bff" }}
              startIcon={<img src={stripeLogoWhite} height={24} />}
              href={`https://dashboard.stripe.com/test/customers/${customer.stripeCustomerId}`}
              target="_blank"
            >
              Compte Client
            </Button>
          </Box>
        )}
      </Stack>
    );
  };

  renderTabContainer = () => {
    let Content = null;

    switch (this.state.currentTab) {
      case 0: {
        Content = ContractsList;
        break;
      }
      case 1: {
        Content = ContactsList;
        break;
      }
      case 2: {
        Content = Report;
        break;
      }
      default: {
        Content = "div";
        break;
      }
    }

    return <Content {...this.props} />;
  };

  modalToBlockTheClient = () => {
    const { openModalToBlockTheClient, reason, listOfCodes, errors } = this.state;

    return (
      <DialogWrapper onClose={() => this.openModalToBlockTheClient()} open={openModalToBlockTheClient}>
        <DialogTitle>
          <Trans i18nKey="blocking_sheet" />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <SelectUi
                    error={errors.reason}
                    formControlError={errors.reason}
                    formHelperErrorMsg={this.getErrorMessage("reason")}
                    id="reason"
                    inputLabelText={<Trans i18nKey="reason_for_blocking" />}
                    onChange={this.handleChangeFields("reason")}
                    value={`${reason}`}
                  >
                    {this.renderMenuItems(<Trans i18nKey="reason_for_blocking" />, listOfCodes, "name")}
                  </SelectUi>
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.openModalToBlockTheClient()}>
            <Trans i18nKey="cancel" />
          </Button>
          <Button onClick={() => this.handleSubmitTaskBlock(false)} variant="contained">
            Enregistrer et bloquer le client
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  };

  handleSubmitTaskBlock = async (stopTheBlock) => {
    if (this.valid || stopTheBlock) {
      const { reason } = this.state;
      const { customer } = this.props;
      const { id } = customer;
      await managementBlockCustomerJob(id, reason, stopTheBlock);
      this.refreshPage();
    }
  };

  refreshPage = () => {
    const { fetchCustomer, router } = this.props;
    this.setState({ openModalToBlockTheClient: false });
    fetchCustomer(router.params.customerId).then((e) => {
      this.setCustomerInitialState();
    });
  };

  render() {
    const { openModalToBlockTheClient } = this.state;
    const { customer, router } = this.props;
    const { name, taskBlock } = customer;

    return (
      <div>
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="contract_and_contact" />
          </li>
        </Breadcrumbs>

        <HeaderContextWrapper>
          <PageHeader titleText={name} onBackClick={() => router.navigate(-1)}>
            {taskBlock && <text style={{ fontSize: 25, color: "red" }}>CLIENT BLOQUÉ</text>}
            {this.renderPageHeader()}
          </PageHeader>
        </HeaderContextWrapper>

        <TabsWrapper
          indicatorColor="primary"
          onChange={this.handleChangeTab}
          textColor="primary"
          value={this.state.currentTab}
        >
          <Tab label={<Trans i18nKey="contracts" />} />
          <Tab label={<Trans i18nKey="contacts" />} />
          <Tab label={<Trans i18nKey="report" />} />
        </TabsWrapper>

        {this.renderTabContainer()}
        {openModalToBlockTheClient && this.modalToBlockTheClient()}
      </div>
    );
  }
}

Customer.propTypes = {
  customer: PropTypes.object.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  //
};

export default withRouter(withListCodes(withCustomer(Customer)));
