// React imports
import React, { FC } from "react";
// Externals imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: string };
};

const headerLabel: HeaderLabel = {
  containerNumber: { label: "Numéro de conteneur" },
  containerChipNumber: { label: "Numéro de puce" },
  model: { label: "Modèle" },
  volume: { label: "Volume" },
  matterType: { label: "Type de matière" },
  status: { label: "Statut" },
  note: { label: "Note" },
  liftingRequests: { label: "levée(s)" },
  edit: { label: "Modifier" },
  delete: { label: "Supprimer" },
};
const header: string[] = [
  "containerNumber",
  "containerChipNumber",
  "model",
  "volume",
  "matterType",
  "status",
  "note",
  "liftingRequests",
  "edit",
  "delete",
];

const LocationCcavContainersListHeader: FC = () => {
  return (
    <TableRow>
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
    </TableRow>
  );
};

export default LocationCcavContainersListHeader;
