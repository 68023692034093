// React imports
import React from 'react';
import { Trans } from "react-i18next";
// Internal imports
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import TextFieldUi from "@ui/TextField";
import { formattedDate } from "@utils/dates";
import stripeLogoWhite from "@assets/images/stripe/stripeLogoWhite.png";
import SalesTableHead from "@components/sales/SalesTableHead";
// External imports
import { Box, Button, Table, TableBody, TableCell, TableRow, Toolbar } from '@mui/material';

const SalesTransactionsTabTable = ({ transactions, count, filters, setFilters, handleRowsPerPageChange, handleSearchFilter }) => {

  const handlePageChange = (event, pageNumber) => {
    setFilters(prevState => ({ ...prevState, page: pageNumber }));
  };

  return (
    <>
      <Toolbar>
        <TextFieldUi
          id="cpbr-filtre"
          label={<Trans i18nKey="filter" />}
          onChange={handleSearchFilter(setFilters)}
          type="search"
        />
      </Toolbar>
      <Table>
        <SalesTableHead />
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.name}>
              <TableCell align="left">{formattedDate(transaction.transactionDate)}</TableCell>
              <TableCell align="left">{transaction.customerName}</TableCell>
              <TableCell align="left">{transaction.description}</TableCell>
              <TableCell align="left">{transaction.totalAmount + "$"}</TableCell>
              <TableCell align="center"><Box width={125}></Box></TableCell>
              <TableCell align="left">
                <Box>
                  <Button
                    color="info"
                    variant="contained"
                    sx={{ background: "#635bff" }}
                    startIcon={<img src={stripeLogoWhite} height={24} />}
                    href={`https://dashboard.stripe.com/test/payments/${transaction.stripeTransactionId}`}
                    target="_blank"
                  />
                </Box>
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePaginationWrapper
        component="div"
        count={count}
        id="cpbr-pagination"
        labelRowsPerPage=""
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange(setFilters)}
        page={filters.page}
        rowsPerPage={filters.limit}
      />
    </>
  )
}

export default function SalesTransactionsTab({ transactions, count, filters, setFilters, handleRowsPerPageChange, handleSearchFilter }) {

  return (
    <SalesTransactionsTabTable
      transactions={transactions}
      count={count}
      filters={filters}
      setFilters={setFilters}
      handleRowsPerPageChange={handleRowsPerPageChange}
      handleSearchFilter={handleSearchFilter}
    />
  )
}