import { QueryKey } from "@tanstack/react-query";

const Supplier = {
  all: (): QueryKey => ["Supplier"],
  lists: (): QueryKey => [...Supplier.all(), "list"],
  list: (filters: object): QueryKey => [...Supplier.lists(), { filters }],
  details: (): QueryKey => [...Supplier.all(), "detail"],
  detail: (id: number): QueryKey => [...Supplier.details(), id.toString()],
};

export default Supplier;
