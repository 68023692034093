import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationResult } from "@@types/apiResponse";
import { DefaultError } from "@tanstack/react-query/build/modern/index";
import LocationDetail from "@@types/location-details/location-detail.type";
import LocationDetailResponse from "@@types/location-details/location-detail-response.type";
import putLocationCcavModality from "../../services/location-details/put-location-ccav-modality.service";
import locationDetailKeys from "@hooks/location-details/location-detail.keys";
import mapPayloadToLocationDetail from "@@types/location-details/mappers/mapPayloadToLocationDetail.mapper";
import LocationDetailPayload from "@@types/location-details/location-detail-payload.type";

export type UseLocationCcavModalityMutation = {
  mutate: (payload: LocationDetail) => Promise<MutationResult<LocationDetailResponse>>;
  isPending: boolean;
};

function useEditLocationCcavModality(locationId: number): UseLocationCcavModalityMutation {
  const queryClient = useQueryClient();

  const mutation = useMutation<MutationResult<LocationDetailResponse>, DefaultError, LocationDetailPayload>({
    mutationFn: (payload: LocationDetailPayload): Promise<MutationResult<LocationDetailResponse>> =>
      putLocationCcavModality(locationId, payload),
    onSuccess: async (_data) => {
      // Invalidate queries to reflect the changes
      await queryClient.invalidateQueries({
        queryKey: locationDetailKeys.detail(locationId),
        exact: false,
      });
    },
  });

  const mutate = async (locationDetail: LocationDetail): Promise<MutationResult<LocationDetailResponse>> => {
    const payload = mapPayloadToLocationDetail(locationDetail);
    const response = await mutation.mutateAsync(payload);
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useEditLocationCcavModality;
