import React, { ReactNode } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

function withQueryParams(Component: React.ComponentType<unknown>): React.ComponentType<unknown> {
  return function fn(): ReactNode {
    return (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Component />
      </QueryParamProvider>
    );
  };
}

export default withQueryParams;
