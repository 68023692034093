import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const ContainerIcon = ({
                                size = defaultIconProps.size,
                                fillColor = defaultIconProps.fillColor,
                              }: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={fillColor} />
        <path
          d="M13.375 14L13.0938 14.5625L11.0938 18.5625L11 18.75V19V33V34H12H14.5H15.125L15.4062 33.4375L15.625 33H32.375L32.5938 33.4375L32.875 34H33.5H36H37V33V19V18.75L36.9062 18.5625L34.9062 14.5625L34.625 14H34H32.5H31.875L31.5938 14.5625L31.375 15H16.625L16.4062 14.5625L16.125 14H15.5H14H13.375ZM14.625 16H14.875L15.0938 16.4375L15.375 17H16H32H32.625L32.9062 16.4375L33.125 16H33.375L34.375 18H33.5H32.875L32.5938 18.5625L32.375 19H15.625L15.4062 18.5625L15.125 18H14.5H13.625L14.625 16ZM13 20H13.875L14.0938 20.4375L14.375 21H15H33H33.625L33.9062 20.4375L34.125 20H35V32H34.125L33.9062 31.5625L33.625 31H33H15H14.375L14.0938 31.5625L13.875 32H13V20ZM17 22V30H19V22H17ZM21 22V30H23V22H21ZM25 22V30H27V22H25ZM29 22V30H31V22H29Z"
          fill="white" />
      </svg>
    </Container>
  );
};

export default ContainerIcon;
