import { Button, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { withCustomerItems, withContracts, withCustomerLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";

import DialogWrapper from "./ui/DialogWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import SelectUi from "./ui/Select";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import TextAreaUi from "./ui/TextArea";
import TextFieldUi from "./ui/TextField";
import Autocomplete from "./ui/Autocomplete";
import { orderBy } from "lodash";

const initialState = {
  errors: {
    customerLocationId: false,
    customerItemId: false,
  },
  contractId: "-1",
  customerLocationAddress: "-1",
  customerLocations: [],
  customerLocationFileNumber: "-1",
  customerLocationId: "-1",
  customerItemId: "-1",
  nbOfLifting: 1,
  note: "",
};

class ModalAddLifting extends PureComponent {
  state = {
    ...initialState,
  };

  async componentDidMount() {
    await this.fetchCustomerItems();
    await this.fetchCustomerLocations();
    this.filterCustomerLocations();
  }

  fetchCustomerItems = async () => {
    const { contractId, customerId, fetchCustomerItemsByContract } = this.props;
    const params = { limit: -1 };

    await fetchCustomerItemsByContract(contractId, customerId, params);
  };

  filterCustomerLocations = () => {
    const { customerLocationsLoading, customerLocations, selected } = this.props;

    if (!customerLocationsLoading) {
      const filteredCustomerLocation = customerLocations.filter(
        (cl) => !selected.map((e) => e.locationId).includes(cl.id));
      this.setState({ customerLocations: filteredCustomerLocation })
    }
  }

  fetchCustomerLocations = async () => {
    const { fetchCustomerLocations, router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    return await fetchCustomerLocations(customerId,
      { filter: "", limit: 5000, page: 1 }, contractId)
  };

  get valid() {
    const errors = { ...initialState.errors };
    const { customerItemId, customerLocationId } = this.state;
    let valid = true;

    if (customerItemId === "-1") {
      valid = false;
      errors.customerItemId = true;
    }
    if (customerLocationId === "-1") {
      valid = false;
      errors.customerLocationId = true;
    }

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleChangeCustomerLocationAndFileNumber = () => (selectedValue) => {
    const { value, name, fileNumber, note } = selectedValue;
    this.setState({
      errors: {
        ...this.state.errors,
        customerLocationId: false,
      },
      customerLocationAddress: { value, label: name },
      customerLocationFileNumber: { value, label: fileNumber },
      customerLocationId: value,
      note,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { selected, onClose, refreshList } = this.props;
      const { customerItemId, customerLocationId, note, nbOfLifting } = this.state;

      const newRouteLocation = {
        id: null,
        customer_item_id: customerItemId,
        location_id: customerLocationId,
        note,
        visit_rank: selected.length + 1,
        nb_of_additional_lifting: nbOfLifting,
      };

      // eslint-disable-next-line max-len
      const actualRouteLocations = selected.map(({ customerItemId, locationId, routeLocationId }) => ({
        id: routeLocationId || null,
        customer_item_id: customerItemId,
        location_id: locationId,
      }));

      const { routeId, updateRoute } = this.props;

      await updateRoute(routeId, {
        location_id: customerLocationId,
        update_location_note: note,
        route_locations_attributes: [...[newRouteLocation], ...actualRouteLocations],
      });
      this.setState(initialState);

      refreshList();
      onClose();
    }
  };

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem key={id} value={id}>
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderMenuItemsFileNumber = (label, data, key) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem key={id} value={{ value: id, label: remainingData.name }}>
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderLocationCustomerList = (data) =>
    orderBy(
      data.map(({ id, name, fileNumber, note }) => ({
        value: id,
        label: `${name}`,
        fileNumber,
        name,
        note,
      })),
      ["label"],
      ["asc"]
    );

  renderFileNumberList = (data) =>
    orderBy(
      data.map(({ id, name, fileNumber, note }) => ({
        value: id,
        label: `${fileNumber}`,
        name,
        fileNumber,
        note,
      })),
      ["label"],
      ["asc"]
    );

  render() {
    const {
      errors,
      customerItemId,
      customerLocation,
      note,
      nbOfLifting,
      customerLocationAddress,
      customerLocationFileNumber,
      customerLocations,
    } = this.state;

    const { customerItems, open } = this.props;
    return (
      <DialogWrapper id="add-lifting-modal" onClose={this.handleClose} open={open}>
        <DialogTitle>
          <Trans i18nKey="routes.add_lifting" />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <Autocomplete
                label={<Trans i18nKey="Numéro de dossie)" />}
                id="cpbr-location-for-file-number"
                value={customerLocationFileNumber}
                options={this.renderFileNumberList(customerLocations)}
                onChange={this.handleChangeCustomerLocationAndFileNumber(customerLocation)}
                placeholder="Rechercher un numéro de dossier"
                dropDownMenuPortalTarget="#contract-location-modal"
              />
            </FormGroupWrapper>
            <FormGroupWrapper>
              <Autocomplete
                label={<Trans i18nKey="place" />}
                id="cpbr-location-for-contract"
                value={customerLocationAddress}
                options={this.renderLocationCustomerList(customerLocations)}
                onChange={this.handleChangeCustomerLocationAndFileNumber(customerLocation)}
                placeholder="Rechercher l'emplacement"
                dropDownMenuPortalTarget="#contract-location-modal"
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <SelectUi
                error={errors.customerItemId}
                formControlError={errors.customerItemId}
                formHelperErrorMsg={this.getErrorMessage("customerItemId")}
                id="cpbr-customer-item"
                inputLabelText={<Trans i18nKey="customer_item" />}
                onChange={this.handleChangeFields("customerItemId")}
                value={`${customerItemId}`}
              >
                {this.renderMenuItems(<Trans i18nKey="select_customer_item" />, customerItems, "name")}
              </SelectUi>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                inputProps={{ min: 1 }}
                type="number"
                helperText={this.getErrorMessage("note_item_contract")}
                label={<Trans i18nKey="nb_lifting" />}
                value={nbOfLifting}
                onChange={(event) => this.setState({ nbOfLifting: event.target.value })}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                id="cpbr-note-comments"
                label={<Trans i18nKey="preparation.note_location" />}
                onChange={this.handleChangeFields("note")}
                value={note || ""}
              />
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            <Trans i18nKey="add" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalAddLifting.defaultProps = {
  customerLocationsLoading: true,
};

ModalAddLifting.propTypes = {
  customerItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  routeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateRoute: PropTypes.func.isRequired,
  fetchCustomerItemsByContract: PropTypes.func.isRequired,
};

// eslint-disable-next-line max-len
export default (withCustomerLocations(withCustomerItems(withContracts(withRouter(ModalAddLifting)))));
