import { Tab, Tooltip } from "@mui/material";
import { withCustomerItem } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import Breadcrumbs from "./Breadcrumbs";
import FlexHeaderRowWrapper from "./ui/FlexHeaderRowWrapper";
import HeaderLabel from "./ui/HeaderLabel";
import HeaderContextWrapper from "./ui/HeaderContextWrapper";
import HeaderColumnWrapper from "./ui/HeaderColumnWrapper";
import JobsList from "./JobsList";
import PageHeader from "./ui/PageHeader";
import RecurrencesList from "./RecurrencesList";
import TabsWrapper from "./ui/TabsWrapper";
import RouteTemplatesList from "./RouteTemplatesList";
import RoutesList from "./RoutesList";
import { withRouter } from "@utils/withRouter";

const BundleNote = styled.div`
  max-width: 550px;
  margin-top: 15px;
  font-style: italic;
`;

class CustomerItem extends PureComponent {
  state = {
    currentTab: 0,
    isAFrontLoader: [true, false],
  };

  componentDidMount() {
    this.handleContractType();
    this.currentTab();
    this.fetchCustomerItem();
  }

  componentDidUpdate(prevProps) {
    this.handleContractType();
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      this.fetchCustomerItem();
      this.currentTab();
    }
  }

  currentTab = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentTab = queryParams.get("current_tab");
    if (currentTab) {
      this.setState({ currentTab: parseInt(currentTab) });
    }
  };

  fetchCustomerItem = () => {
    const { fetchCustomerItem, router } = this.props;

    fetchCustomerItem(router.params.customerItemId);
  };

  handleContractType = () => {
    const { customerItem } = this.props;
    const { contractType } = customerItem;

    if (contractType === 'route') {
      this.setState({ isAFrontLoader: true })
    } else {
      this.setState({ isAFrontLoader: false })
    }
  }

  handleChangeTab = (_, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  renderBreadCrumbs = () => {
    const { router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    if (contractId && customerId) {
      return (
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}`}>
              <Trans i18nKey="contract_and_contact" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}`}>
              <Trans i18nKey="contract" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="customer_item" />
          </li>
        </Breadcrumbs>
      );
    }

    return (
      <Breadcrumbs>
        <li>
          <Link to="/preparations">
            <Trans i18nKey="preparations" />
          </Link>
        </li>
        <li>
          <Trans i18nKey="tasks" />
        </li>
      </Breadcrumbs>
    );
  };

  renderPageHeader = () => {
    const { customerItem, customerItemLoading } = this.props;

    if (customerItemLoading) {
      return null;
    }

    const {
      addressSimple,
      bundleNote,
      containerKindName,
      customerName,
      contractNo,
      itemIdentifier,
      locationName,
      name,
      startDate,
    } = customerItem;

    const location = addressSimple ? (
      <HeaderColumnWrapper>
        <HeaderLabel>{locationName}</HeaderLabel>
        {addressSimple}
        <br />
        [Arrondissement]
      </HeaderColumnWrapper>
    ) : (
      ""
    );
    const item = itemIdentifier ? (
      <HeaderColumnWrapper>
        <HeaderLabel>Conteneur {itemIdentifier}</HeaderLabel>
        {containerKindName}
      </HeaderColumnWrapper>
    ) : (
      ""
    );
    const note = bundleNote ? <BundleNote>{bundleNote}</BundleNote> : "";

    return (
      <PageHeader titleText={name}>
        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <HeaderLabel>Contrat #{contractNo}</HeaderLabel>
            {customerName}
            <br />
            Travaux débutent le {startDate}
            {note}
          </HeaderColumnWrapper>
          {location}
          {item}
        </FlexHeaderRowWrapper>
      </PageHeader>
    );
  };

  renderTabContainer = () => {
    if (!this.props.customerItem.id) {
      return null;
    }

    let Content = null;

    switch (this.state.currentTab) {
      case 0: {
        Content = JobsList;
        break;
      }
      case 1: {
        Content = RecurrencesList;
        break;
      }
      case 2: {
        Content = RoutesList;
        break;
      }
      case 3: {
        Content = RouteTemplatesList;
        break;
      }
      default: {
        Content = null;
        break;
      }
    }

    return <Content {...this.props} refresh={this.fetchCustomerItem} />;
  };

  render() {
    const { isAFrontLoader } = this.state;
    const { isPaymentMethodCreditCard } = this.props.customerItem;
    return (
      <div>
        {this.renderBreadCrumbs()}

        <HeaderContextWrapper>{this.renderPageHeader()}</HeaderContextWrapper>

        {/*Montre les tabs d'un contrat de job*/}
        {isAFrontLoader || (<TabsWrapper
          indicatorColor="primary"
          onChange={this.handleChangeTab}
          textColor="primary"
          value={this.state.currentTab}
        >
          <Tab label={<Trans i18nKey="tasks" />} />
          <Tab label={<Trans i18nKey="recurrence" />} />
        </TabsWrapper>)}

        {/*Montre les tabs des contrat de route*/}
        {isAFrontLoader && (<TabsWrapper
          indicatorColor="primary"
          onChange={this.handleChangeTab}
          textColor="primary"
          value={this.state.currentTab}
        >
          <Tab label={<Trans i18nKey="tasks" />} />
          <Tab label={<Trans i18nKey="recurrence" />} />
          <Tab label={<Trans i18nKey="routes.assignation" />} />
          <Tab label={<Trans i18nKey="route_templates.conception" />} />
        </TabsWrapper>)}

        {this.renderTabContainer()}
      </div>
    );
  }
}

CustomerItem.defaultProps = {
  customerItemLoading: true,
};

CustomerItem.propTypes = {
  customerItem: PropTypes.object.isRequired,
  customerItemLoading: PropTypes.bool,
  fetchCustomerItem: PropTypes.func.isRequired,
};

export default withRouter(withCustomerItem(CustomerItem));
