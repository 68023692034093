import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Button, Checkbox, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import styled from "@emotion/styled";

import { MapErrorView, MapLoadingSpinner, withGoogleMap } from "@utils/withGoogleMap";
import { MapContentWrapper } from "@styles/globalStyle";
import MapSideDrawer, { componentToLoad } from "../mapSideDrawer/MapSideDrawer";
import { GoogleMap } from "@react-google-maps/api";
import MapMarker, { MarkerIcon } from "@components/maps/MapMarker";

import * as API from "@services";

import { withRouter } from "@utils/withRouter";
import { withListCodes } from "optigo-redux";

import ModalEditLocationContractAddress from "@components/ModalEditLocationContractAddress";
import ModalEditLocation from "@components/ModalEditLocation";
import { offsetLegacyAddressesOnSameSpot } from "@utils/offsetMarkers";

const MapHeader = styled.div`
  width: 75%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MapHeaderIconButton = styled(IconButton)`
  && {
    padding: 0px 5px;
    transform: scale(1.2);
    color: ${({ theme }) => theme.colors.icon.default};

    &:hover {
      transform: scale(1.35);
      transition: transform 0.1s;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.icon.hover};
    }
  }
`;

const MapHeaderBtn = styled(Button)`
  && {
    padding: 0px 10px;

    &:hover {
      transform: scale(1.02);
      transition: transform 0.02s;
      background-color: transparent;
    }
  }
`;

const MapHeaderPositionBtn = styled(Button)`
  && {
    padding: 0px 5px;

    &:hover {
      background-color: transparent;
    }
  }
`;

const handleMovePositionCheckbox = (setChangePositionCheckBox) => setChangePositionCheckBox((prevState) => !prevState);

const handleChangeLocationPosition = async (
  location,
  setChangePositionCheckBox,
  refreshData,
) => {
  const { lat, long, id } = location;
  setChangePositionCheckBox(false);
  await API.Customer.changeLocationPosition(id, { lat, lng: long });
  refreshData();
};

const showMapSideDrawer = (isMapSideDrawerOpen, setIsMapSideDrawerOpen) => setIsMapSideDrawerOpen(!isMapSideDrawerOpen);

const changeMapType = (mapType, setMapType) => setMapType(mapType);

const hideMapSideDrawer = (setIsMapSideDrawerOpen) => setIsMapSideDrawerOpen(false);

const handleToggleModalEditLocationContractAddress = (
  showModalEditLocationContractNameAddress,
  setShowModalEditLocationContractNameAddress,
) => {
  setShowModalEditLocationContractNameAddress(!showModalEditLocationContractNameAddress);
};

const handleToggleModalEditLocation = (showModalEditLocation, setShowModalEditLocation) => {
  setShowModalEditLocation(!showModalEditLocation);
};

const handleMarkerOnDragEnd = (locationId, setLocationsIndexedById) => (event) => {
  const { latLng } = event;
  const newLat = latLng.lat();
  const newLng = latLng.lng();

  setLocationsIndexedById((prev) => ({
    ...prev,
    [locationId]: {
      ...prev[locationId],
      lat: newLat,
      long: newLng,
    },
  }));
};

const changeSelectedLocation = (selectedLocationId, setSelectedLocation, setLatitudeLongitudeLocation) => {
  setSelectedLocation(selectedLocationId);
  setLatitudeLongitudeLocation((prevState) => ({
    ...prevState,
    selectedLocationId: selectedLocationId,
  }));
};

function LocationMap(props) {
  const { customerLocation, refreshData, googleMap, router, setSelectedLocation } = props;
  const [isMapSideDrawerOpen, setIsMapSideDrawerOpen] = useState(true);
  const [mapType, setMapType] = useState("satellite");
  const [changePositionCheckBox, setChangePositionCheckBox] = useState(false);
  const [showModalEditLocationContractNameAddress, setShowModalEditLocationContractNameAddress] = useState(false);
  const [showModalEditLocation, setShowModalEditLocation] = useState(false);
  const [latitudeLongitudeLocation, setLatitudeLongitudeLocation] = useState({
    selectedLocationId: "",
    lat: 0,
    initialLat: 0,
    lng: 0,
    initialLng: 0,
    center: 0,
    initialCenter: 0,
  });
  const { isLoaded, loadError } = googleMap;
  const [locationsIndexedById, setLocationsIndexedById] = useState({});

  const mapProps = {
    mapContainerStyle: { width: "100%" },
    center: latitudeLongitudeLocation["center"],
    zoom: 16,
    clickableIcons: false,
    mapTypeId: mapType,
    options: { mapTypeControl: false, streetViewControl: true },
  };

  const { residentialPoiOfCollectPois } = customerLocation;

  let path = null;
  if (residentialPoiOfCollectPois) {
    const { params } = router;
    const { id } = residentialPoiOfCollectPois;
    const { contractId, customerId } = params;
    path = `/customers/${customerId}/contracts/${contractId}/locations/${id}`;
  }

  const sideDrawerLocationItemData = {
    isRollOff: !customerLocation.isAFrontLoading,
    isMapSideDrawerOpen: isMapSideDrawerOpen,
    hideMapSideDrawer: () => hideMapSideDrawer(setIsMapSideDrawerOpen),
    sideDrawerLeftTabContent: customerLocation,
    sideDrawerRightTabContent: customerLocation,
    sideDrawerLeftTabEditButtonAction: () =>
      setShowModalEditLocationContractNameAddress(!showModalEditLocationContractNameAddress),
    sideDrawerRightTabEditButtonAction: () => setShowModalEditLocation(!showModalEditLocation),
    selectedLocationId: latitudeLongitudeLocation["selectedLocationId"],
    changeSelectedLocation: (locationID) =>
      changeSelectedLocation(locationID, setSelectedLocation, setLatitudeLongitudeLocation),
    path: path,
  };
  const setLatitudeLongitudeFirstSelectedLocation = () => {
    if (customerLocation) {
      setLatitudeLongitudeLocation({
        selectedLocationId: customerLocation.id,
        lat: +customerLocation.lat,
        initialLat: +customerLocation.lat,
        lng: +customerLocation.long,
        initialLng: +customerLocation.long,
        center: { lat: +customerLocation.lat, lng: +customerLocation.long },
        initialCenter: { lat: +customerLocation.lat, lng: +customerLocation.long },
      });
    }
    setSelectedLocation(customerLocation.id);
  };

  useEffect(() => {
    setLatitudeLongitudeFirstSelectedLocation();
  }, []);

  useEffect(() => {
    if (changePositionCheckBox === true) {
      const { initialLat, initialLng, initialCenter, selectedLocationId } = latitudeLongitudeLocation;
      setLatitudeLongitudeLocation({
        ...latitudeLongitudeLocation,
        lat: initialLat,
        lng: initialLng,
        center: initialCenter,
      });
      setSelectedLocation(selectedLocationId);
    }
  }, [changePositionCheckBox]);

  useEffect(() => {
    const { relatedLocations, id } = customerLocation;

    const newLocations = {
      [customerLocation.id]: {
        id: customerLocation.id,
        long: parseFloat(customerLocation.long),
        lat: parseFloat(customerLocation.lat),
      },
    };

    relatedLocations.forEach(location => {
      newLocations[location.id] = {
        id: location.id,
        long: parseFloat(location.long),
        lat: parseFloat(location.lat),
      };
    });
    setLocationsIndexedById(newLocations);
  }, []);

  const renderMapMarkers = (locations) => {
    const { selectedLocationId } = latitudeLongitudeLocation;
    let collectMapMarker = [];
    let residentialMapMarker = [];

    collectMapMarker = offsetLegacyAddressesOnSameSpot(Object.values(locations)).map((location) => {
      return (
        <MapMarker
          isSelected={selectedLocationId === location.id}
          key={location.id}
          icon={MarkerIcon.location}
          draggable={changePositionCheckBox}
          onDragEnd={handleMarkerOnDragEnd(location.id, setLocationsIndexedById)}
          position={{ lat: +location.lat, lng: +location.long }}
          onClick={() => changeSelectedLocation(location.id, setSelectedLocation, setLatitudeLongitudeLocation)}
        />
      );
    });

    return [...(residentialMapMarker || []), ...(collectMapMarker || [])];
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <MapHeader>
          <MapHeaderIconButton
            size="large"
            disableRipple
            onClick={() => showMapSideDrawer(isMapSideDrawerOpen, setIsMapSideDrawerOpen)}
          >
            <MenuIcon />
          </MapHeaderIconButton>

          <div>
            <MapHeaderBtn
              sx={{ color: "black" }}
              onClick={() => changeMapType("satellite", setMapType)}
            >
              Satellite
            </MapHeaderBtn>
            <MapHeaderBtn
              sx={{ color: "black" }}
              onClick={() => changeMapType("terrain", setMapType)}
            >
              Terrain
            </MapHeaderBtn>
            <MapHeaderBtn
              sx={{ color: "black" }}
              onClick={() => changeMapType("roadmap", setMapType)}
            >
              Carte routière
            </MapHeaderBtn>
            <MapHeaderBtn
              sx={{ color: "black" }}
              onClick={() => changeMapType("hybrid", setMapType)}
            >
              Hybride
            </MapHeaderBtn>

            <MapHeaderPositionBtn
              disableRipple
              disableFocusRipple
              onClick={() => handleMovePositionCheckbox(setChangePositionCheckBox)}
              disabled={customerLocation.isFromCiWeb}
            >
              <Checkbox checked={changePositionCheckBox} />
              Déplacer la position
            </MapHeaderPositionBtn>
            <MapHeaderIconButton
              disabled={!changePositionCheckBox}
              disableRipple
              size="small"
              aria-label="Save"
              onClick={() =>
                handleChangeLocationPosition(
                  locationsIndexedById[+latitudeLongitudeLocation.selectedLocationId],
                  setChangePositionCheckBox,
                  refreshData,
                )
              }
            >
              <SaveIcon />
            </MapHeaderIconButton>
          </div>
        </MapHeader>

        <MapContentWrapper>
          {!loadError ? (
            isLoaded ? (
              <>
                <MapSideDrawer
                  componentToLoad={componentToLoad.locationItem}
                  componentDataToLoad={sideDrawerLocationItemData}
                />
                <GoogleMap {...mapProps}>{renderMapMarkers(locationsIndexedById)}</GoogleMap>
              </>
            ) : (
              <MapLoadingSpinner />
            )
          ) : (
            <MapErrorView />
          )}
        </MapContentWrapper>
      </div>

      {showModalEditLocationContractNameAddress && (
        <ModalEditLocationContractAddress
          location="location"
          customerLocation={customerLocation}
          onClose={() =>
            handleToggleModalEditLocationContractAddress(
              showModalEditLocationContractNameAddress,
              setShowModalEditLocationContractNameAddress,
            )
          }
          open={() =>
            handleToggleModalEditLocationContractAddress(
              showModalEditLocationContractNameAddress,
              setShowModalEditLocationContractNameAddress,
            )
          }
          refreshData={refreshData}
        />
      )}

      {showModalEditLocation && (
        <ModalEditLocation
          customerLocation={customerLocation}
          onClose={() => handleToggleModalEditLocation(showModalEditLocation, setShowModalEditLocation)}
          open={() => handleToggleModalEditLocation(showModalEditLocation, setShowModalEditLocation)}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

export default withListCodes(withRouter(withGoogleMap(LocationMap)));
