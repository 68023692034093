import React, { Dispatch, FC, SetStateAction } from "react";
import { Trans } from "react-i18next";

import LocationDetail, {
  DayModality,
} from "@@types/location-details/location-detail.type";
import LocationModalityModalActionbuttons from "@components/location/route/modality/modals/LocationModalityModalActionbuttons";
import LocationModalityModalDate from "@components/location/route/modality/modals/LocationModalityModalDate";
import LocationModalityModalDays from "@components/location/route/modality/modals/LocationModalityModalDays";
import LocationModalityModalFrequency from "@components/location/route/modality/modals/LocationModalityModalFrequency";
import LocationModalityModalMatter from "@components/location/route/modality/modals/LocationModalityModalMatter";
import { DialogContent, DialogTitle, Stack } from "@mui/material";
import DialogWrapper from "@ui/DialogWrapper";

type LocationModalityModalProps = {
  openModal: boolean;
  modality: LocationDetail;
  weekDays: DayModality[];
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>;
  modalType: string;
  onMutate: (modality: LocationDetail) => void;
  onCancel: (modalType: string) => void;
};

const LocationModalityModal: FC<LocationModalityModalProps> = ({
  openModal,
  modality,
  weekDays,
  setModality,
  modalType,
  onCancel,
  onMutate,
}) => {
  return (
    <DialogWrapper
      width={40}
      open={openModal}
    >
      <DialogTitle>
        {modalType === "create" ? <Trans i18nKey="add" /> : <Trans i18nKey="edit" />}
      </DialogTitle>

      <DialogContent>
        <Stack
          marginTop={2}
          spacing={4}
        >
          {/* MATTER TYPE */}
          <LocationModalityModalMatter
            modality={modality}
            setModality={setModality}
          />
          {/* FREQUENCY */}
          <LocationModalityModalFrequency
            modality={modality}
            setModality={setModality}
          />
          {/* DAYS OF WEEK + LIFTING COUNT */}
          <LocationModalityModalDays
            weekDays={weekDays}
            setModality={setModality}
          />

          {/* DATES SELECTORS */}
          <Stack
            direction="column"
            spacing={2}
          >
            <LocationModalityModalDate
              modalityDate={modality.from}
              dateType={"from"}
              label={"Date de début"}
              setModality={setModality}
            />
            <LocationModalityModalDate
              modalityDate={modality.until}
              dateType={"until"}
              label={"Date de fin"}
              setModality={setModality}
            />
          </Stack>
        </Stack>
      </DialogContent>

      {/* ACTION BUTTONS */}
      <LocationModalityModalActionbuttons
        modality={modality}
        onCancel={onCancel}
        onMutate={onMutate}
        modalType={modalType}
      />
    </DialogWrapper>
  );
};

export default LocationModalityModal;
