import * as API from "@services";
import { useQuery } from "@tanstack/react-query";
import unitKeys from "@hooks/units/unit.keys";

/**
 * Fetches units based on the provided filter states.
 * @param {Object} filterStates - An object containing the filter states.
 * @param {string} filterStates.filter - The filter string.
 * @param {number} filterStates.rowsPerPage - The number of rows per page.
 * @param {number} filterStates.page - The current page number.
 * @param {Object} filterStates.sort - The sorting information.
 * @param {string} filterStates.sort.columnName - The name of the column to sort by.
 * @param {string} filterStates.sort.direction - The direction of the sorting ('asc' or 'desc').
 * @returns {Object} - The result of the query.
 */
const useFetchUnits = (filterStates = {}) => {
  filterStates = { filter: "", rowsPerPage: 10, page: 0, sort: { columnName: "", direction: "" }, ...filterStates };

  return useQuery({
    queryKey: unitKeys.list(filterStates),
    queryFn: () => API.Unit.fetchUnits(filterStates),
    keepPreviousData: true,
  });
};

export default useFetchUnits;
