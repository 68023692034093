// React imports
import React, { FC } from "react";
// Externals imports
import { Tab, Tabs } from "@mui/material";

type ContainerLiftingRequestsModalTabsProps = {
  liftingRequestTabValue: number;
  handleChangeTabRequestLifting: (event: unknown, tabValue: number) => void;
};

const ContainerLiftingRequestsModalTabs: FC<ContainerLiftingRequestsModalTabsProps> = ({
  liftingRequestTabValue,
  handleChangeTabRequestLifting,
}) => {
  return (
    <Tabs
      value={liftingRequestTabValue}
      onChange={handleChangeTabRequestLifting}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
    >
      <Tab
        label="Future"
        value={1}
      />
      <Tab
        label="Passée"
        value={2}
      />
    </Tabs>
  );
};

export default ContainerLiftingRequestsModalTabs;
