import LocationDetail from "@@types/location-details/location-detail.type";
import React, { Dispatch, SetStateAction } from "react";
import { SelectChangeEvent } from "@mui/material";

export type LocationModalityFrequency = {
  handleChangeFrequencyValue: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ) => void;
};

const useLocationModalityFrequency = (
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>
): LocationModalityFrequency => {
  const handleChangeFrequencyValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ): void => {
    setModality((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          frequency: {
            label: parseInt(event.target.value) > 1 ? `Aux ${event.target.value} semaines` : "Chaque semaine",
            value: parseInt(event.target.value),
            error: false,
          },
        };
      }
    });
  };

  return {
    handleChangeFrequencyValue,
  } as LocationModalityFrequency;
};

export default useLocationModalityFrequency;
