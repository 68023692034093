import LocationCcavContainerResponse from "@@types/containers/location-ccav-container-response.type";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";

function mapResponseToLocationCcavContainer(response: LocationCcavContainerResponse): LocationCcavContainer {
  return {
    id: response.id,
    ciWebLabelcode: response.ciWebLabelcode,
    ciWebChipcode: response.ciWebChipcode,
    ciWebStatus: response.ciWebStatus,
    memo: response.memo,
    model: response.model,
    volume: response.volume,
    matterType: response.matterType,
    containerProfileId: response.containerProfileId,
    error: false,
  };
}

function mapResponseToLocationCcavContainers(responses: LocationCcavContainerResponse[]): LocationCcavContainer[] {
  return responses.map(mapResponseToLocationCcavContainer);
}

export default mapResponseToLocationCcavContainers;
