import { LiftingRequestResponse } from "@@types/lifting-requests/lifting-request-response.type";
import { LiftingRequest } from "@@types/lifting-requests/lifting-request.type";

function mapResponseToLiftingRequest(response: LiftingRequestResponse): LiftingRequest {
  return {
    id: response.id,
    routeName: response.routeName,
    liftingDate: response.liftingDate,
    whitelisted: response.whitelisted,
    registrationType: response.registrationType,
    category: response.category,
    createdAt: response.createdAt,
  };
}

function mapResponseToLiftingRequests(responses: LiftingRequestResponse[]): LiftingRequest[] {
  return responses.map(mapResponseToLiftingRequest);
}

export default mapResponseToLiftingRequests;
