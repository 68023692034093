import React, { ReactElement, ReactNode } from "react";

import * as Sentry from "@sentry/react";
import TextField from "@ui/TextField";

interface SentryErrorBoundaryProps {
  children: ReactNode;
}

const SentryErrorBoundary = ({ children }: SentryErrorBoundaryProps): ReactElement => {
  return (
    <Sentry.ErrorBoundary fallback={<TextField>Une erreur s&apos;est produite.</TextField>}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
