import axios from "axios";
import { requestOptions } from "../auth";

const fetchUnitWithAssignments = async (unitID, date = new Date().toISOString().slice(0, 10)) => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/units/${unitID}?date=${date}`, requestOptions());

    return response.data?.data?.attributes ?? null;
  } catch (error) {
    console.warn("[fetch-unit-with-assignments.service] error:", error);
  }
};

export default fetchUnitWithAssignments;
