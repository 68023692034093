import { useEffect, useRef } from "react";

/**
 * Hook that allows us to get the previous value of a state. Useful especially when we
 * want to compare a state with the previous one inside of a useEffect that has that same
 * state as a dependency, but for which we don't want to always trigger.
 * @param {*} value State
 * @returns Previous state
 */
export default function usePrevious(value) {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
