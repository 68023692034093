// React imports
import React, { useEffect, useState } from "react";

// Internal imports
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";
import CustomerForm from "@components/customer/CustomerForm";
import CustomerDetails from "@components/customer/CustomerDetails";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";
import * as API from "@services";

// External iports
import { Check } from "@mui/icons-material";
import { DialogTitle, Step, StepLabel, Stepper, Stack, Box } from "@mui/material";

/**
 * Modal for creating a new customer.
 * @param {Function} createCustomer Optigo-Redux function that handles the creation of a customer
 */
function ModalCustomerNew({ createCustomer }) {
  const [activeStep, setActiveStep] = useState(0);

  const { customer, isLoading, handlers } = useCustomerContext();

  const steps = [
    {
      label: "Création d'un client",
      component: <CustomerForm setActiveStep={setActiveStep} />,
    },
    {
      label: "Informations de vente",
      component: (
        <CustomerDetails>
          <Box width="100%" height="100%" display="flex" justifyContent="flex-end" p={1}>
            <LoadingButton
              loading={isLoading}
              startIcon={<Check />}
              onClick={handlers.handleSubmitCustomerDetails}
              loadingPosition="start"
            >
              Terminer
            </LoadingButton>
          </Box>
        </CustomerDetails>
      ),
    },
  ];

  return (
    <DialogWrapper onClose={() => handlers.handleToggleModalCustomer("")} open={customer.currentlyOpenModal === "new"}>
      <DialogTitle>Ajouter un client</DialogTitle>

      <Stepper activeStep={activeStep} sx={{ padding: 3 }}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {steps[activeStep].component}
    </DialogWrapper>
  );
}

export default ModalCustomerNew;
