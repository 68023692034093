import styled from "@emotion/styled";
import React from "react";

const NoMatchContainer = styled.div`
  flex: 1;
  width: 100%;
  min-height: 300px;
  padding: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const NoMatchTitle = styled.p`
  color: red;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
`;

const NoMatch = () => {
  return (
    <NoMatchContainer>
      <NoMatchTitle>Oups, une erreur s'est produite !</NoMatchTitle>
      Essayez de rafraîchir la page.
      <br />
      Si l'erreur se reproduit, contactez le support technique.
    </NoMatchContainer>
  );
};

export default NoMatch;
