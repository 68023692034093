// React imports
import React, { memo, useCallback } from "react";

// Internal imports
import visa_logo from "@assets/images/stripe/visa_logo.png";
import mastercard_logo from "@assets/images/stripe/mastercard_logo.png";

// External iports
import { isEqual } from "lodash";
import { Stack, Typography, FormControl, MenuItem, Select } from "@mui/material";

import useToggle from "@hooks/useToggle";

const arePaymentMethodSelectPropsEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.paymentMethod, nextProps.paymentMethod)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.isVisible, nextProps.isVisible)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.paymentMethods, nextProps.paymentMethods)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

const PaymentMethodSelect = ({ isVisible, paymentMethod, paymentMethods, onChangePaymentMethod }) => {
  const paymentMethodHandler = useToggle(false);
  const onChange = useCallback((event) => {
    paymentMethodHandler.setState.toggle();
    onChangePaymentMethod(event.target.value);
  }, []);

  if (!isVisible) return null;

  return (
    <Stack direction="row" justifyContent="flex-end" mt="-10px">
      <FormControl variant="standard">
        <Select
          onOpen={paymentMethodHandler.setState.on}
          onClose={paymentMethodHandler.setState.off}
          value={paymentMethod || ""}
          open={paymentMethodHandler.state}
          onChange={onChange}
          label="Méthode de paiement"
          sx={{
            minWidth: "210px",
            ".MuiInput-input": { paddingRight: "16px !important" },
            ".MuiSelect-icon": { marginRight: "-5px" },
          }}
        >
          {paymentMethods.map((paymentMethod) => {
            return (
              <MenuItem key={paymentMethod.id} value={paymentMethod}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img
                    src={paymentMethod?.card?.brand === "visa" ? visa_logo : mastercard_logo}
                    alt="Logo"
                    height={21}
                    width={32}
                    style={
                      paymentMethod?.card?.brand === "mastercard"
                        ? { border: "1px solid #ABABAB", borderRadius: 6 }
                        : { border: "1px solid transparent" }
                    }
                  />

                  <Stack direction="row" spacing={1} alignItems="flex-end">
                    <Typography lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
                      ****
                    </Typography>
                    <Typography lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
                      ****
                    </Typography>
                    <Typography lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
                      ****
                    </Typography>
                    <Typography letterSpacing={3} variant="body1" fontWeight="bold" lineHeight={1} color="black">
                      {paymentMethod?.card?.last4}
                    </Typography>
                  </Stack>
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default memo(PaymentMethodSelect, arePaymentMethodSelectPropsEqual);
