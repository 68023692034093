import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import TableFilterStates from "@@types/common/table-filter-states.type";
import RouteHistoryResponse from "@@types/routes/route-history-response.type";

const fetchLocationRoutesHistoryService = async (
  locationId: number,
  filterStates: TableFilterStates
): Promise<ApiResponse<RouteHistoryResponse[]>> => {
  try {
    const { filter = "", rowsPerPage = 10, page = 0 } = filterStates;

    const res = await http.get<ApiResponse<RouteHistoryResponse[]>>(
      `/locations/${locationId}/routes_history.json?page=${page + 1}&limit=${rowsPerPage}&filter=${filter}`
    );

    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchLocationRoutesHistoryService;
