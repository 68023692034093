import { useQuery, UseQueryResult } from "@tanstack/react-query";
import SupplierKeys from "@hooks/suppliers/supplier.keys";
import fetchSuppliersList from "../../services/supplier-locations/fetch-suppliers-list.service";
import { mapResponseToSuppliers } from "@@types/suppliers/mappers/mapResponseToSupplier.mapper";
import { Supplier } from "@@types/suppliers/supplier.type";
import { SupplierResponse } from "@@types/suppliers/supplier-response.type";

const useFetchSuppliersList = (): UseQueryResult<SupplierResponse[]> => {
  return useQuery({
    queryKey: SupplierKeys.all(),
    queryFn: () => fetchSuppliersList(),
    select: (responses): Supplier[] => mapResponseToSuppliers(responses || []),
  });
};
export default useFetchSuppliersList;
