import AddIcon from "@mui/icons-material/Add";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { debounce } from "lodash";
import { withCustomerLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import FloatingActionButton from "./ui/FloatingActionButton";
import ModalCustomerLocation from "./ModalCustomerLocation";
import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TextFieldUi from "./ui/TextField";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";

const data = {
  adr1: { label: <Trans i18nKey="address.adr_normal" /> },
  city: { label: <Trans i18nKey="address.city" /> },
  name: { label: <Trans i18nKey="name_simple" /> },
  postalCode: { label: <Trans i18nKey="address.postal_code" /> },
  doorNo: { label: <Trans i18nKey="address.door_no" /> },
};

class CustomerLocationsList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "identifier",
    direction: "asc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    customerLocationModalOpened: false,
    customerLocationToEdit: {},
    customerLocationToDelete: null,
    deleteCustomerLocationModalOpened: false,
    sort: {
      ...this.initialSort,
    },
  };

  state = {
    ...this.initialState,
  };

  componentDidMount() {
    this.fetchCustomerLocations();
  }

  componentWillUnmount() {
    this.props.flushCustomerLocations();
  }

  debouncedFetchCustomerLocations = debounce(() => {
    this.fetchCustomerLocations();
  }, 300);

  fetchCustomerLocations = () => {
    const { filter, limit, page } = this.state;
    const { fetchCustomerLocationsListGroupBy, router } = this.props;
    const { params } = router;
    const { contractId, customerId } = params;

    // eslint-disable-next-line max-len
    fetchCustomerLocationsListGroupBy(customerId, { filter: filter.trim(), page: page + 1, limit }, contractId);
  };

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchCustomerLocations
    );
  };

  handleDeleteCustomerLocation = async () => {
    const { deleteLocation, router } = this.props;

    await deleteLocation(router.params.customerId, this.state.customerLocationToDelete);

    const { customerLocationToDelete, deleteCustomerLocationModalOpened } = this.initialState;

    this.setState(
      {
        customerLocationToDelete,
        deleteCustomerLocationModalOpened,
      },
      this.fetchCustomerLocations
    );
  };

  handleFilter = handleFilter(this.debouncedFetchCustomerLocations).bind(this);
  handlePageChange = handlePageChange(this.fetchCustomerLocations).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchCustomerLocations).bind(this);
  handleSort = handleSort.bind(this);

  handleToggleCustomerLocationModal = (opened, customerLocation) => (e) => {
    // eslint-disable-next-line max-len
    if (opened) {
      e.stopPropagation();
    }
    const customerLocationToEdit = opened ? customerLocation : this.initialState.customerLocationToEdit;

    this.setState({
      customerLocationModalOpened: opened,
      customerLocationToEdit,
    });
  };

  handleNavigate = (path) => (e) => {
    const { router } = this.props;

    e.stopPropagation();
    router.navigate(path);
  };

  handleToggleDeleteCustomerLocationModal = (opened, customerLocationId) => (e) => {
    if (opened) {
      e.stopPropagation();
    }
    const customerLocationToDelete = opened ? customerLocationId : this.initialState.customerLocationToDelete;

    this.setState({ deleteCustomerLocationModalOpened: opened, customerLocationToDelete });
  };

  handleNavigateToEditLocation = (path) => (e) => {
    const { router } = this.props;

    e.stopPropagation();

    router.navigate(path);
  };

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["name", "doorNo", "adr1", "postalCode", "city"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel active={columnName === name} direction={direction} onClick={this.handleSort(name)}>
                {data[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  renderTableRows = () => {
    const { customerLocations, customerLocationsLoading } = this.props;

    console.log("renderTableRows customerLocations:", customerLocations);

    if (customerLocationsLoading) {
      return <TableLoading />;
    }

    if (!customerLocations.length) {
      return <TableCellNoData />;
    }

    return sortedData(customerLocations, this.state).map((customerLocation) => {
      const { contract } = this.props;
      const { id, adr1, city, doorNo, name, postalCode, province, companyName, residentialPoiOfCollectPois } =
        customerLocation;

      let path = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}`;
      let pathEdit = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}/edit`;

      if (residentialPoiOfCollectPois) {
        //if there is a residential_poi AND some collect_poi in the list AND is from ci_web
        const { id } = residentialPoiOfCollectPois;
        path = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}`;
        pathEdit = `/customers/${contract.customerId}/contracts/${contract.id}/locations/${id}/edit`;
      }

      return (
        <TableRow
          key={id}
          onClick={this.handleNavigate(path)}
          sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        >
          <TableCell
            style={{
              overflowWrap: "break-word",
              width: "50%",
              whiteSpace: "normal",
            }}
          >
            {companyName && companyName.length > 1 ? companyName : name}
          </TableCell>
          <TableCell>{doorNo}</TableCell>
          <TableCell
            style={{
              overflowWrap: "break-word",
              width: "50%",
              whiteSpace: "normal",
            }}
          >
            {doorNo && city && province && postalCode
              ? `${adr1}`
              : `${doorNo || ""}, ${adr1 || ""}, ${city || ""}, ${province || ""}, ${postalCode || ""}`}
          </TableCell>
          <TableCell classes={{ root: "zipcode-cell" }}>{postalCode}</TableCell>
          <TableCell>
            {city}, {province}
          </TableCell>
          <TableCell classes={{ root: "action-cell" }} />
          <TableCell classes={{ root: "action-cell no-pointer" }}>
            <IconButton
              color="secondary"
              id="cpbr-delete-location"
              onClick={this.handleToggleDeleteCustomerLocationModal(true, id)}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { customerLocationToEdit, deleteCustomerLocationModalOpened, filter, limit, page } = this.state;

    const { contract } = this.props;
    const actionName = customerLocationToEdit.id ? <Trans i18nKey="edit" /> : <Trans i18nKey="add" />;

    return (
      <PageContainer>
        <PaperWrapper>
          <div>
            <FloatingActionButton
              color="secondary"
              onClick={
                // eslint-disable-next-line max-len
                this.handleToggleCustomerLocationModal(true, this.initialState.customerLocationToEdit)
              }
            >
              <AddIcon />
            </FloatingActionButton>
          </div>

          <TableOverflowWrapper>
            <Toolbar>
              <TextFieldUi
                id="cpbr-filtre"
                label={<Trans i18nKey="filter" />}
                onChange={this.handleFilter}
                type="search"
                value={filter}
              />
            </Toolbar>

            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>
          <TablePaginationWrapper
            component="div"
            count={this.props.customerLocationsCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />
        </PaperWrapper>

        <ModalCustomerLocation
          key={customerLocationToEdit.id}
          actionName={actionName}
          customerId={contract.customerId}
          contractId={contract.id}
          callback={this.fetchAndResetPagination}
          customerLocation={customerLocationToEdit}
          onClose={this.handleToggleCustomerLocationModal(false)}
          open={this.state.customerLocationModalOpened}
          refreshList={this.fetchAndResetPagination}
        />

        <ModalWarning
          onCancel={this.handleToggleDeleteCustomerLocationModal(false)}
          onSubmit={this.handleDeleteCustomerLocation}
          open={deleteCustomerLocationModalOpened}
          title={<Trans i18nKey="warning" />}
        >
          <Trans i18nKey="warning_delete_location" />
        </ModalWarning>
      </PageContainer>
    );
  }
}

CustomerLocationsList.defaultProps = {
  customerLocationsLoading: true,
};

CustomerLocationsList.propTypes = {
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLocationsLoading: PropTypes.bool,
  customerLocationsCount: PropTypes.number.isRequired,
  deleteLocation: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  flushCustomerLocations: PropTypes.func.isRequired,
};

export default withCustomerLocations(CustomerLocationsList);
