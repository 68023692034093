/**
 * Downloads a file using a Blob and a specified file name.
 *
 * @param {Blob} blob - The Blob representing the file content.
 * @param {string} fileName - The desired file name for the download.
 * @returns {void}
 */
const downloadFile = (blob: Blob, fileName: string): void => {
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export { downloadFile };
