import React, { useEffect } from "react";
import LoadingButton from "@ui/LoadingButton";
import DialogWrapper from "@ui/DialogWrapper";
import { ContentCopy } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, CircularProgress, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

function ModalJobCopy({ children, onCancel, onSubmit, open, title, loading, backdrop }) {
  const { Backdrop, message, isOpen: isBackdropOpen } = backdrop;

  return (
    <DialogWrapper id="task_copy" onClose={onCancel} open={open}>
      {isBackdropOpen && (
        <Backdrop>
          <Stack spacing={2} alignItems={"center"}>
            <Typography align="center" variant="h6">
              {message}
            </Typography>

            {loading ? <CircularProgress /> : <CheckCircleOutline sx={{ fontSize: 50 }} color="success" />}
          </Stack>
        </Backdrop>
      )}

      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ overflow: "visible", mt: 1 }}>{children}</DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Annuler
        </Button>

        <LoadingButton loading={loading} startIcon={<ContentCopy />} onClick={onSubmit} loadingPosition="start">
          Copier
        </LoadingButton>
      </DialogActions>
    </DialogWrapper>
  );
}

export default ModalJobCopy;
