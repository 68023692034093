import React, { Dispatch, FC, SetStateAction } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import useFetchListCodes from "@hooks/list-codes/fetch-list-codes.hook";
import useLocationModalityMatter from "@components/location/route/modality/modals/hooks/location-modality-modal-matter.hook";
import LocationDetail from "@@types/location-details/location-detail.type";

type LocationModalityModalMatterProps = {
  modality: LocationDetail;
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>;
};

const LocationModalityModalMatter: FC<LocationModalityModalMatterProps> = ({ modality, setModality }) => {
  const { data: listCodes = [] } = useFetchListCodes("CONTAINER_MATTER");
  const { handleChangeMatterValue } = useLocationModalityMatter(setModality);

  return (
    <FormControl fullWidth>
      <InputLabel>Type de matière</InputLabel>
      <Select
        error={modality.matter.error}
        label={"Type de matière"}
        value={modality.matter.value}
        name="matter"
        onChange={(event) => handleChangeMatterValue(listCodes, event)}
        fullWidth
      >
        {listCodes?.map((option) => {
          return (
            <MenuItem
              key={option.code}
              value={option.code}
            >
              {option.description}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LocationModalityModalMatter;
