import LocationDetail, { DayModality } from "@@types/location-details/location-detail.type";
import { Dispatch, SetStateAction } from "react";

export type LocationModalityDays = {
  handleSelectModalityDays: (weekDay: DayModality) => void;
};

const useLocationModalityDays = (
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>
): LocationModalityDays => {
  const handleSelectModalityDays = (weekDay: DayModality): void => {
    setModality((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [weekDay.label]: {
            ...weekDay,
            active: !weekDay.active,
            value: !weekDay.active ? 1 : 0,
            error: false,
          },
        };
      }
    });
  };

  return {
    handleSelectModalityDays,
  } as LocationModalityDays;
};

export default useLocationModalityDays;
