import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { debounce, orderBy } from "lodash";
import { PropTypes } from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRecurrences } from "optigo-redux";
import moment from "moment";
import { withRouter } from "@utils/withRouter";

import ModalRecurrence from "./ModalRecurrence";
import ModalWarning from "./ModalWarning";
import FloatingActionButton from "./ui/FloatingActionButton";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableCellNoData from "./ui/TableCellNoData";
import TableLoading from "./ui/TableLoading";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TextFieldUi from "./ui/TextField";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import EditIcon from "@mui/icons-material/Edit";
import ModalEditRecurrence from "@components/modals/recurrence/ModalEditRecurrence.tsx";
import updateRecurrence from "@services/recurrences/update-recurrence.service.ts";
import { formattedDate } from "@utils/dates.js";
import ModalConfirm from "@components/modals/recurrence/ModalConfirm.tsx";
import * as API from "@services";

const headerLabels = {
  code: { label: <Trans i18nKey="code" /> },
  type: { label: <Trans i18nKey="kind" /> },
  endDate: { label: <Trans i18nKey="end_date" /> },
  locationName: { label: <Trans i18nKey="location_or_route_name" /> },
  schedule: { label: <Trans i18nKey="schedule" /> },
  startDate: { label: <Trans i18nKey="start_date" /> },
  unitName: { label: <Trans i18nKey="jobs.unit" /> },
};

class RecurrencesList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "startDate",
    direction: "desc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    deleteRecurrenceModalOpened: false,
    recurrenceModalOpened: false,
    recurrenceToEdit: null,
    recurrenceToDelete: null,
    editRecurrenceModalOpened: false,
    warningEditRecurrenceOpened: false,
    units: [],
    sort: {
      ...this.initialSort,
    },
  };

  state = {
    ...this.initialState,
  };
  handleSort = handleSort.bind(this);

  componentDidMount() {
    this.fetchRecurrences();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
      this.fetchRecurrences();
      this.currentTab();
    }
  }

  componentWillUnmount() {
    this.props.flushRecurrences();
  }

  currentTab = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const currentTab = queryParams.get("current_tab");
    if (currentTab) {
      this.setState({ currentTab: parseInt(currentTab) });
    }
  };

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchRecurrences
    );
  };

  fetchRecurrences = () => {
    const { fetchCustomerItemRecurrences, router } = this.props;
    const { filter, limit, page } = this.state;

    // On va toujours chercher 5000 résultats et c'est le slice sur le sortedData qui va gérer la page
    fetchCustomerItemRecurrences(router.params.customerItemId, {
      filter: filter.trim(),
      page: 1,
      limit: 5000,
    });
  };

  debouncedFetchRecurrences = debounce(() => {
    this.fetchRecurrences();
  }, 300);
  handleFilter = handleFilter(this.debouncedFetchRecurrences).bind(this);
  handlePageChange = handlePageChange(this.fetchRecurrences).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchRecurrences).bind(this);

  handleToggleRecurrenceModal = (recurrenceModalOpened) => () => {
    this.setState({
      recurrenceModalOpened,
    });
  };

  handleToggleDeleteRecurrenceModal = (opened, recurrenceId) => () => {
    const recurrenceToDelete = opened ? recurrenceId : this.initialState.recurrenceToDelete;

    this.setState({ deleteRecurrenceModalOpened: opened, recurrenceToDelete });
  };

  handleDeleteRecurrence = async () => {
    const { deleteRecurrence, router } = this.props;

    await deleteRecurrence(router.params.customerItemId, this.state.recurrenceToDelete);

    const { deleteRecurrenceModalOpened, recurrenceToDelete } = this.initialState;

    this.setState(
      {
        deleteRecurrenceModalOpened,
        recurrenceToDelete,
      },
      this.fetchRecurrences
    );
  };

  handleToggleEditRecurrenceModal = (opened, recurrence) => async () => {
    if (opened) {
      const data = await API.Unit.fetchUnits({ rowsPerPage: "all" });
      const loadingType = recurrence.type === "Route" ? "AVANT" : "ROLL-OFF";
      const units = data.instances.filter((unit) => unit.loadingType === loadingType);
      const orderedUnits = orderBy(units, ["name", "asc"]);
      const recurrenceToEdit = opened ? recurrence : this.initialState.recurrenceToEdit;
      this.setState({
        editRecurrenceModalOpened: opened,
        recurrenceToEdit,
        units: orderedUnits,
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        editRecurrenceModalOpened: opened,
        recurrenceToEdit: { ...prevState.recurrenceToEdit, error: true },
      }));
    }
  };

  handleToggleWarningEditRecurrence = (opened) => () => {
    if (this.state.recurrenceToEdit.unitName) {
      this.setState({ warningEditRecurrenceOpened: opened });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        recurrenceToEdit: { ...prevState.recurrenceToEdit, error: true },
      }));
    }
  };

  handleEditRecurrence = (event) => {
    const unitName = event.target.value;
    this.setState((prevState) => ({ ...prevState, recurrenceToEdit: { ...prevState.recurrenceToEdit, unitName } }));
  };

  handleSaveEditRecurrence = async () => {
    const payload = {
      id: this.state.recurrenceToEdit.id,
      unitName: this.state.recurrenceToEdit.unitName,
      recurrenceType: this.state.recurrenceToEdit.type,
    };

    await updateRecurrence(payload);

    const { editRecurrenceModalOpened, recurrenceToEdit } = this.initialState;

    this.setState(
      {
        editRecurrenceModalOpened,
        recurrenceToEdit,
      },
      this.fetchRecurrences
    );
    this.fetchAndResetPagination();
  };

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;

    return (
      <TableHead>
        <TableRow>
          {["startDate", "endDate", "type", "code", "schedule", "unitName", "locationName"].map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={columnName === name}
                direction={direction}
                onClick={this.handleSort(name)}
              >
                {headerLabels[name].label}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  renderDeleteIcon = (endDate, id) => {
    if (endDate) {
      return <TableCell />;
    }
    return (
      <TableCell classes={{ root: "action-cell" }}>
        <IconButton
          color="secondary"
          id="cpbr-delete-recurrence"
          onClick={this.handleToggleDeleteRecurrenceModal(true, id)}
          size="large"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    );
  };

  renderEditIcon = (recurrence) => {
    if (recurrence.endDate) {
      return <TableCell />;
    }
    return (
      <TableCell classes={{ root: "action-cell" }}>
        <IconButton
          color="secondary"
          id="cpbr-edit-recurrence"
          onClick={this.handleToggleEditRecurrenceModal(true, recurrence)}
          size="large"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
    );
  };

  renderTableRows = () => {
    const { recurrences, recurrencesLoading } = this.props;
    const { limit, page } = this.state;

    if (recurrencesLoading) {
      return <TableLoading />;
    }

    if (!recurrences.length) {
      return <TableCellNoData />;
    }

    // ON gèere la pagination ici pour avoir les données dans le bon ordre globalement
    let bottomLimit = page * limit;
    let topLimit = (page + 1) * limit;

    return sortedData(this.props.recurrences, this.state.sort)
      .slice(bottomLimit, topLimit)
      .map((recurrence) => {
        const { id, code, endDate, locationName, schedule, startDate, unitName, type } = recurrence;

        // On ne peut pas faire ça ici comme ça ça fuck la paginationn!
        // Il faut les filtrer dans le controleur si on veut les enlever !
        // if (endDate && moment(endDate) < moment().startOf("date")) {
        //   return null;
        // }

        return (
          <TableRow key={id}>
            <TableCell>{startDate}</TableCell>
            <TableCell>{endDate}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{code}</TableCell>
            <TableCell>{schedule}</TableCell>
            <TableCell>{unitName}</TableCell>
            <TableCell>{locationName}</TableCell>
            {this.renderEditIcon(recurrence)}
            {this.renderDeleteIcon(endDate, id)}
          </TableRow>
        );
      });
  };

  render() {
    const { customerItem, recurrencesCount } = this.props;
    const { customerTaskBlock } = customerItem;
    const {
      filter,
      limit,
      page,
      recurrenceModalOpened,
      deleteRecurrenceModalOpened,
      editRecurrenceModalOpened,
      recurrenceToEdit,
      warningEditRecurrenceOpened,
      units,
    } = this.state;
    const tomorrow = moment().add(1, "days");
    return (
      <PageContainer>
        <PaperWrapper>
          <div>
            <Tooltip
              disableHoverListener={!customerItem.isPaymentMethodCreditCard}
              title={"Impossible de créer une récurrence sur un contrat par carte de crédit."}
              placement="bottom-end"
            >
              <div>
                <FloatingActionButton
                  tooltipLabel={customerTaskBlock ? <Trans i18nKey="blocked_customer" /> : ""}
                  disabled={customerTaskBlock || customerItem.isPaymentMethodCreditCard}
                  color="secondary"
                  onClick={this.handleToggleRecurrenceModal(true)}
                >
                  <AddIcon />
                </FloatingActionButton>
              </div>
            </Tooltip>
          </div>

          <TableOverflowWrapper>
            <Toolbar>
              <TextFieldUi
                id="cpbr-filtre"
                label={<Trans i18nKey="filter" />}
                onChange={this.handleFilter}
                type="search"
                value={filter}
              />
            </Toolbar>

            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={recurrencesCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />

          {recurrenceModalOpened && (
            <ModalRecurrence
              customerItem={customerItem}
              open={recurrenceModalOpened}
              onClose={this.handleToggleRecurrenceModal(false)}
              refreshList={this.fetchAndResetPagination}
            />
          )}

          {editRecurrenceModalOpened && (
            <ModalEditRecurrence
              units={units}
              recurrenceToEdit={recurrenceToEdit}
              opened={editRecurrenceModalOpened}
              onChange={this.handleEditRecurrence}
              onSubmit={this.handleToggleWarningEditRecurrence(true)}
              onClose={this.handleToggleEditRecurrenceModal(false)}
            />
          )}

          <ModalConfirm
            onCancel={this.handleToggleWarningEditRecurrence(false)}
            onSave={this.handleSaveEditRecurrence}
            open={warningEditRecurrenceOpened}
            title={<Trans i18nKey="warning" />}
          >
            Êtes-vous certain de vouloir modifier la récurrence de la
            {recurrenceToEdit?.type === "Route" ? " route" : " tâche"} {recurrenceToEdit?.locationName} pour être
            exécuté par le camion {recurrenceToEdit?.unitName} à partir du {formattedDate(tomorrow)} ?
          </ModalConfirm>

          <ModalWarning
            onCancel={this.handleToggleDeleteRecurrenceModal(false)}
            onSubmit={this.handleDeleteRecurrence}
            open={deleteRecurrenceModalOpened}
            title={<Trans i18nKey="warning" />}
          >
            <Trans i18nKey="warning_delete_recurrence" />
          </ModalWarning>
        </PaperWrapper>
      </PageContainer>
    );
  }
}

RecurrencesList.defaultProps = {
  recurrencesLoading: true,
};

RecurrencesList.propTypes = {
  customerItem: PropTypes.object.isRequired,
  fetchCustomerItemRecurrences: PropTypes.func.isRequired,
  flushRecurrences: PropTypes.func.isRequired,

  recurrences: PropTypes.arrayOf(PropTypes.object).isRequired,
  recurrencesCount: PropTypes.number.isRequired,
  recurrencesLoading: PropTypes.bool,
};

export default withRouter(withRecurrences(RecurrencesList));
