import styled from "@emotion/styled";

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const MultiSelectBox = styled.div`
  border: 1px solid #ececec;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 10px;
  width: 45px;
  height: 40px;
  text-align: center;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: #ececec;
    border-color: #4da1ff;
  }

  &.active {
    background-color: #4da1ff !important;
    border-color: #4da1ff;
    color: #fff;
  }
`;

export const SelectBoxWrapper = styled.div`
  margin-top: 15px;
`;
