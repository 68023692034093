import axios from "axios";
import { requestOptions } from "../auth";

const reassign = async (payload) => {
  try {
    return axios.post(`${import.meta.env.VITE_API_URL}/units/reassign`, payload, requestOptions());
  } catch (error) {
    console.warn("[reassign.service] error:", error);

    return false;
  }
};

export default reassign;
