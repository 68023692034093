import { FormGroup, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

const initialState = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  saturday: false,
  sunday: false,
};

const weekdays = [
  { label: <Trans i18nKey="weekdays.mon" />, value: "monday" },
  { label: <Trans i18nKey="weekdays.tues" />, value: "tuesday" },
  { label: <Trans i18nKey="weekdays.wed" />, value: "wednesday" },
  { label: <Trans i18nKey="weekdays.thur" />, value: "thursday" },
  { label: <Trans i18nKey="weekdays.fri" />, value: "friday" },
  { label: <Trans i18nKey="weekdays.sat" />, value: "saturday" },
  { label: <Trans i18nKey="weekdays.sun" />, value: "sunday" },
];

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const MultiSelectBox = styled.div`
  border: 1px solid #ececec;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    background-color: #ececec;
    border-color: #4da1ff;
  }

  &.active {
    background-color: #4da1ff !important;
    border-color: #4da1ff;
    color: #fff;
  }
  &.error {
    background-color: #fff !important;
    border-color: #ff0000;
    color: #040f13;
  }
`;

const SelectBoxWrapper = styled.div`
  margin-top: 15px;
`;

class FrequencyWeeklyMultiSelectBox extends PureComponent {
  state = {
    ...initialState,
  };

  handleChange = () => {
    const values = [];

    for (const key of Object.keys(this.state)) {
      if (this.state[key]) {
        values.push(key);
      }
    }

    this.props.onChange(values);
  };

  handleSelectWeekDay = (day) => () => {
    const { [day]: dayValue } = this.state;

    this.setState(
      {
        ...this.state,
        [day]: !dayValue,
      },
      this.handleChange
    );
  };

  renderSelectBox = (data) => [
    ...data.map(({ label, value }) => (
      <MultiSelectBox
        className={this.state[value] ? "active" : this.props.error ? "error" : ""}
        key={value}
        onClick={this.handleSelectWeekDay(value)}
        value={value}
      >
        <Typography>{label}</Typography>
      </MultiSelectBox>
    )),
  ];

  render() {
    return (
      <SelectBoxWrapper>
        <FormGroup>
          <FlexWrapper>{this.renderSelectBox(weekdays)}</FlexWrapper>
        </FormGroup>
      </SelectBoxWrapper>
    );
  }
}

FrequencyWeeklyMultiSelectBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: Promise.bool,
};

export default FrequencyWeeklyMultiSelectBox;
