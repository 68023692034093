// TODO Change .js to .ts

import { axiosClientV1 } from "../axios";

import * as API from "@services";
import DeleteBearerTokenService from "./delete-bearer-token.service";

/**
 * Signs the user out
 *
 * @class
 */
class LogoutService {
  /**
   * Signs a user out
   *
   * @returns {Promise<void>}
   */
  static async logout() {
    try {
      const response = await axiosClientV1.get(`/users/logout`, API.Auth.requestOptions());

      if (response.status !== 200) throw new Error(`${response.status}`);

      DeleteBearerTokenService.delete();
    } catch (error) {
      console.warn("[API.logout] Error:", error);

      throw error;
    }
  }
}

export default LogoutService;
