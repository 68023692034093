import React, { useEffect, useState } from "react";

import useSetCurrentUser from "@hooks/auth/useSetCurrentUser";
import { LoadingSpinner } from "./routes/utils";

const LoadingScreen = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { mutateAsync: setCurrentUser } = useSetCurrentUser();

  useEffect(() => {
    (async () => {
      try {
        await setCurrentUser();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, [setCurrentUser, setLoading]);

  if (loading) return <LoadingSpinner />;

  return children;
};

export default LoadingScreen;
