import axios from "axios";
import { requestOptions } from "../auth";

const fetchUnit = async (unitId) => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/units/${unitId}`, requestOptions());

    return response.data?.data?.attributes ?? null;
  } catch (error) {
    console.warn("[fetch-unit.service] error:", error);
  }
};

export default fetchUnit;
