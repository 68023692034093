import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const ScrollIcon = ({ size, fillColor, strokeColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_862_1654)">
          <rect
            width="62"
            height="40"
            transform="translate(-15 -4)"
            fill={fillColor}
            fillOpacity="0.81"
          />
          <path
            d="M16 23V11"
            stroke={strokeColor}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 17L16 11L22 17"
            stroke={strokeColor}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_862_1654">
            <rect
              width="32"
              height="32"
              rx="16"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};
