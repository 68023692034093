import { useQuery, UseQueryResult } from "@tanstack/react-query";
import FetchRouteLocationAnomaliesService from "../../services/route-locations/fetch-route-location-anomalies.service";
import routeLocation from "@hooks/route-locations/routeLocation.keys";
import RouteLocationAnomaly from "@@types/route-locations/route-location-anomaly.type";
import mapResponseToRouteLocationAnomalies from "@@types/route-locations/mappers/mapResponseToRouteLocationAnomaly";

function useFetchRouteLocationAnomalies(routeLocationId: number): UseQueryResult<RouteLocationAnomaly[]> {
  return useQuery({
    queryKey: routeLocation.anomalies(routeLocationId),
    queryFn: () => FetchRouteLocationAnomaliesService.fetchAnomalies(routeLocationId),
    select: ({ data: response }): RouteLocationAnomaly[] => mapResponseToRouteLocationAnomalies(response || []),
    enabled: routeLocationId !== 0,
  });
}

export default useFetchRouteLocationAnomalies;
