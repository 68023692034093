export const getTotalDistance = (dir) => {
  var sum = 0;
  for (var i = 0; i < dir.legs.length; i++) {
    if (dir.legs[i] != null && dir.legs[i].duration != null) {
      sum += dir.legs[i].distance.value;
    }
  }
  return sum;
};

export const formatLength = (meters) => {
  var km = parseInt(meters / 1000);
  meters -= km * 1000;
  var ret = "";
  if (km > 0) ret += km + " km ";
  if (km < 10) ret += meters + " m";
  return ret;
};

export const getTotalDuration = (dir) => {
  var sum = 0;
  for (var i = 0; i < dir.legs.length; i++) {
    if (dir.legs[i] != null && dir.legs[i].duration != null) {
      sum += dir.legs[i].duration.value;
    }
  }
  return sum;
};

export const formatTime = (seconds) => {
  var days;
  var hours;
  var minutes;
  days = parseInt(seconds / (24 * 3600));
  seconds -= days * 24 * 3600;
  hours = parseInt(seconds / 3600);
  seconds -= hours * 3600;
  minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  var ret = "";
  if (days > 0) ret += days + " days ";
  if (days > 0 || hours > 0) ret += hours + " hrs ";
  if (days > 0 || hours > 0 || minutes > 0) ret += minutes + " min ";
  if (days == 0 && hours == 0) ret += seconds + " sec";
  return ret;
};
