import React, { useEffect } from "react";
import LoadingButton from "@ui/LoadingButton";
import { CurrencyExchange } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

function ModalRefund({ children, onCancel, onSubmit, open, title, loading }) {
  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ minWidth: 600 }}>{children}</DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Annuler
        </Button>

        <LoadingButton loading={loading} startIcon={<CurrencyExchange />} onClick={onSubmit} loadingPosition="start">
          Soumettre
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ModalRefund;
