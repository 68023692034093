import React, { FC } from "react";
import { Stack, Table, Dialog } from "@mui/material";
import TableLoading from "@ui/TableLoading";
import ContainerLiftingRequestsModalTabs from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestModalTabs";
import useLocationContainerModalLiftingRequests from "@components/location/route/containers/modals/hooks/location-container-modal-lifting-requests.hook";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import ContainerLiftingRequestsFooter from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsModalFooter";
import ContainerLiftingRequestsListHeader from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsModalListHeader";
import ContainerLiftingRequestsListBody from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsModalListBody";
import ContainerLiftingRequestsHeader from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsModalHeader";

type LocationCcavContainerLiftingRequestsModalProps = {
  openModalContainerLiftingRequests: boolean;
  selectedContainer: LocationCcavContainer;
  onClose: (modalType: string) => void;
};

const ContainerLiftingRequestsModal: FC<LocationCcavContainerLiftingRequestsModalProps> = ({
  openModalContainerLiftingRequests,
  selectedContainer,
  onClose,
}) => {
  const { liftingRequestData, liftingRequestHandlers } = useLocationContainerModalLiftingRequests(selectedContainer.id);
  return (
    <Dialog
      maxWidth="lg"
      open={openModalContainerLiftingRequests}
      onClose={() => onClose("liftingRequests")}
    >
      <Stack
        sx={{ minWidth: 1000, minHeight: 800, maxHeight: 800 }}
        justifyContent="space-between"
      >
        <Stack p={2}>
          {/* MODAL HEADER */}
          <ContainerLiftingRequestsHeader
            selectedContainer={selectedContainer}
            liftingRequestData={liftingRequestData}
            liftingRequestHandlers={liftingRequestHandlers}
          />
          {/* MODAL TABS */}
          <ContainerLiftingRequestsModalTabs
            liftingRequestTabValue={liftingRequestData.liftingRequestTabValue}
            handleChangeTabRequestLifting={liftingRequestHandlers.handleChangeTabRequestLifting}
          />

          {/* MODAL LIST */}
          <Table stickyHeader>
            <ContainerLiftingRequestsListHeader />
            {!liftingRequestData.liftingRequests?.data && <TableLoading />}
            {liftingRequestData.liftingRequests?.data && (
              <ContainerLiftingRequestsListBody liftingRequests={liftingRequestData.liftingRequests.data} />
            )}
          </Table>
        </Stack>

        {/* MODAL FOOTER */}
        <ContainerLiftingRequestsFooter
          filterStates={liftingRequestData.filterStates}
          count={liftingRequestData.liftingRequests?.count}
          handleChangePage={liftingRequestHandlers.handleChangePage}
          handleChangeRowsPerPage={liftingRequestHandlers.handleChangeRowsPerPage}
          onClose={() => onClose("liftingRequests")}
        />
      </Stack>
    </Dialog>
  );
};

export default ContainerLiftingRequestsModal;
