import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/withRouter";
import { MapErrorView, MapLoadingSpinner, withGoogleMap } from "@utils/withGoogleMap";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import ModalCustomerLocation from "./ModalCustomerLocation";
import { MapContentWrapper, RouteContentWrapper } from "@styles/globalStyle";

// Map imports
import MapMarker, { MarkerIcon } from "./maps/MapMarker";
import { GoogleMap } from "@react-google-maps/api";
import MapSideDrawer, { componentToLoad } from "./mapSideDrawer/MapSideDrawer";

import {
  withCustomerItem,
  withCustomerLocations,
  withJobTemplates,
  withLocations,
  withRouteTemplate,
  withRouteTemplates,
  withSupplierLocations,
} from "optigo-redux";
import { Button, Checkbox, CircularProgress, Grid, MenuItem } from "@mui/material";
import { getErrorMessage, handleChangeFields } from "@utils/form";

import {
  createArrayForDataMenu,
  handleMenuDataTable,
  menuDataTable,
  moveDataFromItemsToSelected,
  updateArrayDataTableMenu,
} from "./routes/dataTable";

import {
  getItemsCustomerLocations,
  handleToggleAllItemsAndSelected,
  InputControl,
  LoadingSpinner,
  onDragEnd,
  onDragStart,
  renderCancelButton,
  renderDragAndDrop,
  resetAllCheckboxInState,
  toggleSelectionCheckbox,
  willShowCountCircleForItem,
} from "./routes/utils";

import {
  createRouteTemplateLocationsParams,
  displayActionName,
  displayAddLocationButton,
  displayRightTitle,
  displaySaveButton,
  loadSavedRouteTemplateLocations,
  loadTsp,
  manageInfoWindowAppearance,
  renderLocationsFields,
  tspCallback,
} from "./routeTemplate/utils";

import { apiIsLoaded, fitBoundsForAll, fitBoundsForSelected } from "./routes/mapsAndTsp";
import RouteHeader from "./ui/RouteHeader";
import RouteSidebar from "./ui/RouteSidebar";
import TextFieldUi from "./ui/TextField";
import RouteDetailsDialog from "./RouteDetailsDialog";
import move from "lodash-move";
import Breadcrumbs from "./Breadcrumbs";
import { offsetLegacyMarkersOnSameSpot } from "@utils/offsetMarkers";

const initialState = {
  errors: {
    averageLiftingTime: false,
    contractId: false,
    customerItemId: false,
    name: false,
    masterRouteName: false,
    nameHypen: false,
    supplierLocationId: false,
    showSideBar: false,
  },
  averageLiftingTime: "",
  checkedDraggableMaster: null,
  contractId: "-1",
  customerItemId: "-1",
  draggedItemIsChecked: false,
  items: null,
  itemsAllChecked: false,
  masterRouteName: "",
  name: "",
  nbCheckedItemsLeft: 0,
  nbCheckedItemsRight: 0,
  selected: [],
  selectedCompleted: false,
  selectedAllChecked: false,
  supplierLocationId: "-1",
  menuDataTableOpened: false,
  arrayDataTableMenu: [],
  itemToAdd: [],
  dataTableAvailableOrSelected: true,
  customerLocationModalOpened: false,
  customerLocationToEdit: {},
  showContainerDialog: false,
  locationId: "",
  lastSelectedShowWindowsIndex: null,
  lastItemShowWindowsIndex: null,
  mapType: "roadmap",
  lastSelectedUpdatedIndex: -1,
  lastItemUpdatedIndex: -1,
  displayAssignMarker: true,
  displayUnassignMarker: true,
  isAssignCheckboxDisabled: false,
  isUnassignCheckboxDisabled: false,
  isMapSideDrawerOpen: false,
  sideDrawerLeftTabContent: {
    data: {},
    rank: {
      text: 1,
      length: 1,
      rankChangeCallback: () => { },
    },
  },
  sideDrawerRightTabContent: [],
  quickAssign: false,
};

class MasterRouteTemplateCreate extends PureComponent {
  state = {
    ...initialState,
  };

  // eslint-disable-next-line react/sort-comp
  id2List = {
    droppable: "items",
    droppable2: "selected",
  };
  // Bind nécessaires pour les fonctions externes qui doivent avoir accès à this
  renderCancelButton = renderCancelButton.bind(this);
  fitBoundsForSelected = fitBoundsForSelected.bind(this);
  fitBoundsForAll = fitBoundsForAll.bind(this);
  willShowCountCircleForItem = willShowCountCircleForItem.bind(this);
  apiIsLoaded = apiIsLoaded.bind(this);
  onDragEnd = onDragEnd.bind(this);
  onDragStart = onDragStart.bind(this);
  resetAllCheckboxInState = resetAllCheckboxInState.bind(this);
  toggleSelectionCheckbox = toggleSelectionCheckbox.bind(this);
  getErrorMessage = getErrorMessage.bind(this);
  handleChangeFields = handleChangeFields.bind(this);
  menuDataTable = menuDataTable.bind(this);
  createArrayForDataMenu = createArrayForDataMenu.bind(this);
  moveDataFromItemsToSelected = moveDataFromItemsToSelected.bind(this);
  updateArrayDataTableMenu = updateArrayDataTableMenu.bind(this);
  handleMenuDataTable = handleMenuDataTable.bind(this);
  handleToggleAllItemsAndSelected = handleToggleAllItemsAndSelected.bind(this);
  renderDragAndDrop = renderDragAndDrop.bind(this);
  createRouteTemplateLocationsParams = createRouteTemplateLocationsParams.bind(this);
  loadSavedRouteTemplateLocations = loadSavedRouteTemplateLocations.bind(this);
  displaySaveButton = displaySaveButton.bind(this);
  loadTsp = loadTsp.bind(this);
  tspCallback = tspCallback.bind(this);
  renderLocationsFields = renderLocationsFields.bind(this);
  displayAddLocationButton = displayAddLocationButton.bind(this);
  displayRightTitle = displayRightTitle.bind(this);
  displayActionName = displayActionName.bind(this);
  manageInfoWindowAppearance = manageInfoWindowAppearance.bind(this);

  get items() {
    const { customerLocationsLoading } = this.props;

    if (!customerLocationsLoading && !this.state.items) {
      const items = getItemsCustomerLocations(this.props.customerLocations);
      this.setState({ items });
      return items;
    }
    return this.state.items;
  }

  get selected() {
    const { routeTemplateLoading } = this.props;
    if (!routeTemplateLoading && !this.state.selectedCompleted) {
      if (
        this.props.routeTemplate &&
        this.props.routeTemplate.routeTemplateLocations &&
        this.props.routeTemplate.routeTemplateLocations.length > 0
      ) {
        this.loadSavedRouteTemplateLocations(this.props.routeTemplate);
        this.setState({ selectedCompleted: true });
      }
    }
    if (this.state.selected) {
      return this.state.selected;
    }
    return [];
  }

  get valid() {
    const errors = { ...initialState.errors };
    const { name, masterRouteName, id } = this.state;
    const { routeTemplates, router, customerItem } = this.props;
    const routes = routeTemplates.filter((rn) => rn.name === name);
    const path = router.location.pathname;
    let valid = true;
    const splitMasterRouteName = masterRouteName.split(/[-]+/);

    if (splitMasterRouteName[0] !== "CCAV") {
      valid = false;
      errors[name] = true;
      errors.nameHypen = true;
    }
    if (
      splitMasterRouteName[1] === undefined ||
      !splitMasterRouteName[1].match(/A+[0-9]/) ||
      splitMasterRouteName[1] === ""
    ) {
      if (splitMasterRouteName[1] === undefined || !splitMasterRouteName[1].match(/^[A-Z]*$/)) {
        valid = false;
        errors[name] = true;
        errors.nameHypen = true;
      }
    }
    if (customerItem.containerKindName === "Recyclage") {
      if (splitMasterRouteName[2] !== "R") {
        valid = false;
        errors[name] = true;
        errors.nameHypen = true;
      }
    }
    if (customerItem.containerKindName === "Matières organiques") {
      if (splitMasterRouteName[2] !== "O") {
        valid = false;
        errors[name] = true;
        errors.nameHypen = true;
      }
    }
    if (customerItem.containerKindName === "Ordure") {
      if (splitMasterRouteName[2] !== "D") {
        valid = false;
        errors[name] = true;
        errors.nameHypen = true;
      }
    }
    if (
      splitMasterRouteName[3] === undefined ||
      !splitMasterRouteName[3].match(/^(?:LU?|MA?|ME?|JE?|VE?|SA?|DI?)$/) ||
      splitMasterRouteName[3].length < 2
    ) {
      valid = false;
      errors[name] = true;
      errors.nameHypen = true;
    }
    if (splitMasterRouteName[4] || splitMasterRouteName[4] === "") {
      valid = false;
      errors[name] = true;
      errors.nameHypen = true;
    }
    if (path.includes("edit")) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routes.length; i++) {
        // eslint-disable-next-line max-len
        if (routes[i].name === name && routes[i].masterRoute === masterRouteName && routes[i].id !== id) {
          valid = false;
          errors[name] = true;
          errors.nameHypen = true;
        }
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routeTemplates.length; i++) {
        if (routeTemplates[i].masterRoute === masterRouteName) {
          valid = false;
          errors[name] = true;
          errors.nameHypen = true;
        }
      }
    }

    this.setState({ errors });
    return valid;
  }

  // eslint-disable-next-line react/sort-comp
  componentDidMount() {
    this.flushCustomerLocations();
    this.flushRouteTemplate();
    this.fetchRouteTemplate();
    const { router } = this.props;
    const { customerItemId } = router.params;
    this.props.fetchRouteTemplatesByCustomerItem(customerItemId, {
      filter: "",
      limit: 250,
      page: 1,
    });
    this.fetchAllSuppliersLocations();
    this.fetchCustomerItem();
    this.fetchCustomerLocations();
  }

  async componentWillUnmount() {
    await this.flushRouteTemplate();
    await this.flushCustomerLocations();
  }

  getList = (id) => this.state[this.id2List[id]];

  fetchAndModalCallback = () => {
    this.setState(this.initialState, this.fetchCustomerLocations);
  };

  fetchAllSuppliersLocations = async () => {
    await this.props.fetchAllSuppliersLocations();
  };

  fetchCustomerLocations = async () => {
    const { fetchCustomerLocations, router } = this.props;

    const masterRouteId = router.params.masterRouteId;
    await fetchCustomerLocations(
      router.params.customerId,
      {
        filter: "",
        limit: 1000,
        page: 1,
      },
      router.params.contractId,
      masterRouteId,
      router.params.routeTemplateId
    );
  };

  fetchCustomerItem = async () => {
    const { fetchCustomerItem, router } = this.props;
    await fetchCustomerItem(router.params.customerItemId);
  };

  fetchRouteTemplate = async () => {
    const { fetchRouteTemplate, router } = this.props;
    const currentRouteTemplateId = router.params.routeTemplateId;

    if (currentRouteTemplateId) {
      await fetchRouteTemplate(router.params.routeTemplateId);

      const newState = { ...this.state, ...this.props.routeTemplate };
      this.setState(newState);
    }
  };

  flushRouteTemplate = async () => {
    const { flushRouteTemplate } = this.props;
    await flushRouteTemplate();
  };

  flushCustomerLocations = async () => {
    const { flushCustomerLocations } = this.props;
    await flushCustomerLocations();
  };

  handleRefreshAfterModify = async () => {
    this.handleToggleCustomerLocationModal(false)();

    this.setState(initialState, this.fetchRouteTemplate);
  };

  handleToggleCustomerLocationModal = (customerLocationModalOpened) => () => {
    const { customerLocationToEdit } = this.state;

    this.setState({
      customerLocationModalOpened,
      customerLocationToEdit,
    });
  };

  handleShowContainerDetailsDialog = (item) => {
    this.getLocationItems(item.locationId).then((value) => {
      this.setState({
        locationItems: { [item.locationId]: value },
        locationId: item.locationId,
        showContainerDialog: true,
      });
    });
  };

  handleCloseContainerDetailsDialog = () => {
    this.setState({
      showContainerDialog: false,
      locationId: null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { createRouteTemplate, updateRouteTemplate, router, flushRouteTemplate, routeTemplate } = this.props;

      const { master_route, masterRouteId, routeTemplateId, contractId, customerItemId } = router.params;

      const { averageLiftingTime, name, completeName, supplierLocationId, masterRouteName } = this.state;

      const actualRouteTemplateId = router.params.routeTemplateId;

      const routeTemplateLocationsParams = this.createRouteTemplateLocationsParams(this.selected);

      if (actualRouteTemplateId && !master_route) {
        await updateRouteTemplate(actualRouteTemplateId, {
          average_lifting_time: averageLiftingTime,
          master_route: masterRouteId,
          route_template_id: routeTemplate.id,
          name,
          complete_name: completeName,
          route_template_locations_attributes: routeTemplateLocationsParams,
          supplier_location_id: supplierLocationId,
        });
        await flushRouteTemplate();
      } else {
        await createRouteTemplate(router.params.customerItemId, {
          average_lifting_time: averageLiftingTime,
          complete_name: completeName,
          contract_id: contractId,
          customer_item_id: customerItemId,
          is_a_master_route: true,
          master_route: masterRouteName,
          master_route_id: routeTemplateId,
          name: "-",
          route_template_locations_attributes: routeTemplateLocationsParams,
          supplier_location_id: supplierLocationId,
        });
      }

      this.setState(initialState);

      const path = `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${router.params.customerItemId}?current_tab=3`;
      this.props.router.navigate(path);
    }
  };

  handleShowHideSidebar = () => {
    const { showSideBar } = this.state;
    // if (showSideBar) {
    //   document.firstElementChild.classList.remove('popup-open');
    // } else {
    //   document.firstElementChild.classList = 'popup-open';
    // }
    this.setState({ showSideBar: !showSideBar });
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  getLocationItems = async (id) => {
    const { fetchLocationItems } = this.props;
    const itemsDetails = await fetchLocationItems(id);
    return itemsDetails;
  };

  updateArrayMarker = async (source, destination) => {
    const newSelected = await move(this.state.selected, source, destination);
    this.setState((prevState) => ({
      selected: newSelected,
      sideDrawerLeftTabContent: {
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: destination + 1,
          length: newSelected.length,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  assignLocationFromItemToSelected = (item) => {
    const { items, selected } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newSelected = [...selected, data];
    const newItems = [...items.filter((f) => !(data.id === f.id))];

    this.setState((prevState) => ({
      selected: newSelected,
      items: newItems,
      sideDrawerLeftTabContent: {
        // Update only the rank data
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: prevState.selected.length + 1,
          length: prevState.selected.length + 1,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  unassignLocationFromSelectedToItem = (item) => {
    const { items, selected } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newItems = [...items, data];
    const newSelected = [...selected.filter((f) => !(data.id === f.id))];

    this.setState((prevState) => ({
      items: newItems,
      selected: newSelected,
      sideDrawerLeftTabContent: { ...prevState.sideDrawerLeftTabContent, rank: {} }, // Remove rank data since unassigned
    }));
  };

  changeMapType = (mapType) => {
    this.setState({ mapType });
  };

  handleIsLocationAssigned = (itemID) => this.state.selected.some((selectedItem) => selectedItem.id === itemID);

  hideMapSideDrawer = () => this.setState({ isMapSideDrawerOpen: false, sideDrawerLeftTabContent: {} });

  showMapSideDrawer = (item, rank) => {
    // If no rank is passed as parameter, only shows item data
    this.getLocationItems(item.locationId).then((value) => {
      this.setState({
        isMapSideDrawerOpen: true,
        sideDrawerLeftTabContent: { data: item, rank },
        sideDrawerRightTabContent: value,
      });
    });
  };

  handleChangeQuickAssign = (event) => {
    this.setState({ quickAssign: event.target.checked, sideDrawerLeftTabContent: {} }, () => {
      this.state.quickAssign && this.hideMapSideDrawer();
    });
  };

  renderBreadCrumbs = () => {
    const { router } = this.props;
    const { params } = router;
    const { contractId, customerId, customerItemId } = params;

    if (contractId && customerId) {
      return (
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}`}>
              <Trans i18nKey="contract_and_contact" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}`}>
              <Trans i18nKey="contract" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}?current_tab=3`}>
              <Trans i18nKey="customer_item" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="route_templates.conception" />
          </li>
        </Breadcrumbs>
      );
    }
  };

  renderMapMarkers = () => {
    const assignedMarkers = this.renderMapAssignedMarkers();
    const unassignedMarkers = this.renderMapUnassignedMarkers();
    const masterRouteMarkers = this.renderMapMasterRouteMarkers();

    // Join all three arrays under one array and if an array is null, replace it with an empty array instead
    return [...(unassignedMarkers || []), ...(assignedMarkers || []), ...(masterRouteMarkers || [])];
  };

  renderMapAssignedMarkers = () => {
    const { displayAssignMarker, quickAssign, selected, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const selectedLength = selected.length === 0 ? 1 : selected.length;
    const { data } = sideDrawerLeftTabContent;

    return (
      router.location.pathname.includes("new") &&
      displayAssignMarker &&
      offsetLegacyMarkersOnSameSpot(
        this.selected.map((selected, index) => {
          const rank = {
            text: index + 1,
            length: selectedLength,
            rankChangeCallback: this.updateArrayMarker,
          };

          return (
            <MapMarker
              key={index}
              icon={MarkerIcon.assigned}
              label={index + 1} // Show label on markers since it's an assigned marker
              position={{
                lat: +selected.address.lat,
                lng: +selected.address.lng,
              }} // Convert string to int and pass coords
              onClick={
                quickAssign
                  ? () => this.unassignLocationFromSelectedToItem(selected)
                  : () => this.showMapSideDrawer(selected, rank)
              }
              isSelected={
                !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
              }
            />
          );
        })
      )
    );
  };

  renderMapUnassignedMarkers = () => {
    const { displayUnassignMarker, quickAssign, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const { data } = sideDrawerLeftTabContent;

    return (
      router.location.pathname.includes("new") &&
      displayUnassignMarker &&
      offsetLegacyMarkersOnSameSpot(
        this.items.map((item, index) => (
          <MapMarker
            key={index}
            icon={MarkerIcon.unassigned}
            position={{ lat: +item.address.lat, lng: +item.address.lng }} // Convert string to int and pass coords
            onClick={
              quickAssign ? () => this.assignLocationFromItemToSelected(item) : () => this.showMapSideDrawer(item)
            }
            isSelected={!quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === item.id : null}
          />
        ))
      )
    );
  };

  renderMapMasterRouteMarkers = () => {
    const { isAMasterRoute, quickAssign, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const { data } = sideDrawerLeftTabContent;

    return (
      isAMasterRoute &&
      router.location.pathname.includes("edit") &&
      offsetLegacyMarkersOnSameSpot(
        this.selected.map((selected, index) => (
          <MapMarker
            key={index}
            icon={MarkerIcon.unassigned}
            position={{
              lat: +selected.address.lat,
              lng: +selected.address.lng,
            }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.assignLocationFromItemToSelected(selected)
                : () => this.showMapSideDrawer(selected)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data") ? data.id === selected.id : null
            }
          />
        ))
      )
    );
  };

  handleAssignCheckboxClick = () => {
    const { displayAssignMarker } = this.state;
    this.setState({ displayAssignMarker: !displayAssignMarker, isAssignCheckboxDisabled: true });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isAssignCheckboxDisabled: false }), 1200);
  };

  handleUnassignCheckboxClick = () => {
    const { displayUnassignMarker } = this.state;
    this.setState({
      displayUnassignMarker: !displayUnassignMarker,
      isUnassignCheckboxDisabled: true,
    });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isUnassignCheckboxDisabled: false }), 1200);
  };

  render() {
    const {
      errors,
      isAMasterRoute,
      masterRouteName,
      menuDataTableOpened,
      name,
      showSideBar,
      customerLocationModalOpened,
      customerLocationToEdit,
      showContainerDialog,
      locationId,
      displayAssignMarker,
      displayUnassignMarker,
      sideDrawerLeftTabContent,
      sideDrawerRightTabContent,
      isMapSideDrawerOpen,
      mapType,
      isAssignCheckboxDisabled,
      isUnassignCheckboxDisabled,
    } = this.state;

    const { isLoaded, loadError } = this.props.googleMap;
    const { center, customerItem, customerLocationsLoading, router, zoom, routeTemplate } = this.props;

    const { routeTemplateId, masterRouteId, master_route } = router.params;
    const path = router.location.pathname;

    if (customerLocationsLoading) {
      return (
        <LoadingSpinner>
          <CircularProgress
            color="primary"
            size={70}
          />
        </LoadingSpinner>
      );
    }

    if (!this.items) {
      return null;
    }

    const actionName = this.displayActionName(customerLocationToEdit.id, master_route);
    const title = this.displayRightTitle(routeTemplateId, master_route, masterRouteId, isAMasterRoute);

    let nameChanger = "";
    if (name !== "-") {
      nameChanger = name;
    }

    // Single object to contain all passed props for side drawer component
    const sideDrawerContractItemData = {
      isMapSideDrawerOpen,
      isLocationAssignable: path.includes("new"),
      hideMapSideDrawer: this.hideMapSideDrawer,
      handleIsLocationAssigned: this.handleIsLocationAssigned,
      handleAssignSelectedLocation: this.assignLocationFromItemToSelected,
      handleUnassignSelectedLocation: this.unassignLocationFromSelectedToItem,
      sideDrawerLeftTabContent,
      sideDrawerRightTabContent,
    };

    // Single object to contain all passed props/properties to google map component
    const mapProps = {
      mapContainerStyle: { width: "100%" },
      center,
      zoom,
      clickableIcons: false,
      mapTypeId: mapType,
      options: { mapTypeControl: false, streetViewControl: true },
    };

    // Single object to contain all passed props to header component
    const headerProps = {
      currentAction: "edit",
      returnOnClick: this.renderCancelButton,
      pageTitle: title,
      sidebarOnClick: this.handleShowHideSidebar,
      sidebarTitle: "EMPLACEMENTS",
      isQuickAssignShowing: path.includes("new"),
      quickAssign: this.state.quickAssign,
      quickAssignOnClick: this.handleChangeQuickAssign,
    };

    return (
      <Fragment>
        {this.renderBreadCrumbs()}
        <div id="route-template-wrapper">
          <RouteHeader {...headerProps} />

          <MapContentWrapper>
            {!loadError ? (
              isLoaded ? (
                <>
                  <MapSideDrawer
                    componentToLoad={componentToLoad.contractItem}
                    componentDataToLoad={sideDrawerContractItemData}
                    urlCustomerContract={`/customers/${router.params.customerId}/contracts/${router.params.contractId}`}
                  />
                  <GoogleMap {...mapProps}>{this.renderMapMarkers()}</GoogleMap>
                </>
              ) : (
                <MapLoadingSpinner />
              )
            ) : (
              <MapErrorView />
            )}
          </MapContentWrapper>

          <RouteContentWrapper>
            <Grid
              container
              justifyContent="center"
            >
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("satellite")}
              >
                Satellite
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("terrain")}
              >
                Terrain
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("roadmap")}
              >
                Carte routière
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("hybrid")}
              >
                Hybride
              </Button>
              <React.Fragment>
                <Button
                  sx={{ color: "black" }}
                  style={{ marginLeft: 70 }}
                  disabled={isAssignCheckboxDisabled}
                  onClick={this.handleAssignCheckboxClick}
                >
                  <Checkbox checked={displayAssignMarker} />
                  EMPLACEMENTS ASSIGNÉS
                </Button>
                <Button
                  sx={{ color: "black" }}
                  disabled={isUnassignCheckboxDisabled}
                  onClick={this.handleUnassignCheckboxClick}
                >
                  <Checkbox checked={displayUnassignMarker} />
                  EMPLACEMENTS NON-ASSIGNÉS
                </Button>
              </React.Fragment>
            </Grid>

            <form>
              <Grid
                container
                spacing={3}
              >
                {path.includes("master_route") && (
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <InputControl>
                      <TextFieldUi
                        error={errors.name || errors.nameHypen}
                        helperText={
                          errors.name ? this.getErrorMessage("name") : this.getErrorMessage("nameHypen", "invalid")
                        }
                        id="route-template-name"
                        label={<Trans i18nKey="route_templates.name" />}
                        onChange={this.handleChangeFields("name")}
                        value={nameChanger}
                        fullWidth
                      />
                    </InputControl>
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.name || errors.nameHypen}
                      helperText={<Trans i18nKey="validate_route_master_name" />}
                      id="route-template-master-route"
                      onChange={this.handleChangeFields("masterRouteName")}
                      label={<Trans i18nKey="route_templates.name" />}
                      value={masterRouteName}
                      fullWidth
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {this.renderLocationsFields()}
                </Grid>
              </Grid>

              {/* eslint-disable-next-line max-len */}
              {this.displayAddLocationButton(routeTemplateId, masterRouteId, master_route, isAMasterRoute)}

              <Grid
                container
                justifyContent="center"
                style={{ display: "none" }}
              >
                <Grid item>
                  <Button onClick={this.loadTsp}>TSP</Button>
                </Grid>
              </Grid>

              <RouteSidebar
                closeSidebarOnClick={this.handleShowHideSidebar}
                sidebarTitle={title}
                showSideBar={showSideBar}
                switchChecked={menuDataTableOpened}
                switchOnChange={this.handleMenuDataTable}
              >
                {!menuDataTableOpened && this.state.selected && this.state.items
                  ? this.menuDataTable()
                  : this.renderDragAndDrop()}
              </RouteSidebar>
            </form>
          </RouteContentWrapper>

          <div id="directions" />

          <ModalCustomerLocation
            key={customerLocationToEdit.id}
            actionName={actionName}
            callback={this.fetchAndModalCallback}
            customerId={customerItem.customerId}
            contractId={customerItem.contractId}
            onClose={this.handleToggleCustomerLocationModal(false)}
            open={customerLocationModalOpened}
            refreshList={this.handleRefreshAfterModify}
          />

          {this.displaySaveButton(routeTemplateId, masterRouteId, master_route, isAMasterRoute)}

          {showContainerDialog ? (
            <RouteDetailsDialog
              items={this.state.locationItems[locationId]}
              dialogOpen={showContainerDialog}
              locationId
              onDialogClose={this.handleCloseContainerDetailsDialog}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

MasterRouteTemplateCreate.defaultProps = {
  customerItemLoading: true,
  customerLocationsLoading: true,
  routeTemplateLoading: true,
  center: {
    lat: 46.807763,
    lng: -71.38549,
  },
  zoom: 10,
};

MasterRouteTemplateCreate.propTypes = {
  center: PropTypes.object,
  createRouteTemplate: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  customerItemLoading: PropTypes.bool,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLocationsLoading: PropTypes.bool,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerItem: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  fetchRouteTemplate: PropTypes.func.isRequired,
  flushCustomerLocations: PropTypes.func.isRequired,
  flushRouteTemplate: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  routeTemplate: PropTypes.object.isRequired,
  routeTemplateLoading: PropTypes.bool,
  supplierLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateRouteTemplate: PropTypes.func.isRequired,
  zoom: PropTypes.number,
};

// eslint-disable-next-line max-len
export default withRouter(
  withGoogleMap(
    withJobTemplates(
      withCustomerItem(
        withSupplierLocations(
          withCustomerLocations(withLocations(withRouteTemplate(withRouteTemplates(MasterRouteTemplateCreate))))
        )
      )
    )
  )
);
