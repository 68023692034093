import { useRef } from "react";

/**
 * Custom hook to know whether or not the component mounted for the first time.
 * @returns True or false
 */
export const useFirstMountState = () => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
};
