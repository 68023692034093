import React from "react";

import { Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { withRouter } from "@utils/withRouter";

import LocationFutureRouteList from "@components/location/route/future/LocationFutureRouteList";
import LocationRoutesHistoryList from "@components/location/route/history/LocationRoutesHistoryList";
import LocationCcavContainersList from "@components/location/route/containers/LocationCcavContainersList";
import LocationModalitiesList from "@components/location/route/modality/LocationModalitiesList";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

function RenderTabContainerRoute(props) {
  const { tabValue, customerLocation, selectedLocation } = props;

  switch (tabValue) {
    case 0:
      {
        /* CONTENEURS */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationCcavContainersList
              selectedLocation={selectedLocation}
              customerLocation={customerLocation}
            />
          </Paper>
        </TabContainer>
      );
    case 1:
      {
        /* MODALITÉS */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationModalitiesList
              selectedLocation={selectedLocation}
              customerLocation={customerLocation}
            />
          </Paper>
        </TabContainer>
      );

    case 2:
      {
        /* ROUTES À VENIR */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationFutureRouteList
              selectedLocation={selectedLocation}
              customerLocation={customerLocation}
            />
          </Paper>
        </TabContainer>
      );
    case 3:
      {
        /* HISTORIQUE DES ROUTES */
      }
      return (
        <TabContainer>
          <Paper
            square
            elevation={0}
          >
            <LocationRoutesHistoryList
              selectedLocation={selectedLocation}
              customerLocation={customerLocation}
            />
          </Paper>
        </TabContainer>
      );
    default:
      console.log("[ERROR] renderTabContainer: returned default.");
      return <div />;
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default withRouter(RenderTabContainerRoute);
