import React, { Dispatch, ReactElement, SetStateAction } from "react";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import Dropdown from "@ui/dropdown/Dropdown";
import ContainerProfile from "@@types/container-profile/container-profile.type";
import { SelectChangeEvent } from "@mui/material";
import useFetchAvailableCcavContainerModels from "@hooks/container-profiles/fetch-available-ccav-container-models.hook";
import useCreateLocationCcavContainer from "@hooks/containers/create-location-ccav-container.hook";
import useMutateLocationCcavContainer from "@hooks/containers/mutate-location-ccav-container.hook";

export type UseLocationCcavContainerModal = {
  containerProfiles: ContainerProfile[];
  renderMenuItems: (options: ContainerProfile[]) => ReactElement | ReactElement[];
  handleChangeValue: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => void;
  handleSubmitContainer: (modalType: string, payload: LocationCcavContainer) => void;
};

const useLocationCcavContainerModal = (
  locationId: number,
  setSelectedContainer: Dispatch<SetStateAction<LocationCcavContainer | undefined>>,
  onClose: (type: string) => void
): UseLocationCcavContainerModal => {
  const { data: containerProfiles = [] } = useFetchAvailableCcavContainerModels(locationId);
  const { mutate: createContainer } = useCreateLocationCcavContainer(locationId);
  const { mutate: updateContainer } = useMutateLocationCcavContainer(locationId);
  const handleSubmitContainer = (modalType: string, payload: LocationCcavContainer): void => {
    if (payload.containerProfileId !== 0) {
      submitContainer(modalType, payload);
    } else {
      setSelectedContainer((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            error: true,
          };
        }
      });
    }
  };

  const submitContainer = (modalType: string, payload: LocationCcavContainer): void => {
    switch (modalType) {
      case "edit":
        updateContainer(payload);
        break;
      case "create":
        createContainer(payload);
        break;
    }
    onClose(modalType);
  };

  const renderMenuItems = (options: ContainerProfile[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=" "></Dropdown.MenuItem>;
    }
    return options.map((option: ContainerProfile) => (
      <Dropdown.MenuItem
        key={option.id}
        value={option?.id?.toString()}
      >
        {option.model} {option.fraction}
      </Dropdown.MenuItem>
    ));
  };

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent
  ): void => {
    setSelectedContainer((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [event.target.name]: event.target.value,
        };
      }
    });
    event.target.name === "containerProfileId" && updateContainerProfileLabels(event.target.value);
  };

  const updateContainerProfileLabels = (containerProfileId: string): void => {
    if (containerProfiles) {
      const containerProfile = containerProfiles.find(
        (containerProfile) => containerProfile?.id?.toString() === containerProfileId
      );

      if (containerProfile) {
        setSelectedContainer((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              model: containerProfile.model,
              volume: containerProfile.volume,
              error: false,
            };
          }
        });
      }
    }
  };

  return {
    containerProfiles,
    renderMenuItems,
    handleChangeValue,
    handleSubmitContainer,
  } as UseLocationCcavContainerModal;
};

export default useLocationCcavContainerModal;
