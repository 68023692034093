import React, { forwardRef } from "react";
import { Marker } from "@react-google-maps/api";
import {
  MarkerSelectedIcon,
  MarkerAssignedIcon,
  MarkerUnassignedIcon,
  MarkerUnfinishedIcon,
  MarkerFinishedWithAnomalyIcon,
  MarkerFinishedWithoutAnomalyIcon,
  MarkerFinishedWithOnlyAnomalyIcon,
  MarkerLocationIcon,
} from "./MapMarkerIcons";

// Export MarkerIcon enum to avoid typos
export const MarkerIcon = {
  selected: "selected", // Teal colored marker
  assigned: "assigned", // Blue colored marker
  unassigned: "unassigned", // Red colored marker
  unfinished: "unfinished", // Gray colored marker
  finishedWithAnomaly: "finishedWithAnomaly", // Yellow colored marker
  finishedWithoutAnomaly: "finishedWithoutAnomaly", // Green colored marker
  finishedWithOnlyAnomaly: "finishedWithOnlyAnomaly", // Red colored marker
  location: "location", // Red colored marker
  none: "none",
};

// Render marker icon depending on the passed MarkerIcon enum
const renderMarkerIcon = (icon) => {
  switch (icon) {
    case "selected":
      return {
        url: MarkerSelectedIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "assigned":
      return {
        url: MarkerAssignedIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "unassigned":
      return {
        url: MarkerUnassignedIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "unfinished":
      return {
        url: MarkerUnfinishedIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "finishedWithAnomaly":
      return {
        url: MarkerFinishedWithAnomalyIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "finishedWithoutAnomaly":
      return {
        url: MarkerFinishedWithoutAnomalyIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "finishedWithOnlyAnomaly":
      return {
        url: MarkerFinishedWithOnlyAnomalyIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "location":
      return {
        url: MarkerLocationIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
    case "none":
      return {};
    default:
      return {
        url: MarkerUnassignedIcon,
        scaledSize: new google.maps.Size(27, 43),
        labelOrigin: new google.maps.Point(13, 14),
      };
  }
};

const MapMarker = forwardRef((props, ref) => {
  const { position, draggable, onDragEnd, onClick, label, icon, isSelected } = props;
  const customLabel = { color: "#FFF", fontSize: "small", fontWeight: "bold", text: `${label}` };
  const markerIcon = isSelected ? renderMarkerIcon(MarkerIcon.selected) : renderMarkerIcon(icon); // Show selected icon or not
  const optionalProps = {
    ref,
    position,
    onDragEnd,
    draggable,
    onClick,
    icon: markerIcon,
    options: { optimized: false },
  };

  // Show label on marker only if it's an assigned marker
  if (label != undefined) {
    optionalProps.label = customLabel;
  }

  return <Marker {...optionalProps} />;
});

export default MapMarker;
