import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { round, sortBy } from "lodash";
import moment from "moment";
import { withCustomerItem, withInterventions, withJob } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import ModalIntervention from "./ModalIntervention";
import { Trans } from "react-i18next";
import { formattedDateDayMonthYear, formattedTimeHourMinutes } from "@utils/dates";

const tableHead = {
  actions: "Actions",
  date: <Trans i18nKey="day" />,
  duration: <Trans i18nKey="duration" />,
  end_time: <Trans i18nKey="end_date" />,
  kind: <Trans i18nKey="kind" />,
  locationName: <Trans i18nKey="place" />,
  start_time: <Trans i18nKey="start_date" />,
};

const DurationInProgress = styled.span`
  color: limegreen;
`;

const LabelInterrupted = styled.span`
  font-style: italic;
`;

class InterventionsList extends PureComponent {
  state = {
    interventionModalOpened: false,
    interventionToEdit: {},
  };

  async componentDidMount() {
    await this.fetchInterventions();
    await this.fetchCustomerItem();
  }

  componentWillUnmount() {
    this.props.flushInterventions();
  }

  fetchCustomerItem = async () => {
    const { fetchCustomerItem, job } = this.props;

    await fetchCustomerItem(job.customerItemId);
  };

  fetchInterventions = () => {
    const { fetchJobInterventions, router } = this.props;

    fetchJobInterventions(router.params.jobId);
  };

  fetchAndResetPagination = async () => {
    this.setState({ interventionModalOpened: false, interventionToEdit: {} }, this.refreshHeaderAndInterventions());
  };

  refreshHeaderAndInterventions = () => {
    const { refreshHeader } = this.props;

    refreshHeader();
    this.fetchInterventions();
  };

  formatDate = (startTime) => {
    if (!startTime) {
      return <span className="cpbr-no-data">-</span>;
    }

    return formattedDateDayMonthYear(startTime);
  };

  formatDuration = (duration, status) => {
    if (status === "NOT_STARTED") {
      return <span className="cpbr-no-data">-</span>;
    }

    const roundedDuration = round(duration);

    if (status === "IN_PROGRESS") {
      return <DurationInProgress className="cpbr-in-progress">{roundedDuration}</DurationInProgress>;
    }

    return roundedDuration;
  };

  formatKind = (kind) => {
    if (!kind) {
      return "-";
    }

    return <Trans i18nKey={kind.toLowerCase()} />;
  };

  formatLocation = (locationName, kind) => {
    if (kind === "WORK") {
      return <Trans i18nKey="not_applicable" />;
    }

    if (!locationName) {
      return <Trans i18nKey="not_selected" />;
    }

    return locationName;
  };

  formatStartTime = (startTime) => {
    if (!startTime) {
      return <span className="cpbr-no-data">-</span>;
    }

    return formattedTimeHourMinutes(startTime);
  };

  handleToggleInterventionModal = (opened, intervention) => () => {
    const interventionToEdit = opened ? intervention : this.state.interventionToEdit;

    this.setState({
      interventionModalOpened: opened,
      interventionToEdit,
    });
  };

  renderInterventionDateOrLabel = (interventionStatus, startTime) => {
    if (this.props.job.status === "INTERRUPTED" && interventionStatus === "NOT_STARTED") {
      return (
        <LabelInterrupted>
          <Trans i18nKey="interruption" />
        </LabelInterrupted>
      );
    }

    return this.formatDate(startTime);
  };

  renderTableHead = () => (
    <TableHead>
      <TableRow>
        {["date", "start_time", "end_time", "duration", "kind", "locationName", "actions"].map((name) => (
          <TableCell key={name}>{tableHead[name]}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  renderTableRows = () => {
    const { interventions, interventionsLoading } = this.props;

    if (interventionsLoading) {
      return <TableLoading />;
    }

    return sortBy(interventions, ["displayOrder"]).map((intervention) => {
      const { id, duration, endTime, kind, locationName, startTime, status } = intervention;

      return (
        <TableRow key={id}>
          <TableCell>{this.renderInterventionDateOrLabel(status, startTime)}</TableCell>
          <TableCell>{this.formatStartTime(startTime)}</TableCell>
          <TableCell>{this.formatStartTime(endTime)}</TableCell>
          <TableCell>{this.formatDuration(duration, status)}</TableCell>
          <TableCell>{this.formatKind(kind)}</TableCell>
          <TableCell>{this.formatLocation(locationName, kind)}</TableCell>
          <TableCell classes={{ root: "action-cell" }}>
            <IconButton
              color="secondary"
              id="cpbr-edit-location"
              onClick={this.handleToggleInterventionModal(true, intervention)}
              size="large"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  renderTable = () => (
    <PaperWrapper>
      <TableOverflowWrapper>
        <Table>
          {this.renderTableHead()}

          <TableBody>{this.renderTableRows()}</TableBody>
        </Table>
      </TableOverflowWrapper>
    </PaperWrapper>
  );

  render() {
    const { customerItemLoading, interventionsLoading, jobloading } = this.props;
    const { interventionToEdit } = this.state;

    if (customerItemLoading || interventionsLoading || jobloading) {
      return null;
    }

    return (
      <PageContainer>
        {this.renderTable()}

        <ModalIntervention
          key={interventionToEdit.id}
          actionName={<Trans i18nKey="edit" />}
          interventionData={interventionToEdit}
          onClose={this.handleToggleInterventionModal(false)}
          open={this.state.interventionModalOpened}
          refreshList={this.fetchAndResetPagination}
        />
      </PageContainer>
    );
  }
}

InterventionsList.defaultProps = {
  customerItemLoading: false,
  interventionsLoading: true,
  jobloading: false,
};

InterventionsList.propTypes = {
  customerItemLoading: PropTypes.bool,
  fetchCustomerItem: PropTypes.func.isRequired,
  fetchJobInterventions: PropTypes.func.isRequired,
  flushInterventions: PropTypes.func.isRequired,
  interventions: PropTypes.arrayOf(PropTypes.object).isRequired,
  interventionsLoading: PropTypes.bool,
  job: PropTypes.object.isRequired,
  jobloading: PropTypes.bool,

  refreshHeader: PropTypes.func.isRequired,
};

export default withInterventions(withJob(withCustomerItem(InterventionsList)));
