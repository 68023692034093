import { Icon } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;

  && {
    input {
      cursor: pointer;
    }
    & > :first-of-type:not(style):not(:first-of-type ~ *),
    & > style + * {
      width: 100%;
    }
  }
`;

const CalendarIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  right: 10px;
  color: ${({ theme }) => theme.colors.default};
  pointer-events: none;
`;

const DatePickerMui = ({ onChange, value, ...remainingProps }) => (
  <Wrapper>
    <DatePicker
      {...remainingProps}
      cancelLabel="Annuler"
      format="LL"
      onChange={onChange}
      value={value}
      renderInput={(params) => <TextField {...params} />}
    />

    <CalendarIcon>today</CalendarIcon>
  </Wrapper>
);

export default DatePickerMui;
