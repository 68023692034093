import { axiosClientV2 as http } from "../axios";

const deleteLocationCcavModality = async (
  locationDetailFrequencyId: number | undefined,
  locationDetailDaysOfWeekId: number | undefined
): Promise<void> => {
  try {
    await http.delete(
      `/location_details/destroy_location_ccav_modality?location_detail_frequency_id=${locationDetailFrequencyId}&location_detail_days_of_week_id=${locationDetailDaysOfWeekId}`
    );
  } catch (error) {
    throw error as Error;
  }
};

export default deleteLocationCcavModality;
