import React, { lazy, ReactElement, Suspense } from "react";
import withQueryParams from "@context/withQueryParams";
import { Outlet, Route, Routes } from "react-router-dom";
import { withMasterRouteTemplatesContext } from "@components/subRouteManagement/context/MasterRouteTemplatesContext";
import { LoadingSpinner } from "@components/routes/utils";

const TemplateManagement = lazy(() => import("@components/subRouteManagement/TemplateManagementContainer"));

const SubRouteManagementPage = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Outlet />}
        >
          <Route
            path=""
            element={<TemplateManagement />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default withQueryParams(withMasterRouteTemplatesContext(SubRouteManagementPage));
