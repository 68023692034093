import { FC } from "react";
import BubbleIcon from "@components/icons/BubbleIcon";
import CheckIcon from "@components/icons/CheckIcon";
import { CloseIcon } from "@components/icons/CloseIcon";
import CubeIcon from "@components/icons/CubeIcon";
import FileIcon from "@components/icons/FileIcon";
import HashtagIcon from "@components/icons/HashtagIcon";
import { MarkerIcon } from "@components/icons/MarkerIcon";
import { PinIcon } from "@components/icons/PinIcon";
import RefreshIcon from "@components/icons/RefreshIcon";
import { IconProps } from "@components/icons/Icon.type";
import BoltIcon from "@components/icons/BoltIcon";
import DotIcon from "@components/icons/DotIcon";
import { TrashIcon } from "@components/icons/TrashIcon";
import { EditIcon } from "@components/icons/EditIcon";
import { MapDotMarkerIcon } from "@components/icons/MapDotMarkerIcon";
import { ManualLiftingIcon } from "@components/icons/ManualLiftingIcon";
import { ScrollIcon } from "@components/icons/ScrollIcon";
import { AutomaticLiftingIcon } from "@components/icons/AutomaticLiftingIcon";
import { GeolocateAnomalyIcon } from "@components/icons/GeolocateAnomalyIcon";
import { LocationIcon } from "@components/icons/LocationIcon";
import ContainerIcon from "@components/icons/ContainerIcon";
import { ModalityIcon } from "@components/icons/ModalityIcon";
import ListIcon from "@components/icons/ListIcon";

export const ICON_NAMES: IconNameComponent = {
  bolt: BoltIcon,
  bubble: BubbleIcon,
  check: CheckIcon,
  close: CloseIcon,
  cube: CubeIcon,
  dot: DotIcon,
  file: FileIcon,
  hashtag: HashtagIcon,
  marker: MarkerIcon,
  pin: PinIcon,
  refresh: RefreshIcon,
  trash: TrashIcon,
  edit: EditIcon,
  mapDotMarker: MapDotMarkerIcon,
  manualLifting: ManualLiftingIcon,
  automaticLifting: AutomaticLiftingIcon,
  geolocateAnomalyIcon: GeolocateAnomalyIcon,
  scroll: ScrollIcon,
  location: LocationIcon,
  container: ContainerIcon,
  modality: ModalityIcon,
  list: ListIcon
};

export type IconNames =
  | "bolt"
  | "bubble"
  | "check"
  | "close"
  | "cube"
  | "dot"
  | "file"
  | "hashtag"
  | "marker"
  | "pin"
  | "refresh"
  | "trash"
  | "edit"
  | "mapDotMarker"
  | "manualLifting"
  | "automaticLifting"
  | "geolocateAnomalyIcon"
  | "scroll"
  | "location"
  | "container"
  | "modality"
  | "list";

interface IconNameComponent {
  [name: string]: FC<IconProps>;
}
