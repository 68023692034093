import { MutationResult } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import LocationDetailResponse from "@@types/location-details/location-detail-response.type";
import LocationDetailPayload from "@@types/location-details/location-detail-payload.type";

const putLocationCcavModality = async (
  locationId: number,
  payload: LocationDetailPayload
): Promise<MutationResult<LocationDetailResponse>> => {
  const res = await http.put<MutationResult<LocationDetailResponse>>(
    `/location_details/update_location_ccav_modality`,
    { payload, locationId }
  );
  return res.data;
};

export default putLocationCcavModality;
