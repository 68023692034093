/// <reference types="vite-plugin-svgr/client" />

import React from "react";
import CustomToolbarSelect from "../CustomToolbarSelect";
import CustomToolbar from "../CustomToolbar";
import move from "lodash-move";
import Rank from "../Rank";
import MUIDataTable from "mui-datatables";
import ICNRolloff from "@assets/images/icons/icn_rolloff.svg?react";
import { Link } from "react-router-dom";
import { Button, Card, IconButton, Stack, Tooltip } from "@mui/material";
import { isArrayTruthy } from "@utils/common";
import { includes } from "lodash";
import { formattedDate } from "@utils/dates";

let numberOfRowTest = 100;

export const menuDataTable = function () {
  const { dataTableAvailableOrSelected, selected } = this.state;

  const label = dataTableAvailableOrSelected ? "Emplacement(s) non-assigné(s)" : "Emplacement(s) assigné(s)";

  const columns = [
    {
      name: "routeTemplateLocationId",
      label: "routeTemplateLocationId",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "companyName",
      label: "Nom du client",
      options: {
        setCellProps: (value) => ({
          style: {
            maxWidth: 200,
          },
        }),
      },
    },
    {
      name: "ciWebLinkId",
      label: "Numéro de dossier",
      options: {
        setCellProps: (value) => ({
          style: {
            maxWidth: 175,
            paddingLeft: 50,
          },
        }),
      },
    },
    {
      name: "locationLink",
      label,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "locationName",
      label,
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "index",
      label: "index",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "containerNoAndRotationInfos",
      label: "Numéro de conteneur",
      options: {
        setCellHeaderProps: (value) => ({
          style: {
            maxWidth: 150,
          },
        }),
        customBodyRender: (value) => <h4 style={{ fontWeight: "normal" }}>{value}</h4>,
      },
    },
    {
      name: "model",
      label: "Modèle de conteneur",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          style: {
            paddingRight: 25,
          },
        }),
        customBodyRender: (value) => <h4 style={{ fontWeight: "normal", maxWidth: 100 }}>{value}</h4>,
      },
    },
    {
      name: "frequency",
      label: "Fréquence",
      options: {
        setCellHeaderProps: (value) => ({
          style: {
            paddingLeft: 25,
          },
        }),
        filter: false,
        sort: false,
        customBodyRender: (value) => <p style={{ color: value.collect_stop ? "red" : "" }}>{value.label}</p>,
      },
    },
    {
      name: "row",
      label: "Détails",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (row) => ({
          style: {
            paddingRight: 25,
          },
        }),
        customBodyRender: (row) => (
          <Tooltip title="Détails des contenants">
            <IconButton
              color="secondary"
              aria-label="details"
              style={{ border: "1px solid #424242" }}
              onClick={() => this.handleShowContainerDetailsDialog(row)}
              size="large"
            >
              <ICNRolloff style={{ width: 23, height: 23 }} />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      name: "anomalyIds",
      label: "Cas problème(s)",
      options: {
        filter: false,
        sort: false,
        display: this.state.type === "route",
        customBodyRender: (anomalyIds) => {
          if (isArrayTruthy(anomalyIds)) {
            return (
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  marginRight: "35px",
                  backgroundColor: "#59009e",
                  "&:hover": { backgroundColor: "#3f0070" },
                }}
                onClick={() => this.handleShowAnomalyDetails(anomalyIds)}
              >
                CAS PROBLÈME
              </Button>
            );
          }
        },
      },
    },
    {
      name: "done",
      label: "done",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "anomaly",
      label: "anomaly",
      options: {
        filter: false,
        display: "excluded",
      },
    },
  ];

  const columnsSelected = [
    {
      name: "rank",
      label: "Rang",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          this.state.type === "route" ? (
            <div>{value}</div>
          ) : (
            <Rank
              value={value}
              index={tableMeta.columnIndex}
              length={selected.length}
              rankChangeCallback={this.updateArrayDataTableMenu}
            />
          ),
      },
    },
    ...columns,
  ];

  const options = {
    setRowProps: (data) => {
      if (this.state.type === "route" && !dataTableAvailableOrSelected) {
        return {
          style: {
            // data[13] = anomaly? | data[12] = done?
            backgroundColor: data[13] ? "#ffa6a6" : data[12] ? "#c1ffc9" : "",
          },
        };
      }
    },
    fixedHeader: true,
    rowsPerPage: numberOfRowTest,
    selectableRows: this.state.status === "COMPLETED" ? "none" : "multiple",
    rowsPerPageOptions: [10, 50, 100],
    onChangeRowsPerPage: (number) => (numberOfRowTest = number),
    customToolbar: () => (
      <CustomToolbar
        valueChangeDataTableDisplay={this.state.dataTableAvailableOrSelected}
        changeDataTableDisplay={async () => {
          await this.setState({
            dataTableAvailableOrSelected: !this.state.dataTableAvailableOrSelected,
          });
        }}
      />
    ),

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        valueChangeDataTableDisplay={this.state.dataTableAvailableOrSelected}
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        dataToChangeBetweenTable={(data) => this.moveDataFromItemsToSelected(data)}
      />
    ),
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
  };

  return (
    <MUIDataTable
      title={dataTableAvailableOrSelected ? "Emplacement(s) non-assigné(s)" : "Emplacement(s) assigné(s)"}
      data={this.createArrayForDataMenu()}
      columns={dataTableAvailableOrSelected ? columns : columnsSelected}
      options={options}
    />
  );
};

export const createArrayForDataMenu = function () {
  const { dataTableAvailableOrSelected, selected, items } = this.state;
  this.state.arrayDataTableMenu.length = 0;
  let arrayForCreateData = [];

  if (dataTableAvailableOrSelected === true) {
    arrayForCreateData = items;
  } else {
    arrayForCreateData = selected;
  }
  let rang = 0;

  const { router } = this.props;
  const { params } = router;

  return arrayForCreateData.map((row, index) => {
    rang++;
    const {
      // eslint-disable-next-line max-len
      routeTemplateLocationId,
      locationName,
      punctualLifting,
      customerItemId,
      nbContainers,
      weeklyFrequency,
      locationId,
      companyName,
      timesPerWeek,
      ciWebLinkId,
      item,
      done,
      anomaly,
      anomalyIds,
      visitRank,
      collectStop,
      collectStopTimestamp,
      rotationInfosForDriver,
    } = row;

    let punctualLift = "";
    if (punctualLifting === true || customerItemId) {
      punctualLift = "Levée ponctuelle";
    }

    const frequencyFormat = (
      <div>
        Chaque {weeklyFrequency} semaine(s)
        <br />
        {timesPerWeek} lévee(s) semaine
      </div>
    );

    const collectStopFormat = (
      <div>
        Arrêt de collecte:
        <br />
        {formattedDate(collectStopTimestamp)}
      </div>
    );

    const rank = visitRank ? visitRank : rang;
    const model = item ? item.itemProfile.model : "";
    const ciWebLabelcode = item ? item.ciWebLabelcode : "";

    const containerNoAndRotationInfos = (
      <Stack textAlign={"center"}>
        {ciWebLabelcode}
        <br />
        {rotationInfosForDriver && (
          <Tooltip
            placement={"bottom"}
            title={rotationInfosForDriver.split(":").pop()}
            sx={{ display: "block" }}
          >
            <Card
              variant="contained"
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: 12,
                fontWeight: "bold",
                padding: 1,
                borderRadius: 2,
                display: "block",
                backgroundColor: "#ffc490",
                "&:hover": { backgroundColor: "#f2fcff" },
              }}
            >
              ROTATION
            </Card>
          </Tooltip>
        )}
      </Stack>
    );

    const frequency = collectStop
      ? { collect_stop: true, label: collectStopFormat }
      : { collect_stop: false, label: frequencyFormat };

    return {
      routeTemplateLocationId,
      locationLink: (
        <Link to={`/customers/${params.customerId}/contracts/${params.contractId}/locations/${locationId}/`}>
          {locationName}
        </Link>
      ),
      locationName,
      index,
      rank,
      nbContainers,
      punctualLift,
      model,
      containerNoAndRotationInfos,
      frequency,
      locationId,
      companyName,
      ciWebLinkId,
      row,
      anomalyIds,
      done,
      anomaly,
    };
  });
};

export const moveDataFromItemsToSelected = async function (data) {
  const { dataTableAvailableOrSelected, originalItems, originalSelected } = this.state;
  let [destination, origin] = ["", ""];

  if (dataTableAvailableOrSelected) {
    origin = "items";
    destination = "selected";
  } else {
    origin = "selected";
    destination = "items";
  }

  let destinationObj = this.state[destination];
  if (!destinationObj) {
    destinationObj = [];
  }

  const originObjects = this.state[origin].filter((f) => data.map((e) => e.id).includes(f.routeTemplateLocationId));
  const newState = {
    [destination]: [...destinationObj, ...originObjects],
    [origin]: this.state[origin].filter((f) => !data.map((e) => e.id).includes(f.routeTemplateLocationId)),
    menuDataTableOpened: false,
  };

  const isSelectedEqual = originalSelected.every((e, i) => newState.selected[i] && e.id === newState.selected[i].id);
  const isItemsEqual = originalItems.length === newState.items.length;

  const isEdited = includes([isSelectedEqual, isItemsEqual], false);

  await this.setState({ ...newState, isEdited: isEdited });
};

export const updateArrayDataTableMenu = function (source, destination) {
  const { originalSelected } = this.state;

  const newSelected = move(this.state.selected, source, destination);

  const equals = originalSelected && originalSelected.every((e, i) => e.id === newSelected[i].id);

  this.setState({ selected: newSelected, isEdited: !equals });
};

export const handleMenuDataTable = function () {
  this.setState({
    menuDataTableOpened: !this.state.menuDataTableOpened,
  });
  this.createArrayForDataMenu();
};
