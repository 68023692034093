import RouteLocationHistoryResponse from "@@types/route-locations/route-location-history-response.type";
import RouteLocationHistory from "@@types/route-locations/route-location-history.type";

function mapResponseToRouteLocationHistory(response: RouteLocationHistoryResponse): RouteLocationHistory {
  return {
    id: response.id,
    locationId: response.locationId,
    routeId: response.routeId,
    visitTimestamp: response.visitTimestamp,
    anomalyIds: response.anomalyIds,
    containerModel: response.containerModel,
    containerNumber: response.containerNumber,
    containerChipNumber: response.containerChipNumber,
    status: response.status,
  };
}

function mapResponsesToRouteLocationHistories(responses: RouteLocationHistoryResponse[]): RouteLocationHistory[] {
  return responses.map(mapResponseToRouteLocationHistory);
}

export { mapResponseToRouteLocationHistory, mapResponsesToRouteLocationHistories };
