import { DialogTitle, Stack, Typography } from "@mui/material";
import { SimpleSwitch } from "@ui/SimpleSwitch";
import React from "react";

const handleChangeUserDisabledSwitch = (setUser) => (event, child) => {
  setUser((prevState) => ({
    ...prevState,
    status: {
      disabled: !child,
    },
  }));
};

export default function UserStatus({ user, setUser, type }) {
  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {type === "edit" ? "Modifier" : "Ajouter"} utilisateur
        <Stack direction={"row"} justifyContent="space-between" width={105} alignItems="center">
          <SimpleSwitch
            checked={!user.status.disabled}
            onChange={handleChangeUserDisabledSwitch(setUser)}
            aria-label="login switch"
          />
          <Typography>{user.status.disabled ? "Inactif" : "Actif"}</Typography>
        </Stack>
      </Stack>
    </DialogTitle>
  );
}
