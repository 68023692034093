import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const RefreshIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill={fillColor}
      >
        <g clipPath="url(#clip0_280_6495)">
          <path
            d="M9.58337 1.66667V4.16667H7.08337"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.416626 8.33333V5.83333H2.91663"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.46246 3.75001C1.67378 3.15283 2.03293 2.61892 2.5064 2.1981C2.97988 1.77727 3.55224 1.48324 4.17008 1.34345C4.78792 1.20365 5.43111 1.22265 6.03962 1.39866C6.64814 1.57468 7.20215 1.90197 7.64996 2.35001L9.58329 4.16667M0.416626 5.83334L2.34996 7.65001C2.79777 8.09804 3.35178 8.42534 3.9603 8.60135C4.56881 8.77737 5.212 8.79636 5.82984 8.65657C6.44768 8.51677 7.02004 8.22274 7.49351 7.80192C7.96699 7.38109 8.32614 6.84718 8.53746 6.25001"
            stroke={strokeColor}
            strokeOpacity="0.46"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_280_6495">
            <rect
              width="10"
              height="10"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};

export default RefreshIcon;
