// React imports
import React, { FC, ReactNode } from "react";
// External imports
import { Trans } from "react-i18next";

// Internal imports
import TableFilterStates from "@@types/common/table-filter-states.type";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: ReactNode };
};
const headerLabel: HeaderLabel = {
  startDate: { label: <Trans i18nKey="route_location.date" /> },
  masterRouteName: { label: "Route maître" },
  routeName: { label: <Trans i18nKey="route_location.name" /> },
  status: { label: <Trans i18nKey="jobs_history.status" /> },
  statusDot: { label: "Détail(s)" },
  resume: { label: "Résumé" },
  unit: { label: <Trans i18nKey="units.title" /> },
};

const header: string[] = ["startDate", "masterRouteName", "routeName", "unit", "status", "resume", "statusDot"];

type LocationRouteHistoryListHeaderProps = {
  filterStates: TableFilterStates;
  onSort: (property: string) => void;
};

const LocationRouteHistoryListHeader: FC<LocationRouteHistoryListHeaderProps> = ({ filterStates, onSort }) => {
  const { sort: { columnName, direction } = {} } = filterStates;
  const createSortHandler = (property: string) => () => {
    onSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {header.map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={columnName === name}
              direction={direction}
              onClick={createSortHandler(name)}
            >
              {headerLabel[name].label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default LocationRouteHistoryListHeader;
