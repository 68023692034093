import React, { FC } from "react";
import { Trans } from "react-i18next";

import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import useLocationCcavContainerDelete from "@components/location/route/containers/modals/hooks/location-container-modal-delete.hook";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogWrapper from "@ui/DialogWrapper";

type LocationCcavDeleteContainerModalProps = {
  openModalDeleteContainer: boolean;
  selectedLocation: number;
  selectedContainer: LocationCcavContainer;
  onClose: (modalType: string) => void;
};

const LocationCcavDeleteContainerModal: FC<LocationCcavDeleteContainerModalProps> = ({
  openModalDeleteContainer,
  selectedLocation,
  selectedContainer,
  onClose,
}) => {
  const { deleteContainer } = useLocationCcavContainerDelete(selectedLocation);
  return (
    <DialogWrapper
      width={40}
      open={openModalDeleteContainer}
    >
      <DialogTitle>
        <Trans i18nKey="delete" />
      </DialogTitle>

      <DialogContent>
        Êtes-vous certain de vouloir supprimer le conteneur ?
        <br />
        Numéro de conteneur: {selectedContainer.ciWebLabelcode || "INCONNUE"}
        <br />
        Numéro de puce: {selectedContainer.ciWebChipcode || "INCONNUE"}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose("delete")}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          variant="contained"
          onClick={() => deleteContainer(selectedContainer).then(() => onClose("delete"))}
        >
          <Trans i18nKey="delete" />
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default LocationCcavDeleteContainerModal;
