import React, { ReactElement } from "react";
import { MenuItem, MenuItemProps } from "@mui/material";

export type DropdownMenuItemProps = {
  value: string;
} & MenuItemProps;

const DropdownMenuItem = ({ value, ...remainingProps }: DropdownMenuItemProps): ReactElement => (
  <MenuItem value={value} {...remainingProps}></MenuItem>
);

export default DropdownMenuItem;
