import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const GeolocateAnomalyIcon = ({ size, strokeColor, fillColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="25"
        viewBox="0 0 22 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6561 10.4091C20.6561 17.7273 10.953 24 10.953 24C10.953 24 1.25 17.7273 1.25 10.4091C1.25 7.91364 2.27228 5.52041 4.09195 3.75586C5.91162 1.99131 8.37963 1 10.953 1C13.5264 1 15.9944 1.99131 17.8141 3.75586C19.6338 5.52041 20.6561 7.91364 20.6561 10.4091Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="7"
          width="20"
          height="8"
          rx="2"
          fill={fillColor}
        />
        <path
          d="M6.10899 10.876C6.10899 10.46 6.19499 10.09 6.36699 9.766C6.54299 9.438 6.79099 9.184 7.11099 9.004C7.43099 8.82 7.79899 8.728 8.21499 8.728C8.74299 8.728 9.18699 8.87 9.54699 9.154C9.90699 9.434 10.139 9.816 10.243 10.3H8.97699C8.90099 10.14 8.79499 10.018 8.65899 9.934C8.52699 9.85 8.37299 9.808 8.19699 9.808C7.92499 9.808 7.70699 9.906 7.54299 10.102C7.38299 10.294 7.30299 10.552 7.30299 10.876C7.30299 11.204 7.38299 11.466 7.54299 11.662C7.70699 11.854 7.92499 11.95 8.19699 11.95C8.37299 11.95 8.52699 11.908 8.65899 11.824C8.79499 11.74 8.90099 11.618 8.97699 11.458H10.243C10.139 11.942 9.90699 12.326 9.54699 12.61C9.18699 12.89 8.74299 13.03 8.21499 13.03C7.79899 13.03 7.43099 12.94 7.11099 12.76C6.79099 12.576 6.54299 12.322 6.36699 11.998C6.19499 11.67 6.10899 11.296 6.10899 10.876ZM11.3699 13.048C11.1619 13.048 10.9939 12.992 10.8659 12.88C10.7419 12.764 10.6799 12.618 10.6799 12.442C10.6799 12.266 10.7419 12.12 10.8659 12.004C10.9939 11.888 11.1619 11.83 11.3699 11.83C11.5739 11.83 11.7379 11.888 11.8619 12.004C11.9899 12.12 12.0539 12.266 12.0539 12.442C12.0539 12.614 11.9899 12.758 11.8619 12.874C11.7379 12.99 11.5739 13.048 11.3699 13.048ZM15.9466 10.18C15.9466 10.436 15.8866 10.67 15.7666 10.882C15.6506 11.09 15.4726 11.258 15.2326 11.386C14.9966 11.51 14.7066 11.572 14.3626 11.572H13.7806V13H12.6046V8.77H14.3626C14.7026 8.77 14.9906 8.83 15.2266 8.95C15.4666 9.07 15.6466 9.236 15.7666 9.448C15.8866 9.66 15.9466 9.904 15.9466 10.18ZM14.2486 10.636C14.5806 10.636 14.7466 10.484 14.7466 10.18C14.7466 9.872 14.5806 9.718 14.2486 9.718H13.7806V10.636H14.2486Z"
          fill="white"
        />
      </svg>
    </Container>
  );
};
