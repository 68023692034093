import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { LiftingRequest } from "@@types/lifting-requests/lifting-request.type";
import TableFilterStates from "@@types/common/table-filter-states.type";

const fetchContainerLiftingRequestsHistory = async (
  containerId: number,
  filterStates: TableFilterStates,
  registrationCategories: string[],
  liftingTypes: string[]
): Promise<ApiResponse<LiftingRequest[]>> => {
  const { filter = "", rowsPerPage = 10, page = 0 } = filterStates;

  try {
    const res = await http.get<ApiResponse<LiftingRequest[]>>(
      `/lifting_requests/container_lifting_requests_history?container_id=${containerId}&page=${
        page + 1
      }&limit=${rowsPerPage}&filter=${filter}&registrationCategories=${registrationCategories}&liftingTypes=${liftingTypes}`
    );
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchContainerLiftingRequestsHistory;
