import {
  Markerable,
} from "@@types/markers/locationMarker";
import { groupBy } from "lodash";

const offset = 0.00011;

// The total angle around a circle (360 degrees) is divided by the number of markers (updatedMarkers.length).
// This gives the angle a which represents the angular distance between each marker if they were evenly spaced around the circle.
const angularSeparation = 0.7;

export const offsetMarkers = <T extends Markerable>(markers: T[]): Record<string, T[]> => {
  const group = groupBy(markers, (x) => `${x.marker.position.lat}${x.marker.position.lng}`);

  return Object.keys(group).reduce(
    (acc, coordinates) => {
      if (group[coordinates].length > 1) {
        const markersOnSameSpot = group[coordinates];
        markersOnSameSpot.forEach((marker, index) => {
          if (index > 0) {
            const newLat =
              marker.marker.position.lat +
              offset * index * Math.cos(((angularSeparation * index) / 180) * Math.PI);
            acc[coordinates][index].marker.position = { lat: newLat, lng: marker.marker.position.lng };
          }
        });
      }
      return acc;
    },
    { ...group },
  );
};

export function offsetMarkersOnSameSpot<T extends Markerable>(markers: T[]): T[] {
  const offsetMarkersByCoordinates = offsetMarkers(markers);

  return Object.keys(offsetMarkersByCoordinates).reduce((acc, coordinates) => {
    acc.push(...offsetMarkersByCoordinates[coordinates]);
    return acc;
  }, [] as T[]);
}

type LegacyAddress = {
  lat: string;
  lng: string;
};
type LegacyMarker = {
  address?: LegacyAddress;
};

export function offsetLegacyMarkersOnSameSpot(markers: LegacyMarker[]): never[] {
  const group = groupBy(markers, (x) => (x.address ? `${x.address.lat}${x.address.lng}` : null));
  const updatedMarkers = [...markers];

  const a = 360.0 / updatedMarkers.length;

  if (Object.keys(group).length === 0) return [];

  Object.keys(group).forEach((key) => {
    if (group[key].length > 1) {
      const markersOnSameSpot = group[key];
      markersOnSameSpot.forEach((marker, index) => {
        if (index > 0) {
          const indexOf = updatedMarkers.indexOf(marker);
          if (marker.address) {
            const newLat = +marker.address.lat + offset * index * Math.cos(((a * index) / 180) * Math.PI);
            const newLng = +marker.address.lng + offset * index * Math.sin(((a * index) / 180) * Math.PI);
            updatedMarkers[indexOf].address = { lat: newLat.toString(), lng: newLng.toString() };
          }
        }
      });
    }
  });

  return updatedMarkers as never[];
}

export function offsetLegacyAddressesOnSameSpot(markers: LegacyAddress[]): never[] {
  const group = groupBy(markers, (x) => (x ? `${x.lat}${x.lng}` : null));
  const updatedMarkers = [...markers];

  const a = 360.0 / updatedMarkers.length;

  if (Object.keys(group).length === 0) return [];

  Object.keys(group).forEach((key) => {
    if (group[key].length > 1) {
      const markersOnSameSpot = group[key];
      markersOnSameSpot.forEach((marker, index) => {
        if (index > 0) {
          const indexOf = updatedMarkers.indexOf(marker);
          if (marker) {
            const newLat = +marker.lat + -0.00002 * index * Math.cos(((a * index) / 180) * Math.PI);
            const newLng = +marker.lng + -0.00002 * index * Math.sin(((a * index) / 180) * Math.PI);
            updatedMarkers[indexOf] = { lat: newLat.toString(), lng: newLng.toString() };
          }
        }
      });
    }
  });

  return updatedMarkers as never[];
}
