// React imports
import React, { useState, useEffect } from "react";
// Internal imports
import * as API from "@services";
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import useAsync from "@hooks/useAsync";
import TabsWrapper from "@ui/TabsWrapper";
import { sortedData } from "@utils/filtering";
import SalesTransactionsTab from "@components/sales/SalesTransactionsTab";
import SalesClientAccountTransactionsTab from "@components/sales/SalesClientAccountTransactionsTab";
import SalesWebTransactionsTab from "@components/sales/SalesWebTransactionsTab";
import SalesUnusedPaidJobsTab from "@components/sales/SalesUnusedPaidJobsTab";
import SalesRefundedTransactionTab from "@components/sales/SalesRefundedTransactionTab";
// External imports
import { Tab, TableContainer } from "@mui/material";
import { debounce } from "lodash";

const filtersInitialState = {
  limit: 10,
  page: 0,
  filter: "",
  columnName: "transactionDate",
  direction: "desc",
};

const handleTabChange = (setTabValue, setFilters) => (event, value) => {
  setFilters(filtersInitialState);
  setTabValue(value);
};

const handleRowsPerPageChange = (setFilters) => (event) => {
  setFilters((prevState) => ({ ...prevState, limit: event.target.value }));
};

const handleSearchFilter = (setFilters) =>
  debounce((event) => {
    setFilters((prevState) => ({ ...prevState, filter: event.target.value }));
  }, 500);

const fetchPaidCustomerItemsAsync = async (setPaidCustomerItems, setCount, tabValue, filters) => {
  let res;

  const pageNumber = filters.page + 1;

  switch (tabValue) {
    case 0:
      res = await API.PaidCustomerItem.fetchTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 1:
      res = await API.PaidCustomerItem.fetchWebTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 2:
      res = await API.PaidCustomerItem.fetchUnusedPaidJobs(pageNumber, filters.limit, filters.filter);
      break;
    case 3:
      res = await API.PaidCustomerItem.fetchRefundedTransactions(pageNumber, filters.limit, filters.filter);
      break;
    case 4:
      break;

    default:
      console.log("[SalesPage] fetchPaidCustomerItemsAsync error");
  }

  const sortedResponse = sortedData(res.data.data, filtersInitialState);

  setPaidCustomerItems(sortedResponse);
  setCount(res.data.count);
};

export default function SalesPage() {
  const [paidCustomerItems, setPaidCustomerItems] = useState([]);
  const [count, setCount] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [filters, setFilters] = useState(filtersInitialState);

  useEffect(() => {
    fetchPaidCustomerItems.run();
  }, [tabValue, filters]);

  const fetchPaidCustomerItems = useAsync(() =>
    fetchPaidCustomerItemsAsync(setPaidCustomerItems, setCount, tabValue, filters)
  );

  return (
    <PageContainer>
      <TabsWrapper
        indicatorColor="primary"
        onChange={handleTabChange(setTabValue, setFilters)}
        textColor="primary"
        value={tabValue}
      >
        <Tab label={"Transactions CC"} value={4} />
        <Tab label={"Transactions Cartes"} value={0} />
        <Tab label={"Transactions web"} value={1} />
        <Tab label={"Tâches payées / non utilisées"} value={2} />
        <Tab label={"Remboursements"} value={3} />
      </TabsWrapper>
      <PaperWrapper>
        <TableContainer>
          {tabValue === 0 && (
            <SalesTransactionsTab
              transactions={paidCustomerItems}
              count={count}
              filters={filters}
              setFilters={setFilters}
              handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
              handleSearchFilter={() => handleSearchFilter(setFilters)}
            />
          )}

          {tabValue === 1 && (
            <SalesWebTransactionsTab
              webTransactions={paidCustomerItems}
              count={count}
              filters={filters}
              setFilters={setFilters}
              handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
              handleSearchFilter={() => handleSearchFilter(setFilters)}
            />
          )}

          {tabValue === 2 && (
            <SalesUnusedPaidJobsTab
              unusedPaidJobs={paidCustomerItems}
              count={count}
              filters={filters}
              setFilters={setFilters}
              handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
              handleSearchFilter={() => handleSearchFilter(setFilters)}
            />
          )}

          {tabValue === 3 && (
            <SalesRefundedTransactionTab
              refundedTransactions={paidCustomerItems}
              count={count}
              filters={filters}
              setFilters={setFilters}
              handleRowsPerPageChange={() => handleRowsPerPageChange(setFilters)}
              handleSearchFilter={() => handleSearchFilter(setFilters)}
            />
          )}

          {tabValue === 4 && (
            <SalesClientAccountTransactionsTab/>
          )}

        </TableContainer>
      </PaperWrapper>
    </PageContainer>
  );
}
