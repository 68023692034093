import React, {useEffect} from "react";
import {
  Stack,
  FormControl,
  InputLabel, Input, DialogTitle, Button
} from "@mui/material";
import {Save} from "@mui/icons-material";
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";

const handleInputsFuelChargeTaxNew = (setFuelChargeTaxes) => (event) => {

  const { name, value } = event.target;

  setFuelChargeTaxes(prevState => ({
    ...prevState,
    newTax: { ...prevState.newTax, data: {...prevState.newTax.data, [name]: value } },
  }));
};

const FuelChargeTaxesNewItem = ({open, onClose, onSave,  fuelChargeTaxes, setFuelChargeTaxes }) => {

  return (
    <DialogWrapper onClose={onClose} open={open}>
      <DialogTitle>Ajouter frais de carburant</DialogTitle>
      <Stack direction="row" alignItems='flex-end' spacing={2} p={3}>
        <FormControl variant="standard" sx={{ width: 300 }}>
          <InputLabel shrink>Nom</InputLabel>
          <Input
            name="name"
            onChange={handleInputsFuelChargeTaxNew(setFuelChargeTaxes)}
            value={fuelChargeTaxes.newTax.data.name}
            error={fuelChargeTaxes.newTax.errors.name}
          />
        </FormControl>

        <FormControl variant="standard" sx={{ width: 75 }}>
          <InputLabel shrink>%</InputLabel>
          <Input
            name="percent_value"
            type="number"
            inputProps={{min: "0", step: "0.1"}}
            onChange={handleInputsFuelChargeTaxNew(setFuelChargeTaxes)}
            value={fuelChargeTaxes.newTax.data.percent_value}
            error={fuelChargeTaxes.newTax.errors.percent_value}
          />
        </FormControl>

        <FormControl variant="standard" sx={{ width: 150 }}>
          <InputLabel shrink>Début</InputLabel>
          <Input
            name="effective_date"
            type="date"
            onChange={handleInputsFuelChargeTaxNew(setFuelChargeTaxes)}
            value={fuelChargeTaxes.newTax.data.effective_date ?? ""}
            error={fuelChargeTaxes.newTax.errors.effective_date}
          />
        </FormControl>

        <FormControl variant="standard" sx={{ width: 150 }}>
          <InputLabel shrink>Fin</InputLabel>
          <Input
            name="end_date"
            type="date"
            onChange={handleInputsFuelChargeTaxNew(setFuelChargeTaxes)}
            value={fuelChargeTaxes.newTax.data.end_date  ?? ""}
            error={fuelChargeTaxes.newTax.errors.end_date}
          />
        </FormControl>
      </Stack>
      <Stack display="flex" justifyContent="flex-end" p={1} spacing={1} direction="row">
        <Button onClick={onClose}>
          Annuler
        </Button>

        <LoadingButton
          startIcon={<Save />}
          onClick={onSave}
          loadingPosition="start"
        >
          Enregister
        </LoadingButton>
      </Stack>
    </DialogWrapper>
  );
};

export default function FuelChargeTaxesNew({ open, onClose, onSave, fuelChargeTaxes, setFuelChargeTaxes}) {

  // Handles the validation of each desired fields
  useEffect(() => {
    const validateNewTaxeInputs = ["name", "percent_value", "effective_date", "end_date"];
    // If a field was marked as an errors but now it's truthy, remove the errors
    validateNewTaxeInputs.forEach((key) => {
      if (fuelChargeTaxes.newTax.data[key]) {
        fuelChargeTaxes.newTax.errors[key] && setFuelChargeTaxes(prevState => ({
          ...prevState,
          newTax: {...prevState.newTax, errors: { ...prevState.newTax.errors, [key]: false }}
        }));
      }
    });
  }, [fuelChargeTaxes.newTax.data]);

  return (
    <FuelChargeTaxesNewItem
      open={open}
      onClose={onClose}
      onSave={onSave}
      fuelChargeTaxes={fuelChargeTaxes}
      setFuelChargeTaxes={setFuelChargeTaxes}
    />
  );
}
