import axios from "axios";

import { requestOptions } from "../auth";

const fetchLastCustomerItem = async (contractId, locationId) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/customer_items/last_contract_location?contract_id=${contractId}&location_id=${locationId}`,
      requestOptions()
    );

    if (response.status === 200) {
      return response.data.name;
    }
  } catch (error) {
    console.warn("[API.fetchLastCustomerItem] Error:", error);
  }
};

const fetchJobComputedPrice = async (
  customerItemId,
  jobTemplateId,
  customerLocationId,
  customerDestinationId = null,
  locationAreaCustomFee = null,
  destinationAreaCustomFee = null,
  locationCustomAreaCode = null,
  destinationCustomAreaCode = null
) => {
  try {
    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/customer_items/compute_price?id=${customerItemId}&job_template_id=${jobTemplateId}&customer_location_id=${customerLocationId}&customer_destination_location_id=${customerDestinationId}&location_area_custom_fee=${locationAreaCustomFee}&destination_area_custom_fee=${destinationAreaCustomFee}&location_custom_area_code=${locationCustomAreaCode}&destination_custom_area_code=${destinationCustomAreaCode}`,
      requestOptions()
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.warn("[API.fetchLastCustomerItem] Error:", error);
    return error.response.data;
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchLastCustomerItem(contractId, locationId)`,
 * - `fetchJobComputedPrice(customerItemId, jobTemplateId, CustomerLocationId)`,
 */
export { fetchLastCustomerItem, fetchJobComputedPrice };
