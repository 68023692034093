import axios from "axios";

import { requestOptions } from "../auth";

class GetCurrentUserService {
  static async get() {
    try {
      const { data: responseData } = await axios.get(
        `${import.meta.env.VITE_API_URL}/users/signed_in_user`,
        requestOptions()
      );

      return responseData.data;
    } catch (error) {
      console.warn("[GetCurrentUserService.get] Error:", error);

      throw error;
    }
  }
}

export default GetCurrentUserService;
