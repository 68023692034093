import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const ModalityIcon = ({
                               size = defaultIconProps.size,
                               fillColor = defaultIconProps.fillColor,
                             }: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={fillColor} />
        <path d="M35 16V22H29" fill={fillColor} />
        <path d="M35 16V22H29" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 32.002V26.002H19" fill={fillColor} />
        <path d="M13 32.002V26.002H19" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M15.51 21.0006C16.0172 19.5674 16.8791 18.286 18.0155 17.276C19.1518 16.266 20.5255 15.5603 22.0083 15.2248C23.4911 14.8893 25.0348 14.9349 26.4952 15.3573C27.9556 15.7798 29.2853 16.5653 30.36 17.6406L35 22.0006M13 26.0006L17.64 30.3606C18.7147 31.4358 20.0444 32.2213 21.5048 32.6437C22.9652 33.0663 24.5089 33.1117 25.9917 32.7764C27.4745 32.4409 28.8482 31.7351 29.9845 30.7251C31.1209 29.7152 31.9828 28.4339 32.49 27.0006"
          fill={fillColor} />
        <path
          d="M15.51 21.0006C16.0172 19.5674 16.8791 18.286 18.0155 17.276C19.1518 16.266 20.5255 15.5603 22.0083 15.2248C23.4911 14.8893 25.0348 14.9349 26.4952 15.3573C27.9556 15.7798 29.2853 16.5653 30.36 17.6406L35 22.0006M13 26.0006L17.64 30.3606C18.7147 31.4358 20.0444 32.2213 21.5048 32.6437C22.9652 33.0663 24.5089 33.1117 25.9917 32.7764C27.4745 32.4409 28.8482 31.7351 29.9845 30.7251C31.1209 29.7152 31.9828 28.4339 32.49 27.0006"
          stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </Container>
  );
};
