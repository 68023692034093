import React from "react";
import { List, ListItem, ListItemText, Avatar, Stack } from "@mui/material";
// Material-UI icon imports
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SmsIcon from "@mui/icons-material/Sms";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { isArrayTruthy } from "@utils/common";
import { formattedPhoneNumber } from "@utils/phoneNumber";

const RouteTemplateName = styled.div`
  display: block;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #b9b9b9;
  }
`;

const handleRouteTemplateNameClick = (routeTemplate, router) => () => {
  router.navigate(
    `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${routeTemplate.customer_item_id}/route_template_locations?masterRouteId=${routeTemplate.master_route_id}&routeTemplateIds=${routeTemplate.id}`
  );
};

const RouteTemplates = ({ associatedSubRouteTemplates, router }) => {
  return associatedSubRouteTemplates.map((routeTemplate) => {
    return (
      <ListItemText
        style={{ paddingLeft: 10 }}
        secondary={
          <React.Fragment>
            <Stack direction="column">
              <RouteTemplateName onClick={handleRouteTemplateNameClick(routeTemplate, router)}>
                {" — " + routeTemplate.master_route + "-" + routeTemplate.name}
              </RouteTemplateName>
            </Stack>
          </React.Fragment>
        }
      />
    );
  });
};

export const LocationItemLeftTabContent = (props) => {
  const { contractNames, addressString, relatedLocations, ciWebCategory, isFromCiWeb, id } = props.data;
  const { selectedLocationId, changeSelectedLocation, path } = props;

  let locations = [];
  if (isFromCiWeb) {
    const baseLocation = {
      id: id,
      ciWebCategory: ciWebCategory,
      addressString: addressString,
    };
    const baseLocationRelatedLocations = relatedLocations.map((location) => {
      return {
        id: location.id,
        ciWebCategory: location.ci_web_category,
        addressString: location.address_string,
      };
    });

    const allLocations = [baseLocation, ...baseLocationRelatedLocations];
    const residential = allLocations.find((location) => location.ciWebCategory === "residential_poi");
    const collects = allLocations.filter((location) => location.id !== residential.id).sort((a, b) => a.id - b.id); // seulement pour que les locations sois toujours dans le meme ordre;
    locations = [residential, ...collects];
  }

  const contractNamesListItemSecondarySpans = contractNames.map((contractName) => (
    <p
      style={{ margin: 0, marginBottom: 4 }}
      key={contractName}
    >
      {contractName}
    </p>
  ));
  return (
    <List>
      <ListItem>
        <Avatar>
          <AssignmentIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="Nom des contrats"
          secondary={contractNamesListItemSecondarySpans}
        />
      </ListItem>
      {locations.length
        ? locations.map((location) => {
            return (
              <ListItem
                style={{ backgroundColor: selectedLocationId === location.id && "burlywood" }}
                onClick={() => changeSelectedLocation(location.id)}
              >
                <Avatar>
                  <LocationOnIcon />
                </Avatar>
                <ListItemText
                  style={{ paddingLeft: 10 }}
                  primary={
                    location.ciWebCategory === "residential_poi" ? "Adresse administrative" : "Adresse de collecte"
                  }
                  secondary={location.addressString ? location.addressString.toUpperCase() : "Information manquante"}
                />
              </ListItem>
            );
          })
        : ""}
      {!isFromCiWeb && (
        <ListItem>
          <Avatar>
            <LocationOnIcon />
          </Avatar>
          <ListItemText
            style={{ paddingLeft: 10 }}
            primary="Adresse"
            secondary={addressString ? addressString.toUpperCase() : "Information manquante"}
          />
        </ListItem>
      )}
    </List>
  );
};

export const LocationItemRightTabContent = (props) => {
  const { isRollOff, router } = props;
  const {
    name,
    vdqAreaName,
    fileNumber,
    ciWebLinkId,
    note,
    ciWebNotes,
    isFromCiWeb,
    purchaseOrderNo,
    addressString,
    associatedSubRouteTemplates,
    smsAlertPhoneNumber,
  } = props.data;

  return (
    <List>
      <ListItem>
        <Avatar>
          <AssignmentIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="Nom de l'emplacement"
          secondary={name || addressString}
        />
      </ListItem>
      {/* {isRollOff || <ListItem alignItems="flex-start"> */}
      {/*    <Avatar> */}
      {/*        <PersonIcon /> */}
      {/*    </Avatar> */}
      {/*    <ListItemText primary="Nom du client" secondary={companyName || "Information manquante"} /> */}
      {/* </ListItem>} */}
      <ListItem>
        <Avatar>
          <FolderIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="No. du dossier"
          secondary={fileNumber || ciWebLinkId || "Information manquante"}
        />
      </ListItem>
      {isRollOff && (
        <ListItem>
          <Avatar>
            <FolderIcon />
          </Avatar>
          <ListItemText
            style={{ paddingLeft: 10 }}
            primary="No. bon de commande"
            secondary={purchaseOrderNo || "Information manquante"}
          />
        </ListItem>
      )}
      <>
        {isRollOff || (
          <ListItem>
            <Avatar>
              <LocationSearchingIcon />
            </Avatar>
            <ListItemText
              style={{ paddingLeft: 10 }}
              primary="Arrondissement"
              secondary={vdqAreaName || "Information manquante"}
            />
          </ListItem>
        )}
      </>
      <ListItem>
        <Avatar>
          <NotesIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="Note de l'emplacement"
          secondary={note || "Information manquante"}
          classes={{
            secondary: css`
              text-align: "justify";
              word-break: "break-all";
            `,
          }}
        />
      </ListItem>
      <>
        {isRollOff && (
          <ListItem>
            <Avatar>
              <SmsIcon />
            </Avatar>
            <ListItemText
              style={{ paddingLeft: 10 }}
              primary="Alerte SMS"
              secondary={formattedPhoneNumber(smsAlertPhoneNumber) || "Information manquante"}
              classes={{
                secondary: css`
                  text-align: "justify";
                  word-break: "break-all";
                `,
              }}
            />
          </ListItem>
        )}
      </>
      <>
        {!isFromCiWeb || (
          <ListItem>
            <Avatar>
              <NotesIcon />
            </Avatar>
            <ListItemText
              style={{ paddingLeft: 10 }}
              primary="Notes VDQ"
              secondary={ciWebNotes || "Information manquante"}
              classes={{
                secondary: css`
                  text-align: "justify";
                  word-break: "break-all";
                `,
              }}
            />
          </ListItem>
        )}
        <>
          {isArrayTruthy(associatedSubRouteTemplates) && (
            <ListItem>
              <Avatar>
                <DirectionsCarIcon />
              </Avatar>
              <Stack direction="column">
                <ListItemText
                  style={{ paddingLeft: 10 }}
                  primary="Sous-routes"
                />
                <RouteTemplates
                  associatedSubRouteTemplates={associatedSubRouteTemplates}
                  router={router}
                />
              </Stack>
            </ListItem>
          )}
        </>
      </>
    </List>
  );
};
