import RouteHistory from "@@types/routes/route-history.type";
import RouteHistoryResponse from "@@types/routes/route-history-response.type";
import { mapResponsesToRouteLocationHistories } from "@@types/route-locations/mappers/mapResponseToRouteLocationHistory";

function mapResponseToRouteHistory(response: RouteHistoryResponse): RouteHistory {
  return {
    customerItemId: response.customerItemId,
    name: response.name,
    originalStartDate: response.originalStartDate,
    masterRouteName: response.masterRouteName,
    locationStatus: response.locationStatus,
    routeLocations: mapResponsesToRouteLocationHistories(response.routeLocations),
    unitNames: response.unitNames,
    id: response.id,
    assigned: response.assigned,
    rotationInfos: response.rotationInfos,
  };
}

function mapResponsesToRouteHistories(responses: RouteHistoryResponse[]): RouteHistory[] {
  return responses.map(mapResponseToRouteHistory);
}

export { mapResponseToRouteHistory, mapResponsesToRouteHistories };
