import { axiosClientV2 as http } from "../axios";
import LocationDetailResponse from "@@types/location-details/location-detail-response.type";
import { ApiResponse } from "@@types/apiResponse";

const fetchLocationCcavModalities = async (location_id: number): Promise<ApiResponse<LocationDetailResponse[]>> => {
  try {
    const res = await http.get<ApiResponse<LocationDetailResponse[]>>(
      `/location_details/location_ccav_modalities?location_id=${location_id}`
    );

    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchLocationCcavModalities;
