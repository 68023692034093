import { Button, DialogActions, DialogContent, DialogTitle, FormGroup } from "@mui/material";
import { find, orderBy } from "lodash";
import { withCustomerLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";

import DialogWrapper from "./ui/DialogWrapper";
import Autocomplete from "./ui/Autocomplete";

const initialState = {
  customerLocation: -1,
};

class ModalContractLocation extends PureComponent {
  state = initialState;

  async componentDidMount() {
    console.log("COMPONENT DID MOUNT LOADING ALL CUSTOMER LOCATIONS - ModalContractLocations");
    const { customerId, fetchCustomerLocations, flushCustomerLocations } = this.props;

    await flushCustomerLocations();
    await fetchCustomerLocations(customerId, { limit: -1 });
  }

  handleChangeCustomerLocation = () => (value) => {
    this.setState({ customerLocation: value });
  };

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { callback, customerLocations, onClose } = this.props;

    const { customerLocation } = this.state;
    // eslint-disable-next-line max-len
    const locationObject = find(
      customerLocations,
      (cl) => cl.id.toString() === customerLocation.value.toString()
    );
    // eslint-disable-next-line max-len
    callback(
      { value: locationObject.contractId, label: locationObject.contractName },
      { value: locationObject.id, label: locationObject.name }
    );

    this.setState(initialState);

    onClose();
  };

  renderLocationContractList = (data) =>
    orderBy(
      data.map(({ id, name, contractName }) => ({ value: id, label: `${name} (${contractName})` })),
      ["label"],
      ["asc"]
    );

  render() {
    const { actionName, customerLocationsLoading, customerLocations } = this.props;
    const { customerLocation } = this.state;

    if (customerLocationsLoading || !customerLocations.length) {
      return null;
    }

    return (
      <DialogWrapper id="contract-location-modal" onClose={this.handleClose} open={this.props.open}>
        <DialogTitle>
          <Trans i18nKey="select_location" />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Autocomplete
                id="cpbr-location-for-contract"
                value={customerLocation}
                options={this.renderLocationContractList(customerLocations)}
                onChange={this.handleChangeCustomerLocation(customerLocation)}
                placeholder="Saisir l'emplacement"
                dropDownMenuPortalTarget="#contract-location-modal"
              />
            </FormGroup>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalContractLocation.defaultProps = {
  customerLocationsLoading: true,
};

ModalContractLocation.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  callback: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLocationsLoading: PropTypes.bool,
  fetchCustomerLocations: PropTypes.func.isRequired,
  flushCustomerLocations: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withCustomerLocations(ModalContractLocation);
