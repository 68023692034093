import { QueryKey } from "@tanstack/react-query";

const liftingRequest = {
  all: (): QueryKey => ["lifting-requests"],
  lists: (): QueryKey => [...liftingRequest.all(), "list"] as const,
  list: (filters: object): QueryKey => [...liftingRequest.lists(), { filters }] as const,
  details: (): QueryKey => [...liftingRequest.all(), "detail"] as const,
  detail: (id: number): QueryKey => [...liftingRequest.details(), id.toString()] as const,
  timeLapse: (id: number, timeLapse: string): QueryKey => [...liftingRequest.detail(id), timeLapse] as const,
};
export default liftingRequest;
