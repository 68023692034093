import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { SupplierResponse } from "@@types/suppliers/supplier-response.type";

const fetchSuppliersList = async (): Promise<SupplierResponse[] | undefined> => {
  try {
    const res = await http.get<ApiResponse<SupplierResponse[]>>(`/suppliers`);
    return res.data?.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchSuppliersList;
