import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import LocationCcavContainerResponse from "@@types/containers/location-ccav-container-response.type";

const fetchLocationCcavContainersService = async (
  locationId: number
): Promise<ApiResponse<LocationCcavContainerResponse[]>> => {
  try {
    const res = await http.get<ApiResponse<LocationCcavContainerResponse[]>>(
      `/locations/${locationId}/ccav_containers.json`
    );

    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchLocationCcavContainersService;
