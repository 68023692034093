import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { orderBy } from "lodash";
import React, { useState, useMemo } from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

import FloatingActionButton from "../ui/FloatingActionButton";
import ModalUnit from "../ModalUnit";
import ModalWarning from "../ModalWarning";
import PageContainer from "../ui/PageContainer";
import PaperWrapper from "../ui/PaperWrapper";
import TableOverflowWrapper from "../ui/TableOverflowWrapper";
import TablePaginationWrapper from "../ui/TablePaginationWrapper";
import TextFieldUi from "../ui/TextField";
import { withRouter } from "@utils/withRouter";

import * as API from "@services";

import useToggle from "@hooks/useToggle";
import useFilterAndPagination from "@hooks/useFilterAndPagination";
import useUnit from "@hooks/units";
import useLogStateChange from "@hooks/useLogStateChange";

const data = {
  id: { label: <Trans i18nKey="units.identifier" /> },
  loadingType: { label: <Trans i18nKey="units.loading_type" /> },
  name: { label: <Trans i18nKey="units.name" /> },
  truckBrand: { label: <Trans i18nKey="units.truck_brand" /> },
  truckModel: { label: <Trans i18nKey="units.truck_model" /> },
  year: { label: <Trans i18nKey="units.year" /> },
};

const ActionWrapper = styled.div``;

const UnitsTableHead = ({ onRequestSort, filterStates }) => {
  const { filter, rowsPerPage, page, sort: { columnName, direction } = {} } = filterStates;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {["id", "name", "loadingType", "truckBrand", "truckModel", "year"].map((name) => (
          <TableCell key={name}>
            <TableSortLabel
              active={columnName === name}
              direction={direction}
              onClick={createSortHandler(name)}
            >
              {data[name].label}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell />
      </TableRow>
    </TableHead>
  );
};

const UnitsTableBody = ({ data, onRowClick, onDeleteUnitClick }) => {
  if (data?.length <= 0) return;

  return data.map((unit) => {
    const { id, loadingType, name, truckBrand, truckModel, year } = unit;

    return (
      <TableRow
        key={id}
        className="link-row"
        onClick={onRowClick(`/units/${id}`)}
      >
        <TableCell>{id}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{loadingType}</TableCell>
        <TableCell>{truckBrand}</TableCell>
        <TableCell>{truckModel}</TableCell>
        <TableCell>{year}</TableCell>

        <TableCell classes={{ root: "action-cell" }}>
          <IconButton
            color="secondary"
            id="cpbr-delete-unit"
            onClick={onDeleteUnitClick(true, id)}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
};

function UnitsList(props) {
  const [unitToEdit, setUnitToEdit] = useState({});
  const [unitToDelete, setUnitToDelete] = useState();

  const unitModalHandler = useToggle();
  const deleteUnitModalHandler = useToggle();

  const { filterStates, updateFilterStates } = useFilterAndPagination();

  const { data: units, refetch: refetchUnits } = useUnit.fetchUnits(filterStates);

  const rows = useMemo(() => {
    return orderBy(units?.instances, [filterStates.sort.columnName], [filterStates.sort.direction]);
  }, [units?.instances, filterStates.sort]);

  const handleRequestSort = (event, columnName) => {
    const isAsc = filterStates.sort.columnName === columnName && filterStates.sort.direction === "asc";
    updateFilterStates.setSort(columnName, isAsc ? "desc" : "asc");
  };

  const handleDeleteUnit = async () => {
    await API.Unit.deleteUnit(unitToDelete);

    setUnitToDelete(null);
    deleteUnitModalHandler.setState.off();

    refetchUnits();
  };

  const handleChangeRowsPerPage = (event) => {
    updateFilterStates.setRowsPerPage(parseInt(event.target.value, 10));
    updateFilterStates.setPage(0);
  };

  const handleRowClick = (path) => () => {
    props.router.navigate(path);
  };

  const handleToggleDeleteUnitModal = (opened, unitId) => (e) => {
    e.stopPropagation();

    setUnitToDelete(opened ? unitId : null);
    deleteUnitModalHandler.setState.toggle();
  };

  const handleChangePage = (event, newPage) => {
    updateFilterStates.setPage(newPage);
  };

  const handleToggleUnitModal = (opened, unit) => () => {
    setUnitToEdit(opened ? unit : {});
    unitModalHandler.setState.toggle();
  };

  const handleFilter = (event) => {
    event.persist();
    updateFilterStates.setFilter(event.target.value);
  };

  return (
    <PageContainer>
      <PaperWrapper>
        <ActionWrapper>
          <FloatingActionButton
            color="secondary"
            onClick={handleToggleUnitModal(true, unitToEdit)}
          >
            <AddIcon />
          </FloatingActionButton>
        </ActionWrapper>

        <TableOverflowWrapper>
          <Toolbar>
            <TextFieldUi
              id="cpbr-filtre"
              label={<Trans i18nKey="filter" />}
              onChange={handleFilter}
              type="search"
              value={filterStates.filter}
            />
          </Toolbar>

          <Table>
            <UnitsTableHead
              filterStates={filterStates}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              <UnitsTableBody
                data={rows}
                onRowClick={handleRowClick}
                onDeleteUnitClick={handleToggleDeleteUnitModal}
              />
            </TableBody>
          </Table>
        </TableOverflowWrapper>

        <TablePaginationWrapper
          component="div"
          count={units?.count || 0}
          id="cpbr-pagination"
          labelRowsPerPage=""
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={filterStates.page}
          rowsPerPage={filterStates.rowsPerPage}
        />
      </PaperWrapper>

      {unitModalHandler.state && (
        <ModalUnit
          key={unitToEdit?.id}
          actionName={unitToEdit?.id ? <Trans i18nKey="edit" /> : <Trans i18nKey="add" />}
          unit={unitToEdit}
          onClose={handleToggleUnitModal(false)}
          open={unitModalHandler.state}
          refreshList={refetchUnits}
        />
      )}

      <ModalWarning
        onCancel={handleToggleDeleteUnitModal(false)}
        onSubmit={handleDeleteUnit}
        open={deleteUnitModalHandler.state}
        title={<Trans i18nKey="warning" />}
      >
        <Trans i18nKey="warning_delete_unit" />
      </ModalWarning>
    </PageContainer>
  );
}

export default withRouter(UnitsList);
