import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const PhotoWrapper = styled.div`
  background-color: #f3f3f3;
`;

const Photo = styled.img`
  display: inherit;
  width: 100%;
  height: auto;
  max-width: 410px;
  max-height: 348px;
  margin: 0 auto;
`;

type ModalAnomaliesPhotosProps = {
  photos: string[] | null;
};

const ModalAnomaliesPhotos: FC<ModalAnomaliesPhotosProps> = ({ photos }): ReactElement | ReactElement[] => {
  return photos?.length ? (
    <Stack
      direction="row"
      spacing={1}
    >
      {photos.map((photo, index) => {
        return (
          <PhotoWrapper key={index}>
            <Photo src={photo} />
          </PhotoWrapper>
        );
      })}
    </Stack>
  ) : (
    <div>Pas de photo associée au cas problème</div>
  );
};
export default ModalAnomaliesPhotos;
