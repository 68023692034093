import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export default function UsersTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Nom&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Email&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Rôles&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Actif&nbsp;
        </TableCell>
        <TableCell align="left" sx={{ fontSize: 13 }}>
          Modifier&nbsp;
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
