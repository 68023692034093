import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import React, { useCallback, useState } from "react";

import HeaderTitle from "./HeaderTitle";
import Menu from "./Menu";
import useCurrentRoles from "@hooks/auth/useCurrentRoles";

const AppBarSC = styled(AppBar)`
  flex: none;

  button {
    margin-right: 15px;
  }
`;

const BurgerMenuIcon = styled(MenuIcon)`
  &.icon-color {
    color: ${(props) => props.theme.colors.basic.white};
  }
`;

const Header = () => {
  const { isAdmin, isAccountant } = useCurrentRoles();
  const [menuOpened, setMenuOpened] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setMenuOpened((prevState) => !prevState);
  }, []);

  return (
    <AppBarSC position="static" elevation={0}>
      <Toolbar>
        <IconButton
          color="secondary"
          aria-label="Menu"
          onClick={handleToggleMenu}
          size="large"
          data-cy="menuHeaderButton"
        >
          <BurgerMenuIcon classes={{ root: "icon-color" }} />
        </IconButton>

        <Menu
          opened={menuOpened}
          toggleMenu={handleToggleMenu}
          disablePrices={!isAccountant}
          isUserAdmin={isAdmin}
        />

        <HeaderTitle />
      </Toolbar>
    </AppBarSC>
  );
};

export default Header;
