import styled from "@emotion/styled";

const FlexHeaderRowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    ${theme.breakpoint.md} {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`;

export default FlexHeaderRowWrapper;
