// React imports
import React, { FC } from "react";
// Externals imports
import { IconButton, TableCell, TableRow } from "@mui/material";
import LocationDetail from "@@types/location-details/location-detail.type";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { formattedDate } from "@utils/dates";

type LocationModality = {
  locationModalities: LocationDetail[];
  onClick: (modalType: string, modality: LocationDetail) => void;
  isFromCiWeb: boolean;
};

const LocationModalitiesListBody: FC<LocationModality> = ({ locationModalities, onClick, isFromCiWeb }) => {
  return locationModalities.map((modality) => {
    const weekDays = [
      modality.monday,
      modality.tuesday,
      modality.wednesday,
      modality.thursday,
      modality.friday,
      modality.saturday,
      modality.sunday,
    ];

    return (
      <TableRow key={`${modality.locationDetailDaysOfWeekId} ${modality.locationDetailFrequencyId}`}>
        <TableCell>{modality.matter.label}</TableCell>
        <TableCell>{modality.from.value ? formattedDate(modality.from.value) : ""}</TableCell>
        <TableCell>{modality.until.value ? formattedDate(modality.until.value) : ""}</TableCell>
        <TableCell>{modality.frequency.label}</TableCell>
        {Object.values(weekDays).map((day) => {
          return <TableCell key={day.label}>{day.value ? day.value : ""}</TableCell>;
        })}
        <TableCell>
          <IconButton
            disabled={isFromCiWeb}
            color="secondary"
            onClick={() => onClick("update", modality)}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            disabled={isFromCiWeb}
            color="secondary"
            onClick={() => onClick("delete", modality)}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
};

export default LocationModalitiesListBody;
