// React imports
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Trans } from "react-i18next";
// Internal imports
import * as API from "@services";
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import { withRouter } from "@utils/withRouter";
import AccountingItemParent from "@components/accountingItemsPricesAndTaxes/AccountingItemPrices/AccountingItemParent";
import useAsync from "@hooks/useAsync";
import { withListCodes } from "optigo-redux";
import { isArrayTruthy, isObjectTruthy } from "@utils/common";
import FuelChargeTaxes from "@components/accountingItemsPricesAndTaxes/Taxes/FuelChargeTaxes";
import AccountingItemSelects from "@components/accountingItemsPricesAndTaxes/AccountingItemPrices/AccountingItemSelects";
import TabsWrapper from "@ui/TabsWrapper";
import LoaderSpinner from "@components/LoaderSpinner";
import useNotifier from "@hooks/useNotifier";
import useApiResponseHandler from "@hooks/useApiResponseHandler";
// External imports
import { Tab } from "@mui/material";

const initialContractState = {
  saleType: { id: "", name: "" },
  planType: { id: "", name: "" },
};

const initialAccountingItemsStateData = {
  accounting_id: "",
  name: "",
  sku: "",
  unit_price: "",
  has_limit: false,
  limit: "",
};

const initialErrorsState = {
  name: false,
  sku: false,
  unit_price: false,
  limit: false,
};

const initialAccountingItemsState = {
  data: initialAccountingItemsStateData,
  errors: initialErrorsState,
  children: { data: initialAccountingItemsStateData, errors: initialErrorsState },
};

const handleTabChange = (setTabValue, setContract) => (event, value) => {
  setTabValue(value);
  setContract(initialContractState);
};

const handleSetSelectsValues = async (code, key, setSelectsValues, specificListOfCodes) => {
  let res = await specificListOfCodes(code);

  const values = res.filter((t) => t.id !== "PRIVATE_COLLECTION");

  setSelectsValues((prevState) => ({
    ...prevState,
    [key]: values,
  }));
};

const fetchSaleDetailsAsync = async (setSelectsValues, specificListOfCodes) => {
  try {
    // When component is mounted, fetch the values to put inside our selects
    await handleSetSelectsValues("SALES_TYPES", "saleTypes", setSelectsValues, specificListOfCodes);
    await handleSetSelectsValues("_PLANS", "planTypes", setSelectsValues, specificListOfCodes);

    return true;
  } catch (error) {
    console.warn("[fetchSaleDetailsAsync] error:", error);
    return false;
  }
};

const fetchAccountingItemsAsync = async (saleType, planType, setAccoutingItems, setShowSpinner, prevRef) => {
  setShowSpinner(true);

  let res = await API.Contract.fetchContractsPrices(saleType, planType);

  const accountingItemsParentArray = [];

  Object.values(res).map((accountingItemParent) => {
    const accountingItemsChildArray = [];

    accountingItemParent.children.map((accountingItemChild) => {
      const child = { data: accountingItemChild, errors: initialErrorsState };
      accountingItemsChildArray.push(child);
    });

    const accountingItem = {
      data: accountingItemParent,
      errors: initialErrorsState,
      children: accountingItemsChildArray,
    };
    accountingItemsParentArray.push(accountingItem);
  });

  setAccoutingItems(accountingItemsParentArray);
  prevRef.current = accountingItemsParentArray;

  setShowSpinner(false);
};

const AccountingItemParentComponent = ({ accountingItems, setAccountingItems, handleApiResponse, prevRef }) => {
  return (
    <>
      {accountingItems.map((accountingItem) => {
        return (
          <AccountingItemParent
            key={accountingItem.data.accounting_id}
            accountingItem={accountingItem}
            accountingItems={accountingItems}
            setAccountingItems={setAccountingItems}
            handleApiResponse={handleApiResponse}
            prevRef={prevRef}
          />
        );
      })}
    </>
  );
};

function AccountingItemsPricesAndTaxesPage({ specificListOfCodes }) {
  const [accountingItems, setAccountingItems] = useState(initialAccountingItemsState);
  const [contract, setContract] = useState(initialContractState);
  const [tabValue, setTabValue] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectsValues, setSelectsValues] = useState({
    planTypes: "",
    saleTypes: "",
  });
  const [selectsPlanTypes, setSelectsPlanTypes] = useState(selectsValues.planTypes);

  const notifier = useNotifier();

  const handleApiResponse = useApiResponseHandler();

  const prevRef = useRef(accountingItems);

  const updateCustomerSaleDetails = useCallback((key, value) => {
    setContract((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const fetchSaleDetails = useAsync(() => fetchSaleDetailsAsync(setSelectsValues, specificListOfCodes));
  const fetchAccountingItems = useAsync(() =>
    fetchAccountingItemsAsync(contract.saleType.id, contract.planType.id, setAccountingItems, setShowSpinner, prevRef)
  );

  const updateFilteredPlanTypes = useCallback(
    (saleTypeId) => {
      let planTypes = selectsValues.planTypes;

      switch (saleTypeId) {
        // TODO: Juste prendre TYPE_DE_VENTE_PLANS svp
        case "CONSTRUCTION_CONTAINER":
          planTypes = planTypes.filter((value) => value.code_type === "CONSTRUCTION_CONTAINER_PLANS");
          break;
        case "DISPOSAL":
          planTypes = planTypes.filter((value) => value.code_type === "DISPOSAL_PLANS");
          break;
        case "PUBLIC_CALL_FOR_TENDERS":
          planTypes = planTypes.filter((value) => value.code_type === "PUBLIC_CALL_FOR_TENDERS_TEST_PLANS");
          break;
        case "PRIVATE_ROLL_OFF_COLLECTION":
          planTypes = planTypes.filter((value) => value.code_type === "PRIVATE_ROLL_OFF_COLLECTION_PLANS");
          break;
        case "PRIVATE_CCAV_COLLECTION":
          planTypes = planTypes.filter((value) => value.code_type === "PRIVATE_CCAV_COLLECTION_PLANS");
          break;
        case "PRIVATE_MOBILE_FLOOR_COLLECTION":
          planTypes = planTypes.filter((value) => value.code_type === "PRIVATE_MOBILE_FLOOR_COLLECTION_PLANS");
          break;
      }

      // If we have a selected planType, we have to clear it first because the
      // filtered plans might not include the previously selected planType.
      if (contract.planType.id) {
        setContract((prevState) => ({
          ...prevState,
          planType: { id: "", name: "" },
        }));
      }

      setSelectsPlanTypes(planTypes);
    },
    [selectsValues.planTypes, contract.planType]
  );

  useEffect(() => {
    updateFilteredPlanTypes(contract.saleType.id);
  }, [contract.saleType.id, fetchSaleDetails.value, selectsValues.planTypes]);

  useEffect(() => {
    accountingItems && setAccountingItems({});

    setContract((prevState) => ({
      ...prevState,
      planType: { id: "", name: "" },
    }));
  }, [contract.saleType.id]);

  useEffect(() => {
    // Fetch the sale details and taxes details values at mount
    fetchSaleDetails.run();
  }, []);

  useEffect(() => {
    isObjectTruthy(contract.saleType) && isObjectTruthy(contract.planType) && fetchAccountingItems.run();
  }, [contract.planType]);

  return (
    <PageContainer>
      <TabsWrapper
        indicatorColor="primary"
        onChange={handleTabChange(setTabValue, setContract)}
        textColor="primary"
        value={tabValue}
      >
        <Tab
          label={"Contrats"}
          value={0}
        />
        <Tab
          label={"Carburant"}
          value={1}
        />
      </TabsWrapper>
      <PaperWrapper>
        {showSpinner && <LoaderSpinner text={<Trans i18nKey="download_data" />} />}
        {tabValue === 0 && (
          <AccountingItemSelects
            fetchSaleDetails={fetchSaleDetails}
            contract={contract}
            selectsValues={selectsValues}
            selectsPlanTypes={selectsPlanTypes}
            updateCustomerSaleDetails={updateCustomerSaleDetails}
          />
        )}
        {tabValue === 1 && <FuelChargeTaxes notifier={notifier} />}
        {isArrayTruthy(accountingItems) && (
          <AccountingItemParentComponent
            accountingItems={accountingItems}
            setAccountingItems={setAccountingItems}
            handleApiResponse={handleApiResponse}
            prevRef={prevRef}
          />
        )}
      </PaperWrapper>
    </PageContainer>
  );
}

export default withRouter(withListCodes(AccountingItemsPricesAndTaxesPage));
