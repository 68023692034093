// TODO Change .js to .ts

import { useCallback, useMemo } from "react";

import { useAuth } from "@context/auth/AuthProvider";

const SUPER_ADMIN = "SUPER_ADMIN";
const ADMIN = "ADMIN";
const ACCOUNTING = "ACCOUNTING";

/**
 * The callback to set new roles to the current user
 *
 * @callback SetRolesHandler
 * @param {string[]} roles
 * @returns {void}
 */

/**
 * @callback UseCurrentRoles
 * @returns {{
 *    roles: string[],
 *    isSuperAdmin: boolean,
 *    isAdmin: boolean,
 *    isAccountant: boolean,
 *    setRoles: (roles: string[]) => void
 *  }}
 */

/**
 * Returns the current user roles + function to set them
 *
 * @type {UseCurrentRoles}}
 */
const useCurrentRoles = () => {
  const {
    auth: { roles },
    setAuth,
  } = useAuth();

  const isSuperAdmin = useMemo(() => roles.includes(SUPER_ADMIN), [roles]);
  const isAdmin = useMemo(() => roles.includes(ADMIN), [roles]);
  const isAccountant = useMemo(() => roles.includes(ACCOUNTING), [roles]);

  /**
   * @type {SetRolesHandler}
   */
  const setRoles = useCallback(
    (roles) => {
      setAuth(auth => ({
        ...auth,
        roles,
      }));
    },
    [setAuth],
  );

  return {
    roles, isSuperAdmin, isAdmin, isAccountant, setRoles,
  };
};

export default useCurrentRoles;
