// React imports
import React, { useEffect, useState, memo, useCallback } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import visa_logo from "@assets/images/stripe/visa_logo.png";
import mastercard_logo from "@assets/images/stripe/mastercard_logo.png";

// External iports
import { isEqual, findIndex } from "lodash";
import { Delete, Check } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Typography,
  Stack,
  Paper,
  IconButton,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  Slide,
  Fade,
  Tooltip,
} from "@mui/material";

import { green } from "@mui/material/colors";

const arePaymentMethodPropsEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.paymentMethod, nextProps.paymentMethod)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.loading, nextProps.loading)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.status, nextProps.status)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.isDefaultPaymentMethod, nextProps.isDefaultPaymentMethod)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

const DefaultCardBadge = () => (
  <Box bgcolor="#CEF5F6" borderRadius="4px" width="fit-content">
    <Typography variant="body2" color="#0055BC" px="6px">
      Par défaut
    </Typography>
  </Box>
);

const CardNumber = ({ card }) => (
  <Stack spacing={1}>
    <Typography variant="overline" color="text.secondary" fontWeight="bold" lineHeight={1}>
      Numéro de carte
    </Typography>

    <Stack direction="row" spacing={1} alignItems="flex-end">
      <Typography letterSpacing={3} lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
        ****
      </Typography>
      <Typography letterSpacing={3} lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
        ****
      </Typography>
      <Typography letterSpacing={3} lineHeight={0.7} variant="body1" fontWeight="bold" color="text.secondary">
        ****
      </Typography>
      <Typography letterSpacing={3} variant="body1" fontWeight="bold" lineHeight={1} color="black">
        {card.last4}
      </Typography>
    </Stack>
  </Stack>
);

const CardLogo = ({ card }) => (
  <img
    src={card.brand === "visa" ? visa_logo : mastercard_logo}
    alt="Logo"
    height={36}
    width={55}
    style={card.brand === "mastercard" ? { border: "1px solid #ABABAB", borderRadius: 6 } : {}}
  />
);

const CardInteractions = ({ loading, status, paymentMethod, onDetachPaymentMethod }) => (
  <Box sx={{ m: 1, position: "relative" }}>
    <Tooltip
      title="Supprimer la carte"
      placement="top"
      PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, -10] } }] }}
    >
      <Fab
        size="medium"
        aria-label="save"
        color="error"
        sx={{
          ...(status === "success" && {
            bgcolor: green[500],
            "&:hover": {
              bgcolor: green[700],
            },
          }),
        }}
        disabled={loading}
        onClick={() => onDetachPaymentMethod(paymentMethod.id)}
      >
        {status === "success" ? <Check /> : <Delete />}
      </Fab>
    </Tooltip>

    {loading && (
      <CircularProgress size={58} sx={{ color: green[500], position: "absolute", top: -5, left: -5, zIndex: 1 }} />
    )}
  </Box>
);

const CardExpiration = ({ expirationDate }) => (
  <Stack spacing={1}>
    <Typography variant="overline" color="text.secondary" fontWeight="bold" lineHeight={1}>
      Expiration
    </Typography>
    <Typography letterSpacing={3} variant="body1" fontWeight="bold" lineHeight={1} color="black">
      {expirationDate}
    </Typography>
  </Stack>
);

const PaymentMethod = memo(function PaymentMethod({
  paymentMethod,
  loading,
  status,
  isDefaultPaymentMethod,
  onDetachPaymentMethod,
}) {
  const { card } = paymentMethod;
  const cardExpMonth = card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month;
  const cardExpYear = card.exp_year.toString().slice(-2);
  const cardExpDate = `${cardExpMonth}/${cardExpYear}`;

  // console.log(
  //   `[PaymentMethod] ${paymentMethod?.id} | loading: ${loading} | status: ${status} | isDefaultPaymentMethod: ${isDefaultPaymentMethod}`
  // );

  return (
    <Card variant="elevation" elevation={4} sx={{ overflow: "hidden" }}>
      <Stack py={2} pl={2}>
        {isDefaultPaymentMethod && <DefaultCardBadge />}

        <Stack direction="row" alignItems="center">
          <CardContent component={Stack} direction="row" width="100%" spacing={3} sx={{ padding: 0 }}>
            <CardLogo card={card} />
            <CardNumber card={card} />
            <CardExpiration expirationDate={cardExpDate} />
          </CardContent>

          <CardActions>
            <CardInteractions
              loading={loading}
              status={status}
              paymentMethod={paymentMethod}
              onDetachPaymentMethod={onDetachPaymentMethod}
            />
          </CardActions>
        </Stack>
      </Stack>
    </Card>
  );
},
arePaymentMethodPropsEqual);

export default PaymentMethod;
