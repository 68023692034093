import axios from "axios";

import { requestOptions } from "../auth";

const createJob = async (customerItemID, payload) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/customer_items/${customerItemID}/jobs.json`,
      { job: { ...payload } },
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.JobService] createJob >> error:", error);
    return error.response.data;
  }
};

const copyJob = async (jobID, params) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/jobs/${jobID}/copy_job.json`,
      { job: { ...params } },
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.JobService] copyJob >> error:", error);
    throw error.response.data;
  }
};

const fetcDailyClientAccountJobs = async (target_date) => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_API_URL}/jobs/daily_client_account_jobs.json?target_date=${target_date}`,
      requestOptions()
    );
    return res;
  } catch (error) {
    console.warn("[API.fetcDailyClientAccountJobs] fetchRefundedTransactions >> error:", error);
    return error.response;
  }
};

const updateBillingWitness = async (jobId, value) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/jobs/${jobId}/update_billing_witness.json`,
      { job: { billing_witness: value } },
      requestOptions()
    );
    return res.data;
  } catch (error) {
    console.warn("[API.JobService] updateBIllingWitness >> error:", error);
    throw error.response.data;
  }
};

/**
 * Job api service for exposing api endpoints.
 *
 * Available requests:
 * - `createJob(customerItemID, payload)`
 * - `copyJob(jobID, params)`
 */
export { createJob, copyJob, fetcDailyClientAccountJobs, updateBillingWitness };
