// React imports
import React, { FC } from "react";
// Externals imports
import { DialogTitle, Stack } from "@mui/material";
import {
  LocationContainerModalLiftingRequestData,
  LocationContainerModalLiftingRequestHandlers,
} from "@components/location/route/containers/modals/hooks/location-container-modal-lifting-requests.hook";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import ContainerLiftingRequestsFiltering from "@components/location/route/containers/modals/lifting-requests/ContainerLiftingRequestsFiltering";

type ContainerLiftingRequestsHeaderProps = {
  selectedContainer: LocationCcavContainer;
  liftingRequestData: LocationContainerModalLiftingRequestData;
  liftingRequestHandlers: LocationContainerModalLiftingRequestHandlers;
};

const ContainerLiftingRequestsHeader: FC<ContainerLiftingRequestsHeaderProps> = ({
  selectedContainer,
  liftingRequestData,
  liftingRequestHandlers,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <DialogTitle>
        Demande(s) de levée(s)
        {selectedContainer.ciWebChipcode && ` | ${selectedContainer.ciWebChipcode}`}{" "}
        {selectedContainer.ciWebLabelcode && ` | ${selectedContainer.ciWebLabelcode}`}
      </DialogTitle>
      <ContainerLiftingRequestsFiltering
        liftingRequestData={liftingRequestData}
        liftingRequestHandlers={liftingRequestHandlers}
      />
    </Stack>
  );
};

export default ContainerLiftingRequestsHeader;
