// TODO Change .js to .ts

import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

/**
 * The signed in user
 *
 * @typedef {object} User
 * @property {number} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 */

/**
 * The auth context data
 *
 * @typedef {object} Auth
 * @property {User | null} user
 * @property {string[]} roles
 */

/**
 * The set auth function
 *
 * @callback SetAuthHandler
 * @property {(auth: Auth) => Auth}
 */

/**
 * The auth context
 *
 * @typedef {object} AuthContext
 * @property {Auth} auth
 * @property {(callback: SetAuthHandler) => void} setAuth
 */

/**
 * The default auth context data
 *
 * @type {AuthContext["auth"]}
 */
const DEFAULT_CONTEXT = {
  user: null,
  roles: [],
};

/**
 * @type {import("react").Context<AuthContext | undefined>}
 */
const AuthContext = createContext(undefined);

/**
 *  Hook that returns the current auth context
 *
 * @returns {AuthContext} AuthContext
 */
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("Context was used outside of its provider");

  return context;
};

/**
 * Provides its children with auth data
 *
 * @returns {import("react").ReactElement} Provider
 */
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(DEFAULT_CONTEXT);

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.object,
};

export default AuthProvider;
