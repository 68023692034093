import { useEffect } from "react";
import { useFirstMountState } from "@hooks/useFirstMountState";

/**
 * Custom useEffect hook that is called only when a state is updated and not
 * on the first component render. Simulates the old react *componentDidUpdate* method.
 * @param {Function} callback Callback to be executed, e.g., `() => { //do something }`
 * @param {Array} deps Array of dependencies, e.g., `[someState]`
 */
export default function useUpdateEffect(callback, deps) {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (!isFirstMount) {
      return callback();
    }
  }, deps);
}
