import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Box, CircularProgress } from "@mui/material";

/**
 * Simple component wrapper to use the `useJsApiLoader` hook from the
 * `@react-google-maps/api` inside of a class. Gives access to the
 * `isLoaded` and `loadError` properties through the `googleMap` prop.
 * @param {JSX} Component Wrapped component
 * @returns Wrapped component and its props, but with the addition `googleMap` prop which includes `isLoaded` and `loadError`.
 */
export const withGoogleMap = (Component) => {
  const ComponentWithGoogleMapProp = (props) => {
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
    });

    return <Component {...props} googleMap={{ isLoaded, loadError }} />;
  };

  return ComponentWithGoogleMapProp;
};

/**
 * Centered Loading spinner for the google map components
 * @returns Loading Spinner
 */
export const MapLoadingSpinner = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
      backgroundColor: "#f3f3f3",
    }}
  >
    <CircularProgress />
  </Box>
);

/**
 * Centered view with error message for the google map components
 * @returns View with google map error message
 */
export const MapErrorView = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
      backgroundColor: "#f3f3f3",
    }}
  >
    <div>Désolé, la carte ne peut être chargée actuellement.</div>
  </Box>
);
