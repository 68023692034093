import React, { useState, useEffect, useMemo } from "react";
import { Backdrop as BackdropMUI } from "@mui/material";
import useUpdateEffect from "@hooks/useUpdateEffect";

function useBackdrop(defaultMessage = "") {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(defaultMessage);

  useUpdateEffect(() => {
    setMessage(defaultMessage);
  }, [defaultMessage]);

  useUpdateEffect(() => {
    !isOpen && cleanUp();
  }, [isOpen]);

  const cleanUp = () => {
    isOpen && setIsOpen(false);
    setMessage(defaultMessage);
  };

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const handlers = useMemo(
    () => ({
      open,
      close,
      setMessage,
    }),
    []
  );

  function Backdrop({ children }) {
    return (
      <BackdropMUI
        sx={{
          color: "#fff",
          zIndex: 10,
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={isOpen}
      >
        {children}
      </BackdropMUI>
    );
  }

  return { Backdrop, isOpen, message, handlers };
}

export default useBackdrop;
