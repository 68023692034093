import React from "react";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import HalfFormControl from "./HalfFormControl";

const HalfFormControlWrapper = styled(HalfFormControl)`
  position: relative;
`;

const ReactSelect = styled(Select)`
  && .react-select__control {
    font-family: ${(props) => props.theme.mainFont};
  }

  && .react-select__value-container {
    padding-left: 22px;
  }

  && .react-select__menu {
    font-family: ${(props) => props.theme.mainFont};
    z-index: 99999;
    background-color: white;
  }

  && .react-select__option {
    padding-left: 22px;
    font-family: 14px;
  }

  && .react-select__option--is-focused {
    background-color: #00000014;
  }

  && .react-select__option--is-selected {
    background-color: ${(props) => props.theme.colors.default};
  }
`;

const menuPortalStyles = {
  zIndex: 9999,
  fontSize: "16px",
  fontWeight: 400,
};

const Autocomplete = ({
  formControlError,
  formControlWidthClass,
  formHelperErrorMsg,
  ...props
}) => (
  <HalfFormControlWrapper
    classes={{ root: formControlWidthClass }}
    error={formControlError}
    variant="outlined"
  >
    <ReactSelect
      menuPosition="fixed"
      noOptionsMessage={() => <Trans i18nKey="no_result" />}
      classNamePrefix="react-select"
      menuPortalTarget={document.querySelector(props.dropDownMenuPortalTarget)}
      styles={{
        menuPortal: (base) => ({
          ...base,
          ...menuPortalStyles,
          ...{ fontFamily: props.theme.mainFont },
        }),
        control: (base) => ({
          ...base,
          height: 35, // Set dropdown height to same as button height
        }),
        option: (base) => ({
          ...base,
          minHeight: 35, // Set dropdown option height to same as button height
          display: "flex",
          alignItems: "center", // Center the text vertically with new height
        }),
      }}
      {...props}
    />
  </HalfFormControlWrapper>
);

Autocomplete.defaultProps = {
  formControlError: false,
  formControlWidthClass: "",
  formHelperErrorMsg: "",
};

Autocomplete.propTypes = {
  formControlError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  formControlWidthClass: PropTypes.string,
  formHelperErrorMsg: PropTypes.string,
  dropDownMenuPortalTarget: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Autocomplete);
