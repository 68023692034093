import "./i18n"; // Translations

import React, { ReactElement, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import store from "../store";
import App from "./components/App";
import LogoutService from "./services/auth/logout.service";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: import.meta.env.VITE_SENTRY_ENVIRONMENT !== "development",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root container");

const root = createRoot(container);

declare global {
  interface Window {
    unauthorizedCallback: () => void;
  }
}

/**
 * Simple app wrapper to have a useEffect on root app render.
 */
const Linea = (): ReactElement => {
  useEffect(() => {
    // Mapping the logout api request to a global function allows rails-fetch to call
    // window.unauthorizedCallback when a request returns a 401 unauthorized
    window.unauthorizedCallback = (): void => {
      LogoutService.logout();
      window.location.href = "/login";
    };
  }, []);

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

root.render(<Linea />);
