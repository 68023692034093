import React from "react";
import {FormControl, InputLabel, MenuItem, Stack, Select} from "@mui/material";

const SelectItems = (items, defaultValue = "", defaultName = "") => {

  if (!items) {
    return (
      <MenuItem key={defaultValue} value={defaultValue}>
        {defaultName}
      </MenuItem>
    );
  }

  return items.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
};

const handleChangeSaleDetails = (values, updateCustomerSaleDetails) => (event) => {
  const selectedValue = values.find((value) => value.id === event.target.value);
  updateCustomerSaleDetails(event.target.name, { ...selectedValue });
};

function AccountingItemSelects({ fetchSaleDetails, contract, selectsValues, selectsPlanTypes, updateCustomerSaleDetails }) {

  return (
    <Stack direction="row" alignItems='center' justifyContent="center" spacing={10} pt={2}>
      <FormControl>
        <InputLabel shrink id="sale-type-label">
          Type de vente
        </InputLabel>
        <Select
          sx={{width: 250}}
          notched
          name="saleType"
          label="Type de vente"
          labelId="sale-type-label"
          MenuProps={{disableScrollLock: true}}
          value={fetchSaleDetails.value ? contract.saleType.id ?? "" : ""}
          onChange={handleChangeSaleDetails(selectsValues.saleTypes, updateCustomerSaleDetails)}
        >
          {SelectItems(selectsValues.saleTypes)}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel shrink id="plan-type-label">
          Type de plan
        </InputLabel>
        <Select
          sx={{width: 250}}
          notched
          name="planType"
          label="Type de plan"
          labelId="plan-type-label"
          MenuProps={{disableScrollLock: true}}
          value={fetchSaleDetails.value ? contract.planType.id ?? "" : ""}
          disabled={!contract.saleType.id}
          onChange={handleChangeSaleDetails(selectsPlanTypes, updateCustomerSaleDetails)}
        >
          {SelectItems(selectsPlanTypes)}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default AccountingItemSelects;