// React imports
import React, { FC } from "react";
// Externals imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: string };
};

const headerLabel: HeaderLabel = {
  matterType: { label: "Type de matière" },
  startDate: { label: "Début" },
  endDate: { label: "Fin" },
  frequency: { label: "Fréquence" },
  monday: { label: "LU" },
  tuesday: { label: "MA" },
  wednesday: { label: "ME" },
  thursday: { label: "JE" },
  friday: { label: "VE" },
  saturday: { label: "SA" },
  sunday: { label: "DI" },
  edit: { label: "Modfier" },
  delete: { label: "Supprimer" },
};
const header: string[] = [
  "matterType",
  "startDate",
  "endDate",
  "frequency",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "edit",
  "delete",
];

const LocationModalitiesListHeader: FC = () => {
  return (
    <TableRow>
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
    </TableRow>
  );
};

export default LocationModalitiesListHeader;
