import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import LoaderSpinner from "./LoaderSpinner";
import { Trans } from "react-i18next";

function UploadFile(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const { headerStartAtRow, firstItemUploaded, uploadXslxFile } = props;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://unpkg.com/xlsx/dist/xlsx.full.min.js";
    document.body.appendChild(script);

    // Clean up the script when the component in unmounted
    return () => document.body.removeChild(script);
  }, []);

  const readExcel = (file, headerStartAtRow) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer", sheetStubs: true });

        const ws = wb.Sheets["Données"];

        const data = XLSX.utils.sheet_to_json(ws, { range: headerStartAtRow, defval: "" });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      firstItemUploaded(d);
      setShowSpinner(false);
    });
  };

  return (
    <div>
      <input
        style={{ left: 10 }}
        type="file"
        disabled={!!showSpinner}
        onChange={(e) => {
          const file = e.target.files[0];
          setShowSpinner(true);
          uploadXslxFile(file);
          readExcel(file, headerStartAtRow);
        }}
      />
      <IconButton size="large" color="secondary" />
      {showSpinner && <LoaderSpinner text={<Trans i18nKey="download_file" />} />}
    </div>
  );
}

export default UploadFile;
