import axios from "axios";
import { requestOptions } from "../auth";

const fetchTaxesPricesList = async () => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/taxes/fuel_taxes_prices_list.json`,
      requestOptions()
    );

    return response.data.data;
  } catch (error) {
    console.warn("[API.fetchTaxesPricesList] Error:", error);
  }
};

const updateFuelTax = async (payload, taxeID) => {
  try {
    const response = await axios.put(`${import.meta.env.VITE_API_URL}/taxes/${taxeID}`, payload, requestOptions());

    return response.data;
  } catch (error) {
    console.warn("[API.createNewTaxPrice] Error:", error);

    return error.response.data;
  }
};

const createNewFuelTax = async (payload) => {
  try {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/taxes`, payload, requestOptions());

    return response.data;
  } catch (error) {
    console.warn("[API.createNewTaxPrice] Error:", error);

    return error.response.data;
  }
};

const deleteFuelTax = async (taxeID) => {
  try {
    const response = await axios.delete(`${import.meta.env.VITE_API_URL}/taxes/${taxeID}`, requestOptions());

    return response.data;
  } catch (error) {
    console.warn("[API.deleteFuelTax] Error:", error);

    return error.response.data;
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchTaxesPricesList`,
 * - `updateFuelTax`,
 * - `createNewFuelTax`,
 * - `deleteFuelTax`,
 */
export { fetchTaxesPricesList, updateFuelTax, createNewFuelTax, deleteFuelTax };
