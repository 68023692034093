// React imports
import React, { useState, useCallback, useEffect } from "react";

// External imports
import { Add } from "@mui/icons-material";
import { Trans } from "react-i18next";
import { withCustomers } from "optigo-redux";
import { Table, TableBody, Toolbar } from "@mui/material";
// Local imports
import TextFieldUi from "@ui/TextField";
import PaperWrapper from "@ui/PaperWrapper";
import PageContainer from "@ui/PageContainer";
import { withRouter } from "@utils/withRouter";
import useUpdateEffect from "@hooks/useUpdateEffect";
import FloatingActionButton from "@ui/FloatingActionButton";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import TablePaginationWrapper from "@ui/TablePaginationWrapper";
import CustomersTableHead from "@components/customer/CustomersTableHead";
import CustomersTableRows from "@components/customer/CustomersTableRows";
import ModalCustomerNew from "@components/modals/customer/ModalCustomerNew";
import ModalCustomerEdit from "@components/modals/customer/ModalCustomerEdit";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";

const handleSort = (name, sort, setSort) => {
  const { columnName, direction } = sort;

  let newColumnName = name;
  let newDirection = "asc";

  if (columnName === name) {
    switch (direction) {
      case "asc": {
        newDirection = "desc";
        break;
      }
      case "desc": {
        newColumnName = null;
        newDirection = undefined;
        break;
      }
      default: {
        break;
      }
    }
  }

  setSort({
    columnName: newColumnName,
    direction: newDirection,
  });
};

const fetchFilteredCustomers = (fetchCustomers, filter, page, limit) => {
  fetchCustomers({
    filter: filter.trim(),
    page: page + 1,
    limit,
  });
};

/**
 * Page used to show a list of our existing customers.
 * @param {*} props
 */
function CustomersPage(props) {
  const [sort, setSort] = useState({ columnName: "identifier", direction: "asc" });
  const [filter, setFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const { customer, handlers } = useCustomerContext();

  const {
    router,
    fetchCustomers,
    flushCustomers,
    editCustomer,
    createCustomer,
    customersCount,
    customers,
    customersLoading,
  } = props;

  useEffect(() => {
    // Whenever the page, filter or limit changes, fetch the customers
    fetchFilteredCustomers(fetchCustomers, filter, page, limit);
  }, [limit, page, filter]);

  useEffect(() => {
    // When unmounting the screen, flush the customers from memory
    return () => flushCustomers();
  }, []);

  useUpdateEffect(() => {
    // Besides on mount, execute the following whenever the edit or new modal is closed
    customer.currentlyOpenModal || fetchAndResetPagination();
  }, [customer.currentlyOpenModal]);

  const handleFilter = (event, setFilter) => {
    event.persist();
    setFilter(event.target.value);
    fetchFilteredCustomers(fetchCustomers, filter, page, limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setSort({ columnName: "identifier", direction: "asc" });
  };

  const handleRowsPerPageChange = (event, setLimit, setPage, setSort) => {
    setLimit(event.target.value);
    setPage(0);
    setSort({ columnName: "identifier", direction: "asc" });
  };

  const fetchAndResetPagination = useCallback(() => {
    setFilter("");
    setLimit(10);
    setPage(0);

    fetchFilteredCustomers(fetchCustomers, filter, page, limit);
  }, []);

  return (
    <PageContainer>
      <PaperWrapper>
        <>
          <FloatingActionButton color="secondary" onClick={() => handlers.handleToggleModalCustomer("new")}>
            <Add />
          </FloatingActionButton>
        </>

        <TableOverflowWrapper>
          <Toolbar>
            <TextFieldUi
              id="cpbr-filtre"
              label={<Trans i18nKey="filter" />}
              onChange={(e) => handleFilter(e, setFilter)}
              type="search"
              value={filter}
            />
          </Toolbar>

          <Table>
            <CustomersTableHead sort={sort} setSort={setSort} handleSort={handleSort} />

            <TableBody>
              <CustomersTableRows
                customersLoading={customersLoading}
                customers={customers}
                sort={sort}
                router={router}
                setCustomer={handlers.setCustomer}
              />
            </TableBody>
          </Table>
        </TableOverflowWrapper>

        <TablePaginationWrapper
          component="div"
          count={customersCount}
          id="cpbr-pagination"
          labelRowsPerPage=""
          onPageChange={handlePageChange}
          onRowsPerPageChange={(e) => handleRowsPerPageChange(e, setLimit, setPage, setSort)}
          page={page}
          rowsPerPage={limit}
        />

        {/* Customer creation modal */}
        {customer.currentlyOpenModal === "new" && <ModalCustomerNew createCustomer={createCustomer} />}

        {/* Customer edit modal */}
        {customer.currentlyOpenModal === "edit" && <ModalCustomerEdit editCustomer={editCustomer} />}
      </PaperWrapper>
    </PageContainer>
  );
}

export default withRouter(withCustomers(CustomersPage));
