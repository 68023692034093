import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const ManualLiftingIcon = ({ size, strokeColor, fillColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="22"
        height="25"
        viewBox="0 0 22 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4061 10.4091C20.4061 17.7273 10.703 24 10.703 24C10.703 24 1 17.7273 1 10.4091C1 7.91364 2.02228 5.52041 3.84195 3.75586C5.66162 1.99131 8.12963 1 10.703 1C13.2764 1 15.7444 1.99131 17.5641 3.75586C19.3838 5.52041 20.4061 7.91364 20.4061 10.4091Z"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.75"
          y="7"
          width="20"
          height="8"
          rx="2"
          fill={fillColor}
        />
        <path
          d="M6.52595 8.77V13H5.34995V10.666L4.55195 13H3.56795L2.76395 10.648V13H1.58795V8.77H3.00995L4.07195 11.518L5.10995 8.77H6.52595ZM9.87138 12.31H8.37138L8.14338 13H6.90738L8.44938 8.77H9.80538L11.3414 13H10.0994L9.87138 12.31ZM9.57738 11.41L9.12138 10.042L8.67138 11.41H9.57738ZM15.6427 13H14.4667L12.9007 10.636V13H11.7247V8.77H12.9007L14.4667 11.164V8.77H15.6427V13ZM17.4646 8.77V11.224C17.4646 11.452 17.5166 11.628 17.6206 11.752C17.7286 11.876 17.8906 11.938 18.1066 11.938C18.3226 11.938 18.4846 11.876 18.5926 11.752C18.7046 11.624 18.7606 11.448 18.7606 11.224V8.77H19.9366V11.224C19.9366 11.612 19.8546 11.944 19.6906 12.22C19.5266 12.492 19.3026 12.698 19.0186 12.838C18.7386 12.974 18.4266 13.042 18.0826 13.042C17.7386 13.042 17.4306 12.974 17.1586 12.838C16.8906 12.698 16.6786 12.492 16.5226 12.22C16.3706 11.948 16.2946 11.616 16.2946 11.224V8.77H17.4646Z"
          fill="white"
        />
      </svg>
    </Container>
  );
};
