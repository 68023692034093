import styled from "@emotion/styled";

const HalfButtonWrapper = styled.div`
  width: 47.5%;

  &.one-sixth {
    width: 24%;

    button {
      width: 100%;
    }
  }
`;

export default HalfButtonWrapper;
