import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";

const StyledTitle = styled(Typography)`
  flex: 1;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
`;

const HeaderTitle = () => {
  const location = useLocation();

  const title = useMemo(() => {
    switch (true) {
      case /\/route_template_locations/.test(location.pathname): {
        return "Gestion des sous-routes";
      }
      case /\/routes/.test(location.pathname): {
        return "Route";
      }
      case /\/customers/.test(location.pathname): {
        return <Trans i18nKey="common.title.client" />;
      }
      case /\/items/.test(location.pathname): {
        return <Trans i18nKey="common.title.inventory" />;
      }
      case /\/jobs/.test(location.pathname): {
        return <Trans i18nKey="common.title.jobs" />;
      }
      case /\/preparations/.test(location.pathname): {
        return <Trans i18nKey="common.title.preparation" />;
      }
      case /\/units/.test(location.pathname): {
        return <Trans i18nKey="common.title.units" />;
      }
      case /\/prices/.test(location.pathname): {
        return "Liste de prix";
      }
      case /\/transactions/.test(location.pathname): {
        return "Transactions";
      }
      // case /Wusers/.test (location.pathname): {
      // title = "Utilisateurs";
      // break;
      // }
      default: {
        return <Trans i18nKey="tasks_planification" />;
      }
    }
  }, [location]);

  return (
    <StyledTitle
      variant="h6"
      color="inherit"
    >
      {title}
    </StyledTitle>
  );
};

export default HeaderTitle;
