import React from "react";
import styled from "@emotion/styled";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomCheckboxWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 25px;
  margin-right: 8px;

  .custom-drag-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
    z-index: 10;
  }
`;

const CustomCheckedIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const CustomDragCheckbox = ({
  checked,
  onChange,
  dataIndex,
  dataItemID,
  dataSourceList,
}) => {
  return (
    <CustomCheckboxWrapper>
      <input
        className="custom-drag-checkbox"
        type="checkbox"
        checked={checked}
        data-source-list={dataSourceList}
        data-index={dataIndex}
        data-item-id={dataItemID}
        onChange={onChange}
      />
      <CustomCheckedIconWrapper>
        {!checked && <RadioButtonUncheckedIcon />}
        {checked && <CheckCircleIcon />}
      </CustomCheckedIconWrapper>
    </CustomCheckboxWrapper>
  );
};
