import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import LoaderSpinner from "@components/LoaderSpinner";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Trans } from "react-i18next";

export default function RemoveHelpOnUnit({ onClose, isOpen, isLoading, onRemove }) {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      {isLoading && <LoaderSpinner text={"Suppression de la demande d'aide"} />}
      <DialogTitle>Retirer la demande d'aide</DialogTitle>

      <DialogContent sx={{ minWidth: 600 }}>
        <Typography>Êtes-vous certain de vouloir retirer la demande d'aide ?</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button onClick={onRemove} variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
