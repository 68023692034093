import useNotifier from "@hooks/useNotifier";
import { isArrayTruthy } from "@utils/common";

/**
 * Custom hook for handling API responses.
 *
 * @returns {Function} A function that takes two arguments: `response` and `successCallback`.
 * If the `response` has a `status` of `"success"`, the `successCallback` will be executed.
 * Otherwise, the hook will use the `notifier` hook to enqueue error messages from the `response.messages` property.
 *
 * @example
 * const handleApiResponse = useApiResponseHandler();
 *
 * const handleResponse = async () => {
 *   const response = await fetchData();
 *   handleApiResponse(response, () => console.log("Successful response!"));
 * };
 */

function useApiResponseHandler() {
  const notifier = useNotifier();

  return (response, successCallback = () => {}) => {
    if (!response || !response.status || !response.messages) {
      console.warn("[useApiResponseHandler] response is falsy or missing the necessary properties:", response);
      return;
    }

    if (response.status === "success") {
      successCallback();
    } else {
      isArrayTruthy(response.messages)
        ? notifier.enqueueMessages(response.messages)
        : console.warn("[useApiResponseHandler] cannot queue notifications, messages is not valid:", response.messages);
    }
  };
}

export default useApiResponseHandler;
