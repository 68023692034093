import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import RouteLocationAnomalyResponse from "@@types/route-locations/route-location-anomaly-response.type";

class FetchRouteLocationAnomaliesService {
  static async fetchAnomalies(routeLocationId: number): Promise<ApiResponse<RouteLocationAnomalyResponse[]>> {
    try {
      const res = await http.get<ApiResponse<RouteLocationAnomalyResponse[]>>(
        `/route_locations/${routeLocationId}/anomalies`
      );

      return res.data;
    } catch (error) {
      throw error as Error;
    }
  }
}

export default FetchRouteLocationAnomaliesService;
