import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { withContracts, withCustomer } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import UploadFile from "./UploadFile";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import LoaderSpinner from "./LoaderSpinner";

import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import SaveIcon from "@mui/icons-material/Save";
import * as API from "@services";

const NUMBEROFCOLUMNS = 37;
const HEADERS = ["Route du contrat", "Contrat", "Customer Item"];

class LoadingRoutes extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "identifier",
    direction: "asc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    sort: {
      ...this.initialSort,
    },
  };

  state = {
    ...this.initialState,
    contractRoute: [],
    confirmationContractRouteCreated: false,
    file: null,
    item: null,
    isAnalyzed: false,
    pathFile: "",
    showSpinner: false,
    showAnalyseButton: false,
    warningModalNumberOfColumn: false,
  };

  handleFilter = handleFilter(this.debouncedFetchContacts).bind(this);
  handlePageChange = handlePageChange(this.fetchContacts).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchContacts).bind(this);
  handleSort = handleSort.bind(this);

  componentDidMount() {
    this.fetchContracts();
  }

  fetchContracts = () => {
    const { filter, page, statusFilter } = this.state;

    const { fetchContracts, router } = this.props;
    const { params } = router;
    const { customerId } = params;

    fetchContracts(customerId, {
      filter: filter.trim(),
      page: page + 1,
      status: statusFilter,
      limit: "all",
    });
  };

  analyzeButton = () => (
    <Button
      style={{
        marginTop: 8,
        background: "#D23C3C",
        color: "#FFF",
        border: 0,
        padding: "2px 7px",
        left: 10,
      }}
      onClick={() => this.analyzeFile()}
    >
      Analyser
    </Button>
  );

  analyzeFile = () => {
    const { item } = this.state;
    if (item && item.length > 0 && Object.keys(item[0]).length === NUMBEROFCOLUMNS) {
      this.setState({ isAnalyzed: true, showAnalyseButton: false });
    } else {
      this.setState({
        warningModalNumberOfColumn: true,
        showSpinner: false,
        showAnalyseButton: false,
      });
    }
  };

  contractTableHeader = () => {
    const { direction } = this.state.sort;
    return (
      <TableHead>
        <TableRow>
          {HEADERS.map((name) => (
            <TableCell key={name}>
              <TableSortLabel
                active={false}
                direction={direction}
                onClick={this.handleSort(name)}
              >
                {name}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  contractTableRows = () => {
    const { contractRoute } = this.state;
    const { contracts } = this.props;

    return sortedData(contractRoute, this.state).map((contractRoute, index) => (
      <TableRow key={index}>
        <TableCell>{contractRoute.route}</TableCell>
        <TableCell>
          <div
            className="d-flex align-items-center pointer"
            style={{ background: "transparent" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contractRoute.contract}
              label="Contract"
              onChange={(event) => this.handleChangeContractRoute(event, contractRoute)}
            >
              {contracts.map((contract) => (
                <MenuItem value={contract.id}>{contract.name}</MenuItem>
              ))}
            </Select>
          </div>
        </TableCell>
        {contractRoute.contract ? this.renderCustomerItemsRow(contractRoute) : "Sélectionner un contrat"}
      </TableRow>
    ));
  };

  handleChangeContractRoute = (event, item) => {
    const { contractRoute } = this.state;
    const copyContractRoute = [...contractRoute];
    const findRoute = contractRoute.findIndex((element) => item.route === element.route);
    copyContractRoute[findRoute].contract = event.target.value;

    this.setState({ contractRoute: copyContractRoute });
  };

  handleCustomerItems = (event, contractRouteSelected) => {
    const { contractRoute } = this.state;
    const copyContractRoute = [...contractRoute];
    const findRoute = contractRoute.findIndex((element) => contractRouteSelected.route === element.route);
    copyContractRoute[findRoute].customer_item = event.target.value;

    this.setState({ contractRoute: copyContractRoute });
  };

  handleCreateContractInCiWebContractRoute = async () => {
    const { contractRoute, pathFile } = this.state;
    const { createContractRoute, router } = this.props;
    const { params } = router;
    const { customerId } = params;

    await createContractRoute(contractRoute, pathFile, customerId);
    this.setState({ confirmationContractRouteCreated: true, isAnalyzed: false });
  };

  handleFileUpload = async (file) => {
    await API.RouteTemplate.uploadXlsxFileForRouteTemplate(file).then((response) => {
      if (response && response.data) {
        this.setState({
          contractRoute: response.data.spreadsheets_routes,
          pathFile: response.data.current_file_path,
        });
      } else {
        alert("Vérifier votre fichier...");
      }
    });
  };
  renderCustomerItemsRow = (contractRoute) => {
    const { contracts } = this.props;
    const contract = contracts.find((element) => contractRoute.contract === element.id);
    const customerItems = contract.customerItems;

    return (
      <TableCell>
        {" "}
        <div
          className="d-flex align-items-center pointer"
          style={{ background: "transparent" }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={contractRoute.customer_item}
            label="Contract"
            onChange={(event) => this.handleCustomerItems(event, contractRoute)}
          >
            {customerItems.map((customerItem) => (
              <MenuItem value={customerItem.id}>{customerItem.name}</MenuItem>
            ))}
          </Select>
        </div>
      </TableCell>
    );
  };

  renderTableContractRoute = () => (
    <PageContainer style={{ width: "auto" }}>
      <PaperWrapper>
        <Table>
          {this.contractTableHeader()}
          <TableBody>{this.contractTableRows()}</TableBody>
        </Table>
      </PaperWrapper>
    </PageContainer>
  );

  warningModalNumberOfColumn = () => (
    <Dialog open={this.state.warningModalNumberOfColumn}>
      <DialogTitle>"Attention"</DialogTitle>
      <DialogContent>
        <DialogContentText>Le fichier ne contient pas {NUMBEROFCOLUMNS} colonnes</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ warningModalNumberOfColumn: false })}>
          <Trans i18nKey="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  confirmationContractRouteCreated = () => (
    <Dialog open={this.state.confirmationContractRouteCreated}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Les emplacements et les routes maîtres sont en cours de création !<br />
          Les données seront disponible dans une heure.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ confirmationContractRouteCreated: false })}>
          <Trans i18nKey="Continuer" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  render() {
    const {
      isAnalyzed,
      showAnalyseButton,
      showSpinner,
      warningModalNumberOfColumn,
      contractRoute,
      file,
      confirmationContractRouteCreated,
    } = this.state;

    return (
      <div>
        {!confirmationContractRouteCreated && (
          <UploadFile
            headerStartAtRow={1}
            firstItemUploaded={(columnHeader) =>
              this.setState({
                showAnalyseButton: true,
                item: columnHeader,
              })
            }
            uploadXslxFile={(xlsxFile) => this.handleFileUpload(xlsxFile)}
          />
        )}

        {warningModalNumberOfColumn && this.warningModalNumberOfColumn()}

        {confirmationContractRouteCreated && this.confirmationContractRouteCreated()}

        {showAnalyseButton && this.analyzeButton()}

        {isAnalyzed && file && this.handleFileUpload()}

        {contractRoute && contractRoute.length > 0 && isAnalyzed && this.renderTableContractRoute()}

        {showSpinner && <LoaderSpinner text={<Trans i18nKey="Analyse en cours..." />} />}
        {contractRoute && contractRoute.length > 0 && isAnalyzed && (
          <Button
            style={{
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              height: 60,
              borderRadius: 80,
              backgroundColor: "#E667CE",
              left: "91%",
              bottom: "5px",
              top: "-15px",
            }}
            tooltipLabel="Sauvegarder"
            onClick={() => this.handleCreateContractInCiWebContractRoute()}
          >
            <SaveIcon />
          </Button>
        )}
      </div>
    );
  }
}

LoadingRoutes.propTypes = {};

export default withCustomer(withContracts(LoadingRoutes));
