import { map, orderBy } from "lodash";

/**
 * Check if at least one property of an object is truthy.
 * @function
 * @param {Object} obj - The object to check
 * @returns {Boolean} - True if at least one property of the object is truthy, false otherwise or if empty object.
 */
function isObjectTruthy(obj, excludeKey = "") {
  return Object.entries(obj).filter(([key, val]) => key !== excludeKey && val).length > 0;
}

/**
 * Check if a passed parameter is an array and not empty
 * @function
 * @param {Array} variable - The value to check
 * @returns {Boolean} - Returns `true` if the parameter is an array and not empty, `false` otherwise
 */
function isArrayTruthy(variable) {
  return Array.isArray(variable) && variable.length > 0;
}

/**
 * Formats an array for using with React Select.
 * @param {Array} elements The array of to format and order
 * @param {*} value The value of an element of the array to use for a select
 * @param {String} label The label of an element of the array to use for a select
 * @returns {Array} - Returns a formatted and ordered array for usage with React Select
 */
const formattedReactSelect = (elements, value = "value", label = "label") => {
  // console.log("[formattedSelect] elements:", elements);
  if (!isArrayTruthy(elements)) return [];

  const formattedElements = map(elements, (element) => ({
    value: element[value],
    label: element[label],
    data: element,
  }));

  const orderedElements = orderBy(formattedElements, ["label"], ["asc"]);

  return orderedElements;
};

export { isObjectTruthy, isArrayTruthy, formattedReactSelect };
