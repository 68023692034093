import axios from "axios";

import { requestOptions } from "../auth";

const fetchTransactions = async (page, limit, filter) => {
  try {
    const res = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/paid_customer_items/transactions.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return res;
  } catch (error) {
    console.warn("[API.PaidCustomerItemService] fetchTransactions >> error:", error);
    return error.response;
  }
};

const fetchWebTransactions = async (page, limit, filter) => {
  try {
    const res = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/paid_customer_items/web_transactions.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return res;
  } catch (error) {
    console.warn("[API.PaidCustomerItemService] fetchWebTransactions >> error:", error);
    return error.response;
  }
};

const fetchUnusedPaidJobs = async (page, limit, filter) => {
  try {
    const res = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/paid_customer_items/unused_paid_jobs.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return res;
  } catch (error) {
    console.warn("[API.PaidCustomerItemService] fetchUnusedPaidJobs >> error:", error);
    return error.response;
  }
};

const fetchRefundedTransactions = async (page, limit, filter) => {
  try {
    const res = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/paid_customer_items/refunded_transactions.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return res;
  } catch (error) {
    console.warn("[API.PaidCustomerItemService] fetchRefundedTransactions >> error:", error);
    return error.response;
  }
};

const refund = async (paidCustomerItemID) => {
  try {
    const res = await axios.post(
      `${import.meta.env.VITE_API_URL}/paid_customer_items/${paidCustomerItemID}/refund`,
      {},
      requestOptions()
    );

    return res.data;
  } catch (error) {
    console.warn("[API.PaidCustomerItemService] refund >> error:", error);
    return error.response.data;
  }
};

/**
 * PaidCustomerItem api service for exposing api endpoints.
 *
 * Available requests:
 * - `refund(paidCustomerItemID)`
 * - `fetchWebTransactions(page, limit, filter)`
 * - `fetchUnusedPaidJobs(page, limit, filter)`
 * - `fetchRefundedTransactions(page, limit, filter)`
 */
export { refund, fetchTransactions, fetchWebTransactions, fetchUnusedPaidJobs, fetchRefundedTransactions };
