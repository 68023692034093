import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { withInterventionInProgress } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withRouter } from "@utils/withRouter";

import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { formattedTime } from "@utils/dates";
import { Trans } from "react-i18next";

const initialState = {
  errors: {
    endTimeChanged: false,
    startTime: false,
    weightDispatcher: false,
    weightUnitDispatcher: false,
  },
  endTime: "",
  startTime: "",
  weightDispatcher: "",
  weightUnitDispatcher: "",
};

class ModalIntervention extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      ...props.interventionData,
    };
  }

  get valid() {
    const errors = { ...initialState.errors };
    let valid = true;

    for (const name of ["startTime", "endTime", "weightDispatcher", "weightUnitDispatcher"]) {
      if (!this.state[name]) {
        valid = false;
        errors[name] = true;
      }
    }
    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // if (this.valid) {
    const { interventionData, editIntervention, refreshList } = this.props;

    const { startTime, endTime, weightUnitDispatcher, weightDispatcher } = this.state;

    const { id } = interventionData;

    await editIntervention(id, {
      start_time: startTime,
      end_time: endTime,
      weight: weightDispatcher,
      weight_unit: weightUnitDispatcher,
    });

    this.setState(initialState);
    refreshList();
    // }
  };

  render() {
    const { actionName } = this.props;

    const { endTime, startTime, errors, weightDispatcher, weightUnitDispatcher } = this.state;

    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        <DialogTitle>
          <Trans i18nKey="edit_intervention" />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <FlexRowWrapper style={{ marginTop: 20 }}>
                <HalfFormControl>
                  <TextFieldUi
                    id="datetime-local"
                    label={<Trans i18nKey="start_date" />}
                    type="datetime-local"
                    onChange={this.handleChangeFields("startTime")}
                    value={formattedTime(startTime) || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    id="datetime-local"
                    label={<Trans i18nKey="end_date" />}
                    type="datetime-local"
                    onChange={this.handleChangeFields("endTime")}
                    value={formattedTime(endTime) || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    error={errors.weightDispatcher}
                    fullWidth
                    type="number"
                    helperText={this.getErrorMessage("weightDispatcher")}
                    id="cpbr-weight-dispatcher"
                    label={<Trans i18nKey="weight" />}
                    onChange={this.handleChangeFields("weightDispatcher")}
                    value={weightDispatcher || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    error={errors.weightUnitDispatcher}
                    fullWidth
                    type="number"
                    helperText={this.getErrorMessage("weightUnitDispatcher")}
                    id="cpbr-weight-unit-dispatcher"
                    label={<Trans i18nKey="weight_unit" />}
                    onChange={this.handleChangeFields("weightUnitDispatcher")}
                    value={weightUnitDispatcher || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

//
ModalIntervention.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  interventionData: PropTypes.object.isRequired,
  editIntervention: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withInterventionInProgress(withRouter(ModalIntervention));
