import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import SwipeableViews from "react-swipeable-views";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Tab, Tabs, Typography, IconButton, ListItemIcon } from "@mui/material";
import { LocationItemLeftTabContent, LocationItemRightTabContent } from "./SideDrawerLocationItem";
import { ContractItemLeftTabContent, ContractItemRightTabContent } from "./SideDrawerContractItem";
import { withRouter } from "@utils/withRouter";

export const componentToLoad = {
  contractItem: "contractItem",
  locationItem: "locationItem",
  none: "none",
};

const LeftTabEditButton = styled(IconButton)`
  && {
    z-index: 5;
    padding: 0px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    color: ${({ theme }) => theme.colors.icon.default};
    &:hover {
      transform: scale(1.2);
      transition: transform 0.1s;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.icon.hover};
    }
  }
`;

const RightTabEditButton = styled(IconButton)`
  && {
    z-index: 5;
    padding: 0px 5px;
    position: absolute;
    top: 5px;
    right: -295px;
    color: ${({ theme }) => theme.colors.icon.default};
    &:hover {
      transform: scale(1.2);
      transition: transform 0.1s;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.icon.hover};
    }
  }
`;

const MapSideDrawerStyle = styled.div`
  position: relative;
  .sidedrawer-menu {
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    transition: 0.3s ease all;
    background-color: #ffffff;
    max-height: 100%;
    overflow: auto;
    &.open,
    &.close {
      .sidedrawer-tab {
        top: 50%;
        z-index: 1;
        right: -15px;
        position: absolute;
        .sidedrawer-btn {
          width: 100%;
          vertical-align: middle;
        }
        .sidedrawer-btn:hover {
          cursor: pointer;
          transform: scale(1.5);
          transition: 0.05s ease-in;
        }
      }
    }
    &.open {
      width: 300px;
      .sidedrawer-tab {
        display: block;
      }
    }
    &.close {
      width: 0px;
      .sidedrawer-tab {
        display: none;
      }
    }
    .menu {
      > li {
        > a {
          padding: 15px 20px;
          color: #fff;
        }
      }
    }
  }
`;

function TabContainer({ children }) {
  return <Typography component="div">{children}</Typography>;
}

class MapSideDrawer extends PureComponent {
  state = {
    value: 0,
  };

  handleTabChange = (event, value) => this.setState({ value });
  handleViewChangeIndex = (index) => this.setState({ value: index });
  handleContainerListState = (itemID) => this.state[itemID] || false;
  handleContainerListCollapse = (itemID) => () => this.setState({ [itemID]: !this.state[itemID] });

  render() {
    const { componentToLoad } = this.props;
    const {
      isRollOff = false,
      isLocationAssignable = false,
      sideDrawerLeftTabContent = {},
      sideDrawerRightTabContent = [],
      sideDrawerLeftTabEditButtonAction = () => {},
      sideDrawerRightTabEditButtonAction = () => {},
      isMapSideDrawerOpen = false,
      hideMapSideDrawer = () => {},
      handleShowAnomalyDetails = () => {},
      handleIsLocationAssigned = () => {},
      handleAssignSelectedLocation = () => {},
      handleUnassignSelectedLocation = () => {},
      selectedLocationId,
      changeSelectedLocation,
      path,
    } = this.props.componentDataToLoad;

    // Conditionally render side drawer tabs depending on the componentToLoad prop
    const renderTabs = (component) => {
      switch (component) {
        case "contractItem":
          return (
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ display: "flex", justifyContent: "center", backgroundColor: "#f5f5f5" }}
            >
              <Tab label="Informations" />
              <Tab label="Conteneur" />
            </Tabs>
          );
        case "locationItem":
          return (
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ display: "flex", justifyContent: "center", backgroundColor: "#f5f5f5" }}
            >
              <Tab label="Adresse" />
              <Tab label="Détails" />
            </Tabs>
          );
        case "none":
          return (
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ display: "flex", justifyContent: "center", backgroundColor: "#f5f5f5" }}
            >
              <Tab label="Information manquante" />
              <Tab label="Information manquante" />
            </Tabs>
          );
        default:
          return (
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ display: "flex", justifyContent: "center", backgroundColor: "#f5f5f5" }}
            >
              <Tab label="Information manquante" />
              <Tab label="Information manquante" />
            </Tabs>
          );
      }
    };

    // Conditionally render side drawer tabs content depending on the componentToLoad prop
    const renderComponent = (component) => {
      const { isFromCiWeb } = sideDrawerLeftTabContent;
      const { router } = this.props;

      switch (component) {
        case "contractItem":
          return (
            <SwipeableViews index={this.state.value} onChangeIndex={this.handleViewChangeIndex}>
              <TabContainer>
                {sideDrawerLeftTabContent && (
                  <ContractItemLeftTabContent
                    data={sideDrawerLeftTabContent}
                    urlCustomerContract={this.props.urlCustomerContract}
                    isLocationAssignable={isLocationAssignable}
                    handleShowAnomalyDetails={handleShowAnomalyDetails}
                    handleIsLocationAssigned={handleIsLocationAssigned}
                    handleAssignSelectedLocation={handleAssignSelectedLocation}
                    handleUnassignSelectedLocation={handleUnassignSelectedLocation}
                  />
                )}
              </TabContainer>

              <TabContainer>
                {sideDrawerRightTabContent && (
                  <ContractItemRightTabContent
                    data={sideDrawerRightTabContent}
                    handleContainerListClick={this.handleContainerListCollapse}
                    isShowing={this.handleContainerListState}
                  />
                )}
              </TabContainer>
            </SwipeableViews>
          );
        case "locationItem":
          return (
            <SwipeableViews animateHeight index={this.state.value} onChangeIndex={this.handleViewChangeIndex}>
              <TabContainer>
                {!isFromCiWeb && (
                  <LeftTabEditButton
                    disableRipple
                    size="small"
                    aria-label="Save"
                    onClick={sideDrawerLeftTabEditButtonAction}
                  >
                    <EditIcon />
                  </LeftTabEditButton>
                )}
                {sideDrawerLeftTabContent && (
                  <LocationItemLeftTabContent
                    data={sideDrawerLeftTabContent}
                    selectedLocationId={selectedLocationId}
                    changeSelectedLocation={changeSelectedLocation}
                    path={path}
                  />
                )}
              </TabContainer>

              <TabContainer>
                <RightTabEditButton
                  disableRipple
                  size="small"
                  aria-label="Save"
                  onClick={sideDrawerRightTabEditButtonAction}
                >
                  <EditIcon />
                </RightTabEditButton>
                {sideDrawerRightTabContent && (
                  <LocationItemRightTabContent data={sideDrawerRightTabContent} isRollOff={isRollOff} router={router} />
                )}
              </TabContainer>
            </SwipeableViews>
          );
        case "none":
          return (
            <SwipeableViews animateHeight index={this.state.value} onChangeIndex={this.handleViewChangeIndex}>
              <TabContainer>Information manquante</TabContainer>
              <TabContainer>Information manquante</TabContainer>
            </SwipeableViews>
          );
        default:
          return (
            <SwipeableViews animateHeight index={this.state.value} onChangeIndex={this.handleViewChangeIndex}>
              <TabContainer>Information manquante</TabContainer>
              <TabContainer>Information manquante</TabContainer>
            </SwipeableViews>
          );
      }
    };

    return (
      <MapSideDrawerStyle>
        <div className={`sidedrawer-menu${isMapSideDrawerOpen === true ? " open" : " close"}`}>
          <div className="sidedrawer-tab">
            <div className="sidedrawer-btn">
              <ListItemIcon onClick={hideMapSideDrawer}>
                <ChevronLeftIcon />
              </ListItemIcon>
            </div>
          </div>

          {renderTabs(componentToLoad)}
          {renderComponent(componentToLoad)}
        </div>
      </MapSideDrawerStyle>
    );
  }
}

MapSideDrawer.defaultProps = {
  componentToLoad: "none",
  componentDataToLoad: [],
};

export default withRouter(MapSideDrawer);
