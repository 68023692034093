import { includes, map } from "lodash";
import React, { useState } from "react";

import useFetchListCodes from "@hooks/list-codes/fetch-list-codes.hook";
import { Save } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  Button,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";
import * as API from "@services";
import DialogWrapper from "@ui/DialogWrapper";
import LoadingButton from "@ui/LoadingButton";
import PropTypes from "prop-types";

const interruptionInitialErrorsStates = {
  code: false,
};

const interruptionInitialState = {
  code: "",
  comment: "",
  photo: null,
};

const interruptionInitialStates = {
  data: interruptionInitialState,
  error: interruptionInitialErrorsStates,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const valid = (interruption, setInterruption) => {
  const errors = map(interruption, (value, key) => {
    if (value === "" && key !== "comment") {
      setInterruption((prevState) => ({
        ...prevState,
        error: { ...prevState.error, [key]: true },
      }));
    }
    return value === "" && key !== "comment";
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const SelectDescription = (items) => {
  return items.map(({ code, description }) => (
    <MenuItem
      key={code}
      value={code}
    >
      {description}
    </MenuItem>
  ));
};

const handleChangeInterruptionInfos = (setInterruption) => (event) => {
  const { name, value } = event.target;
  setInterruption((prevState) => ({
    ...prevState,
    data: { ...prevState.data, [name]: value },
    error: { ...prevState.error, [name]: false },
  }));
};

const handleSubmitInterruption = async (interruption, job, setInterruption, handleClose, refreshList) => {
  const isValid = valid(interruption.data, setInterruption);

  if (isValid) {
    await API.Intervention.createInterruption(job.intervention.id, interruption.data);
    handleClose();
    refreshList();
  }
};

const handleCancelButton = (onCancel, setInterruption) => () => {
  onCancel();
  setInterruption(interruptionInitialStates);
};

const handleFileSelectButton = (setInterruption) => (event) => {
  setInterruption((prevState) => ({
    ...prevState,
    data: {
      ...prevState.data,
      photo: event.target.files[0],
    },
  }));
};

function ModalInterruption({ onCancel, open, handleClose, refreshList, job }) {
  console.log("[ModalInterruption] rendered");
  const [interruption, setInterruption] = useState(interruptionInitialStates);
  const { data: interruptionCategories, isLoading } = useFetchListCodes("INTERRUPTION_CATEGORIES");

  if (isLoading) {
    return;
  }

  const uploaded_photo_name =
    interruption.data.photo &&
    interruption.data.photo.name.slice(0, 30) + (interruption.data.photo.name.length > 30 ? "..." : "");
  return (
    <DialogWrapper
      onClose={handleCancelButton(onCancel, setInterruption)}
      open={open}
    >
      <DialogTitle>Interruption</DialogTitle>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={5}
        mx={10}
        my={2}
      >
        <FormControl fullWidth>
          <InputLabel shrink>Raison</InputLabel>
          <Select
            notched
            name="code"
            label="Raison"
            variant="outlined"
            defaultValue=""
            error={interruption.error.code}
            MenuProps={MenuProps}
            onChange={handleChangeInterruptionInfos(setInterruption)}
          >
            {SelectDescription(interruptionCategories)}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          name="comment"
          id="outlined-multiline-static"
          label="Commentaire (facultatif)"
          multiline
          onChange={handleChangeInterruptionInfos(setInterruption)}
        />

        <Stack
          direction="row"
          width={"100%"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            onChange={handleFileSelectButton(setInterruption)}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Télécharger
            </Button>
          </label>
          {interruption.data.photo && (
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                pr={1}
                pl={1}
              >
                {uploaded_photo_name}
              </Typography>

              <Tooltip
                placement={"top"}
                title={
                  <ImageListItem>
                    <img src={URL.createObjectURL(interruption.data.photo)} />
                  </ImageListItem>
                }
              >
                <PreviewIcon
                  color={"primary"}
                  fontSize={"large"}
                />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack
        display="flex"
        justifyContent="flex-end"
        p={1}
        spacing={1}
        direction="row"
      >
        <Button onClick={handleCancelButton(onCancel, setInterruption)}>Annuler</Button>
        <LoadingButton
          startIcon={<Save />}
          onClick={() => {
            handleSubmitInterruption(interruption, job, setInterruption, handleClose, refreshList);
          }}
          loadingPosition="start"
        >
          Confirmer
        </LoadingButton>
      </Stack>
    </DialogWrapper>
  );
}

ModalInterruption.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
};

export default ModalInterruption;
