import { TablePagination as TablePaginationMui } from "@mui/material";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

const TablePaginationMuiWrapper = styled(TablePaginationMui)`
  .select-icon {
    top: 3px;
  }

  .select-wrapper {
    padding-right: 22px;
  }

  .spacer-centered {
    flex: 0.5;
  }
`;

const TablePaginationWrapper = ({ children = "", ...remainingProps }) => (
  <TablePaginationMuiWrapper
    classes={{
      select: "select-wrapper",
      selectIcon: "select-icon",
      spacer: "spacer-centered",
    }}
    {...remainingProps}
  >
    {children}
  </TablePaginationMuiWrapper>
);

TablePaginationWrapper.defaultProps = {
  children: "",
};

TablePaginationWrapper.propTypes = {
  children: PropTypes.node,
};

export default TablePaginationWrapper;
