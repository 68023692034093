import LocationDetail, {
  DateModality,
  FrequencyModality,
  MatterModality,
  DayModality,
} from "@@types/location-details/location-detail.type";
import { includes } from "lodash";
import { Dispatch, SetStateAction } from "react";

export type useLocationModalityModalValidationType = {
  valid: (
    modality: LocationDetail,
    weekDays: DayModality[],
    setModality: Dispatch<SetStateAction<LocationDetail>>
  ) => boolean;
};

const useLocationModalityModalValidation = (): useLocationModalityModalValidationType => {
  const matterTypeValidation = (
    matter: MatterModality,
    setModality: Dispatch<SetStateAction<LocationDetail>>
  ): boolean => {
    setModality((prevState) => ({
      ...prevState,
      matter: { ...prevState.matter, error: !matter.value },
    }));
    return !!matter.value;
  };

  const frequencyValidation = (
    frequency: FrequencyModality,
    setModality: Dispatch<SetStateAction<LocationDetail>>
  ): boolean => {
    setModality((prevState) => ({
      ...prevState,
      frequency: { ...prevState.frequency, error: frequency.value <= 0 },
    }));
    return frequency.value > 0;
  };

  const collectDaysValidation = (weekDays: DayModality[]): boolean => {
    const activeWeekDays = Object.values(weekDays).filter((weekDay) => weekDay.active);
    return activeWeekDays.length !== 0;
  };

  const datesValidation = (
    from: DateModality,
    until: DateModality,
    setModality: Dispatch<SetStateAction<LocationDetail>>
  ): boolean => {
    if (from.active && until.active) {
      if (from.value > until.value) {
        setModality((prevState) => ({
          ...prevState,
          until: { ...prevState.until, error: true },
          from: { ...prevState.from, error: true },
        }));
        return false;
      }
    }
    return true;
  };

  const valid = (
    modality: LocationDetail,
    weekDays: DayModality[],
    setModality: Dispatch<SetStateAction<LocationDetail>>
  ): boolean => {
    const isMatterTypeValid = matterTypeValidation(modality.matter, setModality);
    const isFrequencyValid = frequencyValidation(modality.frequency, setModality);
    const isCollectDaysValid = collectDaysValidation(weekDays);
    const isDatesValid = datesValidation(modality.from, modality.until, setModality);

    return !includes([isMatterTypeValid, isFrequencyValid, isCollectDaysValid, isDatesValid], false);
  };

  return {
    valid,
  } as useLocationModalityModalValidationType;
};

export default useLocationModalityModalValidation;
