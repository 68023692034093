import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const LocationIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <circle
          cx="24"
          cy="24"
          r="24"
          fill={fillColor}
        />
        <path
          d="M21.6061 30.7992V24.795H26.3886V30.7992C26.3886 31.4596 26.9267 32 27.5843 32H31.1712C31.8288 32 32.3668 31.4596 32.3668 30.7992V22.3933H34.3994C34.9494 22.3933 35.2125 21.7088 34.794 21.3485L24.7984 12.3062C24.3441 11.8979 23.6506 11.8979 23.1963 12.3062L13.2007 21.3485C12.7942 21.7088 13.0453 22.3933 13.5953 22.3933H15.6278V30.7992C15.6278 31.4596 16.1659 32 16.8235 32H20.4104C21.068 32 21.6061 31.4596 21.6061 30.7992Z"
          fill="white"
        />
      </svg>
    </Container>
  );
};
