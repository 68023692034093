import React from "react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import PageContainer from "./PageContainer";
import { HeaderTitle } from "../routes/utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const RouteHeader = ({
  returnOnClick,
  pageTitle,
  sidebarOnClick,
  sidebarTitle,
  isQuickAssignShowing,
  quickAssign,
  quickAssignOnClick,
}) => {
  return (
    <div className="header-wrapper">
      <PageContainer>
        <Grid container spacing={3} alignItems="center">
          <Grid item style={{ marginRight: 8 }}>
            <Tooltip title="Retour">
              <IconButton
                color="secondary"
                aria-label="close"
                onClick={returnOnClick}
                variant="outlined"
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <HeaderTitle>{pageTitle}</HeaderTitle>
          </Grid>
          <Grid
            item
            style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}
          >
            {isQuickAssignShowing && (
              <FormControlLabel
                control={
                  <Switch checked={quickAssign} onChange={quickAssignOnClick} value="quickAssign" />
                }
                label={quickAssign ? "Quick Assign" : "Information"}
              />
            )}
            {sidebarTitle && (
              <Button color="primary" variant="outlined" onClick={sidebarOnClick}>
                {sidebarTitle}
              </Button>
            )}
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  );
};

RouteHeader.defaultProps = {
  currentAction: "show",
  pageTitle: "Page title",
};

RouteHeader.propTypes = {
  currentAction: PropTypes.string,
  returnOnClick: PropTypes.func.isRequired,
  pageTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sidebarOnClick: PropTypes.func,
  sidebarTitle: PropTypes.string,
  quickAssign: PropTypes.bool,
};

export default RouteHeader;
