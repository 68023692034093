import React, { Dispatch, FC, SetStateAction } from "react";
import { Stack, TextField } from "@mui/material";
import useLocationModalityFrequency from "@components/location/route/modality/modals/hooks/location-modality-modal-frequency.hook";
import LocationDetail from "@@types/location-details/location-detail.type";

type LocationModalityModalFrequencyProps = {
  modality: LocationDetail;
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>;
};

const LocationModalityModalFrequency: FC<LocationModalityModalFrequencyProps> = ({ modality, setModality }) => {
  const { handleChangeFrequencyValue } = useLocationModalityFrequency(setModality);

  return (
    <Stack
      alignItems="center"
      direction={"row"}
      spacing={2}
    >
      <span>Au(x)</span>
      <TextField
        error={modality.frequency.error}
        type="number"
        inputProps={{ min: 0 }}
        name="frequency"
        value={modality.frequency.value}
        onChange={(event) => handleChangeFrequencyValue(event)}
        sx={{
          width: 60,
          "input::-webkit-outer-spin-button": { WebkitAppearance: "none" },
          "input::-webkit-inner-spin-button": { WebkitAppearance: "none", margin: 0 },
        }}
      />
      <span>semaine(s)</span>
    </Stack>
  );
};

export default LocationModalityModalFrequency;
