/**
 * Deletes the bearer token from localStorage
 *
 * @class
 */
class DeleteBearerTokenService {
  /**
   * Deletes the bearer token from localStorage
   *
   * @returns {void} void
   */
  static delete() {
    localStorage.removeItem("bearerToken");
  }
}

export default DeleteBearerTokenService;
