import React, { ReactElement } from "react";
import { ICON_NAMES, IconNames } from "@components/icons/IconNames.type";
import { IconProps } from "@components/icons/Icon.type";

interface MarkerIconProps {
  name: "marker";
  selected: boolean;
}

export type Props = {
  name: IconNames;
} & (IconProps | MarkerIconProps);

export const Icon = ({ name, ...remaining }: Props): ReactElement => {
  const IconToRender = ICON_NAMES[name] as React.ElementType;
  return <IconToRender {...remaining} />;
};

export default Icon;
