import { IconProps } from "@components/icons/Icon.type";
import React, { ReactElement } from "react";
import Container from "@components/icons/Container";

export const MapDotMarkerIcon = ({ size, fillColor }: IconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15"
        width="15"
        fill={fillColor}
      >
        <circle
          cx="8"
          cy="8"
          r="3"
        />
      </svg>
    </Container>
  );
};
