import { Trans } from "react-i18next";
import React from "react";

export const jobStatuses = {
  COMPLETED: <Trans i18nKey="jobs.statuses.completed" />,
  IN_PROGRESS: <Trans i18nKey="jobs.statuses.in_progress" />,
  INTERRUPTED: <Trans i18nKey="jobs.statuses.interrupted" />,
  TODO: <Trans i18nKey="jobs.statuses.todo" />,
};

export const itemStatuses = {
  active: <Trans i18nKey="items.statuses.active" />,
  inactive: <Trans i18nKey="items.statuses.inactive" />,
  blocked: <Trans i18nKey="items.statuses.blocked" />,
  auto_blocked: <Trans i18nKey="items.statuses.auto_blocked" />,
};
