import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3;

  .inner {
    display: table;
    width: 100%;
    height: 100%;

    .cell {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;

      p {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        color: #006f9c;
        font-weight: 500;
      }
    }
  }
`;

const LoaderSpinner = ({ text }) => (
  <Wrapper className="spinner-loader-wrapper">
    <div className="inner">
      <div className="cell">
        <CircularProgress />
        <p>{text}</p>
      </div>
    </div>
  </Wrapper>
);

LoaderSpinner.defaultProps = {
  text: "Default text",
};

LoaderSpinner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LoaderSpinner;
