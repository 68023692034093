// React imports
import React, { useState, useCallback, useMemo, memo } from "react";

// Internal imports
import * as API from "@services";
import { isArrayTruthy } from "@utils/common";
import AccountingItemChild from "@components/accountingItemsPricesAndTaxes/AccountingItemPrices/AccountingItemChild";
import {
  IconButton,
  ListItem,
  ListItemButton,
  Stack,
  FormControl,
  InputLabel,
  Input,
  Box,
  InputAdornment,
} from "@mui/material";

// External imports
import { Edit, ExpandLess, ExpandMore, Save, Cancel } from "@mui/icons-material";
import { includes, map, isEqual } from "lodash";

const valid = (accountingItem, setAccountingItems, index) => {
  const errors = map(accountingItem.data, (value, key) => {
    if (!value) {
      setAccountingItems((prevState) => {
        const updatedAccountingItems = [...prevState];

        updatedAccountingItems[index] = {
          data: { ...updatedAccountingItems[index].data },
          errors: { ...updatedAccountingItems[index].errors, [key]: true },
          children: [...updatedAccountingItems[index].children],
        };
        return updatedAccountingItems;
      });
    }
    return !value;
  });

  let isValid = !includes(errors, true);

  return isValid;
};

const handleEditAccountingItemParent = (index, setSingleAccountingItem, accountingItem) => (event) => {
  const { name, value } = event.target;
  setSingleAccountingItem(name, value, index, accountingItem.children);
};

const handleSaveEditAccountingItemParent = async (
  accountingItem,
  accountingItems,
  index,
  setAccountingItems,
  handleApiResponse,
  prevRef,
  handleEditAccountingItem
) => {
  const isValid = valid(accountingItem, setAccountingItems, index);

  if (isValid) {
    const res = await API.AccountingItem.updateContractAccountingItem(
      accountingItem.data,
      accountingItem.data.accounting_id
    );

    const error = res.status === "failure";

    const accountingItemsArray = [...accountingItems];

    setAccountingItems(() => {
      accountingItemsArray[index] = {
        data: { ...accountingItemsArray[index].data },
        errors: { ...accountingItemsArray[index].errors, name: error, sku: error },
        children: [...accountingItemsArray[index].children],
      };
      return accountingItemsArray;
    });

    handleApiResponse(res, () => {
      prevRef.current = accountingItemsArray;
      handleEditAccountingItem(accountingItem.data.accounting_id);
    });
  }
};

const handleButtonCancelAccountingItemParent = (
  accountingItem,
  index,
  setAccountingItems,
  prevRef,
  handleEditAccountingItem
) => {
  setAccountingItems((prevState) => {
    const updatedAccountingItems = [...prevState];
    updatedAccountingItems[index] = {
      data: prevRef.current[index].data,
      errors: prevRef.current[index].errors,
      children: updatedAccountingItems[index].children,
    };
    return updatedAccountingItems;
  });

  handleEditAccountingItem(accountingItem.data.accounting_id);
};

const AccountingItemParentItem = ({
  accountingItem,
  accountingItems,
  prevRef,
  setAccountingItems,
  setSingleAccountingItem,
  handleCollapse,
  handleEditAccountingItem,
  editAccountingItemParent,
  collapseRow,
  handleApiResponse,
}) => {
  const index = accountingItems.indexOf(accountingItem);
  const isEditing = editAccountingItemParent[accountingItem.data.accounting_id];

  return (
    <ListItem
      key={accountingItem.data.accounting_id}
      divider={index !== accountingItems.length - 1}
      sx={{ flexDirection: "column" }}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        width={"100%"}
        spacing={2}
        color={"#006F9C"}
      >
        <FormControl
          variant="standard"
          disabled={!isEditing}
          fullWidth
        >
          <InputLabel htmlFor="component-simple">Nom</InputLabel>
          <Input
            name="name"
            value={accountingItem.data.name}
            disableUnderline={!isEditing}
            error={accountingItem.errors.name}
            onChange={handleEditAccountingItemParent(index, setSingleAccountingItem, accountingItem)}
            sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
          />
        </FormControl>

        <FormControl
          variant="standard"
          disabled={!isEditing}
          sx={{ minWidth: "13%" }}
        >
          <InputLabel htmlFor="component-simple">Sku</InputLabel>
          <Input
            name="sku"
            value={accountingItem.data.sku}
            disableUnderline={!isEditing}
            error={accountingItem.errors.sku}
            onChange={handleEditAccountingItemParent(index, setSingleAccountingItem, accountingItem)}
            sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
          />
        </FormControl>

        <FormControl
          variant="standard"
          disabled={!isEditing}
        >
          <InputLabel htmlFor="component-simple">Prix unitaire</InputLabel>
          <Input
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            name="unit_price"
            value={accountingItem.data.unit_price}
            disableUnderline={!isEditing}
            error={accountingItem.errors.unit_price}
            onChange={handleEditAccountingItemParent(index, setSingleAccountingItem, accountingItem)}
            sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
          />
        </FormControl>

        <Stack
          direction={"row"}
          height={40}
        >
          {isEditing && (
            <IconButton
              color="secondary"
              onClick={() =>
                handleSaveEditAccountingItemParent(
                  accountingItem,
                  accountingItems,
                  index,
                  setAccountingItems,
                  handleApiResponse,
                  prevRef,
                  handleEditAccountingItem(accountingItem.data.accounting_id)
                )
              }
            >
              <Save />
            </IconButton>
          )}

          {!isEditing && (
            <IconButton
              color="secondary"
              onClick={handleEditAccountingItem(accountingItem.data.accounting_id)}
            >
              <Edit />
            </IconButton>
          )}
          <Box
            width={40}
            height={"100%"}
          >
            {isEditing && (
              <IconButton
                color="secondary"
                onClick={() =>
                  handleButtonCancelAccountingItemParent(
                    accountingItem,
                    index,
                    setAccountingItems,
                    prevRef,
                    handleEditAccountingItem(accountingItem.data.accounting_id)
                  )
                }
              >
                <Cancel />
              </IconButton>
            )}
          </Box>
        </Stack>

        <Stack direction={"row"}>
          <Box
            width={55}
            height={"100%"}
          >
            {isArrayTruthy(accountingItem.children) ? (
              <ListItemButton onClick={() => handleCollapse(accountingItem.data.customer_item_id)}>
                {collapseRow[accountingItem.data.customer_item_id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Stack>
      <AccountingItemChild
        key={accountingItem.data.customer_item_id}
        collapseRow={collapseRow}
        customerItemId={accountingItem.data.customer_item_id}
        accountingItem={accountingItem}
        accountingItems={accountingItems}
        setAccountingItems={setAccountingItems}
        prevRef={prevRef}
      />
    </ListItem>
  );
};

function AccountingItemParent({ accountingItem, accountingItems, setAccountingItems, handleApiResponse, prevRef }) {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState({});
  const [isEditAccountingItemParentActive, setIsEditAccountingItemParentActive] = useState(false);
  const [editAccountingItemParent, setEditAccountingItemParent] = useState({});

  const handleCollapse = useCallback((customerItemId) => {
    const copyCollapse = collapseRow;
    const actualValue = copyCollapse[customerItemId];
    copyCollapse[customerItemId] = !actualValue;
    setIsCollapseOpen((prevState) => !prevState);
    setCollapseRow(copyCollapse);
  }, []);

  const handleEditAccountingItem = useCallback(
    (accountingId) => (event) => {
      const copyEditAccountingItemParent = editAccountingItemParent;
      const actualValue = copyEditAccountingItemParent[accountingId];
      copyEditAccountingItemParent[accountingId] = !actualValue;
      setIsEditAccountingItemParentActive((prevState) => !prevState);
      setEditAccountingItemParent(copyEditAccountingItemParent);
    },
    []
  );

  const setSingleAccountingItem = useCallback((name, value, parentIndex, children) => {
    setAccountingItems((prevState) => {
      const updatedAccountingItems = [...prevState];
      updatedAccountingItems[parentIndex] = {
        data: { ...updatedAccountingItems[parentIndex].data, [name]: value },
        errors: { ...updatedAccountingItems[parentIndex].errors, [name]: !value },
        children: children,
      };
      return updatedAccountingItems;
    });
  });

  return (
    <AccountingItemParentItem
      key={accountingItem.data.accounting_id}
      accountingItem={accountingItem}
      accountingItems={accountingItems}
      prevRef={prevRef}
      setAccountingItems={setAccountingItems}
      setSingleAccountingItem={setSingleAccountingItem}
      handleCollapse={handleCollapse}
      handleEditAccountingItem={handleEditAccountingItem}
      editAccountingItemParent={editAccountingItemParent}
      collapseRow={collapseRow}
      handleApiResponse={handleApiResponse}
    />
  );
}

const areEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.accountingItem, nextProps.accountingItem)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.prevRef, nextProps.prevRef)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(AccountingItemParent, areEqual);
