import React, { ReactElement } from "react";
import { ModalContent } from "@ui/modal/Modal.styles";

export interface ContentProps {
  children: ReactElement | string | string[];
}

export const Content = ({ children }: ContentProps): ReactElement => <ModalContent>{children}</ModalContent>;

export default Content;
