import axios from "axios";
import { requestOptions } from "../auth";

/**
 * Fetches units with assignments based on specified filters.
 * @param {Object} filterStates - The filter states object.
 * @param {string} filterStates.filter - The filter to apply.
 * @param {string|number} filterStates.rowsPerPage - The number of rows per page or 'all' for all rows.
 * @param {number} filterStates.page - The page number.
 * @param {string} filterStates.date - The date in ISO format (yyyy-mm-dd).
 * @returns {Promise<Object>} The units data retrieved from the API.
 */
const fetchUnitsWithAssignments = async (filterStates = {}) => {
  try {
    const { filter = "", rowsPerPage = "all", page = 1, date = new Date().toISOString().slice(0, 10) } = filterStates;

    const response = await axios.get(
      `${
        import.meta.env.VITE_API_URL
      }/units.json?page=${page}&limit=${rowsPerPage}&filter=${filter}&date=${date}&with_assignments=true`,
      requestOptions()
    );

    return response.data?.data?.instances ?? [];
  } catch (error) {
    console.warn("[fetch-units-with-assignments.service] error:", error);
  }
};

export default fetchUnitsWithAssignments;
