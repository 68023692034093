import React, { useEffect, useState } from "react";
import { Box, Stack, Toolbar, Button } from "@mui/material";
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import { formattedDate, formattedTimeForExport } from "@utils/dates";
import * as API from "@services";
import { isArrayTruthy } from "@utils/common";
import ReportDatesSelects from "@components/report/ReportDatesSelects";
import ReportFinancialSummariesToExcelButton from "@components/report/ReportFinancialSummariesToExcelButton";
import ReportProblemCaseToExcelButton from "@components/report/ReportProblemCaseToExcelButton";
import ReportProblemCaseVdqToExcelButton from "@components/report/ReportProblemCaseVdqToExcelButton";
import ReportTableFinancialSummaries from "@components/report/ReportTableFinancialSummaries";
import ReportTableProblemCase from "@components/report/ReportTableProblemCase";
import LoaderSpinner from "@components/LoaderSpinner";
import ReportContractsSelect from "@components/report/ReportContractsSelect";
import ReportTypeSelect from "@components/report/ReportTypeSelect";
import { Trans } from "react-i18next";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { downloadFile } from "@utils/files";

const initialReportStates = {
  customer: {},
  summaries: [],
  selectedContractsIds: [],
  selectedReportType: 1,
  startDate: formattedDate(),
  endDate: formattedDate(),
  loading: false,
};

const reportTypes = [
  { value: 1, name: "Rapport financier" },
  { value: 2, name: "Rapport des cas problèmes" },
];

const initialReportData = {
  types: reportTypes,
  contracts: [],
};

const apiCall = async (report, type, setReport) => {
  setReport((prevState) => ({
    ...prevState,
    loading: true,
  }));

  const params = {
    contracts_ids: report.selectedContractsIds,
    customer_id: report.customer.id,
    start_date: report.startDate,
    end_date: report.endDate,
    summaries: report.summaries,
  };

  let res;
  switch (type) {
    case 1: {
      res = await API.Contract.fetchContractsSummaries(params);
      break;
    }
    case 2: {
      res = await API.Customer.fetchCustomerProblemCaseReport(params);
      break;
    }
    default: {
      res = "";
      break;
    }
  }

  setReport((prevState) => ({
    ...prevState,
    summaries: res,
    loading: false,
  }));
};

export default function Report(props) {
  const [report, setReport] = useState(initialReportStates);
  const [data, setData] = useState(initialReportData);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState({});

  useEffect(() => {
    setReport((prevState) => ({
      ...prevState,
      customer: props.customer,
    }));

    setData((prevState) => ({
      ...prevState,
      contracts: props.customer.contracts,
    }));
  }, []);

  const handleCollapse = (name) => {
    const copyCollapse = collapseRow;
    const actualValue = copyCollapse[name];
    copyCollapse[name] = !actualValue;
    setIsCollapseOpen((prevState) => !prevState);
    setCollapseRow(copyCollapse);
  };

  const handleDownloadProblemCasePhotos = async () => {
    const res = await API.Report.downloadProblemCasePhotos(report.customer?.id, report.startDate, report.endDate);

    downloadFile(new Blob([res]), `${formattedTimeForExport()}.zip`);
  };

  return (
    <PageContainer style={{ width: "100%" }}>
      <PaperWrapper>
        <TableOverflowWrapper>
          <Toolbar>
            <Stack
              justifyContent={"flex-start"}
              width={"100%"}
              direction="row"
              p={2}
              spacing={2}
            >
              {/* CONTRACT TYPE SELECT*/}
              <ReportTypeSelect
                types={data.types}
                report={report}
                setReport={setReport}
              />

              {/* CONTRACTS SELECT*/}
              <ReportContractsSelect
                contracts={data.contracts}
                report={report}
                setReport={setReport}
              />

              {/*BUTTON CREATE REPORT*/}
              {report.selectedReportType && (
                <Button
                  variant="contained"
                  sx={{ background: "#006F9C", width: 200 }}
                  onClick={() => apiCall(report, report.selectedReportType, setReport)}
                >
                  Créer rapport
                </Button>
              )}

              {/*  /!* START AND END DATE PICKER *!/*/}
              <Box pl="30%">
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <ReportDatesSelects
                    report={report}
                    setReport={setReport}
                  />
                </Stack>
              </Box>
            </Stack>
          </Toolbar>

          {/*/!* EXPORT FINANCIAL REPORT EXCEL BUTTON*!/*/}
          {report.selectedReportType === 1 && isArrayTruthy(report.summaries) && !report.loading && (
            <ReportFinancialSummariesToExcelButton report={report} />
          )}

          <Stack
            direction="row"
            spacing={2}
            pl="36px"
          >
            {/*/!* EXPORT PROBLEM CASE REPORT EXCEL BUTTON*!/*/}
            {report.selectedReportType === 2 && isArrayTruthy(report.summaries) && !report.loading && (
              <ReportProblemCaseToExcelButton report={report} />
            )}

            {/*/!* EXPORT PROBLEM CASE VDQ REPORT EXCEL BUTTON*!/*/}
            {/*/!* APPARAIT UNIQUEMENT POUR CLIENT VILLE DE QUEBEC *!/*/}
            {report.selectedReportType === 2 &&
              report.customer.id === 11 &&
              isArrayTruthy(report.summaries) &&
              !report.loading && <ReportProblemCaseVdqToExcelButton report={report} />}

            <Button
              startIcon={<InsertDriveFileIcon style={{ color: "#000", marginRight: 5 }} />}
              onClick={handleDownloadProblemCasePhotos}
            >
              Télécharger les photos de cas problèmes
            </Button>
          </Stack>

          {/*/!* TABLE VIDE *!/*/}
          {!isArrayTruthy(report.summaries) && !report.loading && (
            <p style={{ textAlign: "center" }}>Aucune donnée pour ces dates...</p>
          )}

          {/*/!* FINANCIAL REPORT TABLE *!/*/}
          {report.selectedReportType === 1 && isArrayTruthy(report.summaries) && !report.loading && (
            <ReportTableFinancialSummaries
              summaries={report.summaries}
              collapseRow={collapseRow}
              handleCollapse={handleCollapse}
            />
          )}

          {/*/!* PROBLEM CASE REPORT TABLE *!/*/}
          {report.selectedReportType === 2 && isArrayTruthy(report.summaries) && !report.loading && (
            <ReportTableProblemCase
              summaries={report.summaries}
              collapseRow={collapseRow}
            />
          )}
        </TableOverflowWrapper>
        {/* LOADING SPINNER */}
        {report.loading && <LoaderSpinner text={<Trans i18nKey="download_data" />} />}
      </PaperWrapper>
    </PageContainer>
  );
}
