// React imports
import React, { useEffect, useState, useCallback } from "react";

// Internal imports
import * as API from "@services";
import StripeSetupForm from "@components/stripe/StripeSetupForm";
import PaymentMethodsList from "@components/payments/PaymentMethodsList";

// External iports
import styled from "@emotion/styled";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { ArrowForwardIosSharp, AddCard, Clear } from "@mui/icons-material";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomerPaymentDetails({
  stripeCustomer,
  setupIntent,
  purchases,
  paymentMethods,
  setSingleProperty,
  stripeDefaultPaymentMethod,
}) {
  useEffect(() => {
    return () => setSingleProperty("stripe", null, "setupIntent");
  }, []);

  const handleAddPaymentMethod = useCallback(
    async (event) => {
      event.stopPropagation();

      if (setupIntent) {
        setSingleProperty("stripe", null, "setupIntent");
      } else {
        const stripeSetupIntent = await API.Stripe.createStripeSetupIntent(stripeCustomer.id);

        setSingleProperty("stripe", stripeSetupIntent, "setupIntent");
      }
    },
    [setupIntent, stripeCustomer.id]
  );

  return (
    <Box>
      {/*/!* Customer bought products if he has any *!/*/}
      {/*{!!purchases?.length && (*/}
      {/*  <Accordion defaultExpanded>*/}
      {/*    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">*/}
      {/*      <Typography>Produits achetés</Typography>*/}
      {/*    </AccordionSummary>*/}
      {/*    <AccordionDetails>*/}
      {/*      <CustomerPurchasesList purchases={purchases} />*/}
      {/*    </AccordionDetails>*/}
      {/*  </Accordion>*/}
      {/*)}*/}

      {/* Customer payment methods if he has any */}
      {!!paymentMethods?.length && (
        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
              <Typography>Méthodes de paiements</Typography>

              <Tooltip
                title={setupIntent ? "Annuler l'ajout de carte" : "Ajouter une carte"}
                placement="top"
                PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, -10] } }] }}
              >
                <IconButton color="primary" onClick={(e) => handleAddPaymentMethod(e)} size="small">
                  {setupIntent ? <Clear sx={{ fontSize: 30 }} /> : <AddCard sx={{ fontSize: 30 }} />}
                </IconButton>
              </Tooltip>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {setupIntent && <StripeSetupForm style={{ paddingBottom: 20 }} />}

            <PaymentMethodsList
              defaultPaymentMethod={stripeDefaultPaymentMethod}
              paymentMethods={paymentMethods}
              setSingleProperty={setSingleProperty}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}
