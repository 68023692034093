import React, { PureComponent } from "react";
import { IconButton, Tab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import FlexHeaderRowWrapper from "./ui/FlexHeaderRowWrapper";
import HeaderColumnWrapper from "./ui/HeaderColumnWrapper";
import HeaderContextWrapper from "./ui/HeaderContextWrapper";
import ModalUnit from "./ModalUnit";
import PageHeader from "./ui/PageHeader";
import { withRouter } from "@utils/withRouter";
import * as API from "@services";
import { Trans } from "react-i18next";

class Unit extends PureComponent {
  state = {
    unit: {},
    currentTab: 0,
    unitModalOpened: false,
  };

  componentDidMount = async () => {
    await this.fetchUnit();
  };

  fetchUnit = async () => {
    const unit = await API.Unit.fetchUnit(this.props.router.params.unitId);

    this.setState({ unit });
  };

  handleToggleUnitModal = (opened) => () => {
    this.setState({ unitModalOpened: opened });
  };

  handleRefreshAfterModify = () => {
    this.handleToggleUnitModal(false)();
    this.fetchUnit();
  };

  renderPageHeader = () => {
    const { router } = this.props;
    const { axles, name, loadingType, truckId, truckRegistration, truckSerialNumber, unloadedWeight } = this.state.unit;

    return (
      <PageHeader
        titleText={`${name}${name ? " / " : ""}${truckId}`}
        onBackClick={() => router.navigate(-1)}
        onEditableClick={this.handleToggleUnitModal(true)}
      >
        <IconButton
          color="secondary"
          id="cpbr-edit-unit"
          onClick={this.handleToggleUnitModal(true, this.state.unit)}
          size="large"
        >
          <EditIcon fontSize="small" />
        </IconButton>

        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="units.truck_registration" />: {truckRegistration}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>

        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="units.truck_serial_number" />: {truckSerialNumber}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>

        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="units.axles" />: {axles}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>

        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="units.loading_type" />: {loadingType}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>

        <FlexHeaderRowWrapper>
          <HeaderColumnWrapper>
            <span><Trans i18nKey="units.unloaded_weight" />: {unloadedWeight}</span>
          </HeaderColumnWrapper>
        </FlexHeaderRowWrapper>
      </PageHeader>
    );
  };

  render() {
    const { id, name, truckBrand, truckModel, year } = this.state.unit;

    return (
      <div>
        <Breadcrumbs>
          <li>
            <Link to="/units">
              <Trans i18nKey="common.title.units" />
            </Link>
          </li>
          <li>{`${name} - ${truckBrand} ${truckModel} ${year}`}</li>
        </Breadcrumbs>

        <HeaderContextWrapper>{this.renderPageHeader()}</HeaderContextWrapper>

        <ModalUnit
          key={id}
          actionName={<Trans i18nKey="edit" />}
          unit={this.state.unit}
          onClose={this.handleToggleUnitModal(false)}
          open={this.state.unitModalOpened}
          refreshList={this.handleRefreshAfterModify}
        />
      </div>
    );
  }
}

export default withRouter(Unit);
