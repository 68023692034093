// React imports
import React, { memo } from "react";

// Internal imports

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// External iports
import { isEqual } from "lodash";
import {
  Stack,
  Typography,
  FormControl,
  Input,
  Tooltip,
  InputAdornment,
  Divider,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";

import { HelpOutline } from "@mui/icons-material";
import useToggle from "@hooks/useToggle";

const areZoneSelectPropsEqual = (prevProps, nextProps) => {
  let arePropsEqual = true;

  if (!isEqual(prevProps.location, nextProps.location)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.locationCustomAreaCode, nextProps.locationCustomAreaCode)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.locationAreaCustomFee, nextProps.locationAreaCustomFee)) {
    arePropsEqual = false;
  }

  if (!isEqual(prevProps.isLocationOutOfAreaError, nextProps.isLocationOutOfAreaError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

const ZoneSelect = ({
  location,
  locationCustomAreaCode,
  locationAreaCustomFee,
  isLocationOutOfAreaError,
  onChangeLocationFee,
  onChangeZone,
}) => {
  const locationAreaHandler = useToggle(false);

  if (!location || !location.data) return null;

  const areaIsOutOfArea = location.data.areaIsOutOfArea;
  const locationArea = location?.data?.areaCustomCode ? location?.data?.areaCustomCode : location?.data?.areaCode;
  const selectedArea = locationCustomAreaCode ?? locationArea;

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        minHeight="15px"
      >
        <Typography
          variant="overline"
          fontSize={10}
          lineHeight={1}
          textAlign="right"
          color={areaIsOutOfArea && "darkorange"}
          alignSelf="center"
        >
          {areaIsOutOfArea ? "HORS-ZONE" : "ZONE"}
        </Typography>

        {!areaIsOutOfArea && (
          <Tooltip
            title={
              <>
                <Typography
                  fontWeight="bold"
                  textAlign="center"
                >
                  Mise en garde
                </Typography>

                <Divider
                  variant="middle"
                  light
                  sx={{ backgroundColor: "lightgray" }}
                />
                <Stack
                  spacing="2px"
                  mt={1}
                >
                  <span>Le changement de zone va être sauvegardé pour cette adresse et réutilisé par la suite.</span>
                </Stack>
              </>
            }
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  minWidth: 245,
                  maxWidth: 350,
                },
              },
            }}
          >
            <HelpOutline
              sx={{ fontSize: 15, marginLeft: "2px", marginRight: "-2px" }}
              color="primary"
            />
          </Tooltip>
        )}

        {areaIsOutOfArea && (
          <Tooltip
            title="Cet emplacement est hors-zone et requiert d'entrer un prix manuellement."
            placement="right"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
          >
            <HelpOutline
              sx={{ fontSize: 15, marginLeft: "2px", marginRight: "-2px" }}
              color="primary"
            />
          </Tooltip>
        )}
      </Stack>

      {areaIsOutOfArea ? (
        <FormControl
          variant="standard"
          sx={{ width: 60, alignSelf: "flex-end" }}
          size="small"
        >
          <Input
            id="component-simple"
            size="small"
            value={locationAreaCustomFee?.toString() || 0} // toString to disallow leading zeros like 054
            onChange={onChangeLocationFee}
            error={isLocationOutOfAreaError}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            sx={{
              // Remove input padding and change text alignment
              ".MuiInput-input": { textAlign: "right", padding: 0 },
              // Remove input arrows
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
            }}
            inputProps={{ min: 0, max: 9999 }}
            type="number"
          />
        </FormControl>
      ) : (
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <FormControl
            variant="standard"
            disabled
          >
            <Stack direction="row">
              <Select
                onClose={locationAreaHandler.setState.toggle}
                value={`${selectedArea}`}
                open={locationAreaHandler.state}
                disableUnderline
                inputProps={{ IconComponent: () => null }}
                onChange={onChangeZone}
                sx={{
                  ".MuiInput-input": { padding: "1px !important", WebkitTextFillColor: "black" },
                  fontSize: 15,
                }}
              >
                {["1", "2", "3", "4"].map((option) => (
                  <MenuItem
                    key={option}
                    value={`${option}`}
                    disabled={option === selectedArea}
                  >
                    {"ZONE " + option}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </FormControl>
          {
            <IconButton
              size="small"
              color="primary"
              disableRipple
              disableFocusRipple
              onClick={locationAreaHandler.setState.toggle}
            >
              <KeyboardArrowDownIcon
                fontSize="inherit"
                sx={{ marginRight: "-10px" }}
              />
            </IconButton>
          }
        </Stack>
      )}
    </Stack>
  );
};

export default memo(ZoneSelect, areZoneSelectPropsEqual);
