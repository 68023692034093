import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSnackbar, withSnackbar } from "notistack";
import { Button } from "@mui/material";
import { forEach } from "lodash";

/**
 * @typedef {function} EnqueueMessage
 * @param {string} message - The message to be displayed.
 * @param {'success'|'info'|'warning'|'error'} variant - The variant of the snackbar message.
 */

/**
 * @typedef {function} EnqueueMessages
 * @param {Array} messages - An array of messages to be displayed.
 * @param {'success'|'info'|'warning'|'error'} variant - The variant of the snackbar messages.
 */

/**
 * @typedef {object} NotifyHook
 * @property {EnqueueMessage} enqueueMessage - A function that displays a single message.
 * @property {EnqueueMessages} enqueueMessages - A function that displays multiple messages.
 */

/**
 * A custom hook for handling snackbar notifications.
 *
 * @returns {NotifyHook} An object with functions for displaying messages.
 */

function useNotifier() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * A function that displays a single message.
   *
   * @param {string} message - The message to be displayed.
   * @param {'success'|'info'|'warning'|'error'} variant - The variant of the snackbar message.
   */
  const enqueueMessage = (message, variant = "error") => {
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <Button variant="contained" onClick={() => closeSnackbar(key)}>
          Fermer
        </Button>
      ),
    });
  };

  /**
   * A function that displays multiple messages.
   *
   * @param {Array} messages - An array of messages to be displayed.
   * @param {'success'|'info'|'warning'|'error'} variant - The variant of the snackbar messages.
   */
  const enqueueMessages = (messages, variant = "error") => {
    forEach(messages, (message) =>
      enqueueSnackbar(message, {
        variant,
        action: (key) => (
          <Button variant="contained" onClick={() => closeSnackbar(key)}>
            Fermer
          </Button>
        ),
      })
    );
  };

  return { enqueueMessage, enqueueMessages };
}

export default useNotifier;
