import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  autoFocus: false,
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const handleContractsSelect = (report, setReport, setAllContractsSelect) => (event, child) => {
  setReport((prevState) => ({
    ...prevState,
    selectedContractsIds: event.target.value,
  }));

  if (child.props.value === "allContracts") {
    if (report.selectedContractsIds?.length === report.customer.contracts?.length) {
      setReport((prevState) => ({
        ...prevState,
        selectedContractsIds: [],
      }));
    } else {
      const selectAllContracts = report.customer.contracts.map((contract) => contract.id);
      setReport((prevState) => ({
        ...prevState,
        selectedContractsIds: selectAllContracts,
      }));
    }
  }

  event.target.value.length === report.customer.contracts.length
    ? setAllContractsSelect(true)
    : setAllContractsSelect(false);
};

const contractsSelectValues = (report) => (event) => {
  const nbOfContracts = report.customer.contracts?.length || 0;
  const selectedContractsCount = event?.length || 0;
  const contractsCounter = `(${selectedContractsCount}/${nbOfContracts})`;

  if (report.selectedReportType === 2) {
    return "Aucune sélection de contrat nécessaire";
  }

  if (!nbOfContracts) {
    return "Aucun contrat disponible";
  }

  if (!selectedContractsCount) {
    return `Aucun contrat sélectionné ${contractsCounter}`;
  }

  if (selectedContractsCount === nbOfContracts) {
    return `Tous les contrats sont sélectionnés ${contractsCounter}`;
  }

  if (selectedContractsCount === 1) {
    return `Un contrat sélectionné ${contractsCounter}`;
  }
  return `Contrats sélectionnés ${contractsCounter}`;
};

const handleSelectAllContracts = (report, setReport, setAllContractsSelect) => (event) => {
  setAllContractsSelect([event.target.checked, event.target.checked]);

  if (report.selectedContractsIds.length === report.customer.contracts?.length) {
    setReport((prevState) => ({
      ...prevState,
      selectedContractsIds: [],
    }));
  } else {
    const selectAllContracts = report.customer.contracts.map((contract) => contract.id);
    setReport((prevState) => ({
      ...prevState,
      selectedContractsIds: selectAllContracts,
    }));
  }
};

export default function ReportContractsSelect({ contracts, report, setReport }) {
  const [allContractsSelect, setAllContractsSelect] = useState([true, false]);

  const selectContracts = contracts.map((contract) => {
    return (
      <MenuItem key={contract.name} value={contract.id}>
        <Checkbox checked={report.selectedContractsIds.indexOf(contract.id) > -1} />
        <ListItemText primary={contract.name} />
      </MenuItem>
    );
  });

  return (
    <FormControl sx={{ width: 500 }}>
      <InputLabel shrink>Contrats</InputLabel>
      <Select
        displayEmpty
        disabled={report.selectedReportType !== 1}
        multiple
        notched
        label="Contrats"
        value={report.selectedContractsIds}
        onChange={handleContractsSelect(report, setReport, setAllContractsSelect)}
        renderValue={contractsSelectValues(report)}
        MenuProps={MenuProps}
      >
        <MenuItem value="allContracts">
          <Checkbox
            checked={
              allContractsSelect === true || report.selectedContractsIds?.length === report.customer.contracts?.length
            }
            onChange={handleSelectAllContracts(report, setReport, setAllContractsSelect)}
            indeterminate={
              report.selectedContractsIds?.length > 0 &&
              report.selectedContractsIds?.length < report.customer.contracts?.length
            }
          />
          <ListItemText primary="Tout sélectionner" />
        </MenuItem>
        {selectContracts}
      </Select>
    </FormControl>
  );
}
