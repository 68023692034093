// TODO Change .js to .ts

// Internal imports
import React, { useCallback } from "react";

// External imports
import styled from "@emotion/styled";
import { Paper, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Local imports
import LogoVilleco from "@assets/images/logo-villeco.png";
import useLogin from "@hooks/auth/useLogin";
import useSetCurrentUser from "@hooks/auth/useSetCurrentUser";

const Logo = styled.img`
  position: relative;
  width: 75%;
  align-self: center;
  padding-bottom: 30px;
`;

const LoginBtn = styled(Button)`
  margin-top: 50px;
`;

const LoginWrapper = styled.div`
  height: 100vh;
  width: 300px;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
`;

const PaperContainer = styled(Paper)`
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 20px 0 20px;
`;

/**
 *
 * @returns Login screen used for authentication.
 */
const Login = () => {
  const navigate = useNavigate();
  const { mutateAsync: login } = useLogin();
  const { mutateAsync: setCurrentUser } = useSetCurrentUser();

  const handleOnFormSubmit = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const formData = new FormData(event.target);

        const email = /** @type {string} **/ (formData.get("email"));
        const password = /** @type {string} **/ (formData.get("password"));

        await login({ email, password });

        await setCurrentUser();

        navigate("/");
      } catch (error) {
        const { data } = error.response ?? {};

        data.messages?.length && alert(data.messages[0]);
      }
    },
    [login]
  );

  return (
    <LoginWrapper>
      <Logo src={LogoVilleco} />

      <form onSubmit={handleOnFormSubmit}>
        <PaperContainer>
          <div>
            <TextField variant="outlined" margin="normal" label="Courriel" name="email" fullWidth />

            <TextField
              variant="outlined"
              margin="normal"
              type={"password"}
              label="Mot de passe"
              name="password"
              fullWidth
            />
          </div>

          <LoginBtn type="submit" variant="contained" color="primary">
            Connexion
          </LoginBtn>
        </PaperContainer>
      </form>
    </LoginWrapper>
  );
};

export default Login;
