import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Trans } from "react-i18next";
import * as API from "@services";
import React, { useState } from "react";

const downloadFile = (response, fileName) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([response.data]));
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

const ExportProblemCaseReportToExcel = ({ report, loading, setLoading }) => {
  return (
    <Button
      startIcon={<InsertDriveFileIcon style={{ color: "#000", marginRight: 5 }} />}
      onClick={() => handleExportProblemeCaseReportToExcel(report, setLoading)}
    >
      {loading ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CircularProgress style={{ width: 25, height: 25 }} />
          <span style={{ fontSize: 12, marginLeft: 8 }}>
            <Trans i18nKey="xlsx_file_download" />
          </span>
        </div>
      ) : (
        <span>Téléchargement les cas problèmes</span>
      )}
    </Button>
  );
};

const handleExportProblemeCaseReportToExcel = async (report, setLoading) => {
  setLoading(true);

  const params = {
    contracts_ids: report.selectedContractsIds,
    customer_id: report.customer.id,
    start_date: report.startDate,
    end_date: report.endDate,
    summaries: report.summaries,
  };

  await API.Report.exportProblemCaseReport(params).then((response) => {
    downloadFile(response, `rapport_cas_problemes_du_${report.startDate}_au_${report.endDate}.xlsx`, setLoading);
  });
  setLoading(false);
};

export default function ReportProblemCaseToExcelButton({ report }) {
  const [loading, setLoading] = useState(false);

  return (
    <ExportProblemCaseReportToExcel
      report={report}
      loading={loading}
      setLoading={setLoading}
    />
  );
}
