import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { itemStatuses } from "@utils/statuses";

const RouteDetailsDialog = ({ onDialogClose, dialogOpen, items, note, ...remainingProps }) => {
  if (items === "" || items === undefined) {
    return null;
  }

  if (items === undefined) {
    return (
      <Dialog
        open={dialogOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...remainingProps}
      >
        <DialogTitle id="alert-dialog-title">Détails des contenants</DialogTitle>
        <DialogContent>&nbsp; &nbsp; Pas de contenants</DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...remainingProps}
    >
      <DialogTitle id="alert-dialog-title">Détails des contenants</DialogTitle>
      <DialogContent>
        {items.map((item, index) => (
          <div key={index}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: item.ciWebStatus === "active" ? "green" : "red" }}
            >
              No. du contenant : {item.ciWebLabelcode ? item.ciWebLabelcode : "Information manquante"}
              <br />
              Code à puce : {item.ciWebChipcode ? item.ciWebChipcode : "Information manquante"}
              <br />
              Modèle : {item.itemProfile.model ? item.itemProfile.model : "Information manquante"}
              <br />
              {/* Model : {item.itemProfile.model ? item.itemProfile.model : 'Information manquante'} */}
              {/* <div/> */}
              {/* Type de bac */}
              {/* : {item.itemProfile.container_type ? item.itemProfile.container_type : 'Information manquante'} */}
              {/* <div/> */}
              Statut : {item.ciWebStatus ? itemStatuses[item.ciWebStatus] : "Information manquante"}
              <br />
              Alias : {item.alias ? item.alias : "Information manquante"}
              <br />
              Note de conteneur : {item.memo || ""}
              <br />
              Note de l'emplacement : {item.locationNote || ""}
            </DialogContentText>
            <br />
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={onDialogClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

RouteDetailsDialog.propTypes = {
  dialogContentText: PropTypes.string,
  routeData: PropTypes.object,
  onDialogClose: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
};

export default RouteDetailsDialog;
