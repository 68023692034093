// React imports
import React, { useEffect, useState } from "react";

// External iports
import { Trans } from "react-i18next";
import { Save, ChevronRight } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, MenuItem, TextField, Select, InputLabel } from "@mui/material";

// Internal imports
import * as API from "@services";
import SelectHalfUi from "@ui/SelectHalf";
import LoadingButton from "@ui/LoadingButton";
import { isObjectTruthy } from "@utils/common";
import FlexRowWrapper from "@ui/FlexRowWrapper";
import HalfFormControl from "@ui/HalfFormControl";
import FormGroupWrapper from "@ui/FormGroupWrapper";
import CustomerUtils from "@utils/customer/customerUtils";
import { useCustomerContext } from "@context/customer/CustomerContextProvider";
import useNotifier from "@hooks/useNotifier";
import useApiResponseHandler from "@hooks/useApiResponseHandler";

const provinces = [
  { name: <Trans i18nKey="provinces.ab" />, code: "AB" },
  { name: <Trans i18nKey="provinces.bc" />, code: "BC" },
  { name: <Trans i18nKey="provinces.pe" />, code: "PE" },
  { name: <Trans i18nKey="provinces.mb" />, code: "MB" },
  { name: <Trans i18nKey="provinces.nb" />, code: "NB" },
  { name: <Trans i18nKey="provinces.ns" />, code: "NS" },
  { name: <Trans i18nKey="provinces.on" />, code: "ON" },
  { name: <Trans i18nKey="provinces.qc" />, code: "QC" },
  { name: <Trans i18nKey="provinces.sk" />, code: "SK" },
  { name: <Trans i18nKey="provinces.nl" />, code: "NL" },
  { name: <Trans i18nKey="provinces.nu" />, code: "NU" },
  { name: <Trans i18nKey="provinces.nt" />, code: "NT" },
  { name: <Trans i18nKey="provinces.yt" />, code: "YT" },
];

const renderProvinces = (data) => [
  ...data.map(({ name, code }) => (
    <MenuItem key={code} value={code}>
      {name}
    </MenuItem>
  )),
];

const handleOnTextFieldChange = (setFields) => (event) => {
  const { name, value } = event.target;

  setFields((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

const handleCreateCustomer = (fields, customer, handlers, setActiveStep, notifier) => async (event) => {
  event.preventDefault();
  handlers.setIsLoading(true);

  let isFormValid = handlers.validateCustomerForm();

  if (isFormValid) {
    try {
      // Create the customer in our database
      const responseCreatedCustomer = await API.Customer.createCustomer({
        ...CustomerUtils.mappedCustomerFields(fields),
      });

      if (responseCreatedCustomer && responseCreatedCustomer.status === "failure") {
        notifier.enqueueMessages(responseCreatedCustomer.messages, "error");
      }

      if (responseCreatedCustomer && responseCreatedCustomer.status === "success") {
        if (!customer.stripe.data) {
          handlers.setSingleProperty("data", responseCreatedCustomer.data);
        } else {
          // We already have an existing stripe customer, so just attach it to our customer in the db
          const updatedCustomer = await API.Customer.updateCustomer(responseCreatedCustomer.data.id, {
            stripe_customer_id: customer.stripe.data.id,
          });

          // Update the customer context
          handlers.setCustomer((prevState) => ({
            ...prevState,
            data: updatedCustomer.data,
          }));
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.warn("[handleCreateCustomer] error:", error);
    }
  }
  handlers.setIsLoading(false);
};

const handleEditCustomer = (fields, customer, handlers, handleApiResponse) => async (event) => {
  event.preventDefault();

  let isFormValid = handlers.validateCustomerForm();

  if (isFormValid) {
    handlers.setIsLoading(true);

    try {
      // Edit the customer in our database
      const res = await API.Customer.updateCustomer(customer.id, { ...CustomerUtils.mappedCustomerFields(fields) });

      handleApiResponse(res, () => {
        handlers.setCurrentCustomer(res.data);
        handlers.handleToggleModalCustomer("");
      });
    } catch (error) {
      console.warn("[handleEditCustomer] error:", error);
    }

    handlers.setIsLoading(false);
  }
};

/**
 * Form with all the necessary fields for editing or creating a customer.
 * @param {Boolean} isEditing Boolean to know whether or not the form will be used for editing an existing customer
 * @param {Function} setActiveStep Function from useState to change the stepper's active step
 */
function CustomerForm({ isEditing, setActiveStep }) {
  const { customer, handlers, isLoading, fields, errors } = useCustomerContext();
  const notifier = useNotifier();
  const handleApiResponse = useApiResponseHandler();

  useEffect(() => {
    // If we have a valid stripe payment intent, prefill the fields and fetch customer payment methods
    if (customer.stripe.paymentIntent.data) {
      handlers.setFields((prevState) => ({
        ...prevState,
        ...CustomerUtils.mappedPaymentIntentData(customer.stripe.paymentIntent.data),
      }));
    }
  }, [customer.stripe.paymentIntent.data]);

  // Handles the validation of each desired fields
  useEffect(() => {
    const validatableFields = ["name", "email", "principalPhone", "doorNo", "postalCode", "adr1", "city", "province"];
    // If a field was marked as an error but now it's truthy, remove the error
    validatableFields.forEach((key) => {
      if (fields[key]) {
        errors[key] && handlers.setSingleError(key, false);
      }
    });
  }, [fields]);

  useEffect(() => {
    // When editing a customer, prefill the fields
    isEditing &&
      isObjectTruthy(customer.data) &&
      handlers.setFields((prevState) => ({
        ...prevState,
        ...CustomerUtils.mappedBackendCustomerFields(customer.data),
      }));
  }, [customer.data]);

  const handleOnSubmit = isEditing
    ? handleEditCustomer(fields, customer.data, handlers, handleApiResponse)
    : handleCreateCustomer(fields, customer, handlers, setActiveStep, notifier);

  return (
    <form onSubmit={handleOnSubmit}>
      <DialogContent style={{ position: "relative", display: "flex", flexDirection: "column" }}>
        <h3>Informations sur le client</h3>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 100 }}
                name="name"
                type="text"
                label="Nom"
                value={fields.name}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.name}
                helperText={errors.name && "Nom invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>

            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 100 }}
                name="email"
                type="email"
                label="Adresse courriel"
                value={fields.email}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.email}
                helperText={errors.email && "Adresse courriel invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 14 }}
                name="principalPhone"
                type="tel"
                label="Téléphone principal"
                value={fields.principalPhone.trim()}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.principalPhone}
                helperText={errors.principalPhone && "Téléphone invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>

            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 14 }}
                name="billingPhone"
                type="tel"
                label="Téléphone facturation"
                value={fields.billingPhone.trim()}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextField
            inputProps={{ maxLength: 10 }}
            name="tribeNumber"
            type="text"
            label="No. de Bande - Premières nations"
            value={fields.tribeNumber}
            onChange={handleOnTextFieldChange(handlers.setFields)}
            InputLabelProps={{ shrink: true }}
            disabled={isLoading}
          />
        </FormGroupWrapper>

        <h3>Adresse de facturation</h3>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 10 }}
                name="doorNo"
                type="text"
                label="Numéro civique"
                value={fields.doorNo.trim()}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.doorNo}
                helperText={errors.doorNo && "Numéro civique invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>

            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 7 }}
                name="postalCode"
                type="text"
                label="Code postal"
                value={fields.postalCode.trim()}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.postalCode}
                helperText={errors.postalCode && "Code postal invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextField
            inputProps={{ maxLength: 50 }}
            name="adr1"
            type="text"
            label="Rue"
            value={fields.adr1}
            onChange={handleOnTextFieldChange(handlers.setFields)}
            error={errors.adr1}
            helperText={errors.adr1 && "Nom de rue invalide"}
            InputLabelProps={{ shrink: true }}
            disabled={isLoading}
            required
          />
        </FormGroupWrapper>

        <FormGroupWrapper>
          <TextField
            inputProps={{ maxLength: 50 }}
            name="adr2"
            type="text"
            label="Numéro d'appartement, de bureau ou d'unité"
            value={fields.adr2}
            onChange={handleOnTextFieldChange(handlers.setFields)}
            InputLabelProps={{ shrink: true }}
            disabled={isLoading}
          />
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FlexRowWrapper>
            <HalfFormControl>
              <TextField
                inputProps={{ maxLength: 45 }}
                name="city"
                type="text"
                label="Ville"
                value={fields.city}
                onChange={handleOnTextFieldChange(handlers.setFields)}
                error={errors.city}
                helperText={errors.city && "Ville invalide"}
                InputLabelProps={{ shrink: true }}
                disabled={isLoading}
                required
              />
            </HalfFormControl>
            <HalfFormControl error={errors.province}>
              <InputLabel shrink id="province-label">
                Province *
              </InputLabel>
              <Select
                disabled={isLoading}
                required
                notched
                name="province"
                label="Province"
                labelId="province-label"
                value={fields.province}
                onChange={handleOnTextFieldChange(handlers.setFields)}
              >
                {renderProvinces(provinces)}
              </Select>
            </HalfFormControl>
          </FlexRowWrapper>
        </FormGroupWrapper>
      </DialogContent>

      <DialogActions style={{ bottom: 5, right: 5 }}>
        <Button onClick={() => handlers.handleToggleModalCustomer("")} disabled={isLoading}>
          Annuler
        </Button>

        <LoadingButton
          type="submit"
          loading={isLoading}
          loadingPosition="start"
          onClick={handleOnSubmit}
          startIcon={isEditing ? <Save /> : <ChevronRight />}
        >
          {isEditing ? "Sauvegarder" : "Suivant"}
        </LoadingButton>
      </DialogActions>
    </form>
  );
}

export default CustomerForm;
