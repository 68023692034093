// @ts-check

import { useMutation } from "@tanstack/react-query";

import LoginService from "../../services/auth/login.service";

/**
 * Hook to sign a user in wrapping a react-query mutation
 *
 * @typedef {import("@tanstack/react-query").UseMutationResult<void, Error, {email: string, password: string}>} UseMutationResult
 *
 * @returns {UseMutationResult}
 */
const useLogin = () => {
  return useMutation({ mutationFn: LoginService.login });
};

export default useLogin;
