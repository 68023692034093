import { useQuery, UseQueryResult } from "@tanstack/react-query";

import fetchLocationRoutesHistory from "../../services/routes/fetch-location-routes-history.service";
import locationRoutesHistoryKeys from "@hooks/routes/location-routes-history.keys";

import TableFilterStates from "@@types/common/table-filter-states.type";
import RouteHistory from "@@types/routes/route-history.type";

import { mapResponsesToRouteHistories } from "@@types/routes/mappers/mapResponseToRouteHistory";

export type LocationRoutesHistory = {
  routes: RouteHistory[];
  count: number;
};

const useFetchLocationRoutesHistory = (
  locationId: number,
  filterStates: TableFilterStates
): UseQueryResult<LocationRoutesHistory> => {
  filterStates = {
    filter: "",
    rowsPerPage: 10,
    page: 0,
    sort: { columnName: "", direction: "desc" },
    ...filterStates,
  };

  return useQuery({
    queryKey: [locationRoutesHistoryKeys.list(filterStates), locationRoutesHistoryKeys.detail(locationId)],
    queryFn: () => fetchLocationRoutesHistory(locationId, filterStates),
    select: ({ data: response, count }): LocationRoutesHistory => ({
      count,
      routes: mapResponsesToRouteHistories(response || []),
    }),
  });
};

export default useFetchLocationRoutesHistory;
