import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import useDeleteLocationContainer from "@hooks/containers/delete-location-container.hook";

export type UseLocationCcavContainerDelete = {
  deleteContainer: (container: LocationCcavContainer) => Promise<void>;
};

const useLocationCcavContainerDelete = (locationId: number): UseLocationCcavContainerDelete => {
  const { mutate: deleteContainer } = useDeleteLocationContainer(locationId);

  return {
    deleteContainer,
  } as UseLocationCcavContainerDelete;
};

export default useLocationCcavContainerDelete;
