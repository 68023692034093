import { useQuery, UseQueryResult } from "@tanstack/react-query";
import mapResponseToLiftingRequests from "@@types/lifting-requests/mappers/mapResponseToLiftingRequest.mapper";
import liftingRequest from "@hooks/lifting-requests/lifting-request.keys";
import fetchContainerLiftingRequestsHistory from "../../services/lifting-requests/fetch-container-lifting-requests-history.service";
import TableFilterStates from "@@types/common/table-filter-states.type";
import { LiftingRequests } from "@hooks/lifting-requests/fetch-container-lifting-requests-future.hook";

const useFetchContainerLiftingRequestsHistory = (
  containerId: number,
  filterStates: TableFilterStates,
  registrationCategories: string[],
  liftingTypes: string[]
): UseQueryResult<LiftingRequests> => {
  return useQuery({
    queryKey: [
      liftingRequest.timeLapse(containerId, "history"),
      liftingRequest.list({ filterStates, registrationCategories, liftingTypes }),
    ],
    queryFn: () =>
      fetchContainerLiftingRequestsHistory(containerId, filterStates, registrationCategories, liftingTypes),
    select: ({ data: response, count }): LiftingRequests => ({
      data: mapResponseToLiftingRequests(response || []),
      count,
    }),
    enabled: containerId !== 0,
  });
};

export default useFetchContainerLiftingRequestsHistory;
