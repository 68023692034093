// React imports
import styled from '@emotion/styled';
import { CheckCircle, Edit, Remove } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import TableCellNoData from '@ui/TableCellNoData';
import TableLoading from '@ui/TableLoading';
import { isObjectTruthy } from '@utils/common';
import { orderBy } from 'lodash';
import React from 'react';

// External imports
// Internal impots
const RemoveIconWrapper = styled(Remove)`
  font-size: 12px !important;
  position: relative;
`;

const Checkmark = ({ checked }) =>
  checked ? <CheckCircle color="success" fontSize="small" /> : <RemoveIconWrapper fontSize="small" />;

const sortedData = (data, sort) => {
  const { columnName, direction } = sort;

  return orderBy(data, [columnName], [direction]);
};

const handleRowClick = (path, router) => () => {
  router.navigate(path);
};

const handleOpenModalCustomerEdit = (customerToEdit, setCustomer) => (event) => {
  // This ensures that the edit button is clickable or else it would detect the row click instead
  event.stopPropagation();

  setCustomer((prevState) => ({
    ...prevState,
    currentlyOpenModal: "edit",
    data: customerToEdit,
  }));
};

export default function CustomersTableRows({ customersLoading, customers, sort, router, setCustomer }) {
  if (customersLoading) {
    return <TableLoading />;
  }

  if (!customers.length) {
    return <TableCellNoData />;
  }

  return sortedData(customers, sort).map((customer) => {
    const { id, name, street, city, postalCode, province, active } = customer;

    return (
      <TableRow key={id} className="link-row" onClick={handleRowClick(`/customers/${id}`, router)}>
        <TableCell>{name}</TableCell>

        <TableCell>{street}</TableCell>

        <TableCell>{city}</TableCell>

        <TableCell>{postalCode}</TableCell>

        <TableCell classes={{ root: "province-cell" }}>{province}</TableCell>

        <TableCell classes={{ root: "icon-cell" }}>
          <Checkmark checked={active} />
        </TableCell>

        <TableCell>
          <IconButton
            color="secondary"
            id="cpbr-archive-customer"
            onClick={handleOpenModalCustomerEdit(customer, setCustomer)}
            size="large"
          >
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
}
