import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const HashtagIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill={fillColor}
      >
        <path
          d="M1 4.3125H10"
          stroke="black"
          strokeOpacity="0.46"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7.6875H10"
          stroke="black"
          strokeOpacity="0.46"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 0.9375L3.25 11.0625"
          stroke={strokeColor}
          strokeOpacity="0.46"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.75 0.9375L6.625 11.0625"
          stroke={strokeColor}
          strokeOpacity="0.46"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default HashtagIcon;
