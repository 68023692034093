import { Stack, TextField, Typography } from "@mui/material";
import React from "react";

const handleEditUserTextArea = (userState, setUser) => (event) => {
  setUser((prevState) => ({
    ...prevState,
    data: {
      ...prevState.data,
      [userState]: { error: false, value: event.target.value },
    },
  }));
};

export default function UserInformations({ user, setUser }) {
  return (
    <Stack spacing={2}>
      <Typography pl={3} fontSize={20} fontWeight="bold">
        Informations
      </Typography>
      <Stack alignItems="flex-start" justifyContent="center" spacing={3} pl={3} pr={3} pt={1}>
        <Stack direction="row" spacing={2} width="100%">
          {/* FIRST NAME */}
          <TextField
            error={user.data.firstName.error}
            fullWidth
            id="outlined-error"
            label="Prénom"
            defaultValue={user.data.firstName.value}
            onChange={handleEditUserTextArea("firstName", setUser)}
          />

          {/* LAST NAME */}
          <TextField
            error={user.data.lastName.error}
            fullWidth
            id="outlined-error"
            label="Nom"
            defaultValue={user.data.lastName.value}
            onChange={handleEditUserTextArea("lastName", setUser)}
          />
        </Stack>

        {/* EMAIL */}
        <TextField
          error={user.data.email.error}
          fullWidth
          id="outlined-error"
          label="Courriel"
          defaultValue={user.data.email.value}
          onChange={handleEditUserTextArea("email", setUser)}
        />
      </Stack>
    </Stack>
  );
}
