import React, { Fragment, PureComponent } from "react";
import { Link, unstable_usePrompt as Prompt } from "react-router-dom";
import { withRouter } from "@utils/withRouter";
import { MapErrorView, MapLoadingSpinner, withGoogleMap } from "@utils/withGoogleMap";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import ModalCustomerLocation from "./ModalCustomerLocation";
import { MapContentWrapper, RouteContentWrapper } from "@styles/globalStyle";

import MapMarker, { MarkerIcon } from "./maps/MapMarker";
import { GoogleMap } from "@react-google-maps/api";
import MapSideDrawer, { componentToLoad } from "./mapSideDrawer/MapSideDrawer";

import {
  withCustomerItem,
  withCustomerLocations,
  withJobTemplates,
  withLocations,
  withRouteTemplate,
  withRouteTemplates,
  withSupplierLocations,
} from "optigo-redux";
import { Button, Checkbox, CircularProgress, Grid, MenuItem } from "@mui/material";

import { getErrorMessage, handleChangeFields } from "@utils/form";

import {
  createArrayForDataMenu,
  handleMenuDataTable,
  menuDataTable,
  moveDataFromItemsToSelected,
  updateArrayDataTableMenu,
} from "./routes/dataTable";

import {
  getRouteTemplateLocations,
  handleToggleAllItemsAndSelected,
  InputControl,
  LoadingSpinner,
  onDragEnd,
  onDragStart,
  renderCancelButton,
  resetAllCheckboxInState,
  toggleSelectionCheckbox,
  willShowCountCircleForItem,
} from "./routes/utils";

import {
  createRouteTemplateLocationsParams,
  displayActionName,
  displayAddLocationButton,
  displayRightTitle,
  displaySaveButton,
  loadSavedRouteTemplateLocations,
  loadTsp,
  manageInfoWindowAppearance,
  renderLocationsFields,
  tspCallback,
} from "./routeTemplate/utils";

import { isArrayTruthy } from "@utils/common";
import { apiIsLoaded, fitBoundsForAll, fitBoundsForSelected } from "./routes/mapsAndTsp";
import RouteHeader from "./ui/RouteHeader";
import RouteSidebar from "./ui/RouteSidebar";
import TextFieldUi from "./ui/TextField";
import RouteDetailsDialog from "./RouteDetailsDialog";
import move from "lodash-move";
import Breadcrumbs from "./Breadcrumbs";
import * as API from "@services";
import { includes } from "lodash";
import { offsetLegacyMarkersOnSameSpot } from "@utils/offsetMarkers";

const initialState = {
  errors: {
    averageLiftingTime: false,
    contractId: false,
    customerItemId: false,
    name: false,
    nameHypen: false,
    supplierLocationId: false,
    showSideBar: false,
  },
  averageLiftingTime: "",
  checkedDraggableMaster: null,
  contractId: "-1",
  customerItemId: "-1",
  draggedItemIsChecked: false,
  items: [],
  itemsHasBeenSet: false,
  originalItems: [],
  itemsAllChecked: false,
  masterRoute: "",
  name: "",
  originalName: "",
  nbCheckedItemsLeft: 0,
  nbCheckedItemsRight: 0,
  selected: [],
  originalSelected: [],
  selectedCompleted: false,
  selectedAllChecked: false,
  supplierLocationId: "-1",
  menuDataTableOpened: false,
  arrayDataTableMenu: [],
  itemToAdd: [],
  dataTableAvailableOrSelected: true,
  customerLocationModalOpened: false,
  customerLocationToEdit: {},
  showContainerDialog: false,
  locationId: "",
  lastSelectedShowWindowsIndex: null,
  lastItemShowWindowsIndex: null,
  mapType: "roadmap",
  note: "",
  lastSelectedUpdatedIndex: -1,
  lastItemUpdatedIndex: -1,
  loadingSpinner: false,
  displayAssignMarker: true,
  displayUnassignMarker: true,
  isMapSideDrawerOpen: false,
  isAssignCheckboxDisabled: false,
  isUnassignCheckboxDisabled: false,
  sideDrawerLeftTabContent: {
    data: {},
    rank: {
      text: 1,
      length: 1,
      rankChangeCallback: () => { },
    },
  },
  sideDrawerRightTabContent: [],
  quickAssign: false,
  isEdited: false,
};

class RouteTemplate extends PureComponent {
  state = {
    ...initialState,
  };

  // eslint-disable-next-line react/sort-comp
  id2List = {
    droppable: "items",
    droppable2: "selected",
  };
  // Bind nécessaires pour les fonctions externes qui doivent avoir accès à this
  renderCancelButton = renderCancelButton.bind(this);
  fitBoundsForSelected = fitBoundsForSelected.bind(this);
  fitBoundsForAll = fitBoundsForAll.bind(this);
  willShowCountCircleForItem = willShowCountCircleForItem.bind(this);
  apiIsLoaded = apiIsLoaded.bind(this);
  onDragEnd = onDragEnd.bind(this);
  onDragStart = onDragStart.bind(this);
  resetAllCheckboxInState = resetAllCheckboxInState.bind(this);
  toggleSelectionCheckbox = toggleSelectionCheckbox.bind(this);
  getErrorMessage = getErrorMessage.bind(this);
  handleChangeFields = handleChangeFields.bind(this);
  menuDataTable = menuDataTable.bind(this);
  createArrayForDataMenu = createArrayForDataMenu.bind(this);
  moveDataFromItemsToSelected = moveDataFromItemsToSelected.bind(this);
  updateArrayDataTableMenu = updateArrayDataTableMenu.bind(this);
  handleMenuDataTable = handleMenuDataTable.bind(this);
  handleToggleAllItemsAndSelected = handleToggleAllItemsAndSelected.bind(this);
  createRouteTemplateLocationsParams = createRouteTemplateLocationsParams.bind(this);
  loadSavedRouteTemplateLocations = loadSavedRouteTemplateLocations.bind(this);
  displaySaveButton = displaySaveButton.bind(this);
  loadTsp = loadTsp.bind(this);
  tspCallback = tspCallback.bind(this);
  renderLocationsFields = renderLocationsFields.bind(this);
  displayAddLocationButton = displayAddLocationButton.bind(this);
  displayRightTitle = displayRightTitle.bind(this);
  displayActionName = displayActionName.bind(this);
  manageInfoWindowAppearance = manageInfoWindowAppearance.bind(this);

  get items() {
    const {
      customerLocationsLoading,
      routeTemplate: { routeTemplateLocations } = { routeTemplateLocations: [] },
      router,
      routeTemplateLoading,
    } = this.props;
    const { itemsHasBeenSet } = this.state;
    const { masterRouteId } = router.params;

    if (!routeTemplateLoading && !isArrayTruthy(this.state.items) && !masterRouteId) {
      // ON tente de visualiser une Route Maitre
      if (routeTemplateLocations?.length > 0 && !itemsHasBeenSet) {
        const items = getRouteTemplateLocations(routeTemplateLocations);
        this.setState({ items, originalItems: items, itemsHasBeenSet: true }, () => items); // Return items only after state is updated
      }
    }

    if (!customerLocationsLoading && !isArrayTruthy(this.state.items) && masterRouteId) {
      // On tente de visualiser une sous route
      // Les items quon veut assigner sont toujours celles de la Route Maitre
      const { routeTemplateLocations = [] } = this.state.masterRouteTemplate || {};
      if (routeTemplateLocations?.length > 0 && !itemsHasBeenSet) {
        const items = getRouteTemplateLocations(routeTemplateLocations);
        this.setState({ items, originalItems: items, itemsHasBeenSet: true }, () => items); // Return items only after state is updated
      }
    }

    return this.state.items;
  }

  get selected() {
    const { routeTemplateLoading } = this.props;
    if (!routeTemplateLoading && !this.state.selectedCompleted) {
      if (
        this.props.routeTemplate &&
        this.props.routeTemplate.routeTemplateLocations &&
        this.props.routeTemplate.routeTemplateLocations.length > 0
      ) {
        this.loadSavedRouteTemplateLocations(this.props.routeTemplate);
        this.setState({ selectedCompleted: true });
      }
    }
    if (this.state.selected) {
      return this.state.selected;
    }
    return [];
  }

  get valid() {
    const errors = { ...initialState.errors };
    const { name, masterRoute, id } = this.state;
    const { routeTemplates, router } = this.props;
    const routes = routeTemplates.filter((rn) => rn.masterRoute === masterRoute);
    const path = router.location.pathname;
    let valid = true;

    if (path.includes("edit")) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routes.length; i++) {
        // eslint-disable-next-line max-len
        if (routes[i].name === name && routes[i].masterRoute === masterRoute && routes[i].id !== parseInt(id)) {
          valid = false;
          errors[name] = true;
          errors.nameHypen = true;
        }
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].name === name && routes[i].masterRoute === masterRoute) {
          valid = false;
          errors[name] = true;
          errors.nameHypen = true;
        }
      }
    }

    this.setState({ errors, isEdited: false });
    return valid;
  }

  // eslint-disable-next-line react/sort-comp
  componentDidMount() {
    this.flushCustomerLocations();
    this.flushRouteTemplate();
    this.fetchMasterRouteTemplate();
    this.fetchRouteTemplate();
    const { router } = this.props;
    const { customerItemId } = router.params;
    this.props.fetchRouteTemplatesByCustomerItem(customerItemId, {
      filter: "",
      limit: 250,
      page: 1,
    });
    this.fetchAllSuppliersLocations();
    this.fetchCustomerItem();
    this.fetchCustomerLocations();
  }

  async componentWillUnmount() {
    await this.flushRouteTemplate();
    await this.flushCustomerLocations();
  }

  getList = (id) => this.state[this.id2List[id]];

  fetchAndModalCallback = () => {
    this.setState(this.initialState, this.fetchCustomerLocations);
  };

  fetchAllSuppliersLocations = async () => {
    await this.props.fetchAllSuppliersLocations();
  };

  fetchCustomerLocations = async () => {
    const { fetchCustomerLocations, router } = this.props;

    const masterRouteId = router.params.masterRouteId;

    if (masterRouteId) {
      await fetchCustomerLocations(
        router.params.customerId,
        {
          filter: "",
          limit: 1000,
          page: 1,
        },
        router.params.contractId,
        masterRouteId,
        router.params.routeTemplateId
      );
    }
  };

  fetchCustomerItem = async () => {
    const { fetchCustomerItem, router } = this.props;
    await fetchCustomerItem(router.params.customerItemId);
  };

  fetchRouteTemplate = async () => {
    const { fetchRouteTemplate, router } = this.props;
    const currentRouteTemplateId = router.params.routeTemplateId;

    if (currentRouteTemplateId) {
      await fetchRouteTemplate(router.params.routeTemplateId);

      const newState = { ...this.state, ...this.props.routeTemplate, originalName: this.props.routeTemplate.name };
      this.setState(newState);
    }
  };

  fetchMasterRouteTemplate = async () => {
    const { router } = this.props;
    const masterRouteId = router.params.masterRouteId;
    if (masterRouteId) {
      var masterRouteTemplate = await API.RouteTemplate.fetchMasterRouteTemplate(masterRouteId);
      this.setState({ masterRouteTemplate: masterRouteTemplate["attributes"] });
    }
  };

  flushRouteTemplate = async () => {
    const { flushRouteTemplate } = this.props;
    await flushRouteTemplate();
  };

  flushCustomerLocations = async () => {
    const { flushCustomerLocations } = this.props;
    await flushCustomerLocations();
  };

  handleCompareCurrentRouteTemplateToOriginal = (originalValues, newValues) => {
    const isSelectedEqual = originalValues.selected.every(
      (e, i) => newValues.selected[i] && e.id === newValues.selected[i].id
    );
    const isItemsEqual = originalValues.items.length === newValues.items.length;
    return includes([isSelectedEqual, isItemsEqual], false);
  };

  handleEditRouteTemplateName = () => (event) => {
    const { originalName } = this.state;
    const name = event.target.value;
    this.setState({ isEdited: originalName !== name, name: name });
  };

  handleRefreshAfterModify = async () => {
    this.handleToggleCustomerLocationModal(false)();

    this.setState(initialState, this.fetchRouteTemplate);
  };

  handleToggleCustomerLocationModal = (customerLocationModalOpened) => () => {
    const { customerLocationToEdit } = this.state;

    this.setState({
      customerLocationModalOpened,
      customerLocationToEdit,
    });
  };

  handleShowContainerDetailsDialog = (item) => {
    this.getLocationItems(item).then((value) => {
      this.setState({
        locationItems: value,
        note: item.note,
        locationId: item.locationId,
        showContainerDialog: true,
      });
    });
  };

  handleCloseContainerDetailsDialog = () => {
    this.setState({
      showContainerDialog: false,
      locationId: null,
    });
  };

  handleAssignCheckboxClick = () => {
    const { displayAssignMarker } = this.state;
    this.setState({ displayAssignMarker: !displayAssignMarker, isAssignCheckboxDisabled: true });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isAssignCheckboxDisabled: false }), 1200);
  };

  handleUnassignCheckboxClick = () => {
    const { displayUnassignMarker } = this.state;
    this.setState({
      displayUnassignMarker: !displayUnassignMarker,
      isUnassignCheckboxDisabled: true,
    });

    // Disable checkbox for 1.2 second and enable it back after to prevent spamming
    setTimeout(() => this.setState({ isUnassignCheckboxDisabled: false }), 1200);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { createRouteTemplate, updateRouteTemplate, router, flushRouteTemplate, routeTemplate } = this.props;

      const { master_route, masterRouteId, routeTemplateId } = router.params;

      const { averageLiftingTime, name, completeName, supplierLocationId, masterRoute } = this.state;

      const actualRouteTemplateId = router.params.routeTemplateId;

      const routeTemplateLocationsParams = this.createRouteTemplateLocationsParams(this.selected);
      this.setState({ loadingSpinner: true });

      if (actualRouteTemplateId && !master_route) {
        await updateRouteTemplate(actualRouteTemplateId, {
          average_lifting_time: averageLiftingTime,
          master_route: masterRouteId,
          route_template_id: routeTemplate.id,
          name,
          complete_name: completeName,
          route_template_locations_attributes: routeTemplateLocationsParams,
          supplier_location_id: supplierLocationId,
        });
        await flushRouteTemplate();
      } else {
        await createRouteTemplate(router.params.customerItemId, {
          average_lifting_time: averageLiftingTime,
          customer_item_id: router.params.customerItemId,
          master_route: masterRoute,
          master_route_id: routeTemplateId,
          name,
          complete_name: completeName,
          route_template_locations_attributes: routeTemplateLocationsParams,
          supplier_location_id: supplierLocationId,
        });
      }

      this.setState(initialState);

      const path = `/customers/${router.params.customerId}/contracts/${router.params.contractId}/preparations/${router.params.customerItemId}?current_tab=3`;
      router.navigate(path);
    }
  };

  handleShowHideSidebar = () => {
    const { showSideBar } = this.state;
    // if (showSideBar) {
    //   document.firstElementChild.classList.remove('popup-open');
    // } else {
    //   document.firstElementChild.classList = 'popup-open';
    // }
    this.setState({ showSideBar: !showSideBar });
  };

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  // getLocationItems = async (id) => {
  //   const { fetchLocationItems } = this.props;
  //   const itemsDetails = await fetchLocationItems(id);
  //   return itemsDetails;
  // };

  getLocationItems = async (item) => {
    return [item.item]; // Le premier item est une RouteTemplateLocation, son item est le contenant
  };

  updateArrayMarker = async (source, destination) => {
    const { originalSelected, originalItems } = this.state;
    const newSelected = await move(this.state.selected, source, destination);

    const newValues = { selected: newSelected, items: this.state.items };
    const originalValues = { selected: originalSelected, items: originalItems };

    const isEdited = this.handleCompareCurrentRouteTemplateToOriginal(originalValues, newValues);

    this.setState((prevState) => ({
      isEdited: isEdited,
      selected: newSelected,
      sideDrawerLeftTabContent: {
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: destination + 1,
          length: newSelected.length,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  assignLocationFromItemToSelected = (item) => {
    const { items, selected, originalItems, originalSelected } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newSelected = [...selected, data];
    const newItems = [...items.filter((f) => !(data.routeTemplateLocationId === f.routeTemplateLocationId))];

    const newValues = { selected: newSelected, items: newItems };
    const originalValues = { selected: originalSelected, items: originalItems };

    const isEdited = this.handleCompareCurrentRouteTemplateToOriginal(originalValues, newValues);

    this.setState((prevState) => ({
      isEdited: isEdited,
      selected: newSelected,
      items: newItems,
      sideDrawerLeftTabContent: {
        // Update only the rank data
        ...prevState.sideDrawerLeftTabContent,
        rank: {
          text: prevState.selected.length + 1,
          length: prevState.selected.length + 1,
          rankChangeCallback: this.updateArrayMarker,
        },
      },
    }));
  };

  unassignLocationFromSelectedToItem = (item) => {
    const { items, selected, originalSelected, originalItems } = this.state;
    const data = item.data ? item.data : item; // Called with quick assign or showMapSideDrawer
    const newItems = [...items, data];
    const newSelected = [...selected.filter((f) => !(data.routeTemplateLocationId === f.routeTemplateLocationId))];

    const newValues = { selected: newSelected, items: newItems };
    const originalValues = { selected: originalSelected, items: originalItems };

    const isEdited = this.handleCompareCurrentRouteTemplateToOriginal(originalValues, newValues);

    this.setState((prevState) => ({
      isEdited: isEdited,
      items: newItems,
      selected: newSelected,
      sideDrawerLeftTabContent: { ...prevState.sideDrawerLeftTabContent, rank: {} }, // Remove rank data since unassigned
    }));
  };

  changeMapType = (mapType) => this.setState({ mapType });

  handleIsLocationAssigned = (routeTemplateLocationId) =>
    this.state.selected.some((selectedItem) => selectedItem.routeTemplateLocationId === routeTemplateLocationId); // isLocationAssignable

  hideMapSideDrawer = () => this.setState({ isMapSideDrawerOpen: false, sideDrawerLeftTabContent: {} });

  showMapSideDrawer = (item, rank) => {
    // If no rank is passed as parameter, only shows item data
    this.getLocationItems(item).then((value) => {
      this.setState({
        isMapSideDrawerOpen: true,
        sideDrawerLeftTabContent: { data: item, rank },
        sideDrawerRightTabContent: value,
      });
    });
  };

  handleChangeQuickAssign = (event) => {
    this.setState({ quickAssign: event.target.checked, sideDrawerLeftTabContent: {} }, () => {
      this.state.quickAssign && this.hideMapSideDrawer();
    });
  };

  renderBreadCrumbs = () => {
    const { router } = this.props;
    const { params } = router;
    const { contractId, customerId, customerItemId } = params;

    if (contractId && customerId) {
      return (
        <Breadcrumbs>
          <li>
            <Link to="/customers">
              <Trans i18nKey="customers" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}`}>
              <Trans i18nKey="contract_and_contact" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}`}>
              <Trans i18nKey="contract" />
            </Link>
          </li>
          <li>
            <Link to={`/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}?current_tab=3`}>
              <Trans i18nKey="customer_item" />
            </Link>
          </li>
          <li>
            <Trans i18nKey="route_templates.conception" />
          </li>
        </Breadcrumbs>
      );
    }
  };

  renderMapMarkers = () => {
    const assignedMarkers = this.renderMapAssignedMarkers();
    const unassignedMarkers = this.renderMapUnassignedMarkers();
    const masterRouteMarkers = this.renderMapMasterRouteMarkers();

    // Join all three arrays under one array and if an array is null, replace it with an empty array instead
    return [...(unassignedMarkers || []), ...(assignedMarkers || []), ...(masterRouteMarkers || [])];
  };

  renderMapAssignedMarkers = () => {
    const { displayAssignMarker, quickAssign, selected, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const selectedLength = selected.length === 0 ? 1 : selected.length;
    const { data } = sideDrawerLeftTabContent;

    return (
      router.location.pathname.includes("master_route") &&
      displayAssignMarker &&
      offsetLegacyMarkersOnSameSpot(this.selected).map((selected, index) => {
        const rank = {
          text: index + 1,
          length: selectedLength,
          rankChangeCallback: this.updateArrayMarker,
        };

        return (
          <MapMarker
            key={selected.routeTemplateLocationId.toString()}
            icon={MarkerIcon.assigned}
            label={index + 1} // Show label on markers since it's an assigned marker
            position={{
              lat: +selected.address.lat,
              lng: +selected.address.lng,
            }} // Convert string to int and pass coords
            onClick={
              quickAssign
                ? () => this.unassignLocationFromSelectedToItem(selected)
                : () => this.showMapSideDrawer(selected, rank)
            }
            isSelected={
              !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data")
                ? data.routeTemplateLocationId === selected.routeTemplateLocationId
                : null
            }
          />
        );
      })
    );
  };

  renderMapUnassignedMarkers = () => {
    const { displayUnassignMarker, quickAssign, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const { data } = sideDrawerLeftTabContent;

    return (
      router.location.pathname.includes("master_route") &&
      displayUnassignMarker &&
      offsetLegacyMarkersOnSameSpot(this.items).map((item) => (
        <MapMarker
          key={item.routeTemplateLocationId.toString()}
          icon={MarkerIcon.unassigned}
          position={{ lat: +item.address.lat, lng: +item.address.lng }} // Convert string to int and pass coords
          onClick={quickAssign ? () => this.assignLocationFromItemToSelected(item) : () => this.showMapSideDrawer(item)}
          isSelected={
            !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data")
              ? data.routeTemplateLocationId === item.routeTemplateLocationId
              : null
          }
        />
      ))
    );
  };

  renderMapMasterRouteMarkers = () => {
    const { isAMasterRoute, quickAssign, sideDrawerLeftTabContent } = this.state;
    const { router } = this.props;
    const { data } = sideDrawerLeftTabContent;

    // console.log(offsetLegacyMarkersOnSameSpot(this.selected));
    return (
      isAMasterRoute &&
      router.location.pathname.includes("edit") &&
      offsetLegacyMarkersOnSameSpot(this.selected).map((selected) => (
        <MapMarker
          key={selected.routeTemplateLocationId.toString()}
          icon={MarkerIcon.unassigned}
          position={{ lat: +selected.address.lat, lng: +selected.address.lng }} // Convert string to int and pass coords
          onClick={
            quickAssign ? () => this.assignLocationFromItemToSelected(selected) : () => this.showMapSideDrawer(selected)
          }
          isSelected={
            !quickAssign && sideDrawerLeftTabContent.hasOwnProperty("data")
              ? data.routeTemplateLocationId === selected.routeTemplateLocationId
              : null
          }
        />
      ))
    );
  };

  render() {
    const {
      errors,
      isAMasterRoute,
      masterRoute,
      menuDataTableOpened,
      name,
      showSideBar,
      customerLocationModalOpened,
      customerLocationToEdit,
      showContainerDialog,
      note,
      displayAssignMarker,
      displayUnassignMarker,
      mapType,
      isAssignCheckboxDisabled,
      isUnassignCheckboxDisabled,
      loadingSpinner,
      isEdited,
    } = this.state;

    const { isLoaded, loadError } = this.props.googleMap;
    const { center, customerItem, customerLocationsLoading, router, routeTemplateLoading, zoom, routeTemplate } =
      this.props;

    const { routeTemplateId, masterRouteId, master_route } = router.params;

    if (masterRouteId) {
      if (customerLocationsLoading || routeTemplateLoading || loadingSpinner) {
        return (
          <LoadingSpinner>
            <CircularProgress
              color="primary"
              size={70}
            />
          </LoadingSpinner>
        );
      }
    } else if (routeTemplateLoading) {
      return (
        <LoadingSpinner>
          <CircularProgress
            color="primary"
            size={70}
          />
        </LoadingSpinner>
      );
    }

    if (!this.items) {
      return null;
    }

    const actionName = this.displayActionName(customerLocationToEdit.id, master_route);

    let action;
    if (masterRouteId !== null && routeTemplateId && isAMasterRoute) {
      action = "Visualiser - ";
    }

    if (masterRouteId !== null && routeTemplateId && !isAMasterRoute) {
      action = "Modifier - ";
    }

    if (routeTemplateId && master_route && isAMasterRoute) {
      action = "Ajouter une sous-route - ";
    }

    const title = this.displayRightTitle(routeTemplateId, master_route, masterRouteId, isAMasterRoute);

    let nameChanger = "";
    if (name !== "-") {
      nameChanger = name;
    }

    const path = router.location.pathname;

    // Single object to contain all passed props for side drawer component
    const sideDrawerContractItemData = {
      isMapSideDrawerOpen: this.state.isMapSideDrawerOpen,
      isLocationAssignable: path.includes("master_route"),
      hideMapSideDrawer: this.hideMapSideDrawer,
      handleIsLocationAssigned: this.handleIsLocationAssigned,
      handleAssignSelectedLocation: this.assignLocationFromItemToSelected,
      handleUnassignSelectedLocation: this.unassignLocationFromSelectedToItem,
      sideDrawerLeftTabContent: this.state.sideDrawerLeftTabContent,
      sideDrawerRightTabContent: this.state.sideDrawerRightTabContent,
    };

    // Single object to contain all passed props/properties to google map component
    const mapProps = {
      mapContainerStyle: { width: "100%" },
      center,
      zoom,
      clickableIcons: false,
      mapTypeId: mapType,
      onUnmount: this.onMapUnmount,
      options: { mapTypeControl: false, streetViewControl: true },
      onLoad: (map) => {
        console.log("onLoad");
        const markers = [...this.state.selected, ...this.state.items];
        const bounds = new window.google.maps.LatLngBounds();

        markers.forEach((marker) => {
          bounds.extend({ lat: +marker.address.lat, lng: +marker.address.lng });
        });

        map.fitBounds(bounds);
      },
    };

    // Single object to contain all passed props to header component
    const headerProps = {
      currentAction: "edit",
      returnOnClick: this.renderCancelButton,
      pageTitle: title,
      sidebarOnClick: this.handleShowHideSidebar,
      sidebarTitle: "EMPLACEMENTS",
      isQuickAssignShowing: path.includes("master_route"),
      quickAssign: this.state.quickAssign,
      quickAssignOnClick: this.handleChangeQuickAssign,
    };

    return (
      <Fragment>
        {loadingSpinner && (
          <LoadingSpinner>
            <CircularProgress
              color="primary"
              size={70}
            />
          </LoadingSpinner>
        )}
        <Prompt
          when={isEdited}
          message={"La page contient un ou plusieurs changements non sauvegardés, êtes-vous sûr de vouloir quitter ?"}
        />
        {this.renderBreadCrumbs()}
        <div id="route-template-wrapper">
          <RouteHeader {...headerProps} />

          <MapContentWrapper>
            {!loadError ? (
              isLoaded ? (
                <>
                  <MapSideDrawer
                    componentToLoad={componentToLoad.contractItem}
                    componentDataToLoad={sideDrawerContractItemData}
                    urlCustomerContract={`/customers/${this.props.router.params.customerId}/contracts/${this.props.router.params.contractId}`}
                  />
                  <GoogleMap {...mapProps}>{this.renderMapMarkers()}</GoogleMap>
                </>
              ) : (
                <MapLoadingSpinner />
              )
            ) : (
              <MapErrorView />
            )}
          </MapContentWrapper>

          <RouteContentWrapper>
            <Grid
              container
              justifyContent="center"
            >
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("satellite")}
              >
                Satellite
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("terrain")}
              >
                Terrain
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("roadmap")}
              >
                Carte routière
              </Button>
              <Button
                sx={{ color: "black" }}
                onClick={() => this.changeMapType("hybrid")}
              >
                Hybride
              </Button>
              {path.includes("master_route") && (
                <Fragment>
                  <Button
                    sx={{ color: "black" }}
                    style={{ marginLeft: 70 }}
                    disabled={isAssignCheckboxDisabled}
                    onClick={this.handleAssignCheckboxClick}
                  >
                    <Checkbox checked={displayAssignMarker} />
                    EMPLACEMENTS ASSIGNÉS
                  </Button>
                  <Button
                    sx={{ color: "black" }}
                    disabled={isUnassignCheckboxDisabled}
                    onClick={this.handleUnassignCheckboxClick}
                  >
                    <Checkbox checked={displayUnassignMarker} />
                    EMPLACEMENTS NON-ASSIGNÉS
                  </Button>
                </Fragment>
              )}
            </Grid>

            <form>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.name || errors.nameHypen}
                      helperText={<Trans i18nKey="validate_route_name" />}
                      id="route-template-name"
                      label={<Trans i18nKey="route_templates.name" />}
                      onChange={this.handleEditRouteTemplateName(this)}
                      value={nameChanger}
                      fullWidth
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputControl>
                    <TextFieldUi
                      error={errors.masterRoute}
                      id="route-template-master-route"
                      label={<Trans i18nKey="route_templates.master_route_name" />}
                      InputProps={{ readOnly: true }}
                      value={masterRoute}
                      fullWidth
                    />
                  </InputControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {this.renderLocationsFields()}
                </Grid>
              </Grid>

              {/* eslint-disable-next-line max-len */}
              {this.displayAddLocationButton(routeTemplateId, masterRouteId, master_route, isAMasterRoute)}

              <Grid
                container
                justifyContent="center"
                style={{ display: "none" }}
              >
                <Grid item>
                  <Button onClick={this.loadTsp}>TSP</Button>
                </Grid>
              </Grid>

              <RouteSidebar
                closeSidebarOnClick={this.handleShowHideSidebar}
                sidebarTitle={action + " " + routeTemplate.completeName}
                showSideBar={showSideBar}
                switchChecked={menuDataTableOpened}
                switchOnChange={this.handleMenuDataTable}
              >
                {!menuDataTableOpened && this.state.selected && this.menuDataTable()}
              </RouteSidebar>
            </form>
          </RouteContentWrapper>

          <div id="directions" />

          <ModalCustomerLocation
            key={customerLocationToEdit.id}
            actionName={actionName}
            callback={this.fetchAndModalCallback}
            customerId={customerItem.customerId}
            contractId={customerItem.contractId}
            onClose={this.handleToggleCustomerLocationModal(false)}
            open={customerLocationModalOpened}
            refreshList={this.handleRefreshAfterModify}
          />

          {this.displaySaveButton(routeTemplateId, masterRouteId, master_route, isAMasterRoute)}

          {showContainerDialog ? (
            <RouteDetailsDialog
              items={this.state.locationItems}
              dialogOpen={showContainerDialog}
              locationId
              note={note}
              onDialogClose={this.handleCloseContainerDetailsDialog}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

RouteTemplate.defaultProps = {
  customerItemLoading: true,
  customerLocationsLoading: true,
  routeTemplateLoading: true,
  center: {
    lat: 46.807763,
    lng: -71.38549,
  },
  zoom: 10,
};

RouteTemplate.propTypes = {
  center: PropTypes.object,
  createRouteTemplate: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  customerItemLoading: PropTypes.bool,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLocationsLoading: PropTypes.bool,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerItem: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  fetchRouteTemplate: PropTypes.func.isRequired,
  flushCustomerLocations: PropTypes.func.isRequired,
  flushRouteTemplate: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  routeTemplate: PropTypes.object.isRequired,
  routeTemplateLoading: PropTypes.bool,
  supplierLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateRouteTemplate: PropTypes.func.isRequired,
  zoom: PropTypes.number,
};

// eslint-disable-next-line max-len
export default withRouter(
  withGoogleMap(
    withJobTemplates(
      withCustomerItem(
        withSupplierLocations(
          withCustomerLocations(withLocations(withRouteTemplate(withRouteTemplates(RouteTemplate))))
        )
      )
    )
  )
);
