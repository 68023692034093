import React, { ReactElement } from "react";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";
import { IconProps } from "@components/icons/Icon.type";

export const FileIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
  strokeColor = defaultIconProps.strokeColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill={fillColor}
      >
        <path
          d="M10 8.20833C10 8.45145 9.90518 8.68461 9.7364 8.85651C9.56761 9.02842 9.33869 9.125 9.1 9.125H1.9C1.66131 9.125 1.43239 9.02842 1.2636 8.85651C1.09482 8.68461 1 8.45145 1 8.20833V1.79167C1 1.54855 1.09482 1.31539 1.2636 1.14349C1.43239 0.971577 1.66131 0.875 1.9 0.875H4.15L5.05 2.25H9.1C9.33869 2.25 9.56761 2.34658 9.7364 2.51849C9.90518 2.69039 10 2.92355 10 3.16667V8.20833Z"
          stroke={strokeColor}
          strokeOpacity="0.46"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default FileIcon;
