import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Toolbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { debounce } from "lodash";
import { withJobs } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";
import moment from "moment";

import FloatingActionButton from "./ui/FloatingActionButton";
import ModalWarning from "./ModalWarning";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";
import TableLoading from "./ui/TableLoading";
import TableOverflowWrapper from "./ui/TableOverflowWrapper";
import TablePaginationWrapper from "./ui/TablePaginationWrapper";
import TextFieldUi from "./ui/TextField";
import {
  filteringState,
  handleFilter,
  handlePageChange,
  handleRowsPerPageChange,
  handleSort,
  sortedData,
} from "@utils/filtering";
import { jobStatuses } from "@utils/statuses";

const data = {
  duration: { label: <Trans i18nKey="jobs.duration" /> },
  endDate: { label: <Trans i18nKey="jobs.end_date" /> },
  kind: { label: <Trans i18nKey="jobs.kind" /> },
  status: { label: <Trans i18nKey="jobs.status" /> },
  startDate: { label: <Trans i18nKey="jobs.start_date" /> },
  unit: { label: <Trans i18nKey="jobs.unit" /> },
};

const StatusBadge = styled.span`
  position: relative;
  display: inline;
  margin-bottom: -2px;
  padding: 4px 10px;
  height: 14px;
  border-radius: 14px;

  ${({ status, theme }) => `
    background-color: ${theme.colors.components.taskStatus[status]};
    color: ${status === "TODO" ? "default" : "#ffffff"};
  `}
`;

const sortAllowedOnColumnName = "startDate";

class JobsList extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  initialSort = {
    columnName: "startDate",
    direction: "desc",
  };

  // eslint-disable-next-line react/sort-comp
  initialState = {
    ...filteringState,
    deleteJobModalOpened: false,
    jobModalOpened: false,
    jobToDelete: null,
    sort: {
      ...this.initialSort,
    },
  };

  state = {
    ...this.initialState,
  };
  handleFilter = handleFilter(this.debouncedFetchJobs).bind(this);

  componentDidMount() {
    this.fetchJobs();
  }

  componentWillUnmount() {
    this.props.flushJobs();
  }

  fetchAndResetPagination = () => {
    this.setState(
      {
        ...this.initialState,
      },
      this.fetchJobs
    );
  };

  fetchJobs = () => {
    const { filter, limit, page, sort } = this.state;
    const { direction, columnName } = sort;
    const sortAttributes = { columnName, direction };

    this.props.fetchCustomerItemJobs(this.props.router.params.customerItemId, {
      filter,
      page,
      limit,
      sortAttributes,
    });
  };

  debouncedFetchJobs = debounce(() => {
    this.fetchJobs();
  }, 300);
  handlePageChange = handlePageChange(this.fetchJobs).bind(this);
  handleRowsPerPageChange = handleRowsPerPageChange(this.fetchJobs).bind(this);

  handleDeleteJob = async () => {
    const { deleteJob } = this.props;

    await deleteJob(this.state.jobToDelete);

    const { deleteJobModalOpened, jobToDelete } = this.initialState;

    this.setState(
      {
        deleteJobModalOpened,
        jobToDelete,
      },
      this.fetchJobs
    );
  };

  handleToggleJobModal = (jobModalOpened) => () => {
    this.setState({
      jobModalOpened,
    });
  };

  handleRowClick = (path) => () => {
    this.props.router.navigate(path);
  };

  handleSort = (name) => {
    const { columnName, direction } = this.state.sort;

    let newColumnName = name;
    let newDirection = "asc";

    if (columnName === name) {
      switch (direction) {
        case "asc": {
          newDirection = "desc";
          break;
        }
        case "desc": {
          newColumnName = name;
          newDirection = "asc";
          break;
        }
        default: {
          break;
        }
      }
    }

    this.setState(
      {
        sort: {
          columnName: newColumnName,
          direction: newDirection,
        },
      },
      this.fetchJobs
    );
  };

  // handleSort = handleSort(this.fetchJobs).bind(this);

  handleToggleDeleteJobModal = (opened, jobId) => (e) => {
    e.stopPropagation();

    const jobToDelete = opened ? jobId : this.initialState.jobToDelete;

    this.setState({ deleteJobModalOpened: opened, jobToDelete });
  };

  renderDeleteIcon = (id) => (
    <IconButton color="secondary" id="cpbr-delete-job" onClick={this.handleToggleDeleteJobModal(true, id)} size="large">
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  renderTableHead = () => {
    const { columnName, direction } = this.state.sort;
    return (
      <TableHead>
        <TableRow>
          {["startDate", "endDate", "kind", "status", "unit", "duration"].map((name) => (
            <TableCell key={name} style={{ paddingLeft: 30 }}>
              {sortAllowedOnColumnName.includes(name) && (
                <TableSortLabel
                  active={columnName === name}
                  direction={direction}
                  onClick={() => this.handleSort(name)}
                />
              )}
              {data[name].label}
            </TableCell>
          ))}

          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  renderTableRows = () => {
    const { jobs, jobsLoading, router } = this.props;
    const { params } = router;
    const { contractId, customerId, customerItemId } = params;
    const { limit, page } = this.state;

    if (jobsLoading) {
      return <TableLoading />;
    }

    return sortedData(jobs, this.state).map((job) => {
      const { id, duration, kind, startDate, status, endedAt, unitName } = job;

      return (
        <TableRow
          key={id}
          className="link-row"
          onClick={this.handleRowClick(
            `/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}/jobs/${id}`
          )}
        >
          <TableCell>{startDate}</TableCell>
          <TableCell>{endedAt ? moment(endedAt).format("YYYY-MM-DD (HH:mm)") : ""}</TableCell>
          <TableCell>{kind}</TableCell>
          <TableCell>
            <StatusBadge status={status}>{jobStatuses[status]}</StatusBadge>
          </TableCell>
          <TableCell>{unitName}</TableCell>
          <TableCell>{duration}</TableCell>
          <TableCell classes={{ root: "action-cell" }}>{this.renderDeleteIcon(id)}</TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { customerItem, jobsCount } = this.props;
    const { customerTaskBlock } = customerItem;

    const { deleteJobModalOpened, filter, jobsLoading, jobModalOpened, limit, page } = this.state;

    if (jobsLoading) {
      return null;
    }

    return (
      <PageContainer>
        <PaperWrapper>
          <div>
            <FloatingActionButton
              tooltipLabel={customerTaskBlock ? "Le client est bloqué" : ""}
              disabled
              color="secondary"
              onClick={this.handleToggleJobModal(true)}
            >
              <AddIcon />
            </FloatingActionButton>
          </div>
          <TableOverflowWrapper>
            <Toolbar>
              <TextFieldUi
                id="cpbr-filtre"
                label={<Trans i18nKey="filter" />}
                onChange={this.handleFilter}
                type="search"
                value={filter}
              />
            </Toolbar>

            <Table>
              {this.renderTableHead()}

              <TableBody>{this.renderTableRows()}</TableBody>
            </Table>
          </TableOverflowWrapper>

          <TablePaginationWrapper
            component="div"
            count={jobsCount}
            id="cpbr-pagination"
            labelRowsPerPage=""
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={limit}
          />
        </PaperWrapper>

        <ModalWarning
          onCancel={this.handleToggleDeleteJobModal(false)}
          onSubmit={this.handleDeleteJob}
          open={deleteJobModalOpened}
          title={<Trans i18nKey="warning" />}
        >
          <Trans i18nKey="warning_delete_job" />
        </ModalWarning>
      </PageContainer>
    );
  }
}

JobsList.defaultProps = {
  jobsLoading: true,
};

JobsList.propTypes = {
  customerItem: PropTypes.object.isRequired,
  fetchCustomerItemJobs: PropTypes.func.isRequired,
  flushJobs: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
  jobsCount: PropTypes.number.isRequired,
  jobsLoading: PropTypes.bool,
};

export default withJobs(JobsList);
