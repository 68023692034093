import { MutationResult } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import LocationCcavContainerResponse from "@@types/containers/location-ccav-container-response.type";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";

const putLocationCcavContainerService = async (
  payload: LocationCcavContainer
): Promise<MutationResult<LocationCcavContainerResponse>> => {
  const res = await http.put<MutationResult<LocationCcavContainerResponse>>(
    `/containers/update_ccav_container`,
    payload
  );
  return res.data;
};

export default putLocationCcavContainerService;
