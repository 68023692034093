import LocationDetail, { DateModality } from "@@types/location-details/location-detail.type";
import { Dispatch, SetStateAction } from "react";
import moment, { Moment } from "moment";

export type LocationModalityDate = {
  handleChangeDate: (dateType: string, event: Moment | null) => void;
  handleEnableDateSelector: (dateType: string) => void;
};

const useLocationModalityDate = (
  modalityDate: DateModality,
  setModality: Dispatch<SetStateAction<LocationDetail | undefined>>
): LocationModalityDate => {
  const handleEnableDateSelector = (dateType: string): void => {
    setModality((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [dateType]: {
            ...modalityDate,
            active: dateType == 'from' ? true : !modalityDate.active,
            value: !modalityDate.active ? moment().format("YYYY-MM-DD") : null,
          },
        };
      }
    });
  };

  const handleChangeDate = (dateType: string, event: Moment | null): void => {
    setModality((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [dateType]: { ...modalityDate, value: moment(event).format("YYYY-MM-DD"), error: false },
        };
      }
    });
  };

  return {
    handleEnableDateSelector,
    handleChangeDate,
  } as LocationModalityDate;
};

export default useLocationModalityDate;
