import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { withLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { Trans } from "react-i18next";
import DialogWrapper from "./ui/DialogWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import TextAreaUi from "./ui/TextArea";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

class ModalEditLocation extends PureComponent {
  constructor(props) {
    super(props);
    const { customerLocation } = this.props;
    const {
      companyName,
      id,
      name,
      fileNumber,
      note,
      isFromCiWeb,
      purchaseOrderNo,
      doorNo,
      adr1,
      city,
      province,
      postalCode,
      customerMobilePhoneNumber,
      smsAlertPhoneNumber,
    } = customerLocation;

    this.state = {
      companyName,
      id,
      name,
      fileNumber,
      purchaseOrderNo,
      note,
      isFromCiWeb,
      doorNo,
      adr1,
      city,
      province,
      postalCode,
      customerMobilePhoneNumber,
      smsAlertPhoneNumber,
      errors: {
        name: false,
        smsAlertPhoneNumber: false,
      },
    };
  }

  get valid() {
    const { name, fileNumber, smsAlertPhoneNumber } = this.state;

    let valid = true;

    const errors = {
      name: false,
      fileNumber: false,
    };

    if (this.props.customerLocation.isAFrontLoading) {
      if (name.replace(/\s/g, "") === "") {
        valid = false;
        errors.name = true;
      }

      if (fileNumber && fileNumber.toString().replace(/\s/g, "") === "") {
        valid = false;
        errors.fileNumber = true;
      }
    }

    // Validate if phone is a number and has 10 digits
    if (smsAlertPhoneNumber) {
      const phoneNumber = smsAlertPhoneNumber.replace(/[^\d]/g, "");

      if (phoneNumber.length > 11 || phoneNumber.length < 10) {
        valid = false;
        errors.smsAlertPhoneNumber = true;
      }
    }

    this.setState({ errors });
    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);
  handleChangeFields = handleChangeFields.bind(this);

  updateLocation = async () => {
    if (this.valid) {
      const {
        id,
        companyName,
        name,
        fileNumber,
        note,
        purchaseOrderNo,
        doorNo,
        adr1,
        city,
        province,
        postalCode,
        smsAlertPhoneNumber,
      } = this.state;

      const { refreshData, onClose, editLocation } = this.props;

      const fullAddress = doorNo + " " + adr1 + ", " + city + ", " + province + ", " + postalCode;

      await editLocation(id, {
        company_name: companyName,
        editLocationOnly: true,
        file_number: fileNumber,
        purchase_order_no: purchaseOrderNo,
        name: name || fullAddress,
        note,
        phone_no: smsAlertPhoneNumber,
      });
      refreshData();
      onClose();
    }
  };

  renderCustomerName = () => {
    const { companyName } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 100 }}
              label={<Trans i18nKey="customers" />}
              value={companyName || ""}
              onChange={this.handleChangeFields("companyName")}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderFileNumber = () => {
    const { fileNumber, isFromCiWeb } = this.state;

    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 20 }}
              disabled={isFromCiWeb}
              label={<Trans i18nKey="location.file_number" />}
              value={fileNumber || ""}
              onChange={this.handleChangeFields("fileNumber")}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderPurchaseOrderNo = () => {
    const { purchaseOrderNo, isFromCiWeb } = this.state;

    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 255 }}
              label={<Trans i18nKey="purchase_order_no_short" />}
              value={purchaseOrderNo || ""}
              onChange={this.handleChangeFields("purchaseOrderNo")}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderSmsAlertPhoneNumber = () => {
    const { customerMobilePhoneNumber, smsAlertPhoneNumber, errors } = this.state;

    return (
      <Stack direction="row" alignItems="center" height="45px">
        <TextField
          error={errors.smsAlertPhoneNumber}
          label="Alerte SMS"
          InputLabelProps={{
            shrink: true,
          }}
          value={formattedPhoneNumber(smsAlertPhoneNumber) || ""}
          onChange={this.handleChangeFields("smsAlertPhoneNumber")}
          fullWidth
          sx={{
            ".MuiInputBase-input": {
              padding: "0 0 0 25px",
              height: 42,
            },
          }}
        />
        <Tooltip title={"Même numéro que la fiche client (Cellulaire)"} placement="top">
          <Button
            onClick={() => this.setState({ smsAlertPhoneNumber: customerMobilePhoneNumber })}
            color="primary"
            sx={{ minWidth: 40, minHeight: 42 }}
            disabled={smsAlertPhoneNumber || !customerMobilePhoneNumber}
          >
            <ContentCopyIcon />
          </Button>
        </Tooltip>
      </Stack>
    );
  };

  renderLocationName = () => {
    const { name, errors, isFromCiWeb } = this.state;

    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextFieldUi
              inputProps={{ maxLength: 100 }}
              disabled={isFromCiWeb}
              error={errors.name}
              helperText={this.getErrorMessage("name")}
              label={<Trans i18nKey="location.name" />}
              value={name || ""}
              onChange={this.handleChangeFields("name")}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  renderLocationNote = () => {
    const { note } = this.state;
    return (
      <Fragment>
        <FormGroupWrapper>
          <FormControl>
            <TextAreaUi
              label={<Trans i18nKey="preparation.note_location" />}
              value={note || ""}
              onChange={this.handleChangeFields("note")}
            />
          </FormControl>
        </FormGroupWrapper>
      </Fragment>
    );
  };

  render() {
    const { open } = this.props;

    return (
      <DialogWrapper id="quick-job-modal" open={open}>
        <DialogTitle>
          <span style={{ display: "inline-block", float: "right", textAlign: "right" }} />
        </DialogTitle>
        <DialogContent>
          {/*{this.renderCustomerName()}*/}
          {this.renderLocationName()}
          {this.renderFileNumber()}
          {this.renderPurchaseOrderNo()}
          {this.renderLocationNote()}
          {this.renderSmsAlertPhoneNumber()}
          <form />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()}>
            <Trans i18nKey="cancel" />
          </Button>
          <Button variant="contained" onClick={() => this.updateLocation()}>
            <Trans i18nKey="edit" />
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalEditLocation.propTypes = {
  editLocation: PropTypes.func.isRequired,
  customerLocation: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

// eslint-disable-next-line max-len
export default withLocations(ModalEditLocation);
