import React from "react";
import styled from "@emotion/styled";
import { Tabs as TabsMui } from "@mui/material";
import PropTypes from "prop-types";

import PageContainer from "./PageContainer";

const BgWrapper = styled.div`
  background: ${(props) => props.theme.colors.palette.background.medium};
`;

const TabsWrapper = ({ children, ...remainingProps }) => (
  <BgWrapper>
    <PageContainer>
      <TabsMui {...remainingProps}>{children}</TabsMui>
    </PageContainer>
  </BgWrapper>
);

TabsWrapper.defaultProps = {
  children: "",
};

TabsWrapper.propTypes = {
  children: PropTypes.node,
};

export default TabsWrapper;
