import React, { Dispatch, FC, SetStateAction } from "react";
import { Trans } from "react-i18next";

import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import useLocationCcavContainerModal from "@components/location/route/containers/modals/hooks/location-container-modal";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import DialogWrapper from "@ui/DialogWrapper";
import Dropdown from "@ui/dropdown/Dropdown";

type LocationCcavContainerModalProps = {
  isFromCiWeb: boolean;
  modalType: string;
  openModalContainer: boolean;
  selectedContainer: LocationCcavContainer;
  selectedLocation: number;
  setSelectedContainer: Dispatch<SetStateAction<LocationCcavContainer | undefined>>;
  onClose: (modalType: string) => void;
};

const LocationCcavContainerModal: FC<LocationCcavContainerModalProps> = ({
  isFromCiWeb,
  modalType,
  openModalContainer,
  selectedContainer,
  selectedLocation,
  setSelectedContainer,
  onClose,
}) => {
  const { containerProfiles, renderMenuItems, handleChangeValue, handleSubmitContainer } =
    useLocationCcavContainerModal(selectedLocation, setSelectedContainer, onClose);
  return (
    <DialogWrapper
      width={40}
      open={openModalContainer}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pr={3}
      >
        <DialogTitle>
          {modalType === "create" ? <Trans i18nKey="add" /> : <Trans i18nKey="edit" />}
        </DialogTitle>

        {!containerProfiles.length && <span style={{ color: "red" }}>{"*Vous devez d'abord créer une modalité*"}</span>}
      </Stack>

      <DialogContent>
        <Stack spacing={4}>
          {/* CONTAINER MODEL */}
          <Dropdown
            error={selectedContainer.error}
            disabled={isFromCiWeb || !containerProfiles.length}
            label={"Modèle de conteneur"}
            value={selectedContainer?.containerProfileId?.toString()}
            name="containerProfileId"
            onChange={(event) => handleChangeValue(event)}
            fullWidth
          >
            {renderMenuItems(containerProfiles)}
          </Dropdown>

          {/* CONTAINER NUMBER */}
          <TextField
            disabled={isFromCiWeb}
            defaultValue={selectedContainer.ciWebLabelcode}
            name={"ciWebLabelcode"}
            onChange={(event) => handleChangeValue(event)}
            label="Numéro de conteneur"
            variant="outlined"
          />

          {/* CONTAINER CHIP NUMBER */}
          <TextField
            disabled={isFromCiWeb}
            defaultValue={selectedContainer.ciWebChipcode}
            name={"ciWebChipcode"}
            onChange={(event) => handleChangeValue(event)}
            label="Numéro de puce"
            variant="outlined"
          />

          {/* CONTAINER NOTE */}
          <TextField
            defaultValue={selectedContainer.memo}
            name={"memo"}
            onChange={(event) => handleChangeValue(event)}
            label="Note conteneur"
            variant="outlined"
            multiline
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose(modalType)}>
          <Trans i18nKey="cancel" />
        </Button>

        <Button
          variant="contained"
          onClick={() => handleSubmitContainer(modalType, selectedContainer)}
        >
          {modalType === "create" ? <Trans i18nKey="add" /> : <Trans i18nKey="edit" />}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default LocationCcavContainerModal;
