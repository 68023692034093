import RouteLocationAnomaly from "@@types/route-locations/route-location-anomaly.type";
import RouteLocationAnomalyResponse from "@@types/route-locations/route-location-anomaly-response.type";

function mapResponseToRouteLocationAnomaly(response: RouteLocationAnomalyResponse): RouteLocationAnomaly {
  return {
    date: response.date,
    containerChipNumber: response.containerChipNumber,
    containerNumber: response.containerNumber,
    customMessage: response.customMessage,
    code: response.code,
    categoryName: response.categoryName,
    categoryDetailName: response.categoryDetailName,
    photos: response.photos,
    time: response.time,
    vehicleName: response.vehicleName,
  };
}

function mapResponseToRouteLocationAnomalies(responses: RouteLocationAnomalyResponse[]): RouteLocationAnomaly[] {
  return responses.map(mapResponseToRouteLocationAnomaly);
}

export default mapResponseToRouteLocationAnomalies;
