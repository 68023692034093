import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";

class Rank extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func,
  };

  render() {
    const { value, rankChangeCallback, length } = this.props;
    const arrayOfNumber = Array.from({ length }, (_, i) => i + 1);

    return (
      <FormControl>
        <select
          value={value}
          onChange={(event) => {
            rankChangeCallback(value - 1, event.target.value - 1);
          }} // source, destination
          style={{ fontSize: "inherit" }}
        >
          {arrayOfNumber.map((numb, index) => (
            <option key={index} value={numb}>
              {numb}
            </option>
          ))}
        </select>
      </FormControl>
    );
  }
}

export default Rank;
