// React imports
import React, { Dispatch, FC, SetStateAction } from "react";
// Internal imports
import RouteLocationHistory from "@@types/route-locations/route-location-history.type";
import { isArrayTruthy } from "@utils/common";
import { formattedTime } from "@utils/dates";
// External imports
import { Button, TableCell, TableRow } from "@mui/material";

type LocationRouteHistoryDetailsBodyProps = {
  routeLocations: RouteLocationHistory[];
  setSelectedRouteLocationId: Dispatch<SetStateAction<number>>;
  setShowRouteLocationAnomaliesDialog: Dispatch<SetStateAction<boolean>>;
};

const LocationRoutesHistoryDetailsBody: FC<LocationRouteHistoryDetailsBodyProps> = ({
  routeLocations,
  setSelectedRouteLocationId,
  setShowRouteLocationAnomaliesDialog,
}) => {
  const handleShowRouteLocationAnomaliesDialog = (selectedRouteLocationId: number): void => {
    setSelectedRouteLocationId(selectedRouteLocationId);
    setShowRouteLocationAnomaliesDialog(true);
  };

  return routeLocations.map((routeLocation) => {
    const routeLocationStatus =
      routeLocation.status === "finished_with_only_anomaly"
        ? "Anomalie"
        : routeLocation.status === "finished_without_anomaly"
        ? "Levée"
        : "Non Levée";

    return (
      <TableRow key={routeLocation.id}>
        <TableCell>{routeLocation.containerModel || "INCONNU"}</TableCell>
        <TableCell>{routeLocation.containerNumber || "INCONNU"}</TableCell>
        <TableCell>{routeLocation.containerChipNumber || "INCONNU"}</TableCell>
        <TableCell>{routeLocationStatus}</TableCell>
        <TableCell>{routeLocation.visitTimestamp ? formattedTime(routeLocation.visitTimestamp) : "N/A"}</TableCell>
        <TableCell>
          {isArrayTruthy(routeLocation.anomalyIds) ? (
            <Button
              size={"small"}
              variant="contained"
              color="secondary"
              sx={{
                marginRight: "15px",
                backgroundColor: "#59009e",
                "&:hover": { backgroundColor: "#3f0070" },
              }}
              onClick={() => handleShowRouteLocationAnomaliesDialog(routeLocation.id)}
            >
              CAS PROBLÈME
            </Button>
          ) : (
            "N/A"
          )}
        </TableCell>
      </TableRow>
    );
  });
};
export default LocationRoutesHistoryDetailsBody;
