import { FormHelperText, InputLabel, Select as MuiSelect, OutlinedInput } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

import HalfFormControl from "./HalfFormControl";

const InputLabelWrapper = styled(InputLabel)`
  background: #fff;
  left: -4px !important;
  padding: 0 5px !important;
  z-index: 2 !important;
`;

const SelectHalf = ({
  children,
  formControlError,
  formControlWidthClass,
  formHelperErrorMsg,
  inputLabelText,
  ...remainingProps
}) => (
  <HalfFormControl classes={{ root: formControlWidthClass }} error={formControlError} variant="outlined">
    <InputLabelWrapper disableAnimation shrink>
      {inputLabelText}
    </InputLabelWrapper>

    <MuiSelect input={<OutlinedInput />} {...remainingProps}>
      {children}
    </MuiSelect>

    <FormHelperText>{formHelperErrorMsg}</FormHelperText>
  </HalfFormControl>
);

SelectHalf.defaultProps = {
  children: "",
  formControlError: false,
  formControlWidthClass: "",
  formHelperErrorMsg: "",
};

export default SelectHalf;
