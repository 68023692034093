// React imports
import React, { memo, useCallback } from "react";

// External imports
import { isEqual, debounce } from "lodash";
import { Stack, TextField } from "@mui/material";

function JobNoteTextField({ jobNote, handlers }) {
  // console.log("[JobNoteTextField] >>> RENDERED");

  const handleChangeJobNote = useCallback((event) => {
    handlers.setSingleCurrent("jobNote", event.target.value);
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextField
        multiline
        rows={4}
        label="Note de la tâche"
        InputLabelProps={{
          shrink: true,
        }}
        value={jobNote || ""}
        onChange={(event) => handleChangeJobNote(event)}
        fullWidth
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { jobNote: prevJobNote } = prevProps;
  const { jobNote: nextJobNote } = nextProps;
  let arePropsEqual = true;

  // If the current jobNote changed, rerender
  if (!isEqual(prevJobNote, nextJobNote)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default memo(JobNoteTextField, areEqual);
