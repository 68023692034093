import React, { ReactElement } from "react";
import { ICON_SIZE } from "@components/icons/constants";
import { Box, SvgIconProps } from "@mui/material";
import { IconSize } from "@components/icons/Icon.type";

interface ContainerProps extends SvgIconProps {
  children: ReactElement;
  size: IconSize;
}

export const Container = ({ children, size }: ContainerProps): ReactElement => {
  const width = ICON_SIZE[size];
  const height = ICON_SIZE[size];
  return (
    <Box
      id="icon-container"
      sx={{
        height: `${height}px`,
        width: `${width}px`,
        svg: {
          backgroundColor: "transparent",
          height: "100%",
          width: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
