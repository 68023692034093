import { Grid, Typography } from "@mui/material";
import { parseInt } from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

import { nTimes } from "@utils/arrays";

const selectionInitialValue = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  0: false,
};

const initialState = {
  1: selectionInitialValue,
  2: selectionInitialValue,
  3: selectionInitialValue,
  4: selectionInitialValue,
};

const days = [
  { label: <Trans i18nKey="weekdays.mon" />, value: 1 },
  { label: <Trans i18nKey="weekdays.tues" />, value: 2 },
  { label: <Trans i18nKey="weekdays.wed" />, value: 3 },
  { label: <Trans i18nKey="weekdays.thur" />, value: 4 },
  { label: <Trans i18nKey="weekdays.fri" />, value: 5 },
  { label: <Trans i18nKey="weekdays.sat" />, value: 6 },
  { label: <Trans i18nKey="weekdays.sun" />, value: 0 },
];

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const MultiSelectBox = styled.div`
  border: 1px solid #ececec;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    background-color: #ececec;
    border-color: #4da1ff;
  }

  &.active {
    background-color: #4da1ff !important;
    border-color: #4da1ff;
    color: #fff;
  }

  &.error {
    background-color: #fff !important;
    border-color: #ff0000;
    color: #040f13;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
`;

const SelectBoxWrapper = styled.div`
  margin-top: 15px;
`;

const TypographyWrapper = styled(Typography)`
  margin-right: 10px !important;
`;

class FrequencyMonthlyByDaysOfWeek extends PureComponent {
  state = {
    ...initialState,
  };

  handleChange = () => {
    const values = {};

    for (const week of Object.keys(this.state)) {
      const weekData = this.state[week];

      for (const day of Object.keys(this.state[week])) {
        if (!values[day]) {
          values[day] = [];
        }

        if (weekData[day]) {
          values[day].push(parseInt(week));
        }
      }
    }

    this.props.onChange(values);
  };

  handleSelectWeekDay = (day, week) => () => {
    const { [week]: weekValue } = this.state;
    const { [day]: dayValue } = weekValue;

    this.setState(
      {
        [week]: {
          ...weekValue,
          [day]: !dayValue,
        },
      },
      this.handleChange
    );
  };

  renderRows = () =>
    nTimes(4).map((index) => (
      <Row key={`week-${index}`}>
        <TypographyWrapper>{index}</TypographyWrapper>

        {this.renderSelectBox(index)}
      </Row>
    ));

  renderSelectBox = (row) =>
    days.map(({ label, value }) => (
      <MultiSelectBox
        key={`${row}-${value}`}
        className={this.state[row][value] ? "active" : this.props.error ? "error" : ""}
        onClick={this.handleSelectWeekDay(value, row)}
      >
        <Typography>{label}</Typography>
      </MultiSelectBox>
    ));

  render() {
    return (
      <SelectBoxWrapper>
        <Grid
          container
          spacing={10}
        >
          <Grid
            item
            xs={12}
            sm={10}
          >
            <FlexWrapper>{this.renderRows()}</FlexWrapper>
          </Grid>
        </Grid>
      </SelectBoxWrapper>
    );
  }
}

FrequencyMonthlyByDaysOfWeek.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default FrequencyMonthlyByDaysOfWeek;
