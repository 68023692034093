import React from "react";
// Material-UI core imports
import { Button, Divider, List, ListItem, ListItemText, Collapse, Avatar, ListItemIcon } from "@mui/material";
import SideDrawerRank from "./SideDrawerRank";
// Material-UI icon imports
import DirectionsIcon from "@mui/icons-material/Directions";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import FolderIcon from "@mui/icons-material/Folder";
import CommentIcon from "@mui/icons-material/Comment";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";

import { itemStatuses } from "@utils/statuses";

export const ContractItemLeftTabContent = (props) => {
  const {
    data: {
      id,
      done,
      anomaly,
      anomalyIds = [],
      nbContainers,
      companyName,
      ciWebLiftingComments,
      isFromCiWeb,
      fileNumber,
      address: { addressString } = {},
      locationId,
      purchaseOrderNo,
      routeTemplateLocationId,
      visitRank,
    } = {},
    rank: { length, text, rankChangeCallback } = {},
  } = props.data;

  const {
    isLocationAssignable,
    handleShowAnomalyDetails,
    handleIsLocationAssigned,
    handleAssignSelectedLocation,
    handleUnassignSelectedLocation,
    urlCustomerContract,
  } = props;

  return (
    <List>
      <ListItem>
        <Avatar>
          <PersonIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="Client"
          secondary={companyName || "Information manquante"}
        />
      </ListItem>

      <ListItem
        component={Link}
        to={`${urlCustomerContract}/locations/${locationId}`}
        button
      >
        <Avatar>
          <LocationOnIcon />
        </Avatar>
        <ListItemText
          primaryTypographyProps={{ style: { color: "blue" } }}
          secondaryTypographyProps={{ style: { color: "blue" } }}
          style={{ color: "green", paddingLeft: 10 }}
          primary="Adresse"
          secondary={addressString || "Information manquante"}
        />
      </ListItem>

      {/*<ListItem>*/}
      {/*  <Avatar>*/}
      {/*    <RestoreFromTrashIcon />*/}
      {/*  </Avatar>*/}
      {/*  <ListItemText style={{ paddingLeft: 10 }} primary="Nombre de conteneurs" secondary={nbContainers || "0"} />*/}
      {/*</ListItem>*/}

      {/* If rank object is not empty, show it */}
      {(text || visitRank) && (
        <ListItem>
          <Avatar>
            <DirectionsIcon />
          </Avatar>
          <ListItemText
            primaryTypographyProps={{ component: "div" }}
            secondaryTypographyProps={{ component: "div" }}
            style={{ paddingLeft: 10 }}
            primary="Ordre de visite"
            secondary={
              done ? (
                <div>{visitRank ? visitRank : text}</div>
              ) : (
                <SideDrawerRank
                  value={visitRank ? visitRank : text}
                  rankChangeCallback={rankChangeCallback}
                  length={length}
                />
              )
            }
          />
        </ListItem>
      )}

      {/* File number */}
      <ListItem>
        <Avatar>
          <FolderIcon />
        </Avatar>
        <ListItemText
          style={{ paddingLeft: 10 }}
          primary="Numéro de dossier"
          secondary={fileNumber || "Aucun numéro de dossier"}
        />
      </ListItem>

      {/* Purchase order number */}
      {!isFromCiWeb && (
        <ListItem>
          <Avatar>
            <FolderIcon />
          </Avatar>
          <ListItemText
            style={{ paddingLeft: 10 }}
            primary="Numéro de bon de commande"
            secondary={purchaseOrderNo || "Aucun numéro de bon de commande"}
          />
        </ListItem>
      )}

      {/* Note levée VDQ ( RouteLIst - memo ) */}
      {isFromCiWeb && (
        <ListItem>
          <Avatar>
            <CommentIcon />
          </Avatar>
          <ListItemText
            style={{ paddingLeft: 10 }}
            primary="Note levée ( VDQ )"
            secondary={ciWebLiftingComments || "-"}
          />
        </ListItem>
      )}

      {/* If selected marker has an anomaly, show anomaly button */}
      {anomaly && (
        <ListItem>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "#59009e",
              "&:hover": { backgroundColor: "#3f0070" },
            }}
            onClick={() => handleShowAnomalyDetails(anomalyIds)}
          >
            VOIR CAS PROBLÈME
          </Button>
        </ListItem>
      )}

      {/* If current page is editable, is not an anomaly or is not a completed location, show assign/deassign button */}
      {isLocationAssignable && !anomaly && !done && (
        <ListItem>
          {handleIsLocationAssigned(routeTemplateLocationId) ? (
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: "100%",
                marginTop: "10px",
                backgroundColor: "#E10050",
                "&:hover": { backgroundColor: "#9D0038" },
              }}
              onClick={() => handleUnassignSelectedLocation(props.data)}
            >
              DÉSASSIGNER EMPLACEMENT
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%", marginTop: "10px", "&:hover": { backgroundColor: "#1976d2" } }}
              onClick={() => handleAssignSelectedLocation(props.data)}
            >
              ASSIGNER EMPLACEMENT
            </Button>
          )}
        </ListItem>
      )}
    </List>
  );
};

export const ContractItemRightTabContent = (props) => {
  if (props.data === undefined || props.data.length == 0) {
    return null;
  }

  return props.data.map((item, index) => {
    const {
      ciWebLabelcode,
      ciWebChipcode,
      ciWebStatus,
      alias,
      memo,
      locationNote,
      itemProfile: { model },
    } = item;

    return (
      <List key={item.id}>
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="No. de conteneur"
            secondary={ciWebLabelcode || "Information manquante"}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="No. de puce"
            secondary={ciWebChipcode || "Information manquante"}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="Modèle"
            secondary={model || "Information manquante"}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="Statut"
            secondary={itemStatuses[ciWebStatus] || "Information manquante"}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="Alias"
            secondary={alias || "Information manquante"}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="Note de conteneur"
            secondary={memo || "Information manquante"}
            classes={{
              secondary: css`
                text-align: "justify";
                word-break: "break-all";
              `,
            }}
          />
        </ListItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <ListItem
          sx={{ paddingLeft: "40px", paddingBottom: "0px" }}
          alignItems="flex-start"
        >
          <ListItemText
            primary="Note de l'emplacement"
            secondary={locationNote || "Information manquante"}
            classes={{
              secondary: css`
                text-align: "justify";
                word-break: "break-all";
              `,
            }}
          />
        </ListItem>
      </List>
    );
  });
};
