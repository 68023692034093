import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DefaultError } from "@tanstack/react-query/build/modern/index";
import locationCcavContainersKeys from "@hooks/containers/location-ccav-containers.keys";
import deleteLocationContainer from "../../services/containers/delete-location-container.service";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";

export type UseLocationContainerDelete = {
  mutate: (container: LocationCcavContainer) => Promise<void>;
  isPending: boolean;
};

const useDeleteLocationContainer = (locationId: number): UseLocationContainerDelete => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, DefaultError, number>({
    mutationFn: (containerId: number): Promise<void> => deleteLocationContainer(containerId),
    onSuccess: async (_data) => {
      // Invalidate queries to reflect the changes
      await queryClient.invalidateQueries({
        queryKey: locationCcavContainersKeys.detail(locationId),
        exact: false,
      });
    },
  });

  const mutate = async (container: LocationCcavContainer): Promise<void> => {
    const response = await mutation.mutateAsync(container.id);
    return response;
  };

  return { mutate, isPending: mutation.isPending };
};

export default useDeleteLocationContainer;
