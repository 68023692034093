import { Typography } from "@mui/material";
import { withInterruption } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import FormGroupWrapper from "./ui/FormGroupWrapper";
import PageContainer from "./ui/PageContainer";
import PaperWrapper from "./ui/PaperWrapper";

const Wrapper = styled.div`
  padding: 5px 25px 25px 25px;
`;

const ContentWrapper = styled.div`
  margin: 20px 0;
`;

const Photo = styled.img`
  display: inherit;
  width: 100%;
  height: auto;
  max-width: 640px;
  margin: 0 auto;
`;

const PhotoWrapper = styled.div`
  background-color: #f3f3f3;
`;

class Interruption extends PureComponent {
  componentDidMount() {
    this.fetchInterruption();
  }

  fetchInterruption = () => {
    const { fetchJobInterruption, router } = this.props;

    fetchJobInterruption(router.params.jobId);
  };

  renderPhotos = (photos) => {
    if (photos?.length) {
      return (
        <>
          {photos.map((photo, index) => (
            <PhotoWrapper key={index}>
              <Photo src={photo} />
            </PhotoWrapper>
          ))}
        </>
      );
    } else {
      return (
        <Typography
          variante="body1"
          color="textSecondary"
        >
          Ce cas problème n'a aucune photo.
        </Typography>
      );
    }
  };

  renderInterruption = () => {
    const { interruption } = this.props;
    const { id, comment, photos, description } = interruption;

    if (!id) {
      return null;
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <FormGroupWrapper>
            <Typography
              variante="body1"
              color="textSecondary"
            >
              Raison
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </FormGroupWrapper>

          <FormGroupWrapper>
            <Typography
              variante="body1"
              color="textSecondary"
            >
              Commentaire du technicien
            </Typography>
            <Typography variant="body1">{comment}</Typography>
          </FormGroupWrapper>

          <>
            <Typography
              variante="body1"
              color="textSecondary"
            >
              Photo
            </Typography>
            {this.renderPhotos(photos)}
          </>
        </ContentWrapper>
      </Wrapper>
    );
  };

  render() {
    return (
      <PageContainer>
        <PaperWrapper>{this.renderInterruption()}</PaperWrapper>
      </PageContainer>
    );
  }
}

Interruption.propTypes = {
  fetchJobInterruption: PropTypes.func.isRequired,
  interruption: PropTypes.object.isRequired,
};

export default withInterruption(Interruption);
