const setQuickJobCurrentContract = (setQuickJob) => (contract) => {
  setQuickJob((prevState) => ({
    ...prevState,
    current: {
      ...prevState.current,
      contract,
    },
  }));
};

const setQuickJobCurrentCustomer = (setQuickJob) => (customer) => {
  setQuickJob((prevState) => ({
    ...prevState,
    current: {
      ...prevState.current,
      customer,
    },
  }));
};

const setCurrentCustomer = (setCustomer) => (customer) => {
  setCustomer((prevState) => ({
    ...prevState,
    data: customer,
  }));
};

const QuickJob = {
  setQuickJobCurrentContract,
  setQuickJobCurrentCustomer,
};

const Customer = {
  setCurrentCustomer,
};

export default { QuickJob, Customer };
