import React, { FC } from "react";
import { Badge, IconButton, TableCell, TableRow } from "@mui/material";
import LocationCcavContainer from "@@types/containers/location-ccav-container.type";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";

type LocationCcavContainersListBodyProps = {
  containers: LocationCcavContainer[];
  isFromCiWeb: boolean;
  handleOpenModal: (modalType: string, container: LocationCcavContainer) => void;
};

const LocationCcavContainersListBody: FC<LocationCcavContainersListBodyProps> = ({
  containers,
  isFromCiWeb,
  handleOpenModal,
}) => {
  return containers.map((container) => {
    return (
      <TableRow key={container.id}>
        <TableCell>{container.ciWebLabelcode || "INCONNUE"}</TableCell>
        <TableCell>{container.ciWebChipcode || "INCONNUE"}</TableCell>
        <TableCell>{container.model}</TableCell>
        <TableCell>{container.volume}</TableCell>
        <TableCell>{container.matterType}</TableCell>
        <TableCell>
          <Badge
            sx={{
              "background-color": container.ciWebStatus === "active" ? "green" : "red",
              "border-radius": "14px",
              padding: "4px 8px",
              color: "white",
            }}
          >
            {container.ciWebStatus || "INCONNUE"}
          </Badge>
        </TableCell>

        <TableCell>
          {container.memo && container.memo.length > 10 ? `${container.memo.substring(0, 10)}...` : container.memo}
        </TableCell>

        <TableCell>
          <IconButton
            color="secondary"
            onClick={() => handleOpenModal("liftingRequests", container)}
            size="large"
          >
            <ListIcon fontSize="medium" />
          </IconButton>
        </TableCell>

        <TableCell>
          <IconButton
            color="secondary"
            onClick={() => handleOpenModal("edit", container)}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            disabled={isFromCiWeb}
            color="secondary"
            onClick={() => handleOpenModal("delete", container)}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
};

export default LocationCcavContainersListBody;
