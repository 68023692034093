// React imports
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, useReducer } from "react";

// Internal imports
import * as API from "@services";
import useNotifier from "@hooks/useNotifier";
import CustomerActions from "@context/customer/CustomerActions";
import useLogStateChange from "@hooks/useLogStateChange";

// External iports
import { includes, map, forEach, every } from "lodash";
import { isArrayTruthy } from "@utils/common";

/**
 * Components can consume the context with `useCustomerContext()`.
 */
export const CustomerContext = createContext(null);

/**
 * Hook for using the CustomerContext, avoids having to import useContext along with CustomerContext.
 * @returns CustomerContext
 */
export const useCustomerContext = () => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error("[useCustomerContext] Context was used outside of its provider.");
  }

  return context;
};

const initialCustomerDataState = {
  name: "",
  email: "",
  principalPhone: "",
  billingPhone: "",
  tribeNumber: "",
  doorNo: "",
  postalCode: "",
  adr1: "",
  adr2: "",
  city: "",
  province: "QC",
};

const initialCustomerErrorsState = {
  name: false,
  email: false,
  principalPhone: false,
  doorNo: false,
  postalCode: false,
  adr1: false,
  city: false,
  province: false,
};

const initialCustomerSaleDetailsState = {
  contractID: null,
  saleType: { id: "", name: "", error: false },
  planType: { id: "", name: "", error: false },
  paymentMode: { id: "", name: "", error: false },
  subContractDetails: {
    rollOff: { id: "rollOff", key: "ROLL_OFF", value: "", error: false, checked: false },
    mobileFloor: { id: "mobileFloor", key: "MOBILE_FLOOR", value: "", error: false, checked: false },
    ccav: { id: "ccav", key: "CCAV", value: "", error: false, checked: false },
  },
};

const initialCustomerStripeState = {
  data: null, // Customer Stripe
  setupIntent: null,
  paymentIntent: {
    id: "",
    data: null,
    status: "", // success, error, pending
    error: "",
  },
  paymentMethods: null,
  selectedPaymentMethod: null,
};

const initialCustomerState = {
  data: initialCustomerDataState,
  errorsField: initialCustomerErrorsState,
  purchases: null,
  currentlyOpenModal: "",
  saleDetails: initialCustomerSaleDetailsState,
  stripe: initialCustomerStripeState,
};

const validateCustomerForm = (fields, errors, setErrors) => {
  const updatedErrors = { ...errors };

  forEach(fields, (value, key) => {
    if (errors.hasOwnProperty(key) && !value) {
      updatedErrors[key] = true;
    }
  });

  const canadianPostalCode = new RegExp(
    /[abceghjklmnprstvxy][0-9][abceghjklmnprstvwxyz]\s?[0-9][abceghjklmnprstvwxyz][0-9]/i
  );

  if (!canadianPostalCode.test(fields.postalCode)) {
    updatedErrors.postalCode = true;
  }

  const phoneNumber = fields.principalPhone?.replace(/\D/g, "");

  if (phoneNumber.length > 11 || phoneNumber.length < 10) {
    updatedErrors.principalPhone = true;
  }

  if (!fields.email.includes("@")) {
    updatedErrors.email = true;
  }

  setErrors(updatedErrors);

  // Check that the updatedErrors does not include a single true (error)
  const isFormValid = every(updatedErrors, (error) => !error);

  return isFormValid;
};

const validateSaleDetails = (saleDetails, updateCustomerSaleDetails, dispatch) => {
  const errors = map(saleDetails, (saleDetail, key) => {
    // contractID is just the id of the contract, so no select
    if (key === "contractID" || key === "planType") {
      return false;
    }

    // check if sale type private collection is selected
    // if so and all 3 plan type selects are empty, invalidSubContractDetails become truthy
    const isNoSubContractDetailsSelected =
      key === "subContractDetails" && saleDetails.saleType.id === "PRIVATE_COLLECTION"
        ? !includes(
            Object.values(saleDetail).map((t) => t.value === ""),
            false
          )
        : false;

    if (isNoSubContractDetailsSelected) {
      dispatch({ type: "error", id: "rollOff" });
      dispatch({ type: "error", id: "mobileFloor" });
      dispatch({ type: "error", id: "ccav" });
    }

    const subContractDetailsError =
      key === "subContractDetails"
        ? Object.values(saleDetail).map((t) => {
            if (t.value === "" && t.checked === true) {
              dispatch({ type: "error", id: t.id });
              return true;
            }
          })
        : false;

    // If the select id is falsy (nothing selected)
    // and it's any sale type except private collection, update its error property
    if (key !== "subContractDetails" && !saleDetail.id) {
      updateCustomerSaleDetails(key, { id: saleDetail.id, name: saleDetail.name, error: true });
    }

    return key === "subContractDetails"
      ? isNoSubContractDetailsSelected
        ? isNoSubContractDetailsSelected
        : includes(subContractDetailsError, true)
      : saleDetail.id === "";
  });
  // Check that the errors does not include a single true (error)
  let isSaleDetailsValid = !includes(errors, true);

  return isSaleDetailsValid;
};

function reducerSubContractDetails(state, action) {
  switch (action.type) {
    case "checked": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          checked: !state[action.id].checked,
          value: "", // initialState
          error: false,
        },
      };
    }
    case "change_plan_type": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.event,
          error: false,
        },
      };
    }
    case "error": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          error: true,
        },
      };
    }
    case "reset": {
      return { ...action.state };
    }
    default: {
      return console.warn("[reducerSubContractDetails] error:");
    }
  }
}

const handleSubmitCustomerDetails =
  (
    customer,
    handleToggleModalCustomer,
    setIsLoading,
    fields,
    setErrors,
    errors,
    setTabValue,
    updateCustomerSaleDetails,
    notifier,
    dispatch
  ) =>
  async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { data, saleDetails } = customer;

    const isFormValid = validateCustomerForm(fields, errors, setErrors);

    const isSaleDetailsValid = validateSaleDetails(saleDetails, updateCustomerSaleDetails, dispatch);

    if (!isFormValid) {
      // Go back to the first CustomerForm tab
      setTabValue(0);
    }

    // If we want to switch to CustomerDetails when we click 'sauvegarder' from CustomerForm
    if (!isSaleDetailsValid) {
      // setTabValue(1);
    }
    if (isFormValid && isSaleDetailsValid) {
      const array = [];

      // create array of key: value for sub contract details if private collection selected
      if (!saleDetails.planType.id) {
        Object.values(saleDetails.subContractDetails).map((t) => {
          if (t.checked && t.value) {
            array.push({ [t.key]: t.value });
          }
        });
      }

      const res = await API.Customer.prepareCustomerForWork(
        data.id,
        saleDetails.saleType.id,
        isArrayTruthy(array) ? array : saleDetails.planType.id,
        saleDetails.paymentMode.id,
        saleDetails.contractID
      );

      // Show the messages to the user
      res.status === "failure" && notifier.enqueueMessages(res.messages, "error");

      // Close the modal if the contract and addresses were properly created
      res.status === "success" && handleToggleModalCustomer("");
    }

    setIsLoading(false);
  };

/**
 * Context provider that wraps its children to provide them with the context.
 * Ideally, should only be used when needed, ideally inside the route element.
 *
 * Can be consumed with `useCustomerContext()`.
 * @returns Customer context provider with its context values.
 */
export default function CustomerContextProvider({ children }) {
  const notifier = useNotifier();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(initialCustomerState);
  const [errors, setErrors] = useState(initialCustomerState.errorsField);
  const [tabValue, setTabValue] = useState(0);
  const [fields, setFields] = useState(initialCustomerState.data);

  const [subContractDetails, dispatch] = useReducer(reducerSubContractDetails, customer.saleDetails.subContractDetails);

  const setSingleError = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setErrors((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setErrors((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  // useLogStateChange("CustomerContextProvider >> customer", customer);

  useEffect(() => {
    return () => {
      // Whenever we close the new or edit modals, reset the customer state
      if (customer.currentlyOpenModal) {
        setTabValue(0);
        setIsLoading(false);
        setCustomer(initialCustomerState);
        setFields(initialCustomerDataState);
        setErrors(initialCustomerErrorsState);
      }
    };
  }, [customer.currentlyOpenModal]);

  useEffect(() => {
    updateCustomerSaleDetails("subContractDetails", subContractDetails);
  }, [subContractDetails]);

  const handleToggleModalCustomer = useCallback((modal) => {
    setCustomer((prevState) => ({
      ...prevState,
      currentlyOpenModal: modal,
    }));
  }, []);

  const updateCustomerSaleDetails = useCallback((key, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      saleDetails: {
        ...prevState.saleDetails,
        [key]: value,
      },
    }));
  }, []);

  /**
   * Handler function to update customer state, but just a specific property or a nested property
   */
  const setSingleProperty = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setCustomer((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setCustomer((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  const handlers = useMemo(
    () => ({
      dispatch,
      setFields,
      setTabValue,
      setErrors,
      setIsLoading,
      setCustomer,
      setSingleError,
      updateCustomerSaleDetails,
      handleToggleModalCustomer,
      handleSubmitCustomerDetails: handleSubmitCustomerDetails(
        customer,
        handleToggleModalCustomer,
        setIsLoading,
        fields,
        setErrors,
        errors,
        setTabValue,
        updateCustomerSaleDetails,
        notifier,
        dispatch
      ),
      setCurrentCustomer: CustomerActions.Customer.setCurrentCustomer(setCustomer),
      setSingleProperty,
      validateCustomerForm: () => validateCustomerForm(fields, errors, setErrors),
      validateSaleDetails: () => validateSaleDetails(customer.saleDetails, updateCustomerSaleDetails, dispatch),
    }),
    [tabValue, fields, customer, isLoading, errors]
  );

  const contextValue = useMemo(
    () => ({
      fields,
      tabValue,
      errors,
      isLoading,
      customer,
      handlers,
    }),
    [tabValue, fields, customer, isLoading, errors]
  );

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>;
}
