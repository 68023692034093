import { formattedPhoneNumber } from "@utils/phoneNumber";

const mappedPaymentIntentData = (data) => {
  const {
    customer: stripeCustomerID,
    receipt_email,
    shipping: {
      name,
      phone,
      address: { city, country, line1, line2, postal_code, state },
    },
    metadata: { Products },
  } = data;

  return {
    email: receipt_email,
    name: name,
    principalPhone: formattedPhoneNumber(phone),
    doorNo: line1.match(/\d+/g)[0],
    postalCode: postal_code,
    adr1: line1.replace(/\d+/g, "").trim(),
    adr2: line2,
    city: city,
    province: state,
  };
};

const mappedCustomerFields = (fields) => {
  return {
    adr_1: fields.adr1,
    adr_2: fields.adr2,
    principal_phone: fields.principalPhone,
    billing_phone: fields.billingPhone,
    tribe_number: fields.tribeNumber ? Number(fields.tribeNumber) : null,
    city: fields.city,
    door_no: fields.doorNo,
    email: fields.email,
    name: fields.name,
    postal_code: fields.postalCode,
    province: fields.province,
  };
};

const mappedBackendCustomerFields = (customer) => {
  return {
    name: customer.name,
    email: customer.email,
    principalPhone: formattedPhoneNumber(customer.principalPhone),
    billingPhone: formattedPhoneNumber(customer.billingPhone),
    doorNo: customer.doorNo,
    postalCode: customer.postalCode,
    adr1: customer.adr1,
    adr2: customer.adr2,
    city: customer.city,
    province: customer.province,
    tribeNumber: customer.tribeNumber,
  };
};

export default {
  mappedPaymentIntentData,
  mappedCustomerFields,
  mappedBackendCustomerFields,
};
