import axios from "axios";

import { requestOptions } from "../auth";

const createItem = async (payload) => {
  try {
    const res = await axios.post(`${import.meta.env.VITE_API_URL}/items`, payload, requestOptions());

    return res.data;
  } catch (error) {
    console.warn("[API.createItem] createItem >> error:", error);
    return error.response.data;
  }
};

const fetchItemsList = async (filter = "", limit = 10, page = 1) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/items.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data.data;
  } catch (error) {
    console.warn("[API.fetchItemsList] Error:", error);
  }
};

const fetchAvailableItemsList = async (filter = "", limit = 10, page = 1) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_API_URL}/items/available_list.json?page=${page}&limit=${limit}&filter=${filter}`,
      requestOptions()
    );

    return response.data.data;
  } catch (error) {
    console.warn("[API.fetchAvailableItemsList] Error:", error);
  }
};

/**
 * Report api service for exposing api endpoints.
 *
 * Available requests:
 * - `fetchItemsList`,
 * - `fetchAvailableItemsList`,
 * - `createItem`,
 */
export { fetchItemsList, fetchAvailableItemsList, createItem };
