import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { LiftingRequestResponse } from "@@types/lifting-requests/lifting-request-response.type";
import mapResponseToLiftingRequests from "@@types/lifting-requests/mappers/mapResponseToLiftingRequest.mapper";
import liftingRequest from "@hooks/lifting-requests/lifting-request.keys";
import fetchContainerLiftingRequestsFuture from "../../services/lifting-requests/fetch-container-lifting-requests-future.service";
import TableFilterStates from "@@types/common/table-filter-states.type";

export type LiftingRequests = {
  data: LiftingRequestResponse[];
  count: number;
};

const useFetchContainerLiftingRequestsFuture = (
  containerId: number,
  filterStates: TableFilterStates,
  registrationCategories: string[],
  liftingTypes: string[]
): UseQueryResult<LiftingRequests> => {
  return useQuery({
    queryKey: [
      liftingRequest.timeLapse(containerId, "future"),
      liftingRequest.list({ filterStates, registrationCategories, liftingTypes }),
    ],
    queryFn: () => fetchContainerLiftingRequestsFuture(containerId, filterStates, registrationCategories, liftingTypes),
    select: ({ data: response, count }): LiftingRequests => ({
      data: mapResponseToLiftingRequests(response || []),
      count,
    }),
    enabled: containerId !== 0,
  });
};

export default useFetchContainerLiftingRequestsFuture;
