// React imports
import React, { memo, useEffect, useReducer, useState } from "react";

// External iports
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack } from "@mui/material";

export default function CustomerPrivateCollectionDetails({
  subContractDetails,
  saleType,
  isLoading,
  selectsPlanTypes,
  isUserAccounting,
  SelectItems,
  handlers,
}) {
  return (
    <Stack spacing={3} width="100%" alignContent="space-around">
      <Stack direction="row">
        {/* ROLL OFF */}
        <Stack direction="row" sx={{ width: "100%" }} alignItems="center" justifyContent="flex-start">
          <Checkbox
            checked={subContractDetails.rollOff?.checked}
            onClick={() => {
              handlers.dispatch({ type: "checked", id: "rollOff" });
            }}
          />
          <span>Roll-off</span>
        </Stack>
        <Stack alignItems="flex-end" width={"100%"}>
          <FormControl fullWidth error={subContractDetails.rollOff?.error}>
            <InputLabel shrink>Type de plan</InputLabel>
            <Select
              sx={{
                "&.MuiInputBase-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: subContractDetails.rollOff?.error ? "red" : "",
                  },
                },
              }}
              name="planType"
              notched
              label="Type de plan"
              disabled={!saleType.id || isLoading || !subContractDetails.rollOff?.checked}
              value={subContractDetails.rollOff?.value || ""}
              onChange={(event) => {
                handlers.dispatch({ type: "change_plan_type", id: "rollOff", event: event.target.value });
              }}
            >
              {SelectItems(selectsPlanTypes.rollOff, isUserAccounting)}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      {/* PLANCHER MOBILE */}
      <Stack direction="row">
        <Stack direction="row" sx={{ width: "100%" }} alignItems="center" justifyContent="flex-start">
          <Checkbox
            checked={subContractDetails.mobileFloor?.checked}
            onClick={() => {
              handlers.dispatch({ type: "checked", id: "mobileFloor" });
            }}
          />
          <span>Plancher mobile</span>
        </Stack>

        <Stack alignItems="flex-end" width={"100%"}>
          <FormControl fullWidth error={subContractDetails.mobileFloor?.error}>
            <InputLabel shrink>Type de plan</InputLabel>
            <Select
              sx={{
                "&.MuiInputBase-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: subContractDetails.mobileFloor?.error ? "red" : "",
                  },
                },
              }}
              name="planType"
              notched
              label="Type de plan"
              disabled={!saleType.id || isLoading || !subContractDetails.mobileFloor?.checked}
              value={subContractDetails.mobileFloor?.value || ""}
              onChange={(event) => {
                handlers.dispatch({ type: "change_plan_type", id: "mobileFloor", event: event.target.value });
              }}
            >
              {SelectItems(selectsPlanTypes.mobileFloor, isUserAccounting)}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      {/* CCAV */}
      <Stack direction="row">
        <Stack direction="row" sx={{ width: "100%" }} alignItems="center" justifyContent="flex-start">
          <Checkbox
            checked={subContractDetails.ccav?.checked}
            onClick={() => {
              handlers.dispatch({ type: "checked", id: "ccav" });
            }}
          />
          <span>Chargement avant</span>
        </Stack>

        <Stack alignItems="flex-end" width={"100%"}>
          <FormControl fullWidth error={subContractDetails.ccav?.error}>
            <InputLabel shrink>Type de plan</InputLabel>
            <Select
              sx={{
                "&.MuiInputBase-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: subContractDetails.ccav?.error ? "red" : "",
                  },
                },
              }}
              name="planType"
              notched
              label="Type de plan"
              disabled={!saleType.id || isLoading || !subContractDetails.ccav?.checked}
              value={subContractDetails.ccav?.value || ""}
              onChange={(event) => {
                handlers.dispatch({ type: "change_plan_type", id: "ccav", event: event.target.value });
              }}
            >
              {SelectItems(selectsPlanTypes.ccav, isUserAccounting)}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
}
