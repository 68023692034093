import React from "react";
import { orderBy } from "lodash";
import { Trans } from "react-i18next";

export const getErrorMessage = function getErrorMessage(name, type = "required", customMessage = null) {
  const messageType = type === "required" ? "blank" : "invalid";

  if (customMessage) {
    return <Trans i18nKey={customMessage} />;
  }
  if (this.state.errors[name]) {
    return <Trans i18nKey={`errors.${messageType}`} />;
  }

  return "";
};

export const handleChangeFields = function handleChangeFields(name) {
  return ({ target }) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: false,
      },
      [name]: target.value,
    });
  };
};

export const renderAutocompleteList = (data) =>
  orderBy(
    data.map(({ id, name }) => ({ value: id, label: name })),
    ["label"],
    ["asc"]
  );
