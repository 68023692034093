import { Badge, Icon, IconButton, TextField } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";
import styled from "@emotion/styled";

import theme from "@styles/theme";
import InlineDatePickerWrapper from "@ui/InlineDatePickerWrapper";
import Tooltip from "@ui/Tooltip";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  > * {
    margin-left: 10px;
  }
`;

class ActionBar extends React.Component {
  changeDateCalendar = (step) => {
    const { currentDate, changeCurrentDate } = this.props;
    let dateToChange = null;

    if (step === "backward") {
      dateToChange = moment(moment(currentDate).add(-1, "d").format("YYYY-MM-DD"));
    } else {
      dateToChange = moment(moment(currentDate).add(1, "d").format("YYYY-MM-DD"));
    }
    changeCurrentDate(dateToChange);
  };

  renderUnpublished = (unpublishedCount) => {
    const icon = <Icon color="secondary">tap_and_play</Icon>;

    if (!unpublishedCount) {
      return icon;
    }

    return (
      <Badge id="cpbr-unpublished-all" badgeContent={unpublishedCount} max={1000} color="secondary">
        {icon}
      </Badge>
    );
  };

  render() {
    const { changeCurrentDate, currentDate, refreshJobs, unpublishedCount } = this.props;

    return (
      <Wrapper>
        <IconButton onClick={() => this.changeDateCalendar("backward")} size="large" color="secondary">
          <Icon>arrow_back</Icon>
        </IconButton>
        <InlineDatePickerWrapper
          id="cpbr-date"
          format="LL"
          keyboard
          label="Date"
          value={moment(currentDate)}
          onChange={changeCurrentDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                svg: { color: theme.colors.palette.secondary.main },
              }}
            />
          )}
        />
        <IconButton onClick={() => this.changeDateCalendar("")} size="large" color="secondary">
          <Icon>arrow_forward</Icon>
        </IconButton>

        <Tooltip title={<Trans i18nKey="refresh" />} placement="top">
          <IconButton id="cpbr-refresh" onClick={refreshJobs} size="large" color="secondary">
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title={<Trans i18nKey="dispatch" />} placement="top">
          <IconButton onClick={this.props.publishJobs} size="large" color="secondary">
            {this.renderUnpublished(unpublishedCount)}
          </IconButton>
        </Tooltip>
      </Wrapper>
    );
  }
}

ActionBar.propTypes = {
  changeCurrentDate: PropTypes.func.isRequired,
  currentDate: PropTypes.string.isRequired,
  publishJobs: PropTypes.func.isRequired,
  refreshJobs: PropTypes.func.isRequired,
  unpublishedCount: PropTypes.number.isRequired,
};

export default ActionBar;
