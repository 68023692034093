import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import ContainerProfileResponse from "@@types/container-profile/container-profile-response.type";

const fetchAvailableCcavContainerModels = async (
  locationId: number
): Promise<ApiResponse<ContainerProfileResponse[]>> => {
  try {
    const res = await http.get<ApiResponse<ContainerProfileResponse[]>>(
      `/container_profiles/available_ccav_container_models?location_id=${locationId}`
    );

    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchAvailableCcavContainerModels;
