import React, { ReactElement } from "react";
import { Button } from "@ui/button/Button";
import Icon from "@components/icons/Icon";

export interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton = ({ onClose }: CloseButtonProps): ReactElement => (
  <Button
    sx={{ justifyContent: "flex-end", margin: "20px 20px -30px 0px", width: "97%" }}
    onClick={onClose}
    variant="text"
    isIcon
  >
    <Icon
      name="close"
      size="small"
    />
  </Button>
);

export default CloseButton;
