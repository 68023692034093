import React from "react";
import { connect } from "react-redux";

const mapStateToProps = ({ user }) => ({
  user,
});

/**
 * Redux user wrapper.
 */
const withUser = (WrappedComponent) => {
  const Component = (props) => <WrappedComponent {...props} />;

  return connect(mapStateToProps)(Component);
};

export default withUser;
